define("ember-keyboard/mixins/activate-keyboard-on-insert", ["exports", "@ember/object/mixin", "@ember/object/evented", "@ember/object", "@ember/debug"], function (_exports, _mixin, _evented, _object, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-new-mixins */
  var _default = _exports.default = _mixin.default.create({
    init(...args) {
      (false && !(false) && (0, _debug.deprecate)('`EKOnInsertMixin` of ember-keyboard is deprecated. You can achieve this behavior by using the `on-key` helper, or by using `@keyResponder` in conjunction with a `did-insert` modifier.', false, {
        id: 'ember-keyboard.activate-keyboard-on-insert-mixin',
        for: 'ember-keyboard',
        since: '6.0.2',
        until: '7.0.0',
        url: 'https://adopted-ember-addons.github.io/ember-keyboard/deprecations#activate-keyboard-on-insert-mixin'
      }));
      return this._super(...args);
    },
    activateKeyboardWhenPresent: (0, _evented.on)('didInsertElement', function () {
      (0, _object.set)(this, 'keyboardActivated', true);
    })
  });
});
define("ember-contextual-table/components/dt-column-header", ["exports", "ember-contextual-table/templates/dt-column-header"], function (_exports, _dtColumnHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _dtColumnHeader.default,
    tagName: 'th',
    classNames: ['contextual-header-cell']
  });
});
define("ember-contextual-table/components/dt-selection-column-footer", ["exports", "ember-contextual-table/templates/dt-selection-column-footer"], function (_exports, _dtSelectionColumnFooter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _dtSelectionColumnFooter.default,
    tagName: 'td',
    classNames: ['contextual-selection-footer-cell'],
    actions: {
      change: function (checked) {
        let actionName = checked ? 'selected' : 'deselected';
        let action = this.get(actionName) || function () {};
        action();
      }
    }
  });
});
define("ember-contextual-table/templates/dt-column-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "tw8f3kM7",
    "block": "[[[41,[48,[30,1]],[[[1,\"  \"],[18,1,[[28,[37,3],null,[[\"row\",\"rowIndex\",\"body\"],[[33,4],[33,5],true]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[28,[35,6],[[33,4],[33,7]],null]],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"hash\",\"row\",\"rowIndex\",\"get\",\"propertyName\"]]",
    "moduleName": "ember-contextual-table/templates/dt-column-cell.hbs",
    "isStrictMode": false
  });
});
define("ember-contextual-table/templates/dt-selection-column-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Egh4JxRU",
    "block": "[[[41,[49,[30,1]],[[[1,\"  \"],[18,1,[[28,[37,3],null,[[\"change\",\"isSelected\",\"header\"],[[28,[37,4],[[30,0],\"change\"],null],[28,[37,5],[[33,6]],null],true]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[28,[35,7],null,[[\"type\",\"change\",\"checked\"],[\"checkbox\",[28,[37,4],[[30,0],\"change\"],[[\"value\"],[\"target.checked\"]]],[28,[37,5],[[33,6]],null]]]]],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"has-block-params\",\"yield\",\"hash\",\"action\",\"readonly\",\"isSelected\",\"input\"]]",
    "moduleName": "ember-contextual-table/templates/dt-selection-column-header.hbs",
    "isStrictMode": false
  });
});
define("ember-contextual-table/components/data-filterer", ["exports", "ember-contextual-table/templates/data-filterer", "ember-contextual-table/util/diactricts"], function (_exports, _dataFilterer, _diactricts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function createFieldFilter(fieldName, filter) {
    return {
      fieldName: fieldName,
      filter: filter
    };
  }
  function internalFilterer(data, filterFields) {
    if (Ember.isEmpty(filterFields)) {
      return data;
    }
    return data.filter(function (item) {
      return itemFilterer(item, filterFields);
    });
  }
  function itemFilterer(item, filterFields) {
    let satisfiedFilters = filterFields.filter(filterField => {
      let fieldName = Ember.get(filterField, 'fieldName');
      let filter = Ember.get(filterField, 'filter');
      return (0, _diactricts.diacriticsInsensitiveMatcher)(Ember.get(item, fieldName), filter);
    });
    return satisfiedFilters.length === filterFields.length;
  }
  var _default = _exports.default = Ember.Component.extend({
    layout: _dataFilterer.default,
    tagName: '',
    init() {
      this._super(...arguments);
      if (Ember.isEmpty(this.get('fieldFilters'))) {
        this.set('fieldFilters', Ember.A());
      }
    },
    filteredData: Ember.computed('data.[]', 'filterer', 'fieldFilters.[]', function () {
      if (Ember.isEmpty(this.get('data'))) {
        return [];
      }
      let filterer = this.get('filterer') || internalFilterer;
      return filterer(this.get('data').slice(), this.get('fieldFilters'));
    }),
    actions: {
      onfilterfieldupdated: function (fieldName, filter) {
        let remainingFilters = this.get('fieldFilters').filter(function (item) {
          return Ember.get(item, 'fieldName') !== fieldName;
        });
        if (!Ember.isEmpty(filter)) {
          remainingFilters.push(createFieldFilter(fieldName, filter));
        }
        this.set('fieldFilters', remainingFilters);
      }
    }
  });
});
define("ember-contextual-table/components/pager-component", ["exports", "ember-contextual-table/templates/pager-component"], function (_exports, _pagerComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _pagerComponent.default,
    classNames: ['contextual-pager-component'],
    previousDisabled: Ember.computed('currentPage', function () {
      return this.get('currentPage') <= 1;
    }),
    previousButtonClass: Ember.computed('previousDisabled', function () {
      return this.get('previousDisabled') ? 'disabled' : '';
    }),
    nextButtonClass: Ember.computed('nextDisabled', function () {
      return this.get('nextDisabled') ? 'disabled' : '';
    }),
    firstLabel: '<<',
    lastLabel: '>>',
    previousLabel: '<',
    nextLabel: '>',
    actions: {
      previous: function (e) {
        e.preventDefault();
        if (this.get('previousDisabled')) {
          return;
        }
        (this.get('previous') || function () {})();
      },
      next: function (e) {
        e.preventDefault();
        if (this.get('nextDisabled')) {
          return;
        }
        (this.get('next') || function () {})();
      },
      first(e) {
        e.preventDefault();
        if (this.get('previousDisabled')) {
          return;
        }
        (this.get('first') || function () {})();
      },
      last(e) {
        e.preventDefault();
        if (this.get('nextDisabled')) {
          return;
        }
        (this.get('last') || function () {})();
      }
    }
  });
});
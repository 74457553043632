define("ember-contextual-table/components/dt-column-footer", ["exports", "ember-contextual-table/templates/dt-column-footer"], function (_exports, _dtColumnFooter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _dtColumnFooter.default,
    tagName: 'td',
    classNames: ['contextual-footer-cell']
  });
});
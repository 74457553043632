define("ember-contextual-table/mixins/filterable-column", ["exports", "ember-contextual-table/templates/dt-filterable-column"], function (_exports, _dtFilterableColumn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    layout: _dtFilterableColumn.default,
    filterValueChanged: Ember.observer('filterValue', function () {
      let actionHandler = this.get('filterinformationupdated') || function () {};
      actionHandler(this.get('propertyName'), this.get('filterValue'));
    }),
    actions: {
      onfilterupdate: function (filterValue) {
        this.set('filterValue', filterValue);
      }
    }
  });
});
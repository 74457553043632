define("ember-keyboard/utils/trigger-event", ["exports", "ember-keyboard/utils/keyboard-listener"], function (_exports, _keyboardListener) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.triggerKeyUp = _exports.triggerKeyPress = _exports.triggerKeyDown = void 0;
  const triggerKeyEvent = function triggerKeyEvent(eventType, keyCombo, element) {
    let keyboardListener = _keyboardListener.default.parse(`${eventType}:${keyCombo}`);
    const event = keyboardListener.createMatchingKeyboardEvent();
    element.dispatchEvent(event);
  };
  const triggerKeyDown = _exports.triggerKeyDown = function triggerKeyDown(keyCombo, element = document) {
    triggerKeyEvent('keydown', keyCombo, element);
  };
  const triggerKeyPress = _exports.triggerKeyPress = function triggerKeyPress(keyCombo, element = document) {
    triggerKeyEvent('keypress', keyCombo, element);
  };
  const triggerKeyUp = _exports.triggerKeyUp = function triggerKeyUp(keyCombo, element = document) {
    triggerKeyEvent('keyup', keyCombo, element);
  };
});
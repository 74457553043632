define("ember-contextual-table/templates/dt-filterable-column", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "+Lp2vg/4",
    "block": "[[[41,[52,[48,[30,1]],[52,[33,2],false,true],false],[[[1,\"  \"],[18,1,[[28,[37,4],null,[[\"onfilterupdate\",\"footer\",\"header\"],[[28,[37,5],[[30,0],\"onfilterupdate\"],null],[28,[37,6],[[33,7]],null],[28,[37,6],[[33,8]],null]]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[28,[35,9],null,[[\"value\",\"placeholder\"],[[33,10],[33,11]]]]],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"defaultHeader\",\"yield\",\"hash\",\"action\",\"readonly\",\"footer\",\"header\",\"input\",\"filterValue\",\"placeholder\"]]",
    "moduleName": "ember-contextual-table/templates/dt-filterable-column.hbs",
    "isStrictMode": false
  });
});
define("ember-leaflet/components/point-path-layer", ["exports", "ember-leaflet/components/path-layer"], function (_exports, _pathLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  /**
   * An abstract class that contains options and constants shared between point-based path layers (Circle, CircleMarker). Do not use it directly.
   *
   * @class PointPathLayer
   * @extends ember-leaflet/components/path-layer~PathLayer
   */
  class PointPathLayer extends _pathLayer.default {
    constructor(...args) {
      super(...args);
      /**
       * The latitude where the point layer will be placed at.
       * You can provide a `@location` instead of `@lat` and `@lng`.
       *
       * @argument lat
       * @type {Number}
       */
      /**
       * The longitude where the point layer will be placed at.
       * You can provide a `@location` instead of `@lat` and `@lng`.
       *
       * @argument lng
       * @type {Number}
       */
      _defineProperty(this, "leafletRequiredOptions", [...this.leafletRequiredOptions,
      /**
       * A geographical point where the point layer will be placed at.
       * This is an alternative to providing `@lat` and `@lng`.
       *
       * @argument location
       * @type {LatLng}
       */
      'location']);
      _defineProperty(this, "leafletDescriptors", [...this.leafletDescriptors, 'location:setLatLng']);
    }
    get location() {
      if (this.args.location) {
        return this.args.location;
      } else {
        let [lat, lng] = [this.args.lat, this.args.lng];
        return this.L.latLng(lat, lng);
      }
    }
  }
  _exports.default = PointPathLayer;
});
define("ember-contextual-table/templates/empty-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Dsjuqgpr",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,1],[12],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"showComponent\"]]",
    "moduleName": "ember-contextual-table/templates/empty-component.hbs",
    "isStrictMode": false
  });
});
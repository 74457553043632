define("ember-leaflet/components/circle-layer", ["exports", "ember-leaflet/components/point-path-layer"], function (_exports, _pointPathLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  /**
   * A class for drawing circle overlays on a map.
   * It's an approximation and starts to diverge from a real circle closer
   * to poles (due to projection distortion).
   *
   * @class CircleLayer
   * @extends PointPathLayer
   */
  class CircleLayer extends _pointPathLayer.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "leafletRequiredOptions", [...this.leafletRequiredOptions,
      /**
       * Radius of the circle, in meters.
       *
       * @argument radius
       * @type {Number}
       */
      'radius']);
      _defineProperty(this, "leafletDescriptors", [...this.leafletDescriptors, 'radius']);
    }
    createLayer() {
      return this.L.circle(...this.requiredOptions, this.options);
    }
  }
  _exports.default = CircleLayer;
});
define("ember-contextual-table/templates/data-sorter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "mumQ6M32",
    "block": "[[[18,1,[[28,[37,1],null,[[\"data\",\"onsortfieldupdated\"],[[33,2],[28,[37,3],[[30,0],\"onsortfieldupdated\"],null]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"sortedData\",\"action\"]]",
    "moduleName": "ember-contextual-table/templates/data-sorter.hbs",
    "isStrictMode": false
  });
});
define("ember-contextual-table/templates/row-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "cSF+C+BU",
    "block": "[[[18,1,[[28,[37,1],null,[[\"row\",\"rowIndex\",\"isRowSelected\"],[[33,2],[33,3],[33,4]]]]]]],[\"&default\"],false,[\"yield\",\"hash\",\"row\",\"rowIndex\",\"isRowSelected\"]]",
    "moduleName": "ember-contextual-table/templates/row-component.hbs",
    "isStrictMode": false
  });
});
define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [["de", {
    "age.": "N.A.",
    "age.adult": "Adult",
    "age.infant": "Jungtier",
    "age.subadult": "Sub-Adult",
    "age.unknown": "unbekannt",
    "application.form.cancel": "Abbrechen",
    "application.form.close": "Schließen",
    "application.form.delete": "Löschen",
    "application.form.edit": "Bearbeiten",
    "application.form.fields.description": "Beschreibung",
    "application.form.fields.name": "Name",
    "application.form.save": "Speichern",
    "application.form.saved": "Gespeichert",
    "application.format.date": "MM/DD/YYYY",
    "application.format.date-time": "MM/DD/YYYY HH:mm",
    "application.gdpr.continue": "Bevor du fortfährst, erteile bitte der Wageningen University & Research die Genehmigung, deinen Namen und deine E-Mail-Adresse für wissenschaftliche Forschungszwecke zu speichern und zu verarbeiten.",
    "application.gdpr.text": "Mit deiner Einwilligung erklärst du, dass du diese personenbezogenen Daten freiwillig zur Verfügung stellst. Du hast das Recht, deine Einwilligung zu widerrufen. Die von dir zur Verfügung gestellten personenbezogenen Daten werden ausschließlich für den Zweck verwendet, für den du sie zur Verfügung gestellt hast. Du hast das Recht, die personenbezogenen Daten einzusehen, zu löschen, zu berichtigen und deren Verarbeitung einzuschränken, sowie das Recht auf Einspruch und das Recht auf Datenübertragbarkeit <a target=\"_blank\" href=\"https://www.wur.nl/en/About-Wageningen/Integrity-and-privacy.htm\">https://www.wur.nl/en/About-Wageningen/Integrity-and-privacy.htm</a> Hinweis: Beteiligte Personen haben kein absolutes Recht. Dies wird immer je nach Situation und geltend gemachtem Recht entschieden. Wenn die Verarbeitung für die Erfüllung einer gesetzlichen Aufgabe oder das allgemeine Interesse notwendig ist (z. B.: für Archivierungszwecke im öffentlichen Interesse, wissenschaftliche oder historische Forschungszwecke oder Statistiken), dann hast du nicht das Recht auf Vergessenwerden. Wenn du eine Beschwerde hast, kannst du diese über <a href=\"mailto:privacy@wur.nl\">privacy@wur.nl</a> bei WUR einreichen. Du kannst auch eine Beschwerde bei der niederländischen Datenschutzbehörde einreichen. Weitere Informationen findest du unter <a target=\"_blank\" href=\"https://www.autoriteitpersoonsgegevens.nl\">www.autoriteitpersoonsgegevens.nl</a>. Wenn du Fragen hast, kannst du dich per E-Mail an <a href=\"mailto:functionarisgegevensbescherming@wur.nl\">functionarisgegevensbescherming@wur.nl</a> an den Datenschutzbeauftragten von WUR wenden.",
    "application.gdpr.title": "DSGVO",
    "application.gdpr.user-consent": "Ich erteile der Wageningen University & Research die Genehmigung, meinen Namen und meine E-Mail-Adresse für wissenschaftliche Forschungszwecke zu speichern und zu verarbeiten.",
    "application.menu.dashboard": "Dashboard",
    "application.menu.login": "einloggen",
    "application.menu.logout": "Logout",
    "application.menu.organisations": "Organisationen",
    "application.menu.register": "registrieren",
    "application.menu.settings": "Einstellungen",
    "application.menu.userguide": "Benutzerhandbuch",
    "application.menu.yourtasks": "Ihre Aufgaben",
    "class": "Class",
    "colophon.contributors": "Organisationen",
    "colophon.desc": "Agouti ist eine Anwendung (Application), um Wildtier-Fotofallen-Projekte zu managen und Ihre Daten zu analysieren.",
    "colophon.inbo": "Research Institute for Nature and Forest (INBO)",
    "colophon.partners": "Partner",
    "colophon.release": "veröffentlichen",
    "colophon.title": "Über Agouti",
    "colophon.version": "v3.8.0 / 27-5-2021",
    "colophon.wur": "Wageningen University",
    "component.bait-selector.bait": "Köder",
    "component.bait-selector.baits": "Köder",
    "component.bait-selector.select-bait": "Köder auswählen",
    "component.bait-selector.select-baits": "Köder auswählen",
    "component.deployment-calibration-form": "Durchlauf Kalibrierung",
    "component.deployment-calibration-form.go-to-marking": "Springen zu",
    "component.deployment-calibration-form.height": "Höhe",
    "component.deployment-calibration-form.select-label.bottom": "Unterseite des Sticks",
    "component.deployment-calibration-form.select-label.top": "Spitze des Stocks",
    "component.deployment-row.age": "Alter",
    "component.deployment-row.blanks": "Leerfelder",
    "component.deployment-row.datetime": "Datum und Uhrzeit",
    "component.deployment-row.edit": "Durchlauf bearbeiten",
    "component.deployment-row.hideDetails": "Details verbergen",
    "component.deployment-row.no-observations-added": "Keine Beobachtungen eingegeben.",
    "component.deployment-row.observed": "beobachtete Arten",
    "component.deployment-row.person": "Person",
    "component.deployment-row.quantity": "Anzahl",
    "component.deployment-row.setup": "Setup / pickups",
    "component.deployment-row.sex": "Geschlecht",
    "component.deployment-row.show": "anzeigen",
    "component.deployment-row.showDetails": "Details anzeigen",
    "component.deployment-row.species": "Art",
    "component.deployment-row.specifications": "Spezifikationen",
    "component.deployment-row.view": "Beobachtungen ansehen",
    "component.droplet-deployment-uploader.cancel": "abbrechen",
    "component.droplet-deployment-uploader.creating-sequences": "erstelle Sequenzen...",
    "component.droplet-deployment-uploader.guidance-part-1": "Agouti akzeptiert das Hochladen von einzelnen Fotos/Videos wie auch ZIP-Dateien. Welche Methode besser funktioniert hängt von der Anzahl der Dateien in ihrem Untersuchugsgebiet ab.",
    "component.droplet-deployment-uploader.guidance-part-2": "Wenn Sie weniger als 4000 Fotos oder 25 Videos in einem Durchlauf haben, durchsuchen Sie bitten den Ordner, in welchem die Fotos/Videos enthalten sind und markieren alle.",
    "component.droplet-deployment-uploader.guidance-part-3": "Wenn Sie mehr als 4000 Fotos oder 25 Videos haben, packen Sie diese bitte zunächst in eine ZIP-Datei und laden dann diese ZIP Datei hoch. Stellen Sie sicher, dass Sich alle Fotos/Videos direkt in dieser ZIP-Datei befinden, Unterordner werden nicht unterstützt.",
    "component.droplet-deployment-uploader.new-deployment": "neuer Durchlauf",
    "component.droplet-deployment-uploader.progress": "Fortschritt",
    "component.droplet-deployment-uploader.select-files": "durchsuchen",
    "component.droplet-deployment-uploader.start-upload": "Hochladen starten",
    "component.droplet-deployment-uploader.uploading": "Wird geladen...",
    "component.edit-deployment.add-sampling-point": "Kamerastandort hinzufügen",
    "component.edit-deployment.camera-heading": "Camera heading",
    "component.edit-deployment.camera-height": "Kamera-Höhe",
    "component.edit-deployment.camera-height.empty": "keine Kamera-Höhe gefunden",
    "component.edit-deployment.camera-identifier": "Kamera-ID",
    "component.edit-deployment.camera-identifier-placeholder": "Kamera-ID",
    "component.edit-deployment.camera-tilt": "Camera tilt",
    "component.edit-deployment.cm": "cm",
    "component.edit-deployment.created-at": "Erstellt am",
    "component.edit-deployment.created-by": "Erstellt durch",
    "component.edit-deployment.distance-detection": "Detektions-Entfernung",
    "component.edit-deployment.distance-detection.empty": "keine Detektions-Entfernung gefunden",
    "component.edit-deployment.end": "Ende",
    "component.edit-deployment.end-placeholder": "Ende",
    "component.edit-deployment.error.missing-fields": "Please complete all required fields.",
    "component.edit-deployment.groups-placeholder": "wählen Sie eine oder mehrere Gruppen",
    "component.edit-deployment.invalid": "Ungültige Bereitstellungen werden von Datenexporten ausgeschlossen.",
    "component.edit-deployment.location": "Kamerastandort",
    "component.edit-deployment.location-placeholder": "Kamerastandort",
    "component.edit-deployment.notes": "Bemerkungen",
    "component.edit-deployment.notes-placeholder": "Anmerkungen, Kommentare",
    "component.edit-deployment.notes.empty": "keine Bemerkungen gefunden",
    "component.edit-deployment.person-deploy": "Personen anordnen",
    "component.edit-deployment.person-deploy-placeholder": "Person auswählen",
    "component.edit-deployment.person-deploy.empty": "Keine person anordnen gefunden",
    "component.edit-deployment.person-detect": "Personen sammeln",
    "component.edit-deployment.person-detect-placeholder": "Person auswählen",
    "component.edit-deployment.person-detect.empty": "Keine person sammeln gefunden",
    "component.edit-deployment.save": "Durchlauf beenden",
    "component.edit-deployment.start": "Start",
    "component.edit-deployment.start-placeholder": "Start",
    "component.edit-deployment.tags": "Markierungen",
    "component.edit-deployment.tags-placeholder": "wählen Sie eine oder mehrere Markierungen",
    "component.edit-deployment.tags.empty": "keine Markierungen gefunden",
    "component.edit-deployment.utc-offset": "UTC Versatz",
    "component.edit-location.latitude": "Latitude",
    "component.edit-location.longitude": "Longitude",
    "component.edit-location.name": "Name",
    "component.identification-form.age": "Alter",
    "component.identification-form.amount": "Menge",
    "component.identification-form.behaviour": "Verhalten",
    "component.identification-form.created-at": "Erstellt am",
    "component.identification-form.created-by": "Erstellt durch",
    "component.identification-form.delete-observation": "Beobachtungen löschen",
    "component.identification-form.hide-optional": "Optionale Felder verbergen",
    "component.identification-form.identification": "Beobachtung hinzufügen",
    "component.identification-form.individual": "Individuum",
    "component.identification-form.is-domesticated": "domestiziert",
    "component.identification-form.modified-at": "Zuletzt geändert am",
    "component.identification-form.modified-by": "Zuletzt geändert von",
    "component.identification-form.no-individual": "kein Individuum",
    "component.identification-form.notes": "Bemerkungen",
    "component.identification-form.save-observation": "Beobachtungen speichern",
    "component.identification-form.select-age": "Alter auswählen",
    "component.identification-form.select-sex": "Geschlecht auswählen",
    "component.identification-form.sex": "Geschlecht",
    "component.identification-form.show-all": "Alle Felder anzeigen",
    "component.identification-form.species": "Art",
    "component.identification-form.species-placeholder": "Art",
    "component.identification-form.uncertain": "Unsicher / Wahrscheinlich",
    "component.identification-form.uncertain-short": "Unsicher",
    "component.identification-form.validated": "Validiert",
    "component.is-identifiable-as-domesticated.no": "Nein",
    "component.is-identifiable-as-domesticated.yes": "Ja",
    "component.observation-position-form.view-position": "Springen zu",
    "component.observations-list.hide": "verbergen",
    "component.observations-list.observations": "Liste der Beobachtungen",
    "component.observations-list.quantity": "Anzahl",
    "component.observations-list.sex": "Geschlecht",
    "component.observations-list.show": "anzeigen",
    "component.observations-list.species": "Art",
    "component.observations-list.uncertain": "Unsicher / Wahrscheinlich",
    "component.observations-table.observation-type": "Beobachtungstyp",
    "component.observations-table.observator": "Beobachter",
    "component.overview-marker-popup.annotate": "bestimmen",
    "component.overview-marker-popup.browse": "durchsuchen",
    "component.overview-marker-popup.collected-person": "Person gesammelt",
    "component.overview-marker-popup.date-of-import": "Importdatum",
    "component.overview-marker-popup.end": "Ende",
    "component.overview-marker-popup.set-up-person": "Person einrichten",
    "component.overview-marker-popup.start": "Start",
    "component.overview-marker-popup.statistics": "{sequencesAnnotatedCount} von {sequencesCount} Sequenzen bestimmt",
    "component.overview-marker-popup.uploaded-person": "Person hochgeladen",
    "component.page-footer.all-rights-reserved": "CameraTrapLab & Wageningen University",
    "component.page-footer.colophon": "über",
    "component.page-footer.power": "Powered by Agouti für",
    "component.positioning.angle": "Winkel",
    "component.positioning.calculate-speed": "Geschwindigkeit berechnen",
    "component.positioning.camera-and-deployment-calibration-missing": "Kamerakalibrierung und Einsatzkalibrierung fehlen",
    "component.positioning.camera-calibration-missing": "Kamerakalibrierung fehlt",
    "component.positioning.camera-calibration-present": "Kamerakalibrierung vorhanden",
    "component.positioning.camera-details": "Kameradetails",
    "component.positioning.camera-model-missing-with-support": "Für diesen Einsatz fehlt die Kamerakalibrierung. Bitte kontaktieren Sie den Support.",
    "component.positioning.deployment-calibration-missing": "Einsatzkalibrierung fehlt",
    "component.positioning.dimensions": "Abmessungen",
    "component.positioning.distance": "Entfernung",
    "component.positioning.manufacturer": "Hersteller",
    "component.positioning.model": "Modell",
    "component.positioning.preview-plots": "Vorschau Diagramme",
    "component.positioning.radius": "Radius",
    "component.positioning.speed": "Geschwindigkeit",
    "component.positioning.warning": "Warnung",
    "component.remove-full-deployment.cancel": "Abbrechen",
    "component.remove-full-deployment.confirm-removal": "Entfernen bestätigen",
    "component.remove-full-deployment.delete": "Durchlauf löschen",
    "component.remove-full-deployment.modal.error": "Beim Entfernen ist ein Fehler aufgetreten. Der Fehler ist:",
    "component.remove-full-deployment.modal.info": "<p>Sie sind dabei, eine Bereitstellung und alle zugehörigen Ressourcen zu entfernen. Diese Aktion kann nicht rückgängig gemacht werden.</p><p>Bitte geben Sie \"Löschen\" ein, um das Entfernen zu bestätigen</p>",
    "component.remove-full-deployment.modal.title": "Warnung: Bestätigen Sie das Entfernen der durchlauf",
    "component.remove-full-deployment.validation-word": "löschen",
    "component.shortcuts-list.animal": "Tier",
    "component.shortcuts-list.blank": "Leer",
    "component.shortcuts-list.hide-all": "Verstekc alles",
    "component.shortcuts-list.merge-with-previous": "Mit vorheriger zusammenführen",
    "component.shortcuts-list.merge-with-previous-time": "{amountOfMinutes} Minuten mit vorheriger zusammenführen",
    "component.shortcuts-list.merge-with-previous-time-seconds": "{amountOfSeconds} Sekunden mit vorheriger zusammenführen",
    "component.shortcuts-list.merge-with-previous.modal.body": "Möchten Sie diese Sequenz mit der vorherigen zusammenführen?",
    "component.shortcuts-list.merge-with-previous.modal.confirm": "Bestätigen",
    "component.shortcuts-list.merge-with-previous.modal.title": "Sequenz zusammenführen",
    "component.shortcuts-list.setup-pickup": "Einrichtung / Abholung",
    "component.shortcuts-list.shortcuts": "Verknüpfungen",
    "component.shortcuts-list.show-all": "alle anzeigen",
    "component.shortcuts-list.unknown": "unbekannt",
    "component.shortcuts-list.vehicle": "Fahrzeug",
    "component.utc-offset-selector.example": "Der Bildzeitstempel von <code>{originalExampleTime}</code> wird zu <code>{convertedExampleTime}</code> UTC.",
    "component.utc-offset-selector.help-text": "Der UTC-Versatz hängt von der Zeitzone ab, in der Ihre Kamera konfiguriert wurde. Verwenden Sie ein Tool wie <a href=\"https://www.timeanddate.com/worldclock/converter.html\" target=\"_blank\">timeanddate.com</a>, um den Offset zu berechnen.",
    "component.utc-offset-selector.placeholder": "Versatz auswählen",
    "copied.id": "Id Copied to Clipboard",
    "deployment-row.camera-deployment-id": "Cam UG Id",
    "deployment-row.card-id": "Card Id",
    "deployment-row.information": "Information",
    "deployment-row.location": "Ort",
    "deployment-row.observations": "Beobachtungen",
    "deployment-row.tags": "Markierungen",
    "deployment-row.usable": "verwendbar",
    "deployment.id": "Deployment ID",
    "index.partners.1": "Partner & Finanzierung",
    "index.partners.2": "Agouti unterstützt viele Initiativen und Projekte und wird von verschiedenen Organisationen finanziell unterstützt. Darunter sind:",
    "index.partners.3": "Für den Start von <a href=\"https://mammalnet.com/\" target=\"_blank\">MammalNet</a>-Projekten kontaktieren Sie uns bitte über die unten angegebenen Informationen.",
    "index.partners.4": "Mit Mitteln der <a href=\"https://www.efsa.europa.eu/\" target=\"_blank\">European Food and Safety Authority</a>",
    "index.partners.5": "Unterstützt durch <a href=\"https://www.lifewatch.be/\" target=\"_blank\">LifeWatch</a>",
    "info.annotate": "Nach dem Import des Durchlaufs müssen die Sequenzen bearbeitet werden. Jeder Durchlauf, der zu bearbeitende Sequenzen enthält, wird hier angezeigt. Wählen Sie <i>Artbestimmung</i>, um mit der Bearbeitung des Durchlaufs zu beginnen. Sie können die Bearbeitung jederzeit unterbrechen und später dort fortfahren wo Sie aufgehört haben.",
    "info.annotate.title": "Durchlauf bearbeiten",
    "info.annotationsettings": "Diese Felder kontrollieren Darstellung der Artnamen auf der Artbestimmungs-Seite sowie das cut-off Interval zur Erstellung von Bild-Sequenzen.",
    "info.annotationsettings.title": "Artbestimmungseinstellungen",
    "info.behaviours": "Fügen Sie Verhaltensmuster hinzu, indem Sie Optionen zu dieser Liste hinzufügen.",
    "info.behaviours.title": "Verhalten",
    "info.browse": "Nach der Artbestimmung von Sequenzen können Sie die Beobachtungen, die auf dieser Seite hinzugefügt wurden, einsehen. Klicken Sie <i>zeige</i> in der Nähe eines Durchlaufes, um diese Sequenz unverzüglich anzuschauen und ggf. die Beobachtungen zu korrigieren.",
    "info.browse.title": "Beobachtungen durchsuchen",
    "info.cameras": "Kameras werden durch ihre Seriennummern identifiziert und werden in dieser zentralen Liste geführt. Das bedeutet, dass Kameras aus anderen Projekten hier ebenfalls erscheinen. Bitte überprüfen Sie, ob Ihre Kamera nicht bereits enthalten ist, bevor Sie einen neuen Eintrag hinzufügen. Die Seriennummer der Kameras sind meistens im inneren der Kamera zu finden.",
    "info.cameras.title": "Kameras",
    "info.downloaddata": "Laden Sie die exportierten Daten-Pakete hier herunter. Alle Pakete werden archiviert, so dass Sie diese jederzeit erneut herunterladen oder sich auf eine spezifische Version beziehen können.",
    "info.downloaddata.title": "Daten herunterladen",
    "info.export": "Zum Datenexport werden alle Beobachtungen für zukünftige Analysen in ein standardisiertes Format gespeichert (CSV), das mit der meisten Software gelesen werden kann. Nach der Erstellung einer neuen Daten-Datei laden Sie diese bitte aus der Tabelle unten herunter.",
    "info.export.title": "Daten exportieren",
    "info.generalsettings": "Dieser Bereich enthält allgemeine Informationen zu Ihrem Projekt. Bitte füllen Sie mindestens <i>Name, Beschreibung</i> und <i>Projekteigentümer</i> aus",
    "info.generalsettings.title": "Projektinformationen",
    "info.import.upload.title": "Dateien hochladen",
    "info.import.uploadfiles": "Wähle <i>Dateien hochladen</i> um Fotos oder Videos hochzuladen. Bitte gehen Sie zu dem Ordner, in dem alle Fotos oder Videos Ihrer Anordnung enthalten sind. Es wird empfohlen die Dateien direkt von der Speicherkarte hochzuladen, um eventuelle Datenfehler in den Metadaten durch ihren Computer zu vermeiden.",
    "info.import.uploadfiles.title": "Lädt Fotos oder Videos hoch",
    "info.individuals": "Erhalten Sie Strecken von Sichtungen individueller Tiere, indem Sie individuell erkennbare Tiere zu dieser Liste hinzufügen. Sie können diese auch optinal während der Bearbeitung in der Artbestimmung hinzufügen.",
    "info.individuals.title": "Individuelle Tiere",
    "info.pendingusers": "Wenn ein registrierter Benutzer Zugriff auf Ihr Projekt erbittet, benötigen sie zunächst eine Zustimmung vom Koordinator. Ausstehende Anfragen werden in dieser Liste angezeigt. Ändern Sie nach Akzeptanz des Benutzers seine Rolle nach Bedarf.",
    "info.pendingusers.title": "eingehende Anfrage",
    "info.registeredusers": "Jeder mit Zugriff auf Ihr Projekt wird hier angezeigt. Hier können Sie die Rolle eines Benutzers ändern oder Zugriff für alle widerrufen.  Beachten Sie, dass widerrufene Zugänge nicht rückgängig gemacht werden können, sondern der Benutzer eine erneute Anfrage stellen muss!",
    "info.registeredusers.title": "Registrierter Benutzer",
    "info.sampling.": "Ein Kamerastandort ist ein benutzerdefinierter Ort, welchem Durchläufe zugeordnet werden können. Falls notwendig können einem Kamerastandort viele Durchläufe zugeordnet werden.",
    "info.sampling.title": "Kamerastandorte",
    "info.samplingdesign": "Diese Informationen erläutern Ihr Protokoll in Bezug auf Fotofallen-Standards (camera trapping data standards). Diese gelten für Ihr gesamtes Projekt und nicht nur für einzelne Durchläufe. Beispielsweise können Sie verschiedene Duft-Lockstoffe testen oder Ihre Fotofallen an Wasserlöchern platzieren. Stellen Sie eine Beschreibung bereit, um mehr Details hinzuzufügen.",
    "info.samplingdesign.title": "Projektprotokoll",
    "info.specieslist": "Agouti verwendet standardisierte Artenlisten vom <a href=\"http://www.catalogueoflife.org/\" target=\"_blank\">Catalogue of Life</a>. Erstellen Sie eine Auswahl aus dieser Liste, um es in Ihrem Projekt zu verwenden.",
    "info.specieslist.title": "Artenliste",
    "info.tags": "Markierungen können den Durchläufen zugeordnet werden um das Projekt besser zu strukturieren. Sie sind optional und können frei benannt werden. Viele Markierungen können einem Standort zugeordnet werden. Beispiele: Bezeichnung des benutzten Köders, Habitattyp oder jedwede abstrakte Klassifizierung, die Sie definieren.",
    "info.tags.title": "Markierungen",
    "info.unfinishedupload": "Falls Dateien hochgeladen wurden, aber das Formular <i>Durchlauf importieren</i> nicht vervollständigt wurde, wird diese in dieser Liste angezeigt. Klick <i>vervollständigen</i>, um das Formular auszufüllen und den import abzuschließen. Falls der upload unterbrochen wurde, löschen Sie bitte den Durchlauf.",
    "info.unfinishedupload.title": "Unvollständige Anordnungen",
    "info.user.settings": "Hier können Sie Ihre perönlichen Details aktualisieren und die Sprache der Agouti-Schnittstelle ändern. Beachten Sie bitte, dass die Änderung der Schnittstellensprache nicht die von Ihnen eingegebenen Werte verändert (z.B. Projektbeschreibungen oder Kennzeichnungen).",
    "info.user.settings.title": "Persönliche Einstellungen",
    "invalid": "Invalid",
    "language.locale.de": "German",
    "language.locale.en": "English",
    "language.locale.es": "Spanish",
    "language.locale.fr": "French",
    "language.locale.hr": "Croatian",
    "language.locale.nl": "Dutch",
    "language.locale.pl": "Polish",
    "leaflet-map-cartodb": "Default",
    "leaflet-map-here-hybrid.day": "Gelaende",
    "leaflet-map-here-normal.day": "Normal",
    "leaflet-map-here-terrain.day": "Satellit",
    "link": "Link",
    "login.desc": "Um Zugang zu diesem Projekt zu bekommen, bitte mit Ihrem Agouti-account einloggen.",
    "login.email-address": "Email",
    "login.password": "Passwort",
    "login.remember-me": "Remember me",
    "login.sign-in": "einloggen",
    "login.sign-in-email": "Email-Addresse unbekannt.",
    "login.sign-in-error": "ungültige Zugangsdaten",
    "login.sign-in-password": "falsches Passwort eingegeben.",
    "login.title": "einloggen",
    "menu-item.annotate": "Artbestimmung",
    "menu-item.browse": "Durchsuchen",
    "menu-item.cameras": "Kameras",
    "menu-item.deployments": "Durchlauf",
    "menu-item.export-data": "Daten exportieren",
    "menu-item.import": "Importieren",
    "menu-item.locations": "Einsatzorte",
    "menu-item.observations": "Beobachtungen",
    "menu-item.overview": "Übersicht",
    "menu-item.sampling-management": "Stichproben-Verwaltung",
    "menu-item.settings": "Einstellungen",
    "menu-item.settings.behaviour": "Verhalten",
    "menu-item.settings.deployment-groups": "Bereitstellungsgruppen",
    "menu-item.settings.deployment-tags": "Bereitstellungs-Tags",
    "menu-item.settings.general": "Allgemein",
    "menu-item.settings.individuals": "Einzelpersonen",
    "menu-item.species": "Art",
    "menu-item.user-management": "Benutzerverwaltung",
    "menu-item.users": "Benutzer",
    "menu-item.validate": "Validieren",
    "observation.id": "Observation ID",
    "portal.statistics.deployments": "Durchlauf",
    "portal.statistics.images": "Bilder",
    "portal.statistics.observations": "Beobachtingen",
    "portal.statistics.projects": "Projekte",
    "project-overview.show-less": "Zeige weniger",
    "project-overview.show-more": "Zeige mehr",
    "project-thumbnail.members": "{count, plural, other {# members} =1 {# member}}",
    "project-thumbnail.you-are": "Sie sind {projectrole}",
    "project.annotate.annotate": "Art bestimmen",
    "project.annotate.annotate-ai": "Kommentieren von KI",
    "project.annotate.annotate-view": "View annotations",
    "project.annotate.end": "Ende",
    "project.annotate.inspected-sequences": "Sequenzen beendet",
    "project.annotate.is-blank": "unbeschriebene Sequenzen",
    "project.annotate.is-setup-pickup": "Setup / pickup Sequenzen",
    "project.annotate.location": "Datenpunkt",
    "project.annotate.no-unannotated-deployments": "keine unbestimmten Durchläufe",
    "project.annotate.progress": "Fortschritt",
    "project.annotate.sequence.annotate-overview": "Artbestimmung",
    "project.annotate.sequence.back": "zurück",
    "project.annotate.sequence.back-to-overview": "Back to overview",
    "project.annotate.sequence.dashboard": "Dashboard",
    "project.annotate.sequence.exposure": "veröffentlichen",
    "project.annotate.sequence.finish-annotating": "Artbestimmung beenden",
    "project.annotate.sequence.identify": "Identifikation",
    "project.annotate.sequence.navigate-by-observation": "Liste der Beobachtungen",
    "project.annotate.sequence.navigate-by-sequences": "Liste der Sequenzen",
    "project.annotate.sequence.next": "weiter",
    "project.annotate.sequence.next-unannotated": "nächstes unbestimmtes",
    "project.annotate.sequence.observations.age": "Alter",
    "project.annotate.sequence.observations.back": "zurück zur Liste",
    "project.annotate.sequence.observations.cancel": "abbrechen",
    "project.annotate.sequence.observations.quantity": "Anzahl",
    "project.annotate.sequence.observations.sequence": "Sequenz",
    "project.annotate.sequence.observations.sex": "Geschlecht",
    "project.annotate.sequence.observations.species": "Art",
    "project.annotate.sequence.observations.suggested": "vorgeschlagen",
    "project.annotate.sequence.observations.title": "Beobachtungen in {name}",
    "project.annotate.sequence.order": "Auftrag",
    "project.annotate.sequence.photo-of": "Foto {x} von {y}",
    "project.annotate.sequence.sequence-of": "Sequenz",
    "project.annotate.sequence.sequence-of2": "von {y}",
    "project.annotate.sequence.validation-status": "Validierungsstatus",
    "project.annotate.sequence.warning.unsaved-work.body": "Sie haben eine Beobachtung bearbeitet. Stellen Sie sicher, dass Sie ihre Bearbeitung speichern, bevor sie fortfahren!",
    "project.annotate.sequence.warning.unsaved-work.close-modal": "Ok",
    "project.annotate.sequence.warning.unsaved-work.header": "Achtung: nicht gespeicherte Beobachtungen!",
    "project.annotate.sequences": "Sequenzen",
    "project.annotate.start": "Start",
    "project.annotate.title": "Artbestimmung",
    "project.annotate.unannotated-deployments": "unbestimmte Durchläufe",
    "project.annotate.uninspected-sequences": "unbearbeitete Sequenzen",
    "project.browse.assign-to": "Zuweisen an",
    "project.browse.assigned-to": "Zugewiesen an",
    "project.browse.deployments": "Durchlauf",
    "project.browse.desc": "Auf dieser Seite können sie alle Durchläufe dieses Projekts zu durchsuchen. <br/> Klicken Sie auf \"Details anzeigen\", um Markierungen, Notizen, Spezifikationen und die Möglichkeit der Spezifikations-Bearbeitung zu sehen.",
    "project.browse.edit-deployment.title": "Durchlauf bearbeiten",
    "project.browse.end": "Ende",
    "project.browse.location": "Kamerastandort",
    "project.browse.modal.close": "Schließen",
    "project.browse.no-deployments": "keine Durchläufe verfügbar.",
    "project.browse.sequences": "Anzahl Sequenzen",
    "project.browse.start": "Start",
    "project.browse.tags": "Markierungen",
    "project.browse.title": "Durchsuchen",
    "project.browse.unannotated": "unbestimmt",
    "project.browse.view-observations.delete": "Sind Sie sicher, dass Sie diese Beobachtung löschen möchten?",
    "project.browse.view-observations.filter": "Zeige nur Beobachtungen mit...",
    "project.browse.view-observations.split": "Are you sure you want to split this observation?",
    "project.deployment.aimodel.analyzing": "KI ist beschäftigt",
    "project.deployment.aimodel.failed": "KI ist gescheitert",
    "project.deployment.aimodel.scheduled": "KI ist geplant",
    "project.deployment.aimodel.success": "KI ist fertig",
    "project.deployment.complete.annotate": "Durchlauf bestimmen",
    "project.deployment.complete.deployment-successfully-imported": "Durchlauf erfolgreich importiert",
    "project.deployment.complete.desc": "Bitte füllen Sie diese Details aus um den Import des Durchlaufs abzuschließen",
    "project.deployment.complete.import-new": "weitere Durchläufe importieren",
    "project.deployment.complete.success-message": "<b>Import vollständig.</b>",
    "project.deployment.complete.success-message.close": "Bestimme diesen Durchlauf",
    "project.deployment.complete.title": "neuer Durchlauf",
    "project.deployment.set-timestamp.filter": "Filter",
    "project.deployment.set-timestamp.save": "fortfahren",
    "project.deployment.set-timestamps.desc": "Agouti konnte keinen Zeitstempel für diese Posten finden.<br>Bitte fügen Sie den Zeitstempel manuell ein, damit Agouti die Sequenzen korrekt verarbeiten kann.",
    "project.deployment.set-timestamps.file": "Dateiname",
    "project.deployment.set-timestamps.timestamp": "Zeitstempel (24 h Darstellung)",
    "project.deployment.set-timestamps.title": "Zeitstempel zufügen",
    "project.export.create-export": "Export anlegen",
    "project.export.datasetId": "Datensatz ID",
    "project.export.delete": "löschen",
    "project.export.download": "Download",
    "project.export.filename": "Dateiname",
    "project.export.no-exports-created": "keine Exporte angelegt.",
    "project.export.previous-exports": "vorherige Exporte",
    "project.export.size": "Größe",
    "project.export.status": "Status",
    "project.export.time-of-export": "Uhrzeit des Exports",
    "project.export.title": "Daten exportieren",
    "project.export.title.sub": "Beobachtungen exportieren",
    "project.import.assets": "Aktivposten",
    "project.import.complete": "vervollständigen",
    "project.import.create": "neuer Durchlauf",
    "project.import.create-sequence-confirmation": "Sind Sie sicher, dass sie Sequenzen für diesen Import erzeugen wollen?",
    "project.import.create-sequences": "Sequenzen erzeugen",
    "project.import.delete": "löschen",
    "project.import.delete-deployment-confirmation": "Sind Sie sicher, dass Sie diesen Import löschen wollen?",
    "project.import.end": "Ende",
    "project.import.files": "Dateien ausgewählt",
    "project.import.folder": "Ordner",
    "project.import.guidance": "Wählen Sie den Ordner mit den Bildern oder Videos für diesen Standort.",
    "project.import.images": "Fotos",
    "project.import.new-deployment.new-deployment": "Importiere Durchlauf",
    "project.import.new-deployment.no-unfinished-imports": "Es gibt keine ausstehenden Importe.",
    "project.import.new-deployment.title": "Import",
    "project.import.new-deployment.unfinished-imports": "unvollendete Importe",
    "project.import.process": "Prozess",
    "project.import.sequences": "Sequenzen",
    "project.import.start": "Start",
    "project.import.status": "Status",
    "project.import.timestamp": "Zeitstempel",
    "project.import.type": "Typ",
    "project.import.upload": "hochladen",
    "project.import.upload-modal.cancel": "schließen",
    "project.import.upload-modal.desc1": "Bitte Ihre Dateien auswählen und hochladen. Derzeit werden nur JPG- und AVI-Dateien unterstützt.",
    "project.import.upload-modal.desc2": "...oder ziehen Sie die Bilder in diesen Bereich (drag and drop).",
    "project.import.upload-modal.select": "Dateien auswählen",
    "project.import.upload-modal.title": "Dateien hochladen",
    "project.import.upload-modal.uploading": "Uploading {queueFilesLength} files. Succesfully uploaded {uploaded} files, {duplicates} duplicates and {failed} files failed.",
    "project.import.uploaded": "hochgeladen",
    "project.import.viabrowser": "Via browser",
    "project.import.viaftp": "Via FTP",
    "project.overview.achievements": "Errungenschaften",
    "project.overview.browse": "Durchsuchen",
    "project.overview.deployment": "Durchlauf",
    "project.overview.deployment-for-location": "Durchlauf für {location}",
    "project.overview.deployments": "Durchläufe",
    "project.overview.desc": "Projektbeschreibung",
    "project.overview.favourite-assets": "Favourites",
    "project.overview.favourite-sequences": "Favorisierte Sequenzen",
    "project.overview.import-deployment": "Durchlauf importieren",
    "project.overview.imported-deployments": "Importierte Durchläufe",
    "project.overview.most.observed.species": "am häufigsten beobachtete Art",
    "project.overview.no-deployments": "keine Durchläufe verfügbar.",
    "project.overview.observations": "Beobachtungen",
    "project.overview.read.hide": "verbergen",
    "project.overview.read.show": "mehr lesen",
    "project.overview.records": "Aufzeichnungen",
    "project.overview.records-content": "Sie haben die meisten Arten in diesem Projekt beobachtet!",
    "project.overview.sequences": "Sequenzen",
    "project.overview.stats": "Statistik",
    "project.overview.team.members": "Team-Mitglieder",
    "project.overview.unfinished-deployments": "unvollendete Durchläufe",
    "project.request-access.description": "Klick 'Zugang anfordern' um Zugangsanforderung für dieses Projekt zu bestätigen.",
    "project.request-access.request": "Zugang andfordern",
    "project.request-access.title": "Zugang anfordern",
    "project.sampling-management.add-group": "neue Gruppe hinzufügen",
    "project.sampling-management.add-group.title": "neue Gruppe hinzufügen",
    "project.sampling-management.add-sampling-point": "neuen Kamerastandort zufügen",
    "project.sampling-management.add-sampling-point.title": "neuen Kamerastandort zufügen",
    "project.sampling-management.add-samplingpoint.cancel": "abbrechen",
    "project.sampling-management.add-samplingpoint.missing-coordinates": "Bitte geben Sie Namen und Koordinaten ein",
    "project.sampling-management.add-samplingpoint.save": "speichern",
    "project.sampling-management.add-tag": "neue Markierung hinzufügen",
    "project.sampling-management.add-tag.cancel": "abbrechen",
    "project.sampling-management.add-tag.name": "Name",
    "project.sampling-management.add-tag.name-placeholder": "Name",
    "project.sampling-management.add-tag.save": "speichern",
    "project.sampling-management.add-tag.title": "Markierung hinzufügen",
    "project.sampling-management.delete-tag.confirmation": "Bist du sicher, dass du diesen Tag löschen möchtest?",
    "project.sampling-management.edit-group.title": "Gruppe bearbeiten",
    "project.sampling-management.edit-sampling-point.cancel": "abbrechen",
    "project.sampling-management.edit-sampling-point.save": "speichern",
    "project.sampling-management.edit-sampling-point.title": "Datenpunkt bearbeiten",
    "project.sampling-management.edit-tag.cancel": "abbrechen",
    "project.sampling-management.edit-tag.name": "Name",
    "project.sampling-management.edit-tag.name-placeholder": "Name",
    "project.sampling-management.edit-tag.save": "speichern",
    "project.sampling-management.edit-tag.title": "Markierung bearbeiten",
    "project.sampling-management.sampling-points": "Kamerastandorte",
    "project.sampling-management.sampling-points.name": "Name",
    "project.sampling-management.samplingpoints.delete": "löschen",
    "project.sampling-management.samplingpoints.edit": "bearbeiten",
    "project.sampling-management.tags": "Markierung",
    "project.sampling-management.tags.delete": "löschen",
    "project.sampling-management.tags.edit": "bearbeiten",
    "project.sampling-management.tags.name": "Name",
    "project.sampling-management.title": "Stichprobenverwaltung",
    "project.sampling-management.upload-csv": "CSV hochladen",
    "project.sequence.import.failed": "Sequence import failed.",
    "project.sequence.import.importing": "Importing sequences",
    "project.sequence.import.importing.note": "Note: depending on the size of the upload, how busy the server is, this can take a while.<br>You don't need to wait for the creation to complete.",
    "project.sequence.import.importing.success": "Sequences ready!",
    "project.sequence.import.status": "Status of sequence import",
    "project.settings.add-behaviour": "neues Verhalten zufügen",
    "project.settings.add-behaviour.cancel": "abbrechen",
    "project.settings.add-behaviour.description": "Beschreibung",
    "project.settings.add-behaviour.description-placeholder": "Beschreibung",
    "project.settings.add-behaviour.name": "Name",
    "project.settings.add-behaviour.name-placeholder": "Name",
    "project.settings.add-behaviour.save": "speichern",
    "project.settings.add-behaviour.title": "Verhalten zufügen",
    "project.settings.add-camera": "neue Kamera zufügen",
    "project.settings.add-camera.cancel": "abbrechen",
    "project.settings.add-camera.label": "Label",
    "project.settings.add-camera.label-placeholder": "Label",
    "project.settings.add-camera.make": "Hersteller",
    "project.settings.add-camera.make-placeholder": "Hersteller",
    "project.settings.add-camera.model": "Modell",
    "project.settings.add-camera.model-placeholder": "Modell",
    "project.settings.add-camera.save": "speichern",
    "project.settings.add-camera.serial-number": "Seriennummer",
    "project.settings.add-camera.serial-number-placeholder": "Seriennummer",
    "project.settings.add-camera.title": "Kamera zufügen",
    "project.settings.add-camera.year-of-purchase": "Jahr der Anschaffung",
    "project.settings.add-deployment": "Durchlauf hinzufügen",
    "project.settings.add-individual": "neues Individuum zufügen",
    "project.settings.add-individual.age": "Alter",
    "project.settings.add-individual.cancel": "abbrechen",
    "project.settings.add-individual.name": "Name",
    "project.settings.add-individual.name-placeholder": "Name",
    "project.settings.add-individual.notes": "Notizen",
    "project.settings.add-individual.notes-placeholder": "Notizen",
    "project.settings.add-individual.save": "speichern",
    "project.settings.add-individual.sex": "Geschlecht",
    "project.settings.add-individual.species": "Art",
    "project.settings.add-individual.title": "Individuum zufügen",
    "project.settings.add-location": "Ort hinzufügen",
    "project.settings.add-species": "Art hinzufügen",
    "project.settings.add-species-list": "Artenliste hinzufügen",
    "project.settings.add-species-to-add": "Arten hinzuzufügen",
    "project.settings.add-species.cancel": "abbrechen",
    "project.settings.add-species.save": "speichern",
    "project.settings.add-species.species": "Art",
    "project.settings.add-species.species-placeholder": "Art",
    "project.settings.add-species.title": "Art hinzufügen",
    "project.settings.annotate-calibration-and-tracking": "Kalibrierung und Tracking",
    "project.settings.annotate-timelapses": "Timelapses annotieren",
    "project.settings.annotate-timelapses-disabled": "Timelapses können nicht mit Anmerkungen versehen werden, wenn Sequenzen hinzugefügt wurden.",
    "project.settings.annotation": "Artbestimmung",
    "project.settings.automatic-annotation": "Automatische Erkennung",
    "project.settings.automatic-annotation-on-upload": "Automatisch bei neuen Fotoserien",
    "project.settings.automatic-annotation.decription": "Wenn aktiviert, werden neue Bereitstellungen zuerst von der KI verarbeitet und können danach bearbeitet werden.",
    "project.settings.automatic-annotation.placeholder": "Wählen Sie ein Modell aus",
    "project.settings.behaviour": "Verhalten",
    "project.settings.behaviour.delete": "löschen",
    "project.settings.behaviour.description": "Beschreibung",
    "project.settings.behaviour.edit": "bearbeiten",
    "project.settings.behaviour.name": "Name",
    "project.settings.camera.delete": "löschen",
    "project.settings.camera.edit": "bearbeiten",
    "project.settings.camera.label": "Label",
    "project.settings.camera.make": "Hersteller",
    "project.settings.camera.model": "Modell",
    "project.settings.camera.serial-number": "Seriennummer",
    "project.settings.camera.year-of-purchase": "Jahr der Anschaffung",
    "project.settings.cameras": "Kameras",
    "project.settings.delete-behaviour-confirmation": "Sind Sie sicher, dass Sie dieses Verhalten löschen möchten?",
    "project.settings.delete-camera-confirmation": "Sind Sie sicher, dass Sie diese Kamera löschen möchten?",
    "project.settings.delete-individual-confirmation": "Sind Sie sicher, dass Sie dieses Individuum löschen möchten?",
    "project.settings.delete-sampling-point-confirmation": "Sind Sie sicher, dass sie diesen Datenpunkt entfernen wollen?",
    "project.settings.delete-species-confirmation": "Sind Sie sicher, dass Sie diese Art löschen möchten?",
    "project.settings.deployments-are-assigned": "Einsätze sind zugewiesen",
    "project.settings.description": "Beschreibung",
    "project.settings.description-placeholder": "Beschreibung",
    "project.settings.edit-behaviour.cancel": "abbrechen",
    "project.settings.edit-behaviour.description": "Beschreibung",
    "project.settings.edit-behaviour.description-placeholder": "Beschreibung",
    "project.settings.edit-behaviour.name": "Name",
    "project.settings.edit-behaviour.name-placeholder": "Name",
    "project.settings.edit-behaviour.save": "speichern",
    "project.settings.edit-behaviour.title": "Verhalten bearbeiten",
    "project.settings.edit-camera": "Kamera bearbeiten",
    "project.settings.edit-camera.cancel": "abbrechen",
    "project.settings.edit-camera.label": "Label",
    "project.settings.edit-camera.label-placeholder": "Label",
    "project.settings.edit-camera.make": "Hersteller",
    "project.settings.edit-camera.make-placeholder": "Hersteller",
    "project.settings.edit-camera.model": "Model",
    "project.settings.edit-camera.model-placeholder": "Model",
    "project.settings.edit-camera.save": "speichern",
    "project.settings.edit-camera.serial-number": "Seriennummer",
    "project.settings.edit-camera.serial-number-placeholder": "Seriennummer",
    "project.settings.edit-camera.title": "Kamera bearbeiten",
    "project.settings.edit-camera.year-of-purchase": "Jahr der Anschaffung",
    "project.settings.edit-individual.age": "Alter",
    "project.settings.edit-individual.cancel": "abbrechen",
    "project.settings.edit-individual.name": "Name",
    "project.settings.edit-individual.name-placeholder": "Name",
    "project.settings.edit-individual.notes": "Notizen",
    "project.settings.edit-individual.notes-placeholder": "Notizen",
    "project.settings.edit-individual.save": "speichern",
    "project.settings.edit-individual.sex": "Geschlecht",
    "project.settings.edit-individual.species": "Art",
    "project.settings.edit-individual.title": "Individuum bearbeiten",
    "project.settings.edit-location": "Ort bearbeiten",
    "project.settings.edit-location.images": "Bilder Ort",
    "project.settings.edit-species.cancel": "abbrechen",
    "project.settings.edit-species.common-names": "deutschen Artnamen bearbeiten",
    "project.settings.edit-species.is-identifiable-as-domesticated": "als domestiziert identifiziert",
    "project.settings.edit-species.name": "Art",
    "project.settings.edit-species.save": "speichern",
    "project.settings.edit-species.title": "Art bearbeiten",
    "project.settings.feature": "Eigenschaften",
    "project.settings.feature-methodology": "Methodeneigenschaften",
    "project.settings.feature-methodology-placeholder": "Methodeneigenschaften",
    "project.settings.feature.select-feature": "Select feature",
    "project.settings.general": "allgemein",
    "project.settings.hide-deployment-locations": "Einsatzorte ausblenden",
    "project.settings.image": "Image",
    "project.settings.individual.add-individual": "Individuum zufügen",
    "project.settings.individual.delete": "löschen",
    "project.settings.individual.edit": "bearbeiten",
    "project.settings.individual.name": "Name",
    "project.settings.individual.notes": "Notizen",
    "project.settings.individual.species": "Art",
    "project.settings.individual.zooniverse": "Zooniverse",
    "project.settings.individuals": "Individuen",
    "project.settings.location": "Ort",
    "project.settings.min-image-resolution": "Minimum {min_width} zu {min_height} Pixel",
    "project.settings.name": "Name",
    "project.settings.name-placeholder": "Name",
    "project.settings.open-for-subscriptions": "offen für Einschreibungen",
    "project.settings.organisation": "Organisation",
    "project.settings.organisation-help-block": "Organisations are considered rights holders of the data.",
    "project.settings.organisation-placeholder": "Organisation",
    "project.settings.project-investigator": "Projektprüfer",
    "project.settings.project-investigator-placeholder": "Projektprüfer",
    "project.settings.project-owner": "Kontakt",
    "project.settings.project-owner-placeholder": "Projektkontakt",
    "project.settings.quiet-period": "Inaktive Phase",
    "project.settings.restricted-access": "beschränkter Zugang",
    "project.settings.restricted-images": "Eingeschränkte Bilder",
    "project.settings.restricted-images.note": "Wenn diese Option aktiviert ist, sind Bild-URLs in Exporten/veröffentlichten Daten für externe Benutzer nicht zugänglich.",
    "project.settings.restricted-locations": "Eingeschränkte Standorte",
    "project.settings.restricted-locations.note": "Wenn diese Option aktiviert ist, sehen Bildverarbeiter und Freiwillige nur die Standorte für ihre eigenen Einsätze.",
    "project.settings.restricted-project": "Eingeschränktes Projekt",
    "project.settings.restricted-project.note": "Wenn diese Option aktiviert ist, wird Ihr Projekt von der öffentlichen Homepage und dem Portal auf agouti.eu ausgeblendet.",
    "project.settings.sampling-design": "Stichproben-Design",
    "project.settings.sampling-design.clustered-random": "Gruppiert zufällig (zufällige Arrays)",
    "project.settings.sampling-design.experimental": "Experimentell",
    "project.settings.sampling-design.label": "Stichprobendesigns",
    "project.settings.sampling-design.opportunistic": "Opportunistisch",
    "project.settings.sampling-design.placeholder": "Stichprobendesigns auswählen",
    "project.settings.sampling-design.simple-random": "Zufällig - einfach",
    "project.settings.sampling-design.systematic-random": "Zufällig - systematisch",
    "project.settings.sampling-design.targeted": "Zielgerichtet",
    "project.settings.seconds": "Sekunden",
    "project.settings.sensormethod": "Sensormethode",
    "project.settings.sensormethod.motion-detection": "Bewegungserkennung",
    "project.settings.sensormethod.select-sensormethod": "Sensormethode auswählen",
    "project.settings.sensormethod.timelapse": "Zeitraffer",
    "project.settings.sequence-cut-off": "Sequenz cut-off",
    "project.settings.sequence-cutoff-disabled": "Sequenz cut-off kann nicht mehr verändert werden, wenn Sequenzen zugefügt wurden.",
    "project.settings.species": "Art",
    "project.settings.species.added": "zum Projekt hinzugefügt",
    "project.settings.species.available": "verfügbare Arten",
    "project.settings.species.common-name": "deutscher Artname",
    "project.settings.species.countDeployments": "Deployments",
    "project.settings.species.countObservations": "Observations",
    "project.settings.species.countValObservations": "Validated",
    "project.settings.species.edit": "bearbeiten",
    "project.settings.species.family": "Familie",
    "project.settings.species.genus": "Genus",
    "project.settings.species.isIdentifiableAsDomesticated": "domestiziert",
    "project.settings.species.order": "Ordnung",
    "project.settings.species.remove": "entfernen",
    "project.settings.species.species": "Species",
    "project.settings.success-message": "Projekteinstellungen gespeichert.",
    "project.settings.title": "Projektenstellungen",
    "project.settings.update-location.cancel": "abbrechen",
    "project.settings.update-location.save": "speichern",
    "project.settings.update-location.title": "Ort bearbeiten",
    "project.settings.utc-offset": "Standard UTC Versatz",
    "project.user-management.accept-pending-request-confirmation": "Sind Sie sicher, dass sie diese Anfrage Akzeptieren möchten?",
    "project.user-management.awaiting-access": "Warten auf Zugang",
    "project.user-management.copy": "Einladungslink kopieren",
    "project.user-management.copy.confirmation": "Link kopiert",
    "project.user-management.delete-user-confirmation": "Sind Sie sicher, dass Sie diesem Mitglied keinen Zugang zu diesem Projekt gewähren möchten?",
    "project.user-management.edit-user.cancel": "abbrechen",
    "project.user-management.edit-user.email": "Email-Addresse",
    "project.user-management.edit-user.email-placeholder": "Email-Addresse",
    "project.user-management.edit-user.first-name": "Vorname",
    "project.user-management.edit-user.first-name-placeholder": "Vorname",
    "project.user-management.edit-user.last-name": "Nachname",
    "project.user-management.edit-user.last-name-placeholder": "Nachname",
    "project.user-management.edit-user.member-since": "Mitglied seit",
    "project.user-management.edit-user.revoke-access": "Zugangsberechtigung aufheben",
    "project.user-management.edit-user.role": "Rolle",
    "project.user-management.edit-user.save": "speichern",
    "project.user-management.edit-user.title": "Benutzer bearbeiten",
    "project.user-management.email-address": "Email-Addresse",
    "project.user-management.first-name": "Vorname",
    "project.user-management.last-name": "Nachname",
    "project.user-management.link-description": "Benutzen Sie diesen link um Benutzern zu ermöglichen, Zugang zu Ihrem Projekt zu erhalten.",
    "project.user-management.pending-requests": "unerledigte Anfragen",
    "project.user-management.pending-requests.accept": "akzeptieren",
    "project.user-management.pending-requests.decline": "ablehnen",
    "project.user-management.pending-requests.empty": "keine unerledigten Anfragen gefunden",
    "project.user-management.principal-investigators": "Projektleiter",
    "project.user-management.project-access": "Projektzugang",
    "project.user-management.registered-users": "Mitglieder",
    "project.user-management.registered-users.delete": "löschen",
    "project.user-management.registered-users.edit": "bearbeiten",
    "project.user-management.role": "Rolle",
    "project.user-management.title": "Benutzerverwaltung",
    "project.user-management.userbase": "Mitglieder",
    "project.user-management.userbase.title": "Mitglieder",
    "project.user-management.volunteers": "Ehrenamtliche",
    "project.validate.accept-sequence": "Accept sequence observations",
    "project.validate.accepted-by-at": "Validated by {validator} on {date}",
    "project.validate.add-new-query": "Add new query",
    "project.validate.all-are-true": "All of the following predicates are true",
    "project.validate.edit.title": "Edit validation query",
    "project.validate.filter.created-from": "Created from",
    "project.validate.filter.created-to": "Created to",
    "project.validate.filter.is-equal-to": "Is equal to",
    "project.validate.filter.is-unknown": "Is unknown",
    "project.validate.filter.sampling-point": "Sampling point",
    "project.validate.filter.select-filter-type": "Select filter type",
    "project.validate.filter.select-role": "Select project role",
    "project.validate.filter.select-sampling-point": "Select sampling point",
    "project.validate.filter.select-species": "Select species",
    "project.validate.filter.select-user": "Select user",
    "project.validate.filter.species": "Species",
    "project.validate.filter.user": "User",
    "project.validate.filter.user-project-role": "Project role",
    "project.validate.new.title": "New validation query",
    "project.validate.no-rules-defined-yet": "No predicates defined yet...",
    "project.validate.no-validation-queries": "There are no validation queries defined for this project yet",
    "project.validate.none-are-true": "None of the following predicates are true",
    "project.validate.predicates": "Predicates",
    "project.validate.query-is-failed": "Something went wrong during the execution of the query...",
    "project.validate.query-is-running": "Query is running...",
    "project.validate.query-is-scheduled": "Query is scheduled...",
    "project.validate.results.assest-count": "Asset count",
    "project.validate.results.no-results": "No results found...",
    "project.validate.results.show-done": "Show validated sequences",
    "project.validate.results.total-results": "Total results",
    "project.validate.results.total-results-of": "Showing {count} of {total} results (max {limit})",
    "project.validate.rule-presets": "Rule presets",
    "project.validate.scheduling-explanation": "Queries are run every hour. Execution takes a few minutes, up to half an hour. Please refresh the page to update the status of the validation queries.",
    "project.validate.sequences": "Sequences",
    "project.validate.table.header.candidates": "Matches",
    "project.validate.table.header.name": "Name",
    "project.validate.title": "Validate",
    "project.validate.unaccept-sequence": "Remove validation",
    "project.validate.validate": "validate",
    "project.validate.validation-query-results": "Validation query results",
    "project.zooniverse.password": "Zooniverse Passwort",
    "project.zooniverse.password-placeholder": "Zooniverse Passwort",
    "project.zooniverse.projectid": "Zooniverse ProjektID",
    "project.zooniverse.projectid-placeholder": "Zooniverse ProjektID",
    "project.zooniverse.username": "Zooniverse Benutzername",
    "project.zooniverse.username-placeholder": "Zooniverse Benutzername",
    "projectrole.ADMIN": "Admin",
    "projectrole.AWAITING_ACCESS": "warten auf Zugang",
    "projectrole.DUMMY": "Dummy",
    "projectrole.PHOTO_PROCESSOR": "Bildbearbeiter",
    "projectrole.PRINCIPAL_INVESTIGATOR": "Projektleiter",
    "projectrole.PROJECT_COORDINATOR": "Projektkoordinator",
    "projectrole.TAXONOMIC_EXPERT": "Taxonomie-Experte",
    "projectrole.VIEW_ONLY": "View Only",
    "projectrole.VOLUNTEER": "Ehrenamtlicher",
    "projects.add": "neues Projekt hinzufügen",
    "projects.independent-projects": "unabhängige Projekte",
    "projects.no-projects-available": "Noch keine Projekte angelegt. Bitte kontaktieren Sie Ihren Projektkoordinator für Instruktionen.",
    "projects.search-for": "suchen nach...",
    "projects.search-for.filter": "Projekte filtern",
    "projects.search-for.filterOrg": "Filter on organisation",
    "projects.title": "Projekte",
    "projects.title.user": "meine Projekte",
    "projects.your-role": "Ihre Rolle",
    "publichome.block1.title": "Was ist Agouti?",
    "publichome.block1.txt": "Fotofallen stellen eine einfach und wenig störende (non-invasive) Methode dar, um Wildtiere zu studieren. Allerdings wird der Bearbeitungsaufwand bei tausenden von Fotos in einer typischen Studie sehr schwierig. Auch die Projektverwaltung kann zu einer Herausforderung werden, insbesondere wenn viele Kameras und verschiedene Personen involviert sind.",
    "publichome.block1.txt2": "Agouti hilft Benutzern diese Herausforderungen zu meistern. Es ist eine Komplettlösung für Organisationen und Einzelpersonen, die Fotofallen zur Wildtierbeobachtung einsetzen. Es ermöglicht den Fotofallen-Bearbeitern Kooperations-Projekte zu organisieren, Bilder schnell und einfach zu bearbeiten, standardisierte Zusammenfassungen der Ergebnisse zu erhalten, sicher Bilder und Daten zu archivieren, sowie schließlich Daten zusammenzufassen und zu teilen. Agouti folgt Metadatenstandards, die Vergleiche zwischen Projekten einfach ermöglichen.",
    "publichome.block2.title": "Workflow",
    "publichome.block2.txt": "Agouti ist eine Sammlung von Projekten, die von Organisationen oder Einzelpersonen betrieben werden. Projektteilnehmer laden den kompletten Inhalt von Speicherkarten ihrer Kamerafallen hoch und geben die Orts- und andere Metadaten ein. Agouti importiert diese Bilder, bezieht Datum, Uhrzeit und andere Daten direkt aus den Bildern und gruppiert die Bilder in Sequenzen die dasselbe Ereignis darstellen. Analytiker untersuchen jede Sequenz und beschreiben die Arten in einer einfachen Schnittstelle mit einer oder mehren Beobachtungen.",
    "publichome.block2.txt2": "Sie können Ihre Daten als Camera Trap Data Package (<a target=\"_blank\" href=\"https://tdwg.github.io/camtrap-dp/\">Camtrap DP</a>) zur Analyse in Software wie z als R. Wir empfehlen die Verwendung des R-Pakets <a target=\"_blank\" href=\"https://inbo.github.io/camtraptor/\">camtraptor</a> als Start.",
    "publichome.block3.title": "Import",
    "publichome.block3.txt": "Hochladen der Bilder und Videos über Ihren Browser",
    "publichome.block3.txt2": "Artbestimmung",
    "publichome.block3.txt3": "Beschriftung der Bilder mit Beobachtungen mittels geeigneter Werkzeuge",
    "publichome.block3.txt4": "Analysieren",
    "publichome.block3.txt5": "Durchsuchung der Daten mit eingebauten Werkzeugen und Präsentationen",
    "publichome.block3.txt6": "Export",
    "publichome.block3.txt7": "Generierung standardisierter Dateien um Daten zu analysieren und weiterzugeben",
    "publichome.block4.title": "Intuitive Schnittstelle",
    "publichome.block4.txt": "Agoutis Schnittstelle ist sauber, modern und langfristig auf Produktivität ausgerichtet. Jeder kann nach ein wenig Training mit der Arbeit beginnen. Die Videos unten illustrieren verschiedene Aspekte des Systems.",
    "publichome.block4.txt1": "Getting started",
    "publichome.block4.txt2": "Durchläufe importieren",
    "publichome.block4.txt3": "Artbestimmung",
    "publichome.block4.vid1": "/assets/videos/GettingStarted-DE.mp4",
    "publichome.block4.vid2": "/assets/videos/ImportingDeployments-DE.mp4",
    "publichome.block4.vid3": "/assets/videos/AnnotatingSequences-DE.mp4",
    "publichome.block5.title": "Projekte hervorheben",
    "publichome.block5.txt": "Agouti beheimatet eine Vielfalt von Projekten. Erkunden Sie einige dieser öffentlichen Projekte, um die Möglichkeiten von Agouti zu entdecken.",
    "publichome.block6.f1": "Verwalten",
    "publichome.block6.f2": "Studiendesign",
    "publichome.block6.f3": "räumliche Planung",
    "publichome.block6.f4": "Kameras verfolgen",
    "publichome.block6.f5": "Standorte markieren",
    "publichome.block6.f6": "Qualitätskontrolle",
    "publichome.block6.g1": "Fortschritt",
    "publichome.block6.g2": "Beobachtungen hinzufügen",
    "publichome.block6.g3": "Standardisierte Artenlisten",
    "publichome.block6.g4": "CSV-Dateien exportieren",
    "publichome.block6.g5": "Individuelle Tiere verfolgen",
    "publichome.block6.g6": "Verhalten beschreiben",
    "publichome.block6.h1": "Mitarbeiten",
    "publichome.block6.h2": "Mitglieder einladen",
    "publichome.block6.h3": "Rollen zuweisen",
    "publichome.block6.h4": "Aufgaben zuweisen",
    "publichome.block6.h5": "Fortschritt beobachten",
    "publichome.block6.h6": "Daten organisieren",
    "publichome.block6.title": "Kenndaten",
    "publichome.block6.txt": "Mit Agouti steht Ihnen eine umfangreiche Auswahl an Werkzeugen zur Verfügung.",
    "publichome.block7.title": "privat & sicher",
    "publichome.block7.txt": "Urheberrecht gilt! Agouti erhebt keinerlei Besitzansprüche auf Daten und vetreibt keine Daten an Dritte. Nur Sie entscheiden, wer Zugriff bekommt, damit Sie zu Ihren Bedingungen kooperieren können.",
    "publichome.block7.txt2": "Agouti bietet einen sicheren Ort für Ihre Datenspeicherung. Eleminieren Sie das Risiko von Datenverlusten durch defekte Speicherplatten, Benutzerfehler oder Computerabstürze. Tägliche off-site backups und eine komplett redundante Infrastruktur sichern optimalen Zugriff auf Ihre Daten.",
    "publichome.block8.f1": "Akademisch",
    "publichome.block8.f2": "kostenlos",
    "publichome.block8.f3": "unbegrenzter Speicherplatz",
    "publichome.block8.f4": "unbegrenzte Projekte und Benutzer",
    "publichome.block8.f5": "Best Effort Support",
    "publichome.block8.f6": "beantragen",
    "publichome.block8.g1": "Non-profit",
    "publichome.block8.g2": "kostenlos",
    "publichome.block8.g3": "unbegrenzter Speicherplatz",
    "publichome.block8.g4": "unbegrenzte Projekte und Benutzer",
    "publichome.block8.g5": "Best Effort Support",
    "publichome.block8.g6": "erkundigen",
    "publichome.block8.h1": "kommerziell",
    "publichome.block8.h2": "Pay-what-you-can",
    "publichome.block8.h3": "unbegrenzter Speicherplatz",
    "publichome.block8.h4": "unbegrenzte Projekte und Benutzer",
    "publichome.block8.h5": "Best Effort Support",
    "publichome.block8.h6": "beantragen",
    "publichome.block8.title": "Benutzung von Agouti starten",
    "publichome.block8.txt": "Kosten für Betrieb und Entwicklung von Agouti werden von den Benutzern getragen. Studenten können Agouti kostenfrei verwenden, während andere Beteiligte einen Beitrag leisten. Von kommerziellen Organisationen wird erwartet, einen höheren Beitrag zu leisten, da diese durch die Benutzung von Agouti Zeit und Geld sparen. In diesem kollaborativen Modell werden die Kosten verteilt, so dass jeder Zugang zur Plattform bekommt.",
    "publichome.explore-projects": "Projekte erkunden",
    "publichome.footer1": "Agouti",
    "publichome.footer10": "6708 PB Wageningen",
    "publichome.footer11": "The Netherlands",
    "publichome.footer12": "Research Institute for Nature and Forest (INBO)",
    "publichome.footer13": "Wildlife Management and Alien Species & Open science lab for biodiversity",
    "publichome.footer14": "Havenlaan 88 bus 73",
    "publichome.footer15": "1000 Brussel",
    "publichome.footer16": "Belgium",
    "publichome.footer2": "Benutzer-Mailingliste",
    "publichome.footer3": "Datenschutzbestimmungen",
    "publichome.footer5": "Organisation",
    "publichome.footer6": "Agouti ist eine Initiative der Wageningen University und des Research Institute for Nature and Forest (INBO)",
    "publichome.footer7": "Wageningen University",
    "publichome.footer8": "Department of environmental sciences",
    "publichome.footer9": "Wildlife Ecology and Conservation Group",
    "publichome.slogan": "Eine Plattform zur Verwaltung von Wildtier-Fotofallen-Projekten",
    "publichome.stats1": "Projekte",
    "publichome.stats2": "Durchläufe",
    "publichome.stats3": "Beobachtungen",
    "publichome.stats4": "Bilder",
    "publichome.title": "Agouti",
    "sequence.id": "Sequence ID",
    "sex.": "N.A.",
    "sex.female": "weiblich",
    "sex.male": "männlich",
    "sex.unknown": "unbekannt",
    "taxonRank": "Taxon Rank",
    "toggle-processed": "Hide 100%",
    "user.register.desc": "Registrierung für Agouti-account",
    "user.register.email-address": "Email",
    "user.register.email-address-duplicate": "Email-Adresse bereits verwendet.",
    "user.register.email-address-error": "Bitte geben Sie Ihre Email-Adresse ein.",
    "user.register.first-name": "Vorname",
    "user.register.first-name-error": "Bitte geben Sie Ihren Vornamen ein.",
    "user.register.gdpr-error": "Bitte bestätige deine Zustimmung zur DSGVO",
    "user.register.last-name": "Nachname",
    "user.register.last-name-error": "Bitte geben Sie Ihren Nachnamen ein.",
    "user.register.password": "Passwort",
    "user.register.password-error": "Bitte geben Sie ein Passwort ein.",
    "user.register.title": "Registrierung",
    "user.request-reset-password.desc": "Request a password reset link",
    "user.request-reset-password.title": "Reset password",
    "user.reset-password.desc": "Choose a new Agouti password",
    "user.reset-password.email-address": "E-mail",
    "user.reset-password.email-address-duplicate": "Email address already in use.",
    "user.reset-password.email-address-error": "Please fill in your email.",
    "user.reset-password.password": "Password",
    "user.reset-password.password-are-different-error": "Please enter the same password twice.",
    "user.reset-password.password-confirmation": "Password confirmation",
    "user.reset-password.password-confirmation-error": "Please fill in a password confirmation.",
    "user.reset-password.password-error": "Please fill in a password.",
    "user.reset-password.title": "Reset password",
    "user.settings.account": "Account",
    "user.settings.adaptive-image-size": "Adaptive Bildgröße",
    "user.settings.annotate-shortcuts-label": "Anmerken Verknüpfungen",
    "user.settings.anonymous-in-export": "Anonym bei Datenexporten",
    "user.settings.anonymous-in-export-explanation": "Wenn du diese Option ankreuzt, werden deine personenbezogenen Daten bei Datenexporten anonymisiert. Dein Name und deine E-Mail-Adresse werden bei Datenexporten nicht einbezogen, und du wirst nicht als Beitragender/Mitwirkender, Einrichter und/oder Klassifizierer genannt.",
    "user.settings.autoscroll-thumbnails-annotating": "Automatisches Scrollen bei der Bildanmerkung",
    "user.settings.common": "deutsch",
    "user.settings.email-address": "Email-Addresse",
    "user.settings.email-address-label": "Email",
    "user.settings.email-in-use": "Email-Adresse bereits verwendet.",
    "user.settings.first-name": "Vorname",
    "user.settings.first-name-note": "Andere Mitglieder können das sehen.",
    "user.settings.gravatar": "oder erlaube <a href=\"http://gravatar.com\" title=\"http://gravatar.com\">Gravatar</a>",
    "user.settings.gravatar.disable": "sperren",
    "user.settings.gravatar.enable": "erlauben",
    "user.settings.language": "Sprache",
    "user.settings.last-name": "Nachname",
    "user.settings.organisation": "Organisation",
    "user.settings.organisation-placeholder": "Organisation",
    "user.settings.password": "Passwort",
    "user.settings.picture": "öffentlicher Avatar",
    "user.settings.picture.browse": "Durchsuchen...",
    "user.settings.picture.file": "keine Datei ausgewählt",
    "user.settings.picture.remove": "entfernen",
    "user.settings.picture.upload": "einen neuen Avatar hochladen",
    "user.settings.save": "Änderungen speichern",
    "user.settings.save.update": "Einstellungen aktualisieren",
    "user.settings.saved": "Einstellungen gespeichert.",
    "user.settings.scientific": "wissenschaftlich",
    "user.settings.species-names": "Artname",
    "user.settings.title": "Benutzereinstellungen",
    "user.settings.url.label": "Url (ORCID)",
    "user.settings.url.placeholder": ""
  }], ["en", {
    "age.": "N.A.",
    "age.adult": "Adult",
    "age.infant": "Juvenile",
    "age.subadult": "Sub-Adult",
    "age.unknown": "Unknown",
    "application.form.cancel": "Cancel",
    "application.form.close": "Close",
    "application.form.delete": "Delete",
    "application.form.edit": "Edit",
    "application.form.fields.description": "Description",
    "application.form.fields.name": "Name",
    "application.form.save": "Save",
    "application.form.saved": "Saved",
    "application.format.date": "MM/DD/YYYY",
    "application.format.date-time": "MM/DD/YYYY HH:mm",
    "application.gdpr.continue": "Before you continue, please give Wageningen University and Research permission to store and process your name and email address for scientific research",
    "application.gdpr.text": "By giving your consent, you agree to have voluntarily provided these personal data. You have the right to revoke the given consent. The personal information provided by you will be used solely for the purpose for which you have provided these. You have the right to inspect, delete, correct, and restrict the processing of personal data as well as the right to appeal and the right to data portability <a target=\"_blank\" href=\"https://www.wur.nl/en/About-Wageningen/Integrity-and-privacy.htm\">https://www.wur.nl/en/About-Wageningen/Integrity-and-privacy.htm</a> Note: there is no absolute right for involved persons. This will always have to be considered per situation and the right invoked. If the processing is necessary for the performance of a statutory task or general interest (e.g.: for archiving purposes in the public interest, scientific or historical research purposes or statistics), then the right to be forgotten does not exist. If you have a complaint, you can submit this to WUR via <a href=\"mailto:privacy@wur.nl\">privacy@wur.nl</a>. You can also file a complaint with the Dutch Data Protection Authority. More information on this can be found at <a target=\"_blank\" href=\"https://www.autoriteitpersoonsgegevens.nl\">www.autoriteitpersoonsgegevens.nl</a>. If you have any questions, then you can contact the Data Protection Officer of WUR via <a href=\"mailto:functionarisgegevensbescherming@wur.nl\">functionarisgegevensbescherming@wur.nl</a>.",
    "application.gdpr.title": "GDPR",
    "application.gdpr.user-consent": "I give Wageningen University and Research permission to store and process my name and email address for scientific research",
    "application.menu.dashboard": "Dashboard",
    "application.menu.login": "Sign in",
    "application.menu.logout": "Logout",
    "application.menu.organisations": "Organizations",
    "application.menu.register": "Register",
    "application.menu.settings": "Settings",
    "application.menu.userguide": "User guide",
    "application.menu.yourtasks": "Your tasks",
    "class": "Class",
    "colophon.contributors": "Organizations",
    "colophon.desc": "Agouti is an application for managing camera trapping projects and analyzing your data.",
    "colophon.inbo": "Research Institute for Nature and Forest (INBO)",
    "colophon.partners": "Partners",
    "colophon.release": "Release",
    "colophon.title": "About Agouti",
    "colophon.version": "v3.8.0 / 27-5-2021",
    "colophon.wur": "Wageningen University",
    "component.bait-selector.bait": "Bait",
    "component.bait-selector.baits": "Baits",
    "component.bait-selector.select-bait": "Select bait",
    "component.bait-selector.select-baits": "Select baits",
    "component.deployment-calibration-form": "Deployment calibration",
    "component.deployment-calibration-form.go-to-marking": "Jump to marking",
    "component.deployment-calibration-form.height": "height",
    "component.deployment-calibration-form.select-label.bottom": "Bottom of stick",
    "component.deployment-calibration-form.select-label.top": "Top of stick",
    "component.deployment-row.age": "Age",
    "component.deployment-row.blanks": "Blanks",
    "component.deployment-row.datetime": "Datetime",
    "component.deployment-row.edit": "Edit deployment",
    "component.deployment-row.hideDetails": "Hide details",
    "component.deployment-row.no-observations-added": "No observations have been added.",
    "component.deployment-row.observed": "Observed species",
    "component.deployment-row.person": "Person",
    "component.deployment-row.quantity": "Qt.",
    "component.deployment-row.setup": "Setup / pickups",
    "component.deployment-row.sex": "Sex",
    "component.deployment-row.show": "Show",
    "component.deployment-row.showDetails": "Show details",
    "component.deployment-row.species": "Species",
    "component.deployment-row.specifications": "Specifications",
    "component.deployment-row.view": "View observations",
    "component.droplet-deployment-uploader.cancel": "Cancel",
    "component.droplet-deployment-uploader.creating-sequences": "Creating sequences...",
    "component.droplet-deployment-uploader.guidance-part-1": "Agouti accepts uploads of individual photos/videos as well as ZIP files. What method works best depends on the amount of files in the deployment.",
    "component.droplet-deployment-uploader.guidance-part-2": "If you have a deployment of less than 4000 photos or 25 videos, please browse to the folder containing these photos/videos and select all of them.",
    "component.droplet-deployment-uploader.guidance-part-3": "If you have more than 4000 photos or 25 videos please pack them into a single ZIP file first, and upload that file instead. Make sure that all photos/videos are in the root of the zip file. Subfolders are not supported.",
    "component.droplet-deployment-uploader.new-deployment": "New deployment",
    "component.droplet-deployment-uploader.progress": "Progress",
    "component.droplet-deployment-uploader.select-files": "Browse",
    "component.droplet-deployment-uploader.start-upload": "Begin uploading",
    "component.droplet-deployment-uploader.uploading": "Uploading...",
    "component.edit-deployment.add-sampling-point": "Add Sampling Point",
    "component.edit-deployment.camera-heading": "Camera heading",
    "component.edit-deployment.camera-height": "Camera height",
    "component.edit-deployment.camera-height.empty": "No camera height data found",
    "component.edit-deployment.camera-identifier": "Camera identifier",
    "component.edit-deployment.camera-identifier-placeholder": "Camera identifier",
    "component.edit-deployment.camera-tilt": "Camera tilt",
    "component.edit-deployment.cm": "cm",
    "component.edit-deployment.created-at": "Created at",
    "component.edit-deployment.created-by": "Created by",
    "component.edit-deployment.distance-detection": "Detection distance",
    "component.edit-deployment.distance-detection.empty": "No detection distance found",
    "component.edit-deployment.end": "End",
    "component.edit-deployment.end-placeholder": "End",
    "component.edit-deployment.error.missing-fields": "Please complete all required fields.",
    "component.edit-deployment.groups-placeholder": "Select one or more groups",
    "component.edit-deployment.invalid": "Invalid deployments are excluded from data exports.",
    "component.edit-deployment.location": "Sampling point",
    "component.edit-deployment.location-placeholder": "Sampling point",
    "component.edit-deployment.notes": "Notes",
    "component.edit-deployment.notes-placeholder": "Remarks, comments",
    "component.edit-deployment.notes.empty": "No notes found",
    "component.edit-deployment.person-deploy": "Person deploy",
    "component.edit-deployment.person-deploy-placeholder": "Select a person",
    "component.edit-deployment.person-deploy.empty": "No person deploy found",
    "component.edit-deployment.person-detect": "Person pickup",
    "component.edit-deployment.person-detect-placeholder": "Select a person",
    "component.edit-deployment.person-detect.empty": "No person detect found",
    "component.edit-deployment.save": "Finish deployment",
    "component.edit-deployment.start": "Start",
    "component.edit-deployment.start-placeholder": "Start",
    "component.edit-deployment.tags": "Tags",
    "component.edit-deployment.tags-placeholder": "Select one or more tags",
    "component.edit-deployment.tags.empty": "No tags found",
    "component.edit-deployment.utc-offset": "UTC offset",
    "component.edit-location.latitude": "Latitude",
    "component.edit-location.longitude": "Longitude",
    "component.edit-location.name": "Name",
    "component.identification-form.age": "Age",
    "component.identification-form.amount": "Amount",
    "component.identification-form.behaviour": "Behaviour",
    "component.identification-form.created-at": "Created at",
    "component.identification-form.created-by": "Created by",
    "component.identification-form.delete-observation": "Delete observation",
    "component.identification-form.hide-optional": "Hide optional fields",
    "component.identification-form.identification": "Add observation",
    "component.identification-form.individual": "Individual",
    "component.identification-form.is-domesticated": "Domesticated",
    "component.identification-form.modified-at": "Last modified at",
    "component.identification-form.modified-by": "Last modified by",
    "component.identification-form.no-individual": "No individual",
    "component.identification-form.notes": "Notes",
    "component.identification-form.save-observation": "Save observation",
    "component.identification-form.select-age": "Select age",
    "component.identification-form.select-sex": "Select sex",
    "component.identification-form.sex": "Sex",
    "component.identification-form.show-all": "Show all fields",
    "component.identification-form.species": "Species",
    "component.identification-form.species-placeholder": "Species",
    "component.identification-form.uncertain": "Uncertain / Likely",
    "component.identification-form.uncertain-short": "Uncertain",
    "component.identification-form.validated": "Validated",
    "component.is-identifiable-as-domesticated.no": "No",
    "component.is-identifiable-as-domesticated.yes": "Yes",
    "component.observation-position-form.view-position": "Jump to position",
    "component.observations-list.hide": "Hide",
    "component.observations-list.observations": "Observation list",
    "component.observations-list.quantity": "Qt.",
    "component.observations-list.sex": "Sex",
    "component.observations-list.show": "Show",
    "component.observations-list.species": "Species",
    "component.observations-list.uncertain": "Uncertain / Likely",
    "component.observations-table.observation-type": "Observation type",
    "component.observations-table.observator": "Observator",
    "component.overview-marker-popup.annotate": "Annotate",
    "component.overview-marker-popup.browse": "Browse",
    "component.overview-marker-popup.collected-person": "Collected person",
    "component.overview-marker-popup.date-of-import": "Date of import",
    "component.overview-marker-popup.end": "End",
    "component.overview-marker-popup.set-up-person": "Set up person",
    "component.overview-marker-popup.start": "Start",
    "component.overview-marker-popup.statistics": "{sequencesAnnotatedCount} of {sequencesCount} sequences annotated",
    "component.overview-marker-popup.uploaded-person": "Uploaded person",
    "component.page-footer.all-rights-reserved": "Wageningen University",
    "component.page-footer.colophon": "About",
    "component.page-footer.power": "",
    "component.positioning.angle": "Angle",
    "component.positioning.calculate-speed": "Calculate speed",
    "component.positioning.camera-and-deployment-calibration-missing": "Camera and deployment calibration missing",
    "component.positioning.camera-calibration-missing": "Camera calibration missing",
    "component.positioning.camera-calibration-present": "Camera calibration present",
    "component.positioning.camera-details": "Camera Details",
    "component.positioning.camera-model-missing-with-support": "Camera calibration missing for this deployment. Please contact support.",
    "component.positioning.deployment-calibration-missing": "Deployment calibration missing",
    "component.positioning.dimensions": "Dimensions",
    "component.positioning.distance": "Distance",
    "component.positioning.manufacturer": "Manufacturer",
    "component.positioning.model": "Model",
    "component.positioning.preview-plots": "Preview plots",
    "component.positioning.radius": "Radius",
    "component.positioning.speed": "Speed",
    "component.positioning.warning": "Warning",
    "component.remove-full-deployment.cancel": "Cancel",
    "component.remove-full-deployment.confirm-removal": "Confirm removal",
    "component.remove-full-deployment.delete": "Delete deployment",
    "component.remove-full-deployment.modal.error": "There was an error while removing. The error is:",
    "component.remove-full-deployment.modal.info": "<p>You are about to remove a deployment and all its related resources. This action cannot be undone.</p><p>Please type \"delete\" to confirm the removal</p>",
    "component.remove-full-deployment.modal.title": "Warning: confirm removal of deployment",
    "component.remove-full-deployment.validation-word": "delete",
    "component.shortcuts-list.animal": "Animal",
    "component.shortcuts-list.blank": "Blank",
    "component.shortcuts-list.hide-all": "Hide all",
    "component.shortcuts-list.merge-with-previous": "Merge with previous",
    "component.shortcuts-list.merge-with-previous-time": "{amountOfMinutes} minutes elapsed since previous sequence",
    "component.shortcuts-list.merge-with-previous-time-seconds": "{amountOfSeconds} seconds elapsed since previous sequence",
    "component.shortcuts-list.merge-with-previous.modal.body": "Are you sure you want to merge this sequence with the previous one? Observations of this sequence will be removed.",
    "component.shortcuts-list.merge-with-previous.modal.confirm": "Yes, merge with previous",
    "component.shortcuts-list.merge-with-previous.modal.title": "Merge with previous",
    "component.shortcuts-list.setup-pickup": "Setup / pickup",
    "component.shortcuts-list.shortcuts": "Shortcuts",
    "component.shortcuts-list.show-all": "Show all",
    "component.shortcuts-list.unknown": "Unknown",
    "component.shortcuts-list.vehicle": "Vehicle",
    "component.utc-offset-selector.example": "Image timestamp of <code>{originalExampleTime}</code> becomes <code>{convertedExampleTime}</code> UTC.",
    "component.utc-offset-selector.help-text": "The UTC offset depends on the timezone in which your camera was configured. Use a tool like <a href=\"https://www.timeanddate.com/worldclock/converter.html\" target=\"_blank\">timeanddate.com</a> to calculate the offset.",
    "component.utc-offset-selector.placeholder": "Select offset",
    "copied.id": "Id Copied to Clipboard",
    "deployment-row.camera-deployment-id": "Cam Dep Id",
    "deployment-row.card-id": "Card Id",
    "deployment-row.information": "Information",
    "deployment-row.location": "Location",
    "deployment-row.observations": "Observations",
    "deployment-row.tags": "Tags",
    "deployment-row.usable": "Usable",
    "deployment.id": "Deployment ID",
    "index.partners.1": "Collaborators & Funding",
    "index.partners.2": "Agouti is supporting and collaborating with many initiatives and projects, and is kindly supported by funding from several organizations. Amongst those are:",
    "index.partners.3": "For creating <a href=\"https://mammalnet.com/\" target=\"_blank\">MammalNet</a> projects please contact us via the info found below.",
    "index.partners.4": "With funding from the <a href=\"https://www.efsa.europa.eu/\" target=\"_blank\">European Food and Safety Authority</a>",
    "index.partners.5": "Supported by <a href=\"https://www.lifewatch.be/\" target=\"_blank\">LifeWatch</a>",
    "info.annotate": "After importing deployment the sequences need to be processed. Any deployment that has sequences that need annotating is shown here. Select <i>Annotate</i> to start working on that deployment. You can stop processing at any time, and continue from where you left later.",
    "info.annotate.title": "Deployment Processing",
    "info.annotationsettings": "These fields control the display of species names on the annotation page, and the cut-off interval for building image sequences.",
    "info.annotationsettings.title": "Annotation Settings",
    "info.behaviours": "Add behaviour to observations by adding options to this list.",
    "info.behaviours.title": "Behaviour",
    "info.browse": "After annotating the sequences you can view the observations that were added on this page. Click <i>Show</i> next to a deployment to immediatly view that sequence and correct observations if nessecary.",
    "info.browse.title": "Browsing Observations",
    "info.cameras": "Cameras are identified by serial number, and are kept in this central list. This means that cameras rom other projects show up here as well. Please check if your cameras are not already there before adding new entries. The serial number of Reconyx cameras can be found on the bottom of the camera after opening, just below the barcode.",
    "info.cameras.title": "Cameras",
    "info.downloaddata": "Download exported data packages here. All packages are archived, so downloading or refering to a specific version is possible.",
    "info.downloaddata.title": "Downloading Data",
    "info.export": "When exporting data all observations are packaged into a standardized format (CSV) that can be read by almost all software for further processing. After generating a new data file, download it from the table below.",
    "info.export.title": "Exporting Data",
    "info.generalsettings": "This section contains general information on your project. Please fill out at least the <i>Name, Description</i> and <i>Project Owner</i>",
    "info.generalsettings.title": "Project Information",
    "info.import.upload.title": "Upload files",
    "info.import.uploadfiles": "Select <i>Upload Files</i> to upload photos or videos. Please navigate to the folder that contains all photos or videos of your deployment. It is recommended to upload material directly from the memory card, to prevent software form damaging the metadata.",
    "info.import.uploadfiles.title": "Uploading photos or videos",
    "info.individuals": "Keep track of sightings of individual animals by adding them to this list. You can optionally add them during annotation.",
    "info.individuals.title": "Individual Animals",
    "info.pendingusers": "When a registered user request access to your project, they need approval from the coordinator first. Pending requests show up in this list. After accepting a user, change the role if needed.",
    "info.pendingusers.title": "Incoming Requests",
    "info.registeredusers": "Anyone with access to your project is shown here. Here you can change the role of a user, or revoke access altogether. Remember that reversing revoked access require a new application for access!",
    "info.registeredusers.title": "Registered Users",
    "info.sampling.": "A sampling point is a user-labeled location to which a deployment can be assigned. Multiple deployments can be assigned to a sampling point, if needed.",
    "info.sampling.title": "Sampling Points",
    "info.samplingdesign": "The information here tells something about your protocol, in terms of camera trapping data standards. These apply to your whole project, and not individual deployments. For example: you can test several types of scented bait. Or place your cameras at waterholes. Provide a description to add more detail.",
    "info.samplingdesign.title": "Project Protocol",
    "info.specieslist": "Agouti uses the standardized species list of the <a href=\"http://www.catalogueoflife.org/\" target=\"_blank\">Catalogue of Life</a>. Build a subset of this list to use within this project.",
    "info.specieslist.title": "Species List",
    "info.tags": "Tags can be assigned to deployments to structure your project. They are optional, can be freely labeled and multiple tags can be assigned to a single deployment. For example: use them to keep track of the bait used, habitat type or some abstract class you define.",
    "info.tags.title": "Tags",
    "info.unfinishedupload": "If files were uploaded, but the <i>Import Deployment</i> form was not completed, it will show up in this list. Click <i>Complete</i> to fill out the form and finish the import. If the upload was interrupted delete the deployment instead.",
    "info.unfinishedupload.title": "Incomplete Deployments",
    "info.user.settings": "Here you can update your personal details and change the interface language of Agouti. Note that changing the interface language does not change certain user specified items like project descriptions or labels.",
    "info.user.settings.title": "Personal Settings",
    "invalid": "Invalid",
    "language.locale.de": "German",
    "language.locale.en": "English",
    "language.locale.es": "Spanish",
    "language.locale.fr": "French",
    "language.locale.hr": "Croatian",
    "language.locale.nl": "Dutch",
    "language.locale.pl": "Polish",
    "leaflet-map-cartodb": "Default",
    "leaflet-map-here-hybrid.day": "Hybrid",
    "leaflet-map-here-normal.day": "Normal",
    "leaflet-map-here-terrain.day": "Terrain",
    "link": "Link",
    "login.desc": "To request access to this project, please sign in with your Agouti account.",
    "login.email-address": "Email",
    "login.password": "Password",
    "login.remember-me": "Remember me",
    "login.sign-in": "Sign in",
    "login.sign-in-email": "E-mail address is not known.",
    "login.sign-in-error": "Invalid credentials",
    "login.sign-in-password": "Wrong password entered.",
    "login.title": "Sign in",
    "menu-item.annotate": "Annotate",
    "menu-item.browse": "Browse",
    "menu-item.cameras": "Cameras",
    "menu-item.deployments": "Deployments",
    "menu-item.export-data": "Export data",
    "menu-item.import": "Import",
    "menu-item.locations": "Locations",
    "menu-item.observations": "Observations",
    "menu-item.overview": "Overview",
    "menu-item.sampling-management": "Sampling management",
    "menu-item.settings": "Settings",
    "menu-item.settings.behaviour": "Behaviour",
    "menu-item.settings.deployment-groups": "Deployment groups",
    "menu-item.settings.deployment-tags": "Deployment tags",
    "menu-item.settings.general": "General",
    "menu-item.settings.individuals": "Individuals",
    "menu-item.species": "Species",
    "menu-item.user-management": "User management",
    "menu-item.users": "Users",
    "menu-item.validate": "Validate",
    "observation.id": "Observation ID",
    "portal.statistics.deployments": "Deployments",
    "portal.statistics.images": "Images",
    "portal.statistics.observations": "Observations",
    "portal.statistics.projects": "Projects",
    "project-overview.show-less": "Show less",
    "project-overview.show-more": "Show more",
    "project-thumbnail.members": "{count, plural, other {# members} =1 {# member}}",
    "project-thumbnail.you-are": "You are {projectrole}",
    "project.annotate.annotate": "Annotate",
    "project.annotate.annotate-ai": "Annotate by AI",
    "project.annotate.annotate-view": "View annotations",
    "project.annotate.end": "End",
    "project.annotate.inspected-sequences": "Finished sequences",
    "project.annotate.is-blank": "Blank sequences",
    "project.annotate.is-setup-pickup": "Setup / pickup sequences",
    "project.annotate.location": "Sampling point",
    "project.annotate.no-unannotated-deployments": "No unannotated deployments",
    "project.annotate.progress": "Progress",
    "project.annotate.sequence.annotate-overview": "Annotate",
    "project.annotate.sequence.back": "Back",
    "project.annotate.sequence.back-to-overview": "Back to overview",
    "project.annotate.sequence.dashboard": "Dashboard",
    "project.annotate.sequence.exposure": "Exposure",
    "project.annotate.sequence.finish-annotating": "Finish annotating",
    "project.annotate.sequence.identify": "Identification",
    "project.annotate.sequence.navigate-by-observation": "List of observations",
    "project.annotate.sequence.navigate-by-sequences": "List of sequences",
    "project.annotate.sequence.next": "Next",
    "project.annotate.sequence.next-unannotated": "Next unannotated",
    "project.annotate.sequence.observations.age": "Ages",
    "project.annotate.sequence.observations.back": "Back to list",
    "project.annotate.sequence.observations.cancel": "Cancel",
    "project.annotate.sequence.observations.quantity": "Quantity",
    "project.annotate.sequence.observations.sequence": "Sequence",
    "project.annotate.sequence.observations.sex": "Sex",
    "project.annotate.sequence.observations.species": "Species",
    "project.annotate.sequence.observations.suggested": "Suggested",
    "project.annotate.sequence.observations.title": "Observations in {name}",
    "project.annotate.sequence.order": "Order",
    "project.annotate.sequence.photo-of": "Photo {x} of {y}",
    "project.annotate.sequence.sequence-of": "Sequence",
    "project.annotate.sequence.sequence-of2": "of {y}",
    "project.annotate.sequence.validation-status": "Validation status",
    "project.annotate.sequence.warning.unsaved-work.body": "You were editing an observation, make sure to save your work before proceeding",
    "project.annotate.sequence.warning.unsaved-work.close-modal": "Ok",
    "project.annotate.sequence.warning.unsaved-work.header": "Warning: unsaved observation",
    "project.annotate.sequences": "Sequences",
    "project.annotate.start": "Start",
    "project.annotate.title": "Annotate",
    "project.annotate.unannotated-deployments": "Unannotated deployments",
    "project.annotate.uninspected-sequences": "Pending sequences",
    "project.browse.assign-to": "Assign to",
    "project.browse.assigned-to": "Assigned to",
    "project.browse.deployments": "Deployments",
    "project.browse.desc": "On this page you can browse all deployments in this project. <br/> Show details to view tags, notes, specifications and the ability to edit these specifications.",
    "project.browse.edit-deployment.title": "Edit deployment",
    "project.browse.end": "End",
    "project.browse.groups": "Groups",
    "project.browse.location": "Sampling Point",
    "project.browse.modal.close": "Close",
    "project.browse.no-deployments": "No deployments available.",
    "project.browse.sequences": "Sequences",
    "project.browse.start": "Start",
    "project.browse.tags": "Tags",
    "project.browse.title": "Browse",
    "project.browse.unannotated": "Unannotated",
    "project.browse.view-observations.delete": "Are you sure you want to delete this observation?",
    "project.browse.view-observations.filter": "Show only observations with",
    "project.browse.view-observations.split": "Are you sure you want to split this observation?",
    "project.deployment.aimodel.analyzing": "AI is annotating",
    "project.deployment.aimodel.failed": "AI failed",
    "project.deployment.aimodel.scheduled": "AI is scheduled ..",
    "project.deployment.aimodel.success": "AI is done ..",
    "project.deployment.complete.annotate": "Annotate this deployment",
    "project.deployment.complete.deployment-successfully-imported": "Deployment successfully imported",
    "project.deployment.complete.desc": "Please fill out these details in order to finish the import of this deployment",
    "project.deployment.complete.import-new": "Import another deployment",
    "project.deployment.complete.success-message": "<b>Import complete.</b>",
    "project.deployment.complete.success-message.close": "Annotate this deployment.",
    "project.deployment.complete.title": "New deployment",
    "project.deployment.set-timestamp.filter": "Filter",
    "project.deployment.set-timestamp.save": "Continue",
    "project.deployment.set-timestamps.desc": "Agouti could not find the timestamp for these assets.<br>Please insert the timestamp manually so Agouti can process the sequences correctly.",
    "project.deployment.set-timestamps.file": "Filename",
    "project.deployment.set-timestamps.timestamp": "Timestamp (24 hour notation)",
    "project.deployment.set-timestamps.title": "Add timestamps",
    "project.export.create-export": "Create export",
    "project.export.datasetId": "Dataset ID",
    "project.export.delete": "Delete",
    "project.export.download": "Download",
    "project.export.filename": "Filename",
    "project.export.no-exports-created": "No exports created.",
    "project.export.previous-exports": "Previous exports",
    "project.export.size": "Size",
    "project.export.status": "Status",
    "project.export.time-of-export": "Time of export",
    "project.export.title": "Export data",
    "project.export.title.sub": "Export observations",
    "project.import.assets": "Assets",
    "project.import.complete": "Complete",
    "project.import.create": "New deployment",
    "project.import.create-sequence-confirmation": "Are you sure to create sequences for this import?",
    "project.import.create-sequences": "Create sequences",
    "project.import.delete": "Delete",
    "project.import.delete-deployment-confirmation": "Are you sure you want to delete this import?",
    "project.import.dst": "Daylight saving time",
    "project.import.end": "End",
    "project.import.files": "files selected",
    "project.import.folder": "Folder",
    "project.import.guidance": "Select the folder containing the images or video for this deployment.",
    "project.import.images": "Photos",
    "project.import.new-deployment.new-deployment": "Import deployment",
    "project.import.new-deployment.no-unfinished-imports": "There are no pending deployments.",
    "project.import.new-deployment.title": "Import",
    "project.import.new-deployment.unfinished-imports": "Unfinished imports",
    "project.import.process": "Process",
    "project.import.sequences": "Sequences",
    "project.import.start": "Start",
    "project.import.status": "Status",
    "project.import.timestamp": "Timestamp",
    "project.import.type": "Type",
    "project.import.upload": "Upload",
    "project.import.upload-modal.cancel": "Close",
    "project.import.upload-modal.desc1": "Please select and upload your files. Currently, JPG and AVI files are supported.",
    "project.import.upload-modal.desc2": "Or drag and drop images onto this area.",
    "project.import.upload-modal.select": "Select files",
    "project.import.upload-modal.title": "Upload files",
    "project.import.upload-modal.uploading": "Uploading {queueFilesLength} files. Succesfully uploaded {uploaded} files, {duplicates} duplicates and {failed} files failed.",
    "project.import.uploaded": "Uploaded",
    "project.import.viabrowser": "Via browser",
    "project.import.viaftp": "Via FTP",
    "project.overview.achievements": "Achievements",
    "project.overview.browse": "Browse",
    "project.overview.deployment": "Deployment",
    "project.overview.deployment-for-location": "Deployment for location",
    "project.overview.deployments": "Deployments",
    "project.overview.desc": "Project description",
    "project.overview.favourite-assets": "Favourites",
    "project.overview.favourite-sequences": "Favourite sequences",
    "project.overview.import-deployment": "Import a deployment",
    "project.overview.imported-deployments": "Imported deployments",
    "project.overview.most.observed.species": "Most observed species",
    "project.overview.no-deployments": "No deployments available.",
    "project.overview.observations": "Observations",
    "project.overview.read.hide": "Hide",
    "project.overview.read.show": "Read more",
    "project.overview.records": "Records",
    "project.overview.records-content": "observed the most species in this project",
    "project.overview.sequences": "Sequences",
    "project.overview.stats": "Statistics",
    "project.overview.team.members": "Team members",
    "project.overview.unfinished-deployments": "Unfinished deployments",
    "project.request-access.description": "Click \"Request\" to confirm requesting access to this project.",
    "project.request-access.request": "Request access",
    "project.request-access.title": "Request access",
    "project.sampling-management.add-group": "Add new group",
    "project.sampling-management.add-group.title": "Add group",
    "project.sampling-management.add-sampling-point": "Add new sampling point",
    "project.sampling-management.add-sampling-point.title": "Add new sampling point",
    "project.sampling-management.add-samplingpoint.cancel": "Cancel",
    "project.sampling-management.add-samplingpoint.missing-coordinates": "Please enter name and coordinates.",
    "project.sampling-management.add-samplingpoint.save": "Save",
    "project.sampling-management.add-tag": "Add new tag",
    "project.sampling-management.add-tag.cancel": "Cancel",
    "project.sampling-management.add-tag.name": "Name",
    "project.sampling-management.add-tag.name-placeholder": "Name",
    "project.sampling-management.add-tag.save": "Save",
    "project.sampling-management.add-tag.title": "Add tag",
    "project.sampling-management.delete-tag.confirmation": "Are you sure you want to delete this tag ?",
    "project.sampling-management.edit-group.title": "Edit group",
    "project.sampling-management.edit-sampling-point.cancel": "Cancel",
    "project.sampling-management.edit-sampling-point.save": "Save",
    "project.sampling-management.edit-sampling-point.title": "Edit sampling point",
    "project.sampling-management.edit-tag.cancel": "Cancel",
    "project.sampling-management.edit-tag.name": "Name",
    "project.sampling-management.edit-tag.name-placeholder": "Name",
    "project.sampling-management.edit-tag.save": "Save",
    "project.sampling-management.edit-tag.title": "Edit tag",
    "project.sampling-management.sampling-points": "Sampling points",
    "project.sampling-management.sampling-points.name": "Name",
    "project.sampling-management.samplingpoints.delete": "Delete",
    "project.sampling-management.samplingpoints.edit": "Edit",
    "project.sampling-management.tags": "Deployment Tags",
    "project.sampling-management.tags.delete": "Delete",
    "project.sampling-management.tags.edit": "Edit",
    "project.sampling-management.tags.name": "Name",
    "project.sampling-management.title": "Sampling management",
    "project.sampling-management.upload-csv": "Upload CSV",
    "project.sequence.import.failed": "Sequence import failed.",
    "project.sequence.import.importing": "Importing sequences",
    "project.sequence.import.importing.note": "Note: depending on the size of the upload, how busy the server is, this can take a while.<br>You don't need to wait for the creation to complete.",
    "project.sequence.import.importing.success": "Sequences ready!",
    "project.sequence.import.status": "Status of sequence import",
    "project.settings.add-behaviour": "Add new behaviour",
    "project.settings.add-behaviour.cancel": "Cancel",
    "project.settings.add-behaviour.description": "Description",
    "project.settings.add-behaviour.description-placeholder": "Description",
    "project.settings.add-behaviour.name": "Name",
    "project.settings.add-behaviour.name-placeholder": "Name",
    "project.settings.add-behaviour.save": "Save",
    "project.settings.add-behaviour.title": "Add behaviour",
    "project.settings.add-camera": "Add new camera",
    "project.settings.add-camera.cancel": "Cancel",
    "project.settings.add-camera.label": "Label",
    "project.settings.add-camera.label-placeholder": "Label",
    "project.settings.add-camera.make": "Make",
    "project.settings.add-camera.make-placeholder": "Make",
    "project.settings.add-camera.model": "Model",
    "project.settings.add-camera.model-placeholder": "Model",
    "project.settings.add-camera.save": "Save",
    "project.settings.add-camera.serial-number": "Serial no.",
    "project.settings.add-camera.serial-number-placeholder": "Serial no.",
    "project.settings.add-camera.title": "Add camera",
    "project.settings.add-camera.year-of-purchase": "Year of purchase",
    "project.settings.add-deployment": "Add deployment",
    "project.settings.add-individual": "Add new individual",
    "project.settings.add-individual.age": "Age",
    "project.settings.add-individual.cancel": "Cancel",
    "project.settings.add-individual.name": "Name",
    "project.settings.add-individual.name-placeholder": "Name",
    "project.settings.add-individual.notes": "Notes",
    "project.settings.add-individual.notes-placeholder": "Notes",
    "project.settings.add-individual.save": "Save",
    "project.settings.add-individual.sex": "Sex",
    "project.settings.add-individual.species": "Species",
    "project.settings.add-individual.title": "Add individual",
    "project.settings.add-location": "Add location",
    "project.settings.add-species": "Add species",
    "project.settings.add-species-list": "Add species list",
    "project.settings.add-species-to-add": "Species to add",
    "project.settings.add-species.cancel": "Cancel",
    "project.settings.add-species.save": "Save",
    "project.settings.add-species.species": "Add single species",
    "project.settings.add-species.species-placeholder": "Species",
    "project.settings.add-species.title": "Add species",
    "project.settings.annotate-calibration-and-tracking": "Calibration and tracking",
    "project.settings.annotate-timelapses": "Annotate timelapses",
    "project.settings.annotate-timelapses-disabled": "Timelapses can not be annotated when sequences have been added.",
    "project.settings.annotation": "Annotation",
    "project.settings.automatic-annotation": "Automatic annotation",
    "project.settings.automatic-annotation-on-upload": "Automatically with new photo series",
    "project.settings.automatic-annotation.decription": "When enabled, new deployments are first processed by the AI and can be edited after this is completed.",
    "project.settings.automatic-annotation.placeholder": "Select a model",
    "project.settings.behaviour": "Behaviour",
    "project.settings.behaviour.delete": "Delete",
    "project.settings.behaviour.description": "Description",
    "project.settings.behaviour.edit": "Edit",
    "project.settings.behaviour.name": "Name",
    "project.settings.camera.delete": "Delete",
    "project.settings.camera.edit": "Edit",
    "project.settings.camera.label": "Label",
    "project.settings.camera.make": "Manufacturer",
    "project.settings.camera.model": "Model",
    "project.settings.camera.serial-number": "Serial no.",
    "project.settings.camera.year-of-purchase": "Year of purchase",
    "project.settings.cameras": "Cameras",
    "project.settings.delete-behaviour-confirmation": "Are you sure you want to remove this behaviour?",
    "project.settings.delete-camera-confirmation": "Are you sure you want to remove this camera?",
    "project.settings.delete-individual-confirmation": "Are you sure you want to remove this individual?",
    "project.settings.delete-sampling-point-confirmation": "Are you sure you want to delete this sampling point ?",
    "project.settings.delete-species-confirmation": "Are you sure you want to remove this species?",
    "project.settings.deployments-are-assigned": "Deployments are assigned",
    "project.settings.description": "Description",
    "project.settings.description-placeholder": "Description",
    "project.settings.edit-behaviour.cancel": "Cancel",
    "project.settings.edit-behaviour.description": "Description",
    "project.settings.edit-behaviour.description-placeholder": "Description",
    "project.settings.edit-behaviour.name": "Name",
    "project.settings.edit-behaviour.name-placeholder": "Name",
    "project.settings.edit-behaviour.save": "Save",
    "project.settings.edit-behaviour.title": "Edit behaviour",
    "project.settings.edit-camera": "Edit camera",
    "project.settings.edit-camera.cancel": "Cancel",
    "project.settings.edit-camera.label": "Label",
    "project.settings.edit-camera.label-placeholder": "Label",
    "project.settings.edit-camera.make": "Make",
    "project.settings.edit-camera.make-placeholder": "Make",
    "project.settings.edit-camera.model": "Model",
    "project.settings.edit-camera.model-placeholder": "Model",
    "project.settings.edit-camera.save": "Save",
    "project.settings.edit-camera.serial-number": "Serial no.",
    "project.settings.edit-camera.serial-number-placeholder": "Serial no.",
    "project.settings.edit-camera.title": "Edit camera",
    "project.settings.edit-camera.year-of-purchase": "Year of purchase",
    "project.settings.edit-individual.age": "Age",
    "project.settings.edit-individual.cancel": "Cancel",
    "project.settings.edit-individual.name": "Name",
    "project.settings.edit-individual.name-placeholder": "Name",
    "project.settings.edit-individual.notes": "Notes",
    "project.settings.edit-individual.notes-placeholder": "Notes",
    "project.settings.edit-individual.save": "Save",
    "project.settings.edit-individual.sex": "Sex",
    "project.settings.edit-individual.species": "Species",
    "project.settings.edit-individual.title": "Edit individual",
    "project.settings.edit-location": "Edit location",
    "project.settings.edit-location.images": "Location images",
    "project.settings.edit-species.cancel": "Cancel",
    "project.settings.edit-species.common-names": "Edit common names",
    "project.settings.edit-species.is-identifiable-as-domesticated": "Occurs as domesticated",
    "project.settings.edit-species.name": "Species",
    "project.settings.edit-species.save": "Save",
    "project.settings.edit-species.title": "Edit species",
    "project.settings.feature": "Feature",
    "project.settings.feature-methodology": "Feature methodology",
    "project.settings.feature-methodology-placeholder": "Feature methodology",
    "project.settings.feature.select-feature": "Select feature",
    "project.settings.general": "General",
    "project.settings.hide-deployment-locations": "Hide deployment locations",
    "project.settings.image": "Image",
    "project.settings.individual.add-individual": "Add individual",
    "project.settings.individual.delete": "Delete",
    "project.settings.individual.edit": "Edit",
    "project.settings.individual.name": "Name",
    "project.settings.individual.notes": "Notes",
    "project.settings.individual.species": "Species",
    "project.settings.individual.zooniverse": "Zooniverse",
    "project.settings.individuals": "Individuals",
    "project.settings.location": "Location",
    "project.settings.min-image-resolution": "Minimum {min_width} by {min_height} pixels",
    "project.settings.name": "Name",
    "project.settings.name-placeholder": "Name",
    "project.settings.open-for-subscriptions": "Open for subscriptions",
    "project.settings.organisation": "Organisation",
    "project.settings.organisation-help-block": "Organisations are considered rights holders of the data.",
    "project.settings.organisation-placeholder": "Organisation",
    "project.settings.project-investigator": "PI",
    "project.settings.project-investigator-placeholder": "Project Investigator",
    "project.settings.project-owner": "Contact",
    "project.settings.project-owner-placeholder": "Project Contact",
    "project.settings.quiet-period": "Quiet period",
    "project.settings.restricted-access": "Restricted access",
    "project.settings.restricted-images": "Restricted images",
    "project.settings.restricted-images.note": "When checked, image URLs in exports/published data will not be accessible to external users.",
    "project.settings.restricted-locations": "Restricted locations",
    "project.settings.restricted-locations.note": "When checked, photo processors and volunteers will only see the locations for their own deployments.",
    "project.settings.restricted-project": "Restricted project",
    "project.settings.restricted-project.note": "When checked, your project will be hidden from the public homepage and portal on agouti.eu.",
    "project.settings.sampling-design": "Sampling design",
    "project.settings.sampling-design.clustered-random": "Clustered random (random arrays)",
    "project.settings.sampling-design.experimental": "Experimental",
    "project.settings.sampling-design.label": "Sampling design",
    "project.settings.sampling-design.opportunistic": "Opportunistic",
    "project.settings.sampling-design.placeholder": "Select sampling design",
    "project.settings.sampling-design.simple-random": "Simple random",
    "project.settings.sampling-design.systematic-random": "Systematic random",
    "project.settings.sampling-design.targeted": "Targeted",
    "project.settings.seconds": "seconds",
    "project.settings.sensormethod": "Sensor method",
    "project.settings.sensormethod.motion-detection": "Motion detection",
    "project.settings.sensormethod.select-sensormethod": "Select sensor method",
    "project.settings.sensormethod.timelapse": "Timelapse",
    "project.settings.sequence-cut-off": "Sequence cut-off",
    "project.settings.sequence-cutoff-disabled": "Sequence cut-off can not be changed when sequences have been added.",
    "project.settings.species": "Species",
    "project.settings.species.added": "Added to project",
    "project.settings.species.available": "Available species",
    "project.settings.species.common-name": "Common name",
    "project.settings.species.countDeployments": "Deployments",
    "project.settings.species.countObservations": "Observations",
    "project.settings.species.countValObservations": "Validated",
    "project.settings.species.edit": "Edit",
    "project.settings.species.family": "Family",
    "project.settings.species.genus": "Genus",
    "project.settings.species.isIdentifiableAsDomesticated": "Domesticated",
    "project.settings.species.order": "Order",
    "project.settings.species.remove": "Remove",
    "project.settings.species.species": "Species",
    "project.settings.success-message": "Project settings saved.",
    "project.settings.title": "Project settings",
    "project.settings.update-location.cancel": "Cancel",
    "project.settings.update-location.save": "Save",
    "project.settings.update-location.title": "Edit location",
    "project.settings.utc-offset": "Default UTC offset",
    "project.user-management.accept-pending-request-confirmation": "Are you sure you want to accept this request?",
    "project.user-management.awaiting-access": "Awaiting access",
    "project.user-management.copy": "Copy invite link",
    "project.user-management.copy.confirmation": "Link copied",
    "project.user-management.delete-user-confirmation": "Are you sure you want to revoke this member's access to this project?",
    "project.user-management.edit-user.cancel": "Cancel",
    "project.user-management.edit-user.email": "Email address",
    "project.user-management.edit-user.email-placeholder": "Email address",
    "project.user-management.edit-user.first-name": "First name",
    "project.user-management.edit-user.first-name-placeholder": "First name",
    "project.user-management.edit-user.last-name": "Last name",
    "project.user-management.edit-user.last-name-placeholder": "Last name",
    "project.user-management.edit-user.member-since": "Member since",
    "project.user-management.edit-user.revoke-access": "Revoke access",
    "project.user-management.edit-user.role": "Role",
    "project.user-management.edit-user.save": "Save",
    "project.user-management.edit-user.title": "Edit user",
    "project.user-management.email-address": "Email address",
    "project.user-management.first-name": "First name",
    "project.user-management.last-name": "Last name",
    "project.user-management.link-description": "Use this link to allow users to request access to your project.",
    "project.user-management.pending-requests": "Pending requests",
    "project.user-management.pending-requests.accept": "Accept",
    "project.user-management.pending-requests.decline": "Decline",
    "project.user-management.pending-requests.empty": "No pending requests found",
    "project.user-management.principal-investigators": "Principal investigators",
    "project.user-management.project-access": "Project access",
    "project.user-management.registered-users": "Members",
    "project.user-management.registered-users.delete": "Delete",
    "project.user-management.registered-users.edit": "Edit",
    "project.user-management.role": "Role",
    "project.user-management.title": "User management",
    "project.user-management.userbase": "Members",
    "project.user-management.userbase.title": "Userbase",
    "project.user-management.volunteers": "Volunteers",
    "project.validate.accept-sequence": "Accept sequence observations",
    "project.validate.accepted-by-at": "Validated by {validator} on {date}",
    "project.validate.add-new-query": "Add new query",
    "project.validate.all-are-true": "All of the following predicates are true",
    "project.validate.edit.title": "Edit validation query",
    "project.validate.filter.created-from": "Created from",
    "project.validate.filter.created-to": "Created to",
    "project.validate.filter.is-equal-to": "Is equal to",
    "project.validate.filter.is-unknown": "Is unknown",
    "project.validate.filter.sampling-point": "Sampling point",
    "project.validate.filter.select-filter-type": "Select filter type",
    "project.validate.filter.select-role": "Select project role",
    "project.validate.filter.select-sampling-point": "Select sampling point",
    "project.validate.filter.select-species": "Select species",
    "project.validate.filter.select-user": "Select user",
    "project.validate.filter.species": "Species",
    "project.validate.filter.user": "User",
    "project.validate.filter.user-project-role": "Project role",
    "project.validate.new.title": "New validation query",
    "project.validate.no-rules-defined-yet": "No predicates defined yet...",
    "project.validate.no-validation-queries": "There are no validation queries defined for this project yet",
    "project.validate.none-are-true": "None of the following predicates are true",
    "project.validate.predicates": "Predicates",
    "project.validate.query-is-failed": "Something went wrong during the execution of the query...",
    "project.validate.query-is-running": "Query is running...",
    "project.validate.query-is-scheduled": "Query is scheduled...",
    "project.validate.results.assest-count": "Asset count",
    "project.validate.results.no-results": "No results found...",
    "project.validate.results.show-done": "Show validated sequences",
    "project.validate.results.total-results": "Total results",
    "project.validate.results.total-results-of": "Showing {count} of {total} results (max {limit})",
    "project.validate.rule-presets": "Rule presets",
    "project.validate.scheduling-explanation": "Queries are run every hour. Execution takes a few minutes, up to half an hour. Please refresh the page to update the status of the validation queries.",
    "project.validate.sequences": "Sequences",
    "project.validate.table.header.candidates": "Matches",
    "project.validate.table.header.name": "Name",
    "project.validate.title": "Validate",
    "project.validate.unaccept-sequence": "Remove validation",
    "project.validate.validate": "validate",
    "project.validate.validation-query-results": "Validation query results",
    "project.zooniverse.password": "Zooniverse password",
    "project.zooniverse.password-placeholder": "Zooniverse password",
    "project.zooniverse.projectid": "Zooniverse Projectid",
    "project.zooniverse.projectid-placeholder": "Zooniverse Projectid",
    "project.zooniverse.username": "Zooniverse username",
    "project.zooniverse.username-placeholder": "Zooniverse username",
    "projectrole.ADMIN": "Admin",
    "projectrole.AWAITING_ACCESS": "Awaiting access",
    "projectrole.DUMMY": "Dummy",
    "projectrole.PHOTO_PROCESSOR": "Photo processor",
    "projectrole.PRINCIPAL_INVESTIGATOR": "Principal investigator",
    "projectrole.PROJECT_COORDINATOR": "Project coordinator",
    "projectrole.TAXONOMIC_EXPERT": "Taxonomic expert",
    "projectrole.VIEW_ONLY": "View Only",
    "projectrole.VOLUNTEER": "Volunteer",
    "projects.add": "Add new project",
    "projects.independent-projects": "Independent projects",
    "projects.no-projects-available": "No projects yet. Please contact your project coordinator for instructions.",
    "projects.search-for": "Search for...",
    "projects.search-for.filter": "Filter projects",
    "projects.search-for.filterOrg": "Filter on organisation",
    "projects.title": "Projects",
    "projects.title.user": "Your Projects",
    "projects.your-role": "Your role",
    "publichome.block1.title": "What is Agouti?",
    "publichome.block1.txt": "Camera traps provide an easy and non-invasive way to study wildlife. However, manually processing the millions of images that a typical study generates quickly becomes a challenge. By leveraging artificial intelligence, Agouti can automatically recognize many species and filter blank images.",
    "publichome.block1.txt2": "Agouti is a complete solution for organizations and professionals that use camera traps to survey wildlife. It lets camera trappers organize surveys, efficiently process images, obtain standardized output of the results, and safely archive your data. Agouti follows the <a target=\"_blank\" href=\"https://tdwg.github.io/camtrap-dp\">Camera Trap Data Package</a> standard, a community developed data exchange format for camera trap data.",
    "publichome.block2.title": "Workflow",
    "publichome.block2.txt": "After collecting cameras from the field, users upload the entire contents of the memory cards to Agouti. You enter the location and deployment details and Agouti automatically pulls timestamps and other metadata from the images, and groups images in sequences that represent the same event. You then have the option to apply one of our automatic species classification models, or inspect each image sequence manually and annotate them with one or more observations, using an easy interface.",
    "publichome.block2.txt2": "You can export your data as a Camera Trap Data Package (<a target=\"_blank\" href=\"https://tdwg.github.io/camtrap-dp/\">Camtrap DP</a>) for analysis in software such as R. We recommend using the R package <a target=\"_blank\" href=\"https://inbo.github.io/camtraptor/\">camtraptor</a> as a start.",
    "publichome.block3.title": "Import",
    "publichome.block3.txt": "Upload images and videos by drag-and-drop using only your browser",
    "publichome.block3.txt2": "Annotate",
    "publichome.block3.txt3": "Have our AI models automatically add observations to your image sequences",
    "publichome.block3.txt4": "Analyze",
    "publichome.block3.txt5": "Browse and check data with built-in tools and presentations",
    "publichome.block3.txt6": "Export",
    "publichome.block3.txt7": "Generate standardized camtrap-dp data files for analysis or sharing",
    "publichome.block4.title": "Intuitive Interface",
    "publichome.block4.txt": "Agouti’s interface is aimed at professionals looking for productivity, but is simple enough for anyone to understand with little training. The videos below illustrate several aspects of the system.",
    "publichome.block4.txt1": "Getting started",
    "publichome.block4.txt2": "Importing deployments",
    "publichome.block4.txt3": "Annotating sequences",
    "publichome.block4.vid1": "/assets/videos/GettingStarted-EN.mp4",
    "publichome.block4.vid2": "/assets/videos/ImportingDeployments-EN.mp4",
    "publichome.block4.vid3": "/assets/videos/AnnotatingSequences-EN.mp4",
    "publichome.block5.title": "Highlighted projects",
    "publichome.block5.txt": "Agouti already hosts hundreds of projects. Explore some of these public projects to discover Agouti's possibilities.",
    "publichome.block6.f1": "Manage",
    "publichome.block6.f2": "Sampling design",
    "publichome.block6.f3": "Spatial plan",
    "publichome.block6.f4": "Track cameras",
    "publichome.block6.f5": "Custom deployment tags",
    "publichome.block6.f6": "Quality control",
    "publichome.block6.g1": "Process",
    "publichome.block6.g2": "AI species classification",
    "publichome.block6.g3": "Standardized species list",
    "publichome.block6.g4": "Exports Camera Trap Data Packages",
    "publichome.block6.g5": "Tag individual animals",
    "publichome.block6.g6": "Custom behaviour classes",
    "publichome.block6.h1": "Collaborate",
    "publichome.block6.h2": "Invite members",
    "publichome.block6.h3": "Clear role system",
    "publichome.block6.h4": "Assign tasks to users",
    "publichome.block6.h5": "Monitor progress",
    "publichome.block6.h6": "Keep your data safe",
    "publichome.block6.title": "Features",
    "publichome.block6.txt": "With Agouti you have an complete set of tools at your disposal.",
    "publichome.block7.title": "Private & Secure",
    "publichome.block7.txt": "Data ownership matters. Agouti does not claim any ownership of or license on your data. We do not use, share or distribute data to third parties without your written permission. Your data is not used by Wageningen University to improve our AI models without your written permission. You always maintain full control who gets access to your projects, so you can collaborate on your own terms and timeline.",
    "publichome.block7.txt2": "Agouti stores all data at the two data centers of Wageningen University in the Netherlands. Our fully redundant infrastructure ensures optimal access to your data and eliminates the risk of data loss by failing hard drives, user error or crashing computers. Agouti does not use any commercial cloud services for data storage.",
    "publichome.block8.f1": "Academic",
    "publichome.block8.f2": "Free of charge",
    "publichome.block8.f3": "Unlimited storage",
    "publichome.block8.f4": "Unlimited projects & users",
    "publichome.block8.f5": "Best effort support",
    "publichome.block8.f6": "Get started",
    "publichome.block8.g1": "Non-profit",
    "publichome.block8.g2": "Free of charge",
    "publichome.block8.g3": "Unlimited storage",
    "publichome.block8.g4": "Unlimited projects & users",
    "publichome.block8.g5": "Best effort support",
    "publichome.block8.g6": "Get started",
    "publichome.block8.h1": "Commercial",
    "publichome.block8.h2": "Free, donations welcome",
    "publichome.block8.h3": "Unlimited storage",
    "publichome.block8.h4": "Unlimited projects & users",
    "publichome.block8.h5": "Best effort support",
    "publichome.block8.h6": "Get started",
    "publichome.block8.title": "Start using Agouti",
    "publichome.block8.txt": "Our aim is to facilitate camera trappers in their work, and prevent data from being lost forever. Therefore, we offer Agouti free of charge to academic, non-profit and commercial users. We do appreciate if commercial parties contribute some funds to help to cover hosting and development expenses.",
    "publichome.explore-projects": "Explore projects",
    "publichome.footer1": "Agouti",
    "publichome.footer10": "6708 PB Wageningen",
    "publichome.footer11": "The Netherlands",
    "publichome.footer12": "Research Institute for Nature and Forest (INBO)",
    "publichome.footer13": "Wildlife Management and Alien Species & Open science lab for biodiversity",
    "publichome.footer14": "Havenlaan 88 bus 73",
    "publichome.footer15": "1000 Brussel",
    "publichome.footer16": "Belgium",
    "publichome.footer2": "User mailing list",
    "publichome.footer3": "Privacy policy",
    "publichome.footer5": "Organization",
    "publichome.footer6": "Agouti is an non-profit initiative of Wageningen University and the Research Institute for Nature and Forest (INBO)",
    "publichome.footer7": "Wageningen University",
    "publichome.footer8": "Department of environmental sciences",
    "publichome.footer9": "Wildlife Ecology and Conservation Group",
    "publichome.slogan": "AI powered solutions for wildlife camera-trapping projects",
    "publichome.stats1": "Projects",
    "publichome.stats2": "Deployments",
    "publichome.stats3": "Observations",
    "publichome.stats4": "Images",
    "publichome.title": "Agouti",
    "sequence.id": "Sequence ID",
    "sex.": "N.A.",
    "sex.female": "Female",
    "sex.male": "Male",
    "sex.unknown": "Unknown",
    "taxonRank": "Taxon Rank",
    "toggle-processed": "Hide 100%",
    "user.register.desc": "Register for an Agouti account",
    "user.register.email-address": "E-mail",
    "user.register.email-address-duplicate": "Email address already in use.",
    "user.register.email-address-error": "Please fill in your email.",
    "user.register.first-name": "First name",
    "user.register.first-name-error": "Please fill in your first name.",
    "user.register.gdpr-error": "Please confirm GDPR consent",
    "user.register.last-name": "Last name",
    "user.register.last-name-error": "Please fill in your last name.",
    "user.register.password": "Password",
    "user.register.password-error": "Please fill in a password.",
    "user.register.title": "Register",
    "user.request-reset-password.desc": "Request a password reset link",
    "user.request-reset-password.title": "Reset password",
    "user.reset-password.desc": "Choose a new Agouti password",
    "user.reset-password.email-address": "E-mail",
    "user.reset-password.email-address-duplicate": "Email address already in use.",
    "user.reset-password.email-address-error": "Please fill in your email.",
    "user.reset-password.password": "Password",
    "user.reset-password.password-are-different-error": "Please enter the same password twice.",
    "user.reset-password.password-confirmation": "Password confirmation",
    "user.reset-password.password-confirmation-error": "Please fill in a password confirmation.",
    "user.reset-password.password-error": "Please fill in a password.",
    "user.reset-password.title": "Reset password",
    "user.settings.account": "Account",
    "user.settings.adaptive-image-size": "Adaptive image size",
    "user.settings.annotate-shortcuts-label": "Annotate shortcuts",
    "user.settings.anonymous-in-export": "Anonymous in exports",
    "user.settings.anonymous-in-export-explanation": "When checked, your personal data will be anonymized in exports. Your name and email will not be included in data exports and you will not be credited as contributor, setup person and/or classifier.",
    "user.settings.autoscroll-thumbnails-annotating": "Auto-scroll thumbnails when annotating",
    "user.settings.common": "Common",
    "user.settings.email-address": "Email address",
    "user.settings.email-address-label": "Email",
    "user.settings.email-in-use": "Email address already in use.",
    "user.settings.first-name": "First name",
    "user.settings.first-name-note": "This is what other members will see.",
    "user.settings.gravatar": "Or enable <a href=\"http://gravatar.com\" title=\"http://gravatar.com\">Gravatar</a>",
    "user.settings.gravatar.disable": "Disable",
    "user.settings.gravatar.enable": "Enable",
    "user.settings.language": "Language",
    "user.settings.last-name": "Last name",
    "user.settings.organisation": "Organisation",
    "user.settings.organisation-placeholder": "Organisation",
    "user.settings.password": "Password",
    "user.settings.picture": "Public avatar",
    "user.settings.picture.browse": "Browse...",
    "user.settings.picture.file": "No file chosen",
    "user.settings.picture.remove": "Remove",
    "user.settings.picture.upload": "Upload new avatar",
    "user.settings.save": "Save changes",
    "user.settings.save.update": "Update settings",
    "user.settings.saved": "Settings saved.",
    "user.settings.scientific": "Scientific",
    "user.settings.species-names": "Species names",
    "user.settings.title": "User Settings",
    "user.settings.url.label": "Url (ORCID)",
    "user.settings.url.placeholder": ""
  }], ["es", {
    "age.": "N.A.",
    "age.adult": "Adulto",
    "age.infant": "Juvenil",
    "age.subadult": "Sub-Adulto",
    "age.unknown": "Desconocido",
    "application.form.cancel": "Cancelar",
    "application.form.close": "Cerrar",
    "application.form.delete": "Borrar",
    "application.form.edit": "Editar",
    "application.form.fields.description": "Descripción",
    "application.form.fields.name": "Nombre",
    "application.form.save": "Guardar",
    "application.form.saved": "Guardado",
    "application.format.date": "MM/DD/YYYY",
    "application.format.date-time": "MM/DD/YYYY HH:mm",
    "application.gdpr.continue": "Antes de continuar, por favor, autoriza a la Universidad de Wageningen y a Wageningen Research a almacenar y tratar tu nombre y dirección de correo electrónico con fines de investigación científica.",
    "application.gdpr.text": "Al prestar tu consentimiento, aceptas facilitar estos datos personales de manera voluntaria. Tienes derecho a retirar este consentimiento una vez prestado. Los datos personales que nos facilites se utilizarán únicamente con la finalidad para la que los hayas proporcionado. Tienes derecho al acceso, cancelación, rectificación y limitación del tratamiento de los datos personales, así como el derecho de reclamación y de portabilidad de los datos. <a target=\"_blank\" href=\"https://www.wur.nl/en/About-Wageningen/Integrity-and-privacy.htm\">https://www.wur.nl/en/About-Wageningen/Integrity-and-privacy.htm</a> Nota: no existe ningún derecho absoluto para las personas involucradas. Se estudiará siempre en función del caso y el derecho invocado. Si el tratamiento es necesario para la realización de una tarea obligatoria o el interés general (por ejemplo: con fines de archivo por interés público, con fines de investigación científica o histórica o para estadísticas), en tal caso no existirñá el derecho al olvido. Si deseas enviar una reclamación a WUR, puedes hacerlo a través de <a href=\"mailto:privacy@wur.nl\">privacy@wur.nl</a>. También puedes presentar una reclamación ante la Autoridad de Protección de Datos de los Países Bajos. Se puede obtener más información sobre esta cuestión en <a target=\"_blank\" href=\"https://www.autoriteitpersoonsgegevens.nl\">www.autoriteitpersoonsgegevens.nl</a>. Si tienes alguna pregunta, puedes ponerte en contacto con el Director de Protección de Datos de WUR a través de <a href=\"mailto:functionarisgegevensbescherming@wur.nl\">functionarisgegevensbescherming@wur.nl</a>.",
    "application.gdpr.title": "RGPD",
    "application.gdpr.user-consent": "Autorizo a la Universidad de Wageningen y a Wageningen Research a almacenar y tratar mi nombre y dirección de correo electrónico con fines de investigación científica.",
    "application.menu.dashboard": "Panel de control",
    "application.menu.login": "Iniciar sesión",
    "application.menu.logout": "Cerrar sesión",
    "application.menu.organisations": "Organizaciones",
    "application.menu.register": "Registro",
    "application.menu.settings": "Ajustes",
    "application.menu.userguide": "Guía del usuario",
    "application.menu.yourtasks": "Tus tareas",
    "class": "Class",
    "colophon.contributors": "Organizaciones",
    "colophon.desc": "Agouti es una aplicación para gestionar proyectos de cámaras trampa y analizar los datos.",
    "colophon.inbo": "Research Institute for Nature and Forest (INBO)",
    "colophon.partners": "Socios",
    "colophon.release": "Versión",
    "colophon.title": "Sobre Agouti",
    "colophon.version": "v3.8.0 / 27-5-2021",
    "colophon.wur": "Wageningen University",
    "component.bait-selector.bait": "Cebo",
    "component.bait-selector.baits": "Cebos",
    "component.bait-selector.select-bait": "Seleccionar cebo",
    "component.bait-selector.select-baits": "Seleccionar cebos",
    "component.deployment-calibration-form": "Calibración de implementación",
    "component.deployment-calibration-form.go-to-marking": "Salta a",
    "component.deployment-calibration-form.height": "altura",
    "component.deployment-calibration-form.select-label.bottom": "Parte inferior del palo",
    "component.deployment-calibration-form.select-label.top": "Parte superior del palo",
    "component.deployment-row.age": "Edad",
    "component.deployment-row.blanks": "Vacías",
    "component.deployment-row.datetime": "Fecha y hora",
    "component.deployment-row.edit": "Editar cámara trampa",
    "component.deployment-row.hideDetails": "Ocultar detalles",
    "component.deployment-row.no-observations-added": "No se han añadido observaciones.",
    "component.deployment-row.observed": "Especies observadas",
    "component.deployment-row.person": "Persona",
    "component.deployment-row.quantity": "Cantidad",
    "component.deployment-row.setup": "Instalación / Recogida",
    "component.deployment-row.sex": "Sexo",
    "component.deployment-row.show": "Mostrar",
    "component.deployment-row.showDetails": "Mostrar detalles",
    "component.deployment-row.species": "Especie",
    "component.deployment-row.specifications": "Especificaciones",
    "component.deployment-row.view": "Ver observaciones",
    "component.droplet-deployment-uploader.cancel": "Cancelar",
    "component.droplet-deployment-uploader.creating-sequences": "Creando secuencias...",
    "component.droplet-deployment-uploader.guidance-part-1": "Agouti acepta la carga de fotos/videos individuales, así como archivos ZIP. Dependiendo de la cantidad de archivos de la cámara trampa funcionará mejor un método u otro.",
    "component.droplet-deployment-uploader.guidance-part-2": "Si la cámara trampa tiene menos de 4000 fotos o 25 vídeos, se debe buscar la carpeta que contiene estas fotos/vídeos y seleccionarlas todas.",
    "component.droplet-deployment-uploader.guidance-part-3": "Si se tienen más de 4000 fotos o 25 vídeos, se deben comprimir en un único archivo ZIP y subir ese archivo en su lugar. Es importante asegurar que todas las fotos/vídeos estén en la raíz del archivo ZIP. No se admiten subcarpetas.",
    "component.droplet-deployment-uploader.new-deployment": "Nuevo dispositivo",
    "component.droplet-deployment-uploader.progress": "Progreso",
    "component.droplet-deployment-uploader.select-files": "Explorar",
    "component.droplet-deployment-uploader.start-upload": "Empezar a subir archivos",
    "component.droplet-deployment-uploader.uploading": "Subiendo...",
    "component.edit-deployment.add-sampling-point": "Añadir punto de muestreo",
    "component.edit-deployment.camera-heading": "Camera heading",
    "component.edit-deployment.camera-height": "Altura de la cámara",
    "component.edit-deployment.camera-height.empty": "Altura de la cámara no encontrada",
    "component.edit-deployment.camera-identifier": "Identificador de cámara",
    "component.edit-deployment.camera-identifier-placeholder": "Identificador de cámara",
    "component.edit-deployment.camera-tilt": "Camera tilt",
    "component.edit-deployment.cm": "cm",
    "component.edit-deployment.created-at": "Creado en",
    "component.edit-deployment.created-by": "Creado por",
    "component.edit-deployment.distance-detection": "Distancia de detección",
    "component.edit-deployment.distance-detection.empty": "Distancia de detección no encontrada",
    "component.edit-deployment.end": "Final",
    "component.edit-deployment.end-placeholder": "Final",
    "component.edit-deployment.error.missing-fields": "Por favor, rellene todos los campos obligatorios.",
    "component.edit-deployment.groups-placeholder": "Seleccionar un grupo",
    "component.edit-deployment.invalid": "Las implementaciones inválidas se excluyen de las exportaciones de datos.",
    "component.edit-deployment.location": "Punto de muestreo",
    "component.edit-deployment.location-placeholder": "Punto de muestreo",
    "component.edit-deployment.notes": "Notas",
    "component.edit-deployment.notes-placeholder": "Comentarios",
    "component.edit-deployment.notes.empty": "Notas no encontradas",
    "component.edit-deployment.person-deploy": "Instalador de cámara",
    "component.edit-deployment.person-deploy-placeholder": "Seleccionar una persona",
    "component.edit-deployment.person-deploy.empty": "Instalador de cámara no encontradas",
    "component.edit-deployment.person-detect": "Persona que recoge",
    "component.edit-deployment.person-detect-placeholder": "Seleccionar una persona",
    "component.edit-deployment.person-detect.empty": "Persona que recoge no encontradas",
    "component.edit-deployment.save": "Finalizar dispositivo",
    "component.edit-deployment.start": "Inicio",
    "component.edit-deployment.start-placeholder": "Inicio",
    "component.edit-deployment.tags": "Etiqueta",
    "component.edit-deployment.tags-placeholder": "Seleccionar una o más etiquetas",
    "component.edit-deployment.tags.empty": "Etiquetas no encontradas",
    "component.edit-deployment.utc-offset": "Desplazamiento UTC",
    "component.edit-location.latitude": "Latitud",
    "component.edit-location.longitude": "Longitud",
    "component.edit-location.name": "Nombre",
    "component.identification-form.age": "Edad",
    "component.identification-form.amount": "Cantidad",
    "component.identification-form.behaviour": "Comportamiento",
    "component.identification-form.created-at": "Creado en",
    "component.identification-form.created-by": "Creado por",
    "component.identification-form.delete-observation": "Borrar observaciones",
    "component.identification-form.hide-optional": "Ocultar campos opcionales",
    "component.identification-form.identification": "Añadir observaciones",
    "component.identification-form.individual": "Individuos",
    "component.identification-form.is-domesticated": "Domesticados",
    "component.identification-form.modified-at": "Modificado por última vez en",
    "component.identification-form.modified-by": "Modificado por última vez por",
    "component.identification-form.no-individual": "No. individuos",
    "component.identification-form.notes": "Notas",
    "component.identification-form.save-observation": "Guardar observaciones",
    "component.identification-form.select-age": "Seleccionar edad",
    "component.identification-form.select-sex": "Seleccionar sexo",
    "component.identification-form.sex": "Sexo",
    "component.identification-form.show-all": "Mostrar todos los campos",
    "component.identification-form.species": "Especie",
    "component.identification-form.species-placeholder": "Especies",
    "component.identification-form.uncertain": "Incierto / Probable",
    "component.identification-form.uncertain-short": "Incierto",
    "component.identification-form.validated": "Validado",
    "component.is-identifiable-as-domesticated.no": "No",
    "component.is-identifiable-as-domesticated.yes": "Si",
    "component.observation-position-form.view-position": "Salta a",
    "component.observations-list.hide": "Ocultar",
    "component.observations-list.observations": "Listado de observaciones",
    "component.observations-list.quantity": "Cantidad",
    "component.observations-list.sex": "Sexo",
    "component.observations-list.show": "Mostrar",
    "component.observations-list.species": "Especies",
    "component.observations-list.uncertain": "Incierto / Probable",
    "component.observations-table.observation-type": "Tipo de observación",
    "component.observations-table.observator": "Observador",
    "component.overview-marker-popup.annotate": "Identificar",
    "component.overview-marker-popup.browse": "Explorar",
    "component.overview-marker-popup.collected-person": "Persona que recoge la cámara",
    "component.overview-marker-popup.date-of-import": "Fecha de importación",
    "component.overview-marker-popup.end": "Final",
    "component.overview-marker-popup.set-up-person": "Persona que instala la cámara",
    "component.overview-marker-popup.start": "Inicio",
    "component.overview-marker-popup.statistics": "{sequencesAnnotatedCount} de {sequencesCount} secuencias anotadas",
    "component.overview-marker-popup.uploaded-person": "Persona que sube los archivos de la cámara",
    "component.page-footer.all-rights-reserved": "CameraTrapLab & Wageningen University",
    "component.page-footer.colophon": "Sobre",
    "component.page-footer.power": "Desarrollado por Aguoti para",
    "component.positioning.angle": "Ángulo",
    "component.positioning.calculate-speed": "Calcular velocidad",
    "component.positioning.camera-and-deployment-calibration-missing": "Calibración de cámara y despliegue faltantes",
    "component.positioning.camera-calibration-missing": "Calibración de cámara faltante",
    "component.positioning.camera-calibration-present": "Calibración de cámara presente",
    "component.positioning.camera-details": "Detalles de la cámara",
    "component.positioning.camera-model-missing-with-support": "Falta calibración de cámara para este despliegue. Por favor, póngase en contacto con soporte.",
    "component.positioning.deployment-calibration-missing": "Calibración de despliegue faltante",
    "component.positioning.dimensions": "Dimensiones",
    "component.positioning.distance": "Distancia",
    "component.positioning.manufacturer": "Fabricante",
    "component.positioning.model": "Modelo",
    "component.positioning.preview-plots": "Gráficos de previsualización",
    "component.positioning.radius": "Radio",
    "component.positioning.speed": "Velocidad",
    "component.positioning.warning": "Advertencia",
    "component.remove-full-deployment.cancel": "Cancel",
    "component.remove-full-deployment.confirm-removal": "Confirm removal",
    "component.remove-full-deployment.delete": "Delete deployment",
    "component.remove-full-deployment.modal.error": "There was an error while removing. The error is:",
    "component.remove-full-deployment.modal.info": "<p>You are about to remove a deployment and all its related resources. This action cannot be undone.</p><p>Please type \"delete\" to confirm the removal</p>",
    "component.remove-full-deployment.modal.title": "Warning: confirm removal of deployment",
    "component.remove-full-deployment.validation-word": "delete",
    "component.shortcuts-list.animal": "Animal",
    "component.shortcuts-list.blank": "Vacío",
    "component.shortcuts-list.hide-all": "Esconder todo",
    "component.shortcuts-list.merge-with-previous": "Fusionar con el anterior",
    "component.shortcuts-list.merge-with-previous-time": "{amountOfMinutes} minutes elapsed since previous sequence",
    "component.shortcuts-list.merge-with-previous-time-seconds": "{amountOfSeconds} seconds elapsed since previous sequence",
    "component.shortcuts-list.merge-with-previous.modal.body": "Are you sure you want to merge this sequence with the previous one? Observations of this sequence will be removed.",
    "component.shortcuts-list.merge-with-previous.modal.confirm": "Yes, merge with previous",
    "component.shortcuts-list.merge-with-previous.modal.title": "Merge with previous",
    "component.shortcuts-list.setup-pickup": "Instalar / recoger",
    "component.shortcuts-list.shortcuts": "Acceso directo",
    "component.shortcuts-list.show-all": "Mostrar todo",
    "component.shortcuts-list.unknown": "desconocido",
    "component.shortcuts-list.vehicle": "Vehículo",
    "component.utc-offset-selector.example": "La marca de tiempo de la imagen de <code>{originalExampleTime}</code> se convierte en <code>{convertedExampleTime}</code> UTC.",
    "component.utc-offset-selector.help-text": "El desplazamiento UTC depende de la zona horaria en la que se configuró su cámara. Utilice una herramienta como <a href=\"https://www.timeanddate.com/worldclock/converter.html\" target=\"_blank\">timeanddate.com</a> para calcular el desplazamiento.",
    "component.utc-offset-selector.placeholder": "Seleccione el desplazamiento",
    "copied.id": "Id Copied to Clipboard",
    "deployment-row.camera-deployment-id": "Id de las cámaras",
    "deployment-row.card-id": "Id de la tarjeta",
    "deployment-row.information": "Información",
    "deployment-row.location": "Localización",
    "deployment-row.observations": "Observaciones",
    "deployment-row.tags": "Etiquetas",
    "deployment-row.usable": "Aprovechable",
    "deployment.id": "Deployment ID",
    "index.partners.1": "Colaboradores y Financiamiento",
    "index.partners.2": "Agouti está apoyando y colaborando con muchas iniciativas y proyectos, y es amablemente apoyado por fondos de varias organizaciones. Entre ellos están:",
    "index.partners.3": "Para crear <a href=\"https://mammalnet.com/\" target=\"_blank\">MammalNet</a> proyectos, contáctenos a través de la información que se encuentra a continuación.",
    "index.partners.4": "Con fondos de la <a href=\"https://www.efsa.europa.eu/\" target=\"_blank\">European Food and Safety Authority</a>",
    "index.partners.5": "Apoyado por <a href=\"https://www.lifewatch.be/\" target=\"_blank\">LifeWatch</a>",
    "info.annotate": "Después de importar el dispositivo las secuencias necesitan ser procesadas. Aquí se muestran aquellos dispositivos que tienen secuencias y que necesitan anotaciones. Se debe seleccionar <i>Anotar</i> para empezar a trabajar en la cámara. Se puede parar el procesado en cualquier momento, y después continuar desde el punto donde se dejó.",
    "info.annotate.title": "Procesando cámara",
    "info.annotationsettings": "Estos campos controlan el nombre de las especies mostradas en las páginas de identificaciones y el intervalo temporal de corte usado para crear las secuencias de las imágenes.",
    "info.annotationsettings.title": "Ajustes de identificaciones",
    "info.behaviours": "Añade un comportamiento a las observaciones añadiendo una opción a esta lista.",
    "info.behaviours.title": "Comportamiento",
    "info.browse": "Después de anotar las secuencias se pueden ver las observaciones que fueron añadidas a esta página. Hay que pinchar <i>Mostrar</i> al lado de cada cámara para que se muestre una secuencia y si es preciso corregir la observación.",
    "info.browse.title": "Buscando observaciones",
    "info.cameras": "Las cámaras son identificadas y registradas por el número de serie y guardadas en este listado centralizado. Esto quiere decir que también se muestrean las cámaras de otros proyectos. Por favor, antes de añadir una nueva entrada hay que comprobar si la cámara no está ya registrada. El número de serie de las cámaras Reconix puede localizarse al fondo al abrir la cámara, debajo del código de barras.",
    "info.cameras.title": "Cámaras trampa",
    "info.downloaddata": "Descargar el paquete de datos exportados. Todos los paquetes de datos son archivados, por lo que es posible referirse a una versión de descarga concreta.",
    "info.downloaddata.title": "Descargando datos",
    "info.export": "Cuando se exportan los datos, todas la observaciones se formatean usando el formato CSV, que puede ser leído y procesado por la mayoría de los programas. Después de generar un nuevo archivo, este puede descargarse desde la tabla de abajo.",
    "info.export.title": "Exportar datos",
    "info.generalsettings": "Esta sección contiene información general sobre su Proyecto. Como mínimo se debe rellenar  <i>Nombre, Descripción</i> y <i>Propietario del proyecto</i>",
    "info.generalsettings.title": "Información del proyecto",
    "info.import.upload.title": "Subir archivos",
    "info.import.uploadfiles": "Seleccionar <i>Subir archivos</i> para cargar en el servidor fotos o vídeos. Por favor, navegar hasta la carpeta que contiene todas las fotos o vídeos de la cámara trampa. Se recomienda  subir el material directamente desde la tarjeta de memoria, para evitar que otro software pueda dañar los metadatos.",
    "info.import.uploadfiles.title": "Subir fotos y vídeos",
    "info.individuals": "Registra avistamientos de individuos identificados, añadiéndoles a esta lista. Opcionalmente, se puede añadir esta información durante la anotación de las imágenes.",
    "info.individuals.title": "Identificación de individuos",
    "info.pendingusers": "Cuando un usuario registrado accede al proyecto, primero precisa autorización del coordinador. Las solicitudes pendientes se muestran en este listado. Después de aceptar a un usuario, si es preciso puede cambiarse su rol.",
    "info.pendingusers.title": "Solicitud entrante",
    "info.registeredusers": "Aquí se muestran aquellos usuarios con acceso al proyecto. También se puede cancelar el acceso o cambiar el rol de los usuarios. Advertencia: que para volver a reactivar a un usuario se precisa una nueva solicitud de acceso.",
    "info.registeredusers.title": "Usuario registrado",
    "info.sampling.": "Un punto de muestreo hace referencia a una localización de una o más cámaras que ha sido etiquetada por el usuario.",
    "info.sampling.title": "Puntos de muestreo",
    "info.samplingdesign": "Esta información debe indicar detalles del protocolo usado en el proyecto, no sobre los dispositivos individuales. Por ejemplo: se pueden estar testando diferentes cebos olorosos, o colocar las cámaras en comederos y/o bebederos. Es importante, describir con detalle el protocolo que se está llevando a cabo.",
    "info.samplingdesign.title": "Protocolo del proyecto",
    "info.specieslist": "Agouti usa la lista de especies estandarizadas referenciada en <a href=\"http://www.catalogueoflife.org/\" target=\"_blank\">Catalogue of Life</a>. Se pueden personalizar las especies que van a utilizarse en cada Proyecto.",
    "info.specieslist.title": "Listas de especies",
    "info.tags": "Las etiquetas pueden ser asignadas a dispositivos para ayudar a estructurar el proyecto. Son opcionales, con texto libre y pueden usarse varias en cada dispositivo. Por ejemplo: se pueden usar para indicar el tipo de cebo usado, el tipo de hábitat u otras características que se quieran definir.",
    "info.tags.title": "Etiquetas",
    "info.unfinishedupload": "En este listado aparecen aquellos archivos que se han subido pero que el formulario <i>importar cámara trampa</i> no se ha completado adecuadamente.  Pinchar en <i>Completar</i> para terminar de cumplimentar el formulario y concluir la importación. Si la carga de los archivos se interrumpió es preferible borrar el dispositivo/cámara trampa.",
    "info.unfinishedupload.title": "Cámara trampa incompleta",
    "info.user.settings": "Aquí se pueden actualizar los datos personales y cambiar el idioma de la interfaz de Agouti. Es importante tener en cuenta que el cambio del idioma de la interfaz puede que no cambie ciertos elementos especificados por el usuario, como las descripciones de proyectos o las etiquetas.",
    "info.user.settings.title": "Ajustes personales",
    "invalid": "Invalid",
    "language.locale.de": "German",
    "language.locale.en": "English",
    "language.locale.es": "Spanish",
    "language.locale.fr": "French",
    "language.locale.hr": "Croatian",
    "language.locale.nl": "Dutch",
    "language.locale.pl": "Polish",
    "leaflet-map-cartodb": "Por defecto",
    "leaflet-map-here-hybrid.day": "Híbrido",
    "leaflet-map-here-normal.day": "Normal",
    "leaflet-map-here-terrain.day": "Terreno",
    "link": "Link",
    "login.desc": "Para solicitar acceso a este proyecto, inicie sesión con su cuenta de Agouti.",
    "login.email-address": "Email",
    "login.password": "Contraseña",
    "login.remember-me": "Recuérdeme",
    "login.sign-in": "Inicie sesión",
    "login.sign-in-email": "No se reconoce la dirección de E-mail aportada.",
    "login.sign-in-error": "Credencial inválida",
    "login.sign-in-password": "Contraseña incorrecta.",
    "login.title": "Inicie sesión",
    "menu-item.annotate": "Anotar",
    "menu-item.browse": "Explorar",
    "menu-item.cameras": "Cámaras",
    "menu-item.deployments": "Cámaras trampa",
    "menu-item.export-data": "Exportar datos",
    "menu-item.import": "Importar",
    "menu-item.locations": "Ubicaciones",
    "menu-item.observations": "Observaciones",
    "menu-item.overview": "Vista general/Resumen",
    "menu-item.project-settings": "Ajustes del proyecto",
    "menu-item.sampling-management": "Gestión del muestreo",
    "menu-item.settings": "Ajustes",
    "menu-item.settings.behaviour": "Conducta",
    "menu-item.settings.deployment-groups": "Grupos de implementación",
    "menu-item.settings.deployment-tags": "Etiquetas de implementación",
    "menu-item.settings.general": "General",
    "menu-item.settings.individuals": "Individuos",
    "menu-item.species": "Especies",
    "menu-item.user-management": "Gestión usuarios",
    "menu-item.users": "Usuarios",
    "menu-item.validate": "Validar",
    "observation.id": "Observation ID",
    "portal.statistics.deployments": "Cámaras trampa",
    "portal.statistics.images": "Imágenes",
    "portal.statistics.observations": "Observaciones",
    "portal.statistics.projects": "Proyectos",
    "project-overview.show-less": "Show less",
    "project-overview.show-more": "Show more",
    "project-thumbnail.members": "{count, plural, other {# miembros} =1 {# miembros}}",
    "project-thumbnail.you-are": "Eres un {projectrole}",
    "project.annotate.annotate": "Identificar",
    "project.annotate.annotate-ai": "Anotar por IA",
    "project.annotate.annotate-view": "View annotations",
    "project.annotate.end": "Acabar",
    "project.annotate.inspected-sequences": "Secuencias finalizadas",
    "project.annotate.is-blank": "Secuencias vacías",
    "project.annotate.is-setup-pickup": "Instalación / recogida de secuencias",
    "project.annotate.location": "Punto de muestreo",
    "project.annotate.no-unannotated-deployments": "Dispositivo no identificado",
    "project.annotate.progress": "Progreso",
    "project.annotate.sequence.annotate-overview": "Anotar",
    "project.annotate.sequence.back": "Volver",
    "project.annotate.sequence.back-to-overview": "Back to overview",
    "project.annotate.sequence.dashboard": "Panel de control",
    "project.annotate.sequence.exposure": "Exposición",
    "project.annotate.sequence.finish-annotating": "Finalizar la anotación",
    "project.annotate.sequence.identify": "Identificación",
    "project.annotate.sequence.navigate-by-observation": "Lista de observaciones",
    "project.annotate.sequence.navigate-by-sequences": "Lista de secuencias",
    "project.annotate.sequence.next": "Próximo",
    "project.annotate.sequence.next-unannotated": "Próximo sin anotar",
    "project.annotate.sequence.observations.age": "Edad",
    "project.annotate.sequence.observations.back": "Volver a la lista",
    "project.annotate.sequence.observations.cancel": "Cancelar",
    "project.annotate.sequence.observations.quantity": "Cantidad",
    "project.annotate.sequence.observations.sequence": "Secuencia",
    "project.annotate.sequence.observations.sex": "Sexo",
    "project.annotate.sequence.observations.species": "Especie",
    "project.annotate.sequence.observations.suggested": "Sugerido",
    "project.annotate.sequence.observations.title": "Observaciones en{name}",
    "project.annotate.sequence.order": "Orden",
    "project.annotate.sequence.photo-of": "Foto {x} de {y}",
    "project.annotate.sequence.sequence-of": "Secuencia",
    "project.annotate.sequence.sequence-of2": "de {y}",
    "project.annotate.sequence.validation-status": "Estado de validación",
    "project.annotate.sequence.warning.unsaved-work.body": "Has estado editando una observación, asegúrate de guardar el trabajo antes de continuar",
    "project.annotate.sequence.warning.unsaved-work.close-modal": "Ok",
    "project.annotate.sequence.warning.unsaved-work.header": "Cuidado; observación no guardada",
    "project.annotate.sequences": "Secuencias",
    "project.annotate.start": "Iniciar",
    "project.annotate.title": "Identificar",
    "project.annotate.unannotated-deployments": "Dispositivo no identificado",
    "project.annotate.uninspected-sequences": "Secuencias pendientes",
    "project.browse.assign-to": "Asignar a",
    "project.browse.assigned-to": "Asignado a",
    "project.browse.deployments": "Cámaras trampa",
    "project.browse.desc": "En esta página puede encontrar todas las cámaras trampa pertenecientes al proyecto. <br/> Pulsando 'Mostrar detalles' podrá ver etiquetas, notas y especificaciones y tendrá la posibilidad de editar estos parámetros.",
    "project.browse.edit-deployment.title": "Editar cámara trampa",
    "project.browse.end": "Final",
    "project.browse.groups": "Grupos",
    "project.browse.location": "Punto de muestreo",
    "project.browse.modal.close": "Cerrar",
    "project.browse.no-deployments": "Cámara trampa no disponible.",
    "project.browse.sequences": "Número de secuencias",
    "project.browse.start": "Inicio",
    "project.browse.tags": "Etiquetas",
    "project.browse.title": "Explorador",
    "project.browse.unannotated": "Sin identificar",
    "project.browse.view-observations.delete": "¿Estás seguro de que quieres borrar esta observación?",
    "project.browse.view-observations.filter": "Mostrar observaciones solo con",
    "project.browse.view-observations.split": "Are you sure you want to split this observation?",
    "project.deployment.aimodel.analyzing": "La IA está ocupada",
    "project.deployment.aimodel.failed": "La IA ha fallado",
    "project.deployment.aimodel.scheduled": "La IA está programada ..",
    "project.deployment.aimodel.success": "La IA está lista ..",
    "project.deployment.complete.annotate": "Anotar este dispositivo",
    "project.deployment.complete.deployment-successfully-imported": "Dispositivo importado con éxito",
    "project.deployment.complete.desc": " Por favor, rellena estos datos para finalizar la importación de este dispositivo",
    "project.deployment.complete.import-new": "Importar otro dispositivo",
    "project.deployment.complete.success-message": "<b>Importación concluida.</b>",
    "project.deployment.complete.success-message.close": "Anotar para este dispositivo.",
    "project.deployment.complete.title": "Nuevo dispositivo",
    "project.deployment.set-timestamp.filter": "Filtra",
    "project.deployment.set-timestamp.save": "Continua",
    "project.deployment.set-timestamps.desc": "Agouti no pudo encontrar la fecha y hora de estos archivos.<br>Por favor, introduce la hora manualmente para que Agouti pueda procesar las secuencias correctamente.",
    "project.deployment.set-timestamps.file": "Nombre del archivo",
    "project.deployment.set-timestamps.timestamp": "Marca de agua de tiempo (anotación para 24 horas)",
    "project.deployment.set-timestamps.title": "Añadir marca de agua de tiempo",
    "project.export.create-export": "Crear exportaciones",
    "project.export.datasetId": "ID de los datos",
    "project.export.delete": "Borrar",
    "project.export.download": "Descargar",
    "project.export.filename": "Nombre del archivo",
    "project.export.no-exports-created": "No hay exportaciones creadas.",
    "project.export.previous-exports": "Exportaciones previas",
    "project.export.size": "Tamaño",
    "project.export.status": "Estatus",
    "project.export.time-of-export": "Hora y fecha de exportación",
    "project.export.title": "Exportar datos",
    "project.export.title.sub": "Exportar observaciones",
    "project.import.assets": "Recursos",
    "project.import.complete": "Completo",
    "project.import.create": "Nuevo dispositivo",
    "project.import.create-sequence-confirmation": "¿Estás seguro que quieres crear secuencias para esta importación?",
    "project.import.create-sequences": "Crear secuencias",
    "project.import.delete": "Borrar",
    "project.import.delete-deployment-confirmation": "¿Estás seguro de que quieres borrar la importación?",
    "project.import.end": "Final",
    "project.import.files": "archivo seleccionado",
    "project.import.folder": "Carpeta",
    "project.import.guidance": "Selecciona la carpeta que contiene imágenes y vídeos de este dispositivo.",
    "project.import.images": "Fotos",
    "project.import.new-deployment.new-deployment": "Importar dispositivo",
    "project.import.new-deployment.no-unfinished-imports": "No hay dispositivos pendientes.",
    "project.import.new-deployment.title": "Importar",
    "project.import.new-deployment.unfinished-imports": "Importación incompleta",
    "project.import.process": "Procesar",
    "project.import.sequences": "Nº de secuencias",
    "project.import.start": "Inicio",
    "project.import.status": "Estado",
    "project.import.timestamp": "Marca de tiempo",
    "project.import.type": "Tipo",
    "project.import.upload": "Cargar",
    "project.import.upload-modal.cancel": "Cerrar",
    "project.import.upload-modal.desc1": "Por favor, selecciona y carga tus archivos. Actualmente, se aceptan archivos JPG y AVI.",
    "project.import.upload-modal.desc2": " O arrastra y sualte las imágenes en este área.",
    "project.import.upload-modal.select": "Seleccionar archivos",
    "project.import.upload-modal.title": "Cargar archivos",
    "project.import.upload-modal.uploading": "Uploading {queueFilesLength} files. Succesfully uploaded {uploaded} files, {duplicates} duplicates and {failed} files failed.",
    "project.import.uploaded": "Cargar",
    "project.import.viabrowser": "Vía explorador",
    "project.import.viaftp": "Vía FTP",
    "project.overview.achievements": "Logros",
    "project.overview.browse": "Explorador",
    "project.overview.deployment": "Cámara trampa",
    "project.overview.deployment-for-location": "Cámara trampa para el punto de muestreo",
    "project.overview.deployments": "Cámaras trampa",
    "project.overview.desc": "Descripción del proyecto",
    "project.overview.favourite-assets": "Favourites",
    "project.overview.favourite-sequences": "Secuencias favoritas",
    "project.overview.import-deployment": "Importar cámaras trampa",
    "project.overview.imported-deployments": "Cámaras trampa importadas",
    "project.overview.most.observed.species": "Especies más observadas",
    "project.overview.no-deployments": "No hay cámaras trampa disponibles.",
    "project.overview.observations": "Observaciones",
    "project.overview.read.hide": "Ocultar",
    "project.overview.read.show": "Leer más",
    "project.overview.records": "Registros",
    "project.overview.records-content": "Las especies más observadas en este proyecto",
    "project.overview.sequences": "Secuencias",
    "project.overview.stats": "Estadísticas",
    "project.overview.team.members": "Miembros del equipo",
    "project.overview.unfinished-deployments": "Cámaras trampa incompletas",
    "project.request-access.description": "Haz clic en 'Solicitar' para confirmar solicitud de acceso a este proyecto.",
    "project.request-access.request": "Solicitar acceso",
    "project.request-access.title": "Solicitar acceso",
    "project.sampling-management.add-group": "Añadir nuevo grupo",
    "project.sampling-management.add-group-title": "Añadir nuevo grupo",
    "project.sampling-management.add-sampling-point": "Añadir nuevos puntos de muestreo",
    "project.sampling-management.add-sampling-point.title": "Añadir nuevos puntos de muestreo",
    "project.sampling-management.add-samplingpoint.cancel": "Cancelar",
    "project.sampling-management.add-samplingpoint.missing-coordinates": "Por favor ingrese nombre y coordenadas",
    "project.sampling-management.add-samplingpoint.save": "Guardar",
    "project.sampling-management.add-tag": "Añadir nueva etiqueta",
    "project.sampling-management.add-tag.cancel": "Cancelar",
    "project.sampling-management.add-tag.name": "Nombre",
    "project.sampling-management.add-tag.name-placeholder": "Nombre",
    "project.sampling-management.add-tag.save": "Guardar",
    "project.sampling-management.add-tag.title": "Añadir etiqueta",
    "project.sampling-management.delete-tag.confirmation": "¿Seguro que deseas eliminar esta etiqueta?",
    "project.sampling-management.edit-group.title": "Editar grupo",
    "project.sampling-management.edit-sampling-point.cancel": "Cancelar",
    "project.sampling-management.edit-sampling-point.save": "Guardar",
    "project.sampling-management.edit-sampling-point.title": "Editar punto de muestreo",
    "project.sampling-management.edit-tag.cancel": "Cancelar",
    "project.sampling-management.edit-tag.name": "Nombre",
    "project.sampling-management.edit-tag.name-placeholder": "Nombre",
    "project.sampling-management.edit-tag.save": "Guardar",
    "project.sampling-management.edit-tag.title": "Editar etiqueta",
    "project.sampling-management.sampling-points": "Puntos de muestreo",
    "project.sampling-management.sampling-points.name": "Nombre",
    "project.sampling-management.samplingpoints.delete": "Borrar",
    "project.sampling-management.samplingpoints.edit": "Editar",
    "project.sampling-management.tags": "Etiqueta del dispositivo",
    "project.sampling-management.tags.delete": "Borrar",
    "project.sampling-management.tags.edit": "Editar",
    "project.sampling-management.tags.name": "Nombre",
    "project.sampling-management.title": "Gestión del muestreo",
    "project.sampling-management.upload-csv": "Cargar archivo CSV",
    "project.sequence.import.failed": "Sequence import failed.",
    "project.sequence.import.importing": "Importing sequences",
    "project.sequence.import.importing.note": "Note: depending on the size of the upload, how busy the server is, this can take a while.<br>You don't need to wait for the creation to complete.",
    "project.sequence.import.importing.success": "Sequences ready!",
    "project.sequence.import.status": "Status of sequence import",
    "project.settings.add-behaviour": "Añadir nuevo comportamiento",
    "project.settings.add-behaviour.cancel": "Cancelar",
    "project.settings.add-behaviour.description": "Descripción",
    "project.settings.add-behaviour.description-placeholder": "Descripción",
    "project.settings.add-behaviour.name": "Nombre",
    "project.settings.add-behaviour.name-placeholder": "Nombre",
    "project.settings.add-behaviour.save": "Guardar",
    "project.settings.add-behaviour.title": "Añadir comportamiento",
    "project.settings.add-camera": "Añadir nueva cámara",
    "project.settings.add-camera.cancel": "Cancelar",
    "project.settings.add-camera.label": "Etiqueta",
    "project.settings.add-camera.label-placeholder": "Etiqueta",
    "project.settings.add-camera.make": "Marca Cámara",
    "project.settings.add-camera.make-placeholder": "Marca Cámara",
    "project.settings.add-camera.model": "Modelo",
    "project.settings.add-camera.model-placeholder": "Modelo",
    "project.settings.add-camera.save": "Guardar",
    "project.settings.add-camera.serial-number": "no. de serie",
    "project.settings.add-camera.serial-number-placeholder": "no. de serie",
    "project.settings.add-camera.title": "Añadir cámara",
    "project.settings.add-camera.year-of-purchase": "Año de compra",
    "project.settings.add-deployment": "Añadir despliegues",
    "project.settings.add-individual": "Añadir nuevos individuos",
    "project.settings.add-individual.age": "Edad",
    "project.settings.add-individual.cancel": "Cancelar",
    "project.settings.add-individual.name": "Nombre",
    "project.settings.add-individual.name-placeholder": "Nombre",
    "project.settings.add-individual.notes": "Notas",
    "project.settings.add-individual.notes-placeholder": "Notas",
    "project.settings.add-individual.save": "Guardar",
    "project.settings.add-individual.sex": "Sexo",
    "project.settings.add-individual.species": "Especie",
    "project.settings.add-individual.title": "Añadir individuos",
    "project.settings.add-location": "Añadir localización",
    "project.settings.add-species": "Añadir especies",
    "project.settings.add-species-list": "Agregar lista de especies",
    "project.settings.add-species-to-add": "Especies a agregar",
    "project.settings.add-species.cancel": "Cancelar",
    "project.settings.add-species.save": "Guardar",
    "project.settings.add-species.species": "Especies",
    "project.settings.add-species.species-placeholder": "Especies",
    "project.settings.add-species.title": "Añadir especies",
    "project.settings.annotate-calibration-and-tracking": "Anotar calibración y seguimiento",
    "project.settings.annotate-timelapses": "Anotar timelapses",
    "project.settings.annotate-timelapses-disabled": "Los timelapses no se pueden anotar cuando se han agregado secuencias.",
    "project.settings.annotation": "Anotar",
    "project.settings.automatic-annotation": "Detección automática",
    "project.settings.automatic-annotation-on-upload": "Automáticamente con nueva serie de fotos",
    "project.settings.automatic-annotation.decription": "Cuando está habilitado, la IA procesa primero las nuevas implementaciones y se pueden editar una vez que se completa.",
    "project.settings.automatic-annotation.placeholder": "Seleccione un modelo",
    "project.settings.behaviour": "Comportamiento",
    "project.settings.behaviour.delete": "Borrar",
    "project.settings.behaviour.description": "Descripción",
    "project.settings.behaviour.edit": "Editar",
    "project.settings.behaviour.name": "Nombre",
    "project.settings.camera.delete": "Borrar",
    "project.settings.camera.edit": "Editar",
    "project.settings.camera.label": "Etiqueta",
    "project.settings.camera.make": "Marca",
    "project.settings.camera.model": "Modelo",
    "project.settings.camera.serial-number": "no. de serie",
    "project.settings.camera.year-of-purchase": "Año de compra",
    "project.settings.cameras": "Cámara",
    "project.settings.delete-behaviour-confirmation": "¿Estás seguro de que quieres eliminar este comportamiento?",
    "project.settings.delete-camera-confirmation": "¿Estás seguro de que quieres eliminar esta cámara?,",
    "project.settings.delete-individual-confirmation": "¿Estás seguro de que quieres eliminar este individuo?",
    "project.settings.delete-sampling-point-confirmation": " ¿Estás seguro de que deseas eliminar este punto de muestreo?",
    "project.settings.delete-species-confirmation": "¿Estás seguro de que quieres eliminar esta especie?",
    "project.settings.deployments-are-assigned": "Los despliegues están asignados",
    "project.settings.description": "Descripción",
    "project.settings.description-placeholder": "Descripción",
    "project.settings.edit-behaviour.cancel": "Cancelar",
    "project.settings.edit-behaviour.description": "Descripción",
    "project.settings.edit-behaviour.description-placeholder": "Descripción",
    "project.settings.edit-behaviour.name": "Nombre",
    "project.settings.edit-behaviour.name-placeholder": "Nombre",
    "project.settings.edit-behaviour.save": "Guardar",
    "project.settings.edit-behaviour.title": "Editar comportamiento",
    "project.settings.edit-camera": "Editar cámara",
    "project.settings.edit-camera.cancel": "Cancelar",
    "project.settings.edit-camera.label": "Etiqueta",
    "project.settings.edit-camera.label-placeholder": "Etiqueta",
    "project.settings.edit-camera.make": "Marca cámara",
    "project.settings.edit-camera.make-placeholder": "Marca cámara",
    "project.settings.edit-camera.model": "Modelo",
    "project.settings.edit-camera.model-placeholder": "Modelo",
    "project.settings.edit-camera.save": "Guardar",
    "project.settings.edit-camera.serial-number": "no. de serie",
    "project.settings.edit-camera.serial-number-placeholder": "no. de serie",
    "project.settings.edit-camera.title": "Editar cámara",
    "project.settings.edit-camera.year-of-purchase": "Año de compra",
    "project.settings.edit-individual.age": "Edad",
    "project.settings.edit-individual.cancel": "Cancelar",
    "project.settings.edit-individual.name": "Nombre",
    "project.settings.edit-individual.name-placeholder": "Nombre",
    "project.settings.edit-individual.notes": "Notas",
    "project.settings.edit-individual.notes-placeholder": "Notas",
    "project.settings.edit-individual.save": "Guardar",
    "project.settings.edit-individual.sex": "Sexo",
    "project.settings.edit-individual.species": "Especie",
    "project.settings.edit-individual.title": "Editar individuos",
    "project.settings.edit-location": "Editar localización",
    "project.settings.edit-location.images": "Imágenes",
    "project.settings.edit-species.cancel": "Cancelar",
    "project.settings.edit-species.common-names": "Editar nombres comunes",
    "project.settings.edit-species.is-identifiable-as-domesticated": "Se presenta como domesticada",
    "project.settings.edit-species.name": "Especies",
    "project.settings.edit-species.save": "Guardar",
    "project.settings.edit-species.title": "Editar especies",
    "project.settings.feature": "Características",
    "project.settings.feature-methodology": "Características de la metodología",
    "project.settings.feature-methodology-placeholder": "Características de la metodología",
    "project.settings.feature.select-feature": "Select feature",
    "project.settings.general": "General",
    "project.settings.hide-deployment-locations": "Ocultar las ubicaciones de despliegue",
    "project.settings.image": "Imagen",
    "project.settings.individual.add-individual": "Añadir individuos",
    "project.settings.individual.delete": "Borrar",
    "project.settings.individual.edit": "Editar",
    "project.settings.individual.name": "Nombre",
    "project.settings.individual.notes": "Notas",
    "project.settings.individual.species": "Especie",
    "project.settings.individual.zooniverse": "Zooniverse",
    "project.settings.individuals": "Individuos",
    "project.settings.location": "Localización",
    "project.settings.min-image-resolution": "Mínimo {min_width} por {min_height} pixels",
    "project.settings.name": "Nombre",
    "project.settings.name-placeholder": "Nombre",
    "project.settings.open-for-subscriptions": "Abierto para subscriptores",
    "project.settings.organisation": "Organización",
    "project.settings.organisation-help-block": "Organisations are considered rights holders of the data.",
    "project.settings.organisation-placeholder": "Organización",
    "project.settings.project-investigator": "IP",
    "project.settings.project-investigator-placeholder": "Investigador del proyecto",
    "project.settings.project-owner": "Contact",
    "project.settings.project-owner-placeholder": "Project Contact",
    "project.settings.quiet-period": "Intervalo entre activaciones",
    "project.settings.restricted-access": "Acceso restringido",
    "project.settings.restricted-images": "Imágenes restringidas",
    "project.settings.restricted-images.note": "Cuando está marcado, los usuarios externos no podrán acceder a las URL de las imágenes en las exportaciones/datos publicados.",
    "project.settings.restricted-locations": "Ubicaciones restringidas",
    "project.settings.restricted-locations.note": "Cuando se marca, los fotoprocesadores y los voluntarios solo verán las ubicaciones para sus propios despliegues.",
    "project.settings.restricted-project": "Proyecto restringido",
    "project.settings.restricted-project.note": "Cuando esté marcado, su proyecto se ocultará de la página de inicio pública y del portal en agouti.eu.",
    "project.settings.sampling-design": "Diseño del muestreo",
    "project.settings.sampling-design.clustered-random": "Agrupados aleatorios (conjuntos aleatorios)",
    "project.settings.sampling-design.experimental": "Experimentales",
    "project.settings.sampling-design.label": "Diseño de muestreo",
    "project.settings.sampling-design.opportunistic": "Oportunista",
    "project.settings.sampling-design.placeholder": "Seleccionar diseños de muestreo",
    "project.settings.sampling-design.simple-random": "Aleatoria simple",
    "project.settings.sampling-design.systematic-random": "Aleatoria sistemática",
    "project.settings.sampling-design.targeted": "Dirigida",
    "project.settings.seconds": "Segundos",
    "project.settings.sensormethod": "Método del sensor",
    "project.settings.sensormethod.motion-detection": "Detección de movimiento",
    "project.settings.sensormethod.select-sensormethod": "Seleccionar método del sensor",
    "project.settings.sensormethod.timelapse": "Timelapse",
    "project.settings.sequence-cut-off": "Punto de corte de la secuencia",
    "project.settings.sequence-cutoff-disabled": "El punto de corte de la secuencia no se puede cambiar después de que las secuencias hayan sido añadidas.",
    "project.settings.species": "Especies",
    "project.settings.species.added": "Especie añadida al proyecto",
    "project.settings.species.available": "Especie disponible",
    "project.settings.species.common-name": "Nombre común",
    "project.settings.species.countDeployments": "Deployments",
    "project.settings.species.countObservations": "Observations",
    "project.settings.species.countValObservations": "Validated",
    "project.settings.species.edit": "Editar",
    "project.settings.species.family": "Familia",
    "project.settings.species.genus": "Género",
    "project.settings.species.isIdentifiableAsDomesticated": "Doméstica",
    "project.settings.species.order": "Orden",
    "project.settings.species.remove": "Eliminar",
    "project.settings.species.species": "Especie",
    "project.settings.success-message": "Ajustes del proyecto guardados.",
    "project.settings.title": "Ajustes del proyecto",
    "project.settings.update-location.cancel": "Cancelar",
    "project.settings.update-location.save": "Guardar",
    "project.settings.update-location.title": "Editar localización",
    "project.settings.utc-offset": "Desplazamiento UTC predeterminado",
    "project.user-management.accept-pending-request-confirmation": "¿Estás seguro de que quieres aceptar esta solicitud?",
    "project.user-management.awaiting-access": "Esperando el acceso",
    "project.user-management.copy": "Copiar enlace de invitación",
    "project.user-management.copy.confirmation": "Enlace copiado",
    "project.user-management.delete-user-confirmation": "¿Estás seguro de que quieres anular el acceso de este miembro a este proyecto?",
    "project.user-management.edit-user.cancel": "Cancelar",
    "project.user-management.edit-user.email": "Dirección de Email",
    "project.user-management.edit-user.email-placeholder": "Dirección de Email",
    "project.user-management.edit-user.first-name": "Nombre",
    "project.user-management.edit-user.first-name-placeholder": "Nombre",
    "project.user-management.edit-user.last-name": "Apellido",
    "project.user-management.edit-user.last-name-placeholder": "Apellido",
    "project.user-management.edit-user.member-since": "Miembro desde",
    "project.user-management.edit-user.revoke-access": "Revocar el acceso",
    "project.user-management.edit-user.role": "Rol",
    "project.user-management.edit-user.save": "Guardar",
    "project.user-management.edit-user.title": "Editar usuario",
    "project.user-management.email-address": "Dirección de Email",
    "project.user-management.first-name": "Nombre",
    "project.user-management.last-name": "Apellido",
    "project.user-management.link-description": "Utiliza este enlace para permitir que los usuarios soliciten acceso al proyecto.",
    "project.user-management.pending-requests": "Solicitudes pendientes",
    "project.user-management.pending-requests.accept": "Aceptar",
    "project.user-management.pending-requests.decline": "Declinar",
    "project.user-management.pending-requests.empty": "Sin solicitudes pendientes",
    "project.user-management.principal-investigators": "Investigador principal",
    "project.user-management.project-access": "Acceso al proyecto",
    "project.user-management.registered-users": "Miembros",
    "project.user-management.registered-users.delete": "Borrar",
    "project.user-management.registered-users.edit": "Editar",
    "project.user-management.role": "Rol",
    "project.user-management.title": "Gestión de Usuarios",
    "project.user-management.userbase": "Miembros",
    "project.user-management.userbase.title": "Base de Usuarios",
    "project.user-management.volunteers": "Voluntarios",
    "project.validate.accept-sequence": "Accept sequence observations",
    "project.validate.accepted-by-at": "Validated by {validator} on {date}",
    "project.validate.add-new-query": "Add new query",
    "project.validate.all-are-true": "All of the following predicates are true",
    "project.validate.edit.title": "Edit validation query",
    "project.validate.filter.created-from": "Created from",
    "project.validate.filter.created-to": "Created to",
    "project.validate.filter.is-equal-to": "Is equal to",
    "project.validate.filter.is-unknown": "Is unknown",
    "project.validate.filter.sampling-point": "Sampling point",
    "project.validate.filter.select-filter-type": "Select filter type",
    "project.validate.filter.select-role": "Select project role",
    "project.validate.filter.select-sampling-point": "Select sampling point",
    "project.validate.filter.select-species": "Select species",
    "project.validate.filter.select-user": "Select user",
    "project.validate.filter.species": "Species",
    "project.validate.filter.user": "User",
    "project.validate.filter.user-project-role": "Project role",
    "project.validate.new.title": "New validation query",
    "project.validate.no-rules-defined-yet": "No predicates defined yet...",
    "project.validate.no-validation-queries": "There are no validation queries defined for this project yet",
    "project.validate.none-are-true": "None of the following predicates are true",
    "project.validate.predicates": "Predicates",
    "project.validate.query-is-failed": "Something went wrong during the execution of the query...",
    "project.validate.query-is-running": "Query is running...",
    "project.validate.query-is-scheduled": "Query is scheduled...",
    "project.validate.results.assest-count": "Asset count",
    "project.validate.results.no-results": "No results found...",
    "project.validate.results.show-done": "Show validated sequences",
    "project.validate.results.total-results": "Total results",
    "project.validate.results.total-results-of": "Showing {count} of {total} results (max {limit})",
    "project.validate.rule-presets": "Rule presets",
    "project.validate.scheduling-explanation": "Queries are run every hour. Execution takes a few minutes, up to half an hour. Please refresh the page to update the status of the validation queries.",
    "project.validate.sequences": "Sequences",
    "project.validate.table.header.candidates": "Matches",
    "project.validate.table.header.name": "Name",
    "project.validate.title": "Validate",
    "project.validate.unaccept-sequence": "Remove validation",
    "project.validate.validate": "validate",
    "project.validate.validation-query-results": "Validation query results",
    "project.zooniverse.password": "Contraseña Zooniverse",
    "project.zooniverse.password-placeholder": "Contraseña Zooniverse",
    "project.zooniverse.projectid": "ID Proyecto Zooniverse",
    "project.zooniverse.projectid-placeholder": "ID Proyecto Zooniverse",
    "project.zooniverse.username": "Usuario de Zooniverse",
    "project.zooniverse.username-placeholder": "Usuario de Zooniverse",
    "projectrole.ADMIN": "Admin",
    "projectrole.AWAITING_ACCESS": "Esperando acceso",
    "projectrole.DUMMY": "Dummy",
    "projectrole.PHOTO_PROCESSOR": "Procesador de fotos",
    "projectrole.PRINCIPAL_INVESTIGATOR": "Investigador principal",
    "projectrole.PROJECT_COORDINATOR": "Coordinador del proyecto",
    "projectrole.TAXONOMIC_EXPERT": "Experto en taxonomía",
    "projectrole.VIEW_ONLY": "View Only",
    "projectrole.VOLUNTEER": "Voluntario",
    "projects.add": "Añadir nuevos proyectos",
    "projects.independent-projects": "Proyectos independientes",
    "projects.no-projects-available": "Sin proyectos disponibles. Contacte con el coordinador del proyecto para que le de instrucciones.",
    "projects.search-for": "Buscar por...",
    "projects.search-for.filter": "Filtros del proyecto",
    "projects.search-for.filterOrg": "Filter on organisation",
    "projects.title": "Proyectos",
    "projects.title.user": "Tus Proyectos",
    "projects.your-role": "Tu rol",
    "publichome.block1.title": "¿Qué es Agouti?",
    "publichome.block1.txt": "Las cámaras trampa permiten estudiar de una manera fácil y no invasiva la fauna silvestre. Sin embargo, el procesamiento y almacenamiento de los miles de imágenes que un estudio típico genera, suele ser bastante complicado. Además, la gestión de proyectos puede suponer un reto, especialmente si intervienen muchas cámaras y varias personas.",
    "publichome.block1.txt2": "Agouti ayuda a los usuarios a resolver estos problemas. Es una solución útil para organizaciones e investigadores que usan cámaras trampa en el estudio de la fauna silvestre. Permite organizar muestreos colaborativos, procesar imágenes de forma rápida y sencilla, obtener resúmenes estandarizados de los resultados, archivar imágenes y datos de forma segura y, en última instancia, resumir y compartir datos. Agouti sigue estándares de metadatos, por lo que facilita la comparación entre proyectos.",
    "publichome.block2.title": "Flujo de trabajo",
    "publichome.block2.txt": "Agouti es una colección de proyectos generados por organizaciones o investigadores. Los participantes del proyecto suben todo el contenido de las tarjetas de memoria  de las cámaras trampa e incorporan información sobre la ubicación y otros metadatos. Agouti importa estas imágenes, extrae las fechas, horas y otros datos de las propias imágenes y agrupa las imágenes en secuencias que representan el mismo evento. Usando una interfaz sencilla los investigadores y analistas de imágenes inspeccionan cada secuencia y la anotan con una o más observaciones.",
    "publichome.block2.txt2": "Puede exportar sus datos como un paquete de datos de trampa de cámara (<a target=\"_blank\" href=\"https://tdwg.github.io/camtrap-dp/\">Camtrap DP</a>) para su análisis en software como como R. Recomendamos usar el paquete R <a target=\"_blank\" href=\"https://inbo.github.io/camtraptor/\">camtraptor</a> como comienzo.",
    "publichome.block3.title": "Importar",
    "publichome.block3.txt": "Subir las imágenes y vídeos usando solo el navegador",
    "publichome.block3.txt2": "Identificar",
    "publichome.block3.txt3": "Usar herramientas prácticas para etiquetar las imágenes con observaciones",
    "publichome.block3.txt4": "Analizar",
    "publichome.block3.txt5": "Examinar datos con herramientas incorporadas y presentaciones",
    "publichome.block3.txt6": "Exportar",
    "publichome.block3.txt7": " Generar archivos de datos estandarizados para compartir o para su análisis",
    "publichome.block4.title": "Interfaz intuitiva",
    "publichome.block4.txt": "La interfaz de Agouti es sencilla, moderna y orientada a mejorar la productividad. Cualquiera puede empezar a trabajar con muy pocas instrucciones. Los siguientes vídeos ilustran varios aspectos del sistema.",
    "publichome.block4.txt1": "Cómo empezar",
    "publichome.block4.txt2": "Importando dispositivos",
    "publichome.block4.txt3": "Anotando secuencias",
    "publichome.block4.vid1": "/assets/videos/GettingStarted-ES.mp4",
    "publichome.block4.vid2": "/assets/videos/ImportingDeployments-ES.mp4",
    "publichome.block4.vid3": "/assets/videos/AnnotatingSequences-ES.mp4",
    "publichome.block5.title": "Proyectos destacados",
    "publichome.block5.txt": "Agouti alberga diferentes proyectos. Explore algunos de estos proyectos públicos para descubrir las posibilidades de Agouti.",
    "publichome.block6.f1": "Gestiona",
    "publichome.block6.f2": "Diseño de muestreo",
    "publichome.block6.f3": "Planificación espacial",
    "publichome.block6.f4": "Explora las cámaras trampa",
    "publichome.block6.f5": "Etiqueta cámaras trampa",
    "publichome.block6.f6": "Control de calidad",
    "publichome.block6.g1": "Procesar",
    "publichome.block6.g2": "Añadir observaciones",
    "publichome.block6.g3": "Estandarizar lista de especies",
    "publichome.block6.g4": "Exportar a archivo CSV",
    "publichome.block6.g5": "Explorar animales individuales",
    "publichome.block6.g6": "Anotar comportamiento",
    "publichome.block6.h1": "Colaborar",
    "publichome.block6.h2": "Invitar a miembros",
    "publichome.block6.h3": "Asignar roles",
    "publichome.block6.h4": "Asignar tareas",
    "publichome.block6.h5": "Seguimiento del proceso",
    "publichome.block6.h6": "Organizar datos",
    "publichome.block6.title": "Características",
    "publichome.block6.txt": "Con Agouti el usuario tiene a su disposición un amplio conjunto de herramientas.",
    "publichome.block7.title": "Privacidad y seguridad",
    "publichome.block7.txt": "La propiedad de los datos es importante. Agouti no reclama ninguna propiedad de los datos y no los distribuye a terceros. El usuario decide quién tiene acceso, así que puede colaborar usando sus propios términos.",
    "publichome.block7.txt2": "Agouti proporciona un lugar seguro para almacenar los datos. Elimina el riesgo de pérdida de datos debidos a fallos en los discos duros, errores de usuario o al bloquearse los ordenadores. Se hacen copias de seguridad diarias fuera del sitio web y usando una infraestructura totalmente redundante que garantizan un acceso óptimo a sus datos.",
    "publichome.block8.f1": "Académico",
    "publichome.block8.f2": "Sin coste",
    "publichome.block8.f3": "Almacenamiento ilimitado",
    "publichome.block8.f4": "Proyectos ilimitados y usuarios",
    "publichome.block8.f5": "Best Effort Support",
    "publichome.block8.f6": "Solicitar",
    "publichome.block8.g1": "Sin ánimo de lucro",
    "publichome.block8.g2": "Sin coste",
    "publichome.block8.g3": "Almacenamiento ilimitado",
    "publichome.block8.g4": "Proyectos ilimitados y usuarios",
    "publichome.block8.g5": "Best Effort Support",
    "publichome.block8.g6": "Solicitar",
    "publichome.block8.h1": "Comercial",
    "publichome.block8.h2": "Pay-what-you-can",
    "publichome.block8.h3": "Almacenamiento ilimitado",
    "publichome.block8.h4": "Proyectos ilimitados y usuarios",
    "publichome.block8.h5": "Best effort support",
    "publichome.block8.h6": "Solicitar",
    "publichome.block8.title": "Comenzar a utilizar Agouti",
    "publichome.block8.txt": "Los costes de alojamiento y desarrollo de Agouti corren a cargo de los usuarios. Los estudiantes pueden utilizar Agouti de forma gratuita, mientras que otras partes podrían tener que hacer una contribución. Se espera que los socios con intereses comerciales contribuyan más, ya que ahorran tiempo y dinero gracias a Agouti. En este modelo colaborativo los costes se distribuyen de forma que todo el mundo tenga acceso a la plataforma.",
    "publichome.explore-projects": "Explorar proyectos",
    "publichome.footer1": "Agouti",
    "publichome.footer10": "6708 PB Wageningen",
    "publichome.footer11": "The Netherlands",
    "publichome.footer12": "Research Institute for Nature and Forest (INBO)",
    "publichome.footer13": "Wildlife Management and Alien Species & Open science lab for biodiversity",
    "publichome.footer14": "Havenlaan 88 bus 73",
    "publichome.footer15": "1000 Brussel",
    "publichome.footer16": "Belgium",
    "publichome.footer2": "Lista de correo de usuarios",
    "publichome.footer3": "Política de privacidad",
    "publichome.footer5": "Organización",
    "publichome.footer6": "Agouti es una iniciativa de Wageningen University y el Research Institute for Nature and Forest (INBO)",
    "publichome.footer7": "Wageningen University",
    "publichome.footer8": "Department of environmental sciences",
    "publichome.footer9": "Wildlife Ecology and Conservation Group",
    "publichome.slogan": "Una plataforma para gestionar proyectos de fauna silvestre con cámaras trampa",
    "publichome.stats1": "Proyectos",
    "publichome.stats2": "Cámaras trampa",
    "publichome.stats3": "Observaciones",
    "publichome.stats4": "Imagenes",
    "publichome.title": "Agouti",
    "sequence.id": "Sequence ID",
    "sex.": "N.A.",
    "sex.female": "Hembra",
    "sex.male": "Macho",
    "sex.unknown": "desconocido",
    "taxonRank": "Taxon Rank",
    "toggle-processed": "Hide 100%",
    "user.register.desc": "Regístrese para obtener una cuenta en Agouti.",
    "user.register.email-address": "E-mail",
    "user.register.email-address-duplicate": "Dirección de correo electrónico ya existente.",
    "user.register.email-address-error": "Por favor, escriba su email.",
    "user.register.first-name": "Nombre",
    "user.register.first-name-error": "Por favor, escriba su nombre.",
    "user.register.gdpr-error": "Por favor, confirma tu consentimiento conforme al RGPD.",
    "user.register.last-name": "Apellido",
    "user.register.last-name-error": "Por favor, escriba su apellido.",
    "user.register.password": "Contraseña",
    "user.register.password-error": "Por favor, escriba su contraseña de acceso.",
    "user.register.title": "Registro",
    "user.request-reset-password.desc": "Request a password reset link",
    "user.request-reset-password.title": "Reset password",
    "user.reset-password.desc": "Choose a new Agouti password",
    "user.reset-password.email-address": "E-mail",
    "user.reset-password.email-address-duplicate": "Email address already in use.",
    "user.reset-password.email-address-error": "Please fill in your email.",
    "user.reset-password.password": "Password",
    "user.reset-password.password-are-different-error": "Please enter the same password twice.",
    "user.reset-password.password-confirmation": "Password confirmation",
    "user.reset-password.password-confirmation-error": "Please fill in a password confirmation.",
    "user.reset-password.password-error": "Please fill in a password.",
    "user.reset-password.title": "Reset password",
    "user.settings.account": "Cuenta",
    "user.settings.adaptive-image-size": "Tamaño de imagen adaptivo",
    "user.settings.annotate-shortcuts-label": "Anotar atajos",
    "user.settings.anonymous-in-export": "Exportaciones anónimas",
    "user.settings.anonymous-in-export-explanation": "Si marcas esta casilla, tus datos personales se anonimizarán al realizar exportaciones. No se incluirá tu nombre ni tu correo electrónico en las exportaciones de datos y no se te acreditará como colaborador, preparador ni clasificador.",
    "user.settings.autoscroll-thumbnails-annotating": "Auto-scroll thumbnails when annotating",
    "user.settings.common": "Común",
    "user.settings.email-address": "dirección de Email",
    "user.settings.email-address-label": "Email",
    "user.settings.email-in-use": "Dirección de correo electrónico ya existente.",
    "user.settings.first-name": "Nombre",
    "user.settings.first-name-note": "Esto es lo que otros usuarios verán.",
    "user.settings.gravatar": "o activar <a href=\"http://gravatar.com\" title=\"http://gravatar.com\">Gravatar</a>",
    "user.settings.gravatar.disable": "Desactivar",
    "user.settings.gravatar.enable": "Activar",
    "user.settings.language": "Idioma",
    "user.settings.last-name": "Apellido",
    "user.settings.organisation": "Organización",
    "user.settings.organisation-placeholder": "Organización",
    "user.settings.password": "Contraseña",
    "user.settings.picture": "Foto de perfil",
    "user.settings.picture.browse": "Explorar...",
    "user.settings.picture.file": "No se ha elegido ningún archivo.",
    "user.settings.picture.remove": "Eliminar",
    "user.settings.picture.upload": "Actualizar nueva foto de perfil",
    "user.settings.save": "Guardar cambios",
    "user.settings.save.update": "Actualizar ajustes",
    "user.settings.saved": "Ajustes guardados.",
    "user.settings.scientific": "Científico",
    "user.settings.species-names": "Nombre de la especie",
    "user.settings.title": "Opciones de usuario",
    "user.settings.url.label": "Url (ORCID)",
    "user.settings.url.placeholder": ""
  }], ["fr", {
    "age.": "N.A.",
    "age.adult": "Adulte",
    "age.infant": "Juvénile",
    "age.subadult": "Sub-Adulte",
    "age.unknown": "Inconnu",
    "application.form.cancel": "Annuler",
    "application.form.close": "Fermer",
    "application.form.delete": "Supprimer",
    "application.form.edit": "Modifier",
    "application.form.fields.description": "Description",
    "application.form.fields.name": "Nom",
    "application.form.save": "Enregistrer",
    "application.form.saved": "Sauvegardé",
    "application.format.date": "MM/DD/YYYY",
    "application.format.date-time": "MM/DD/YYYY HH:mm",
    "application.gdpr.continue": "Avant de poursuivre, tu dois donner l'autorisation à Wageningen University and Research de stocker et d'utiliser ton nom et ton adresse e-mail à des fins de recherche scientifique",
    "application.gdpr.text": "En donnant ton consentement, tu acceptes avoir fourni volontairement ces données personnelles. Tu as le droit de révoquer ce consentement. Les informations personnelles que tu auras fournies seront utilisées aux fins pour lesquelles tu les as communiquées. Tu as le droit de vérifier, de supprimer, de corriger et de limiter le traitement de tes données personnelles, ainsi que le droit de recours et le droit à la portabilité des données <a target=\"_blank\" href=\"https://www.wur.nl/en/About-Wageningen/Integrity-and-privacy.htm\">https://www.wur.nl/en/About-Wageningen/Integrity-and-privacy.htm</a> Remarque: il n'y a pas de droit absolu pour les personnes concernées. Cela sera toujours considéré en fonction de la situation et du droit invoqué. Si le traitement est nécessaire à la réalisation d'une tâche légale ou d'un intérêt général (ex: à des fins d'archivage dans l'intérêt public, de recherche scientifique ou historique ou de statistiques), le droit à l'oubli ne s'applique pas. En cas de réclamation, tu peux l'envoyer à WUR via <a href=\"mailto:privacy@wur.nl\">privacy@wur.nl</a>. Tu peux également déposer une réclamation auprès de l'autorité de protection des données hollandaise. Pour plus d'informations à ce sujet, rends-toi sur <a target=\"_blank\" href=\"https://www.autoriteitpersoonsgegevens.nl\">www.autoriteitpersoonsgegevens.nl</a>. En cas de question, contacte l'agent de protection des données de WUR via <a href=\"mailto:functionarisgegevensbescherming@wur.nl\">functionarisgegevensbescherming@wur.nl</a>.",
    "application.gdpr.title": "RGPD",
    "application.gdpr.user-consent": "J'autorise Wageningen University and Research à stocker et à utiliser mon nom et mon adresse e-mail à des fins de recherche scientifique",
    "application.menu.dashboard": "Tableau de bord",
    "application.menu.login": "Connexion",
    "application.menu.logout": "Déconnexion",
    "application.menu.organisations": "Organisations",
    "application.menu.register": "Enregistrement",
    "application.menu.settings": "Configuration",
    "application.menu.userguide": "Mode d'emploi",
    "application.menu.yourtasks": "Vos tâches",
    "class": "Class",
    "colophon.contributors": "Organisations",
    "colophon.desc": "Agouti est une application pour gérer les projets travaillant avec des pièges photos et pour analyser vos données.",
    "colophon.inbo": "Institut de Recherche sur la Nature et la Forêt (INBO)",
    "colophon.partners": "Partenaires",
    "colophon.release": "Sortie",
    "colophon.title": "A propos d'Agouti",
    "colophon.version": "v3.8.0 / 27-5-2021",
    "colophon.wur": "Université de Wageningen",
    "component.bait-selector.bait": "Appât",
    "component.bait-selector.baits": "Appâts",
    "component.bait-selector.select-bait": "Sélectionnez appât",
    "component.bait-selector.select-baits": "Sélectionnez appâts",
    "component.deployment-calibration-form": "Étalonnage du déploiement",
    "component.deployment-calibration-form.go-to-marking": "Sauter à",
    "component.deployment-calibration-form.height": "la taille",
    "component.deployment-calibration-form.select-label.bottom": "Bas de bâton",
    "component.deployment-calibration-form.select-label.top": "Haut de bâton",
    "component.deployment-row.age": "Age",
    "component.deployment-row.blanks": "Vides",
    "component.deployment-row.datetime": "Date et heure",
    "component.deployment-row.edit": "Editer déploiement",
    "component.deployment-row.hideDetails": "Masquer détails",
    "component.deployment-row.no-observations-added": "Aucune observation n'a été ajoutée.",
    "component.deployment-row.observed": "Espèces observées",
    "component.deployment-row.person": "Personne",
    "component.deployment-row.quantity": "Quantité.",
    "component.deployment-row.setup": "Configuration / sélection",
    "component.deployment-row.sex": "Sexe",
    "component.deployment-row.show": "Voir",
    "component.deployment-row.showDetails": "Voir détails",
    "component.deployment-row.species": "Espèce",
    "component.deployment-row.specifications": "Spécifications",
    "component.deployment-row.view": "Voir observations",
    "component.droplet-deployment-uploader.cancel": "Annuler",
    "component.droplet-deployment-uploader.creating-sequences": "Création des séquences...",
    "component.droplet-deployment-uploader.guidance-part-1": "Agouti accepte les téléchargements de photos / vidéos individuelles ainsi que les fichiers ZIP. La méthode qui fonctionne le mieux dépend de la quantité des fichiers dans le déploiement.",
    "component.droplet-deployment-uploader.guidance-part-2": "Si vous avez un déploiement de moins de 4 000 photos ou 25 vidéos, accédez au dossier contenant ces photos / vidéos et sélectionnez-les toutes.",
    "component.droplet-deployment-uploader.guidance-part-3": "Si vous avez plus de 4000 photos ou 25 vidéos, veuillez d'abord les regrouper dans un fichier ZIP, puis télécharger ce fichier. Assurez-vous que toutes les photos / vidéos se trouvent à la racine du fichier zip. Les sous-dossiers ne sont pas pris en charge.",
    "component.droplet-deployment-uploader.new-deployment": "Nouveau déploiement",
    "component.droplet-deployment-uploader.progress": "Progression",
    "component.droplet-deployment-uploader.select-files": "Parcourir",
    "component.droplet-deployment-uploader.start-upload": "Commencer le chargement",
    "component.droplet-deployment-uploader.uploading": "Chargement en cours...",
    "component.edit-deployment.add-sampling-point": "Ajouter un point d'échantillonnage",
    "component.edit-deployment.camera-heading": "Camera heading",
    "component.edit-deployment.camera-height": "Hauteur du piège photo",
    "component.edit-deployment.camera-height.empty": "Pas de hauteur de piège photo trouvée",
    "component.edit-deployment.camera-identifier": "Identifiant de piège photo",
    "component.edit-deployment.camera-identifier-placeholder": "Identifiant de piège photo",
    "component.edit-deployment.camera-tilt": "Camera tilt",
    "component.edit-deployment.cm": "cm",
    "component.edit-deployment.created-at": "Créé à",
    "component.edit-deployment.created-by": "Créé par",
    "component.edit-deployment.distance-detection": "Distance de détection",
    "component.edit-deployment.distance-detection.empty": "Pas de distance de détection trouvée",
    "component.edit-deployment.end": "Fin",
    "component.edit-deployment.end-placeholder": "Fin",
    "component.edit-deployment.error.missing-fields": "Veuillez remplir tous les champs obligatoires.",
    "component.edit-deployment.groups-placeholder": "Sélectionner un ou plusieurs groupes",
    "component.edit-deployment.invalid": "Les déploiements invalides sont exclus des exportations de données.",
    "component.edit-deployment.location": "Point d'échantillonnage",
    "component.edit-deployment.location-placeholder": "Point d'échantillonnage",
    "component.edit-deployment.notes": "Notes",
    "component.edit-deployment.notes-placeholder": "Remarques, commentaires",
    "component.edit-deployment.notes.empty": "Pas de note trouvée",
    "component.edit-deployment.person-deploy": "Personne réalisant le déploiement",
    "component.edit-deployment.person-deploy-placeholder": "Sélectionner une personne",
    "component.edit-deployment.person-deploy.empty": "Pas de personne réalisant le déploiement trouvé",
    "component.edit-deployment.person-detect": "Personne récupérant les pièges photo",
    "component.edit-deployment.person-detect-placeholder": "Selectionner  une personne",
    "component.edit-deployment.person-detect.empty": "Pas de personne récupérant les pièges photo trouvé",
    "component.edit-deployment.save": "Sauver et fermer le déploiement",
    "component.edit-deployment.start": "Début",
    "component.edit-deployment.start-placeholder": "Début",
    "component.edit-deployment.tags": "Tags",
    "component.edit-deployment.tags-placeholder": "Sélectionner un ou plusieurs tags",
    "component.edit-deployment.tags.empty": "Pas de tag trouvé",
    "component.edit-deployment.utc-offset": "Décalage UTC",
    "component.edit-location.latitude": "Latitude",
    "component.edit-location.longitude": "Longitude",
    "component.edit-location.name": "Nom",
    "component.identification-form.age": "Age",
    "component.identification-form.amount": "Quantité",
    "component.identification-form.behaviour": "Comportement",
    "component.identification-form.created-at": "Créé à",
    "component.identification-form.created-by": "Créé par",
    "component.identification-form.delete-observation": "Supprimer observation",
    "component.identification-form.hide-optional": "Masquer les champs optionels",
    "component.identification-form.identification": "Ajouter observation",
    "component.identification-form.individual": "Individu",
    "component.identification-form.is-domesticated": "Domestiquée",
    "component.identification-form.modified-at": "Modifié en dernier lieu à",
    "component.identification-form.modified-by": "Modifié en dernier lieu par",
    "component.identification-form.no-individual": "Pas d'individu",
    "component.identification-form.notes": "Notes",
    "component.identification-form.save-observation": "Sauvegarder observation",
    "component.identification-form.select-age": "Choix de l'age",
    "component.identification-form.select-sex": "Choix du sexe",
    "component.identification-form.sex": "Sexe",
    "component.identification-form.show-all": "Montrer tous les champs",
    "component.identification-form.species": "Espèce",
    "component.identification-form.species-placeholder": "Espèce",
    "component.identification-form.uncertain": "Incertain / Probable",
    "component.identification-form.uncertain-short": "Incertain",
    "component.identification-form.validated": "Validé",
    "component.is-identifiable-as-domesticated.no": "Non",
    "component.is-identifiable-as-domesticated.yes": "Oui",
    "component.observation-position-form.view-position": "Sauter à",
    "component.observations-list.hide": "Masquer",
    "component.observations-list.observations": "Liste des observations",
    "component.observations-list.quantity": "Qté.",
    "component.observations-list.sex": "Sexe",
    "component.observations-list.show": "Voir",
    "component.observations-list.species": "Espèce",
    "component.observations-list.uncertain": "Incertain / Probable",
    "component.observations-table.observation-type": "Type d'observation",
    "component.observations-table.observator": "Observateur",
    "component.overview-marker-popup.annotate": "Annotater",
    "component.overview-marker-popup.browse": "Parcourir",
    "component.overview-marker-popup.collected-person": "Personnes collectées",
    "component.overview-marker-popup.date-of-import": "Date d'import",
    "component.overview-marker-popup.end": "Fin",
    "component.overview-marker-popup.set-up-person": "Configurer personne",
    "component.overview-marker-popup.start": "Début",
    "component.overview-marker-popup.statistics": "{sequencesAnnotatedCount} sur {sequencesCount} sequénces annotatées",
    "component.overview-marker-popup.uploaded-person": "Personnes chargées",
    "component.page-footer.all-rights-reserved": "CameraTrapLab & Université de Wageningen",
    "component.page-footer.colophon": "A propos",
    "component.page-footer.power": "Powered by Agouti for",
    "component.positioning.angle": "Angle",
    "component.positioning.calculate-speed": "Calculer la vitesse",
    "component.positioning.camera-and-deployment-calibration-missing": "Calibration de la caméra et du déploiement manquante",
    "component.positioning.camera-calibration-missing": "Calibration de la caméra manquante",
    "component.positioning.camera-calibration-present": "Calibration de la caméra présente",
    "component.positioning.camera-details": "Détails de la caméra",
    "component.positioning.camera-model-missing-with-support": "Calibration de la caméra manquante pour ce déploiement. Veuillez contacter le support.",
    "component.positioning.deployment-calibration-missing": "Calibration du déploiement manquante",
    "component.positioning.dimensions": "Dimensions",
    "component.positioning.distance": "Distance",
    "component.positioning.manufacturer": "Fabricant",
    "component.positioning.model": "Modèle",
    "component.positioning.preview-plots": "Graphiques de prévisualisation",
    "component.positioning.radius": "Rayon",
    "component.positioning.speed": "Vitesse",
    "component.positioning.warning": "Avertissement",
    "component.remove-full-deployment.cancel": "Cancel",
    "component.remove-full-deployment.confirm-removal": "Confirm removal",
    "component.remove-full-deployment.delete": "Delete deployment",
    "component.remove-full-deployment.modal.error": "There was an error while removing. The error is:",
    "component.remove-full-deployment.modal.info": "<p>You are about to remove a deployment and all its related resources. This action cannot be undone.</p><p>Please type \"delete\" to confirm the removal</p>",
    "component.remove-full-deployment.modal.title": "Warning: confirm removal of deployment",
    "component.remove-full-deployment.validation-word": "delete",
    "component.shortcuts-list.animal": "Animal",
    "component.shortcuts-list.blank": "Vide",
    "component.shortcuts-list.hide-all": "Masquer tout",
    "component.shortcuts-list.merge-with-previous": "Fusionner avec précédent",
    "component.shortcuts-list.merge-with-previous-time": "{amountOfMinutes} minutes écoulées depuis la séquence précédente",
    "component.shortcuts-list.merge-with-previous-time-seconds": "{amountOfSeconds} secondes écoulées depuis la séquence précédente",
    "component.shortcuts-list.merge-with-previous.modal.body": "Êtes-vous sûr de vouloir fusionner cette séquence avec la précédente? Toutes les observations de cette séquence seront ajoutées à la séquence précédente.",
    "component.shortcuts-list.merge-with-previous.modal.confirm": "Fusionner",
    "component.shortcuts-list.merge-with-previous.modal.title": "Fusionner avec précédent",
    "component.shortcuts-list.setup-pickup": "Configuration / prise en charge",
    "component.shortcuts-list.shortcuts": "Raccourcis",
    "component.shortcuts-list.show-all": "Voir tout",
    "component.shortcuts-list.unknown": "Inconnu",
    "component.shortcuts-list.vehicle": "Véhicule",
    "component.utc-offset-selector.example": "L'horodatage de l'image de <code>{originalExampleTime}</code> devient <code>{convertedExampleTime}</code> UTC.",
    "component.utc-offset-selector.help-text": "Le décalage UTC dépend du fuseau horaire dans lequel votre caméra a été configurée. Utilisez un outil tel que <a href=\"https://www.timeanddate.com/worldclock/converter.html\" target=\"_blank\">timeanddate.com</a> pour calculer le décalage.",
    "component.utc-offset-selector.placeholder": "Sélectionnez le décalage",
    "copied.id": "Id Copied to Clipboard",
    "deployment-row.camera-deployment-id": "Cam Dep Id",
    "deployment-row.card-id": "Card Id",
    "deployment-row.information": "Informations",
    "deployment-row.location": "Emplacement",
    "deployment-row.observations": "Observations",
    "deployment-row.tags": "Tags",
    "deployment-row.usable": "Utilisable",
    "deployment.id": "Deployment ID",
    "index.partners.1": "Collaborateurs et financement",
    "index.partners.2": "Agouti soutient et collabore à de nombreuses initiatives et projets et bénéficie du soutien de plusieurs organisations. Parmi ceux-ci sont:",
    "index.partners.3": "Pour créer des projets <a href=\"https://mammalnet.com/\" target=\"_blank\">MammalNet</a>, veuillez nous contacter via les informations ci-dessous.",
    "index.partners.4": "Avec un financement de la <a href=\"https://www.efsa.europa.eu/\" target=\"_blank\">European Food and Safety Authority</a>",
    "index.partners.5": "Supporté par <a href=\"https://www.lifewatch.be/\" target=\"_blank\">LifeWatch</a>",
    "info.annotate": "Après avoir importé le déploiement, les séquences doivent être traitées. Tout déploiement comportant des séquences nécessitant une annotation est présenté ici. Sélectionnez <i> Annoter </i> pour commencer à travailler sur ce déploiement. Vous pouvez arrêter le traitement à tout moment et continuer à partir de votre point de départ.",
    "info.annotate.title": "Déploiements en cours",
    "info.annotationsettings": "Ces champs contrôlent l'affichage des noms d'espèce sur la page d'annotation et l'intervalle de coupure pour la construction de séquences d'images.",
    "info.annotationsettings.title": "Configurations d'annotation",
    "info.behaviours": "Ajoutez un comportement aux observations en ajoutant des options à cette liste.",
    "info.behaviours.title": "Comportement",
    "info.browse": "Après avoir annoté les séquences, vous pouvez afficher les observations ajoutées sur cette page. Cliquez sur <i> Afficher </i> à côté d'un déploiement pour afficher immédiatement cette séquence et corriger les observations éventuelles.",
    "info.browse.title": "Parcourir les observations",
    "info.cameras": "Les pièges photo sont identifiés par leur numéro de série et sont conservés dans cette liste centrale. Cela signifie que les caméras d'autres projets apparaissent également ici. Veuillez vérifier si vos caméras n'y sont pas déjà avant d'ajouter de nouvelles entrées. Le numéro de série des caméras Reconyx se trouve au bas de la caméra après ouverture, juste en-dessous du code barre.",
    "info.cameras.title": "Pièges photo",
    "info.downloaddata": "Téléchargez les packages de données d'exportation ici. Tous les packages sont archivés, il est donc possible de télécharger ou de faire référence à une version spécifique.",
    "info.downloaddata.title": "Télécharger les données",
    "info.export": "Lors de l'exportation de données, toutes les observations sont regroupées dans un format normalisé (CSV) pouvant être lu par presque tous les logiciels pour un traitement ultérieur. Après avoir généré un nouveau fichier de données, téléchargez-le à partir du tableau ci-dessous.",
    "info.export.title": "Exporter les données",
    "info.generalsettings": "Cette section contient des informations générales sur votre projet. Veuillez remplir au moins les <i> Nom, Description </i> et <i> Propriétaire du projet </i>",
    "info.generalsettings.title": "Informations du projet",
    "info.import.upload.title": "Charger des fichiers",
    "info.import.uploadfiles": "Sélectionner <i>Charger des fichiers</i> pour télécharger des photos ou des vidéos. Veuillez accéder au dossier contenant toutes les photos ou vidéos de votre déploiement. Il est recommandé de télécharger du matériel directement à partir de la carte mémoire, afin d'éviter que le logiciel ne détériore les métadonnées.",
    "info.import.uploadfiles.title": "Charger des photos ou des vidéos",
    "info.individuals": "Gardez une trace des observations d'animaux individuels en les ajoutant à cette liste. Vous pouvez éventuellement les ajouter lors de l'annotation.",
    "info.individuals.title": "Animaux individuels",
    "info.pendingusers": "Lorsqu'un utilisateur enregistré demande l'accès à votre projet, il doit d'abord obtenir l'approbation du coordinateur. Les demandes en attente apparaissent dans cette liste. Après avoir accepté un utilisateur, modifiez le rôle si nécessaire.",
    "info.pendingusers.title": "Requêtes entrantes",
    "info.registeredusers": "Toute personne ayant accès à votre projet est affichée ici. Ici, vous pouvez changer le rôle d'un utilisateur ou tout simplement révoquer l'accès. Rappelez-vous que l'annulation de l'accès révoqué nécessite une nouvelle demande d'accès!",
    "info.registeredusers.title": "Utilisateurs enregistrés",
    "info.sampling.": "Un point d'échantillonnage est un emplacement étiqueté par l'utilisateur auquel un déploiement peut être attribué. Plusieurs déploiements peuvent être affectés à un point d'échantillonnage, si nécessaire.",
    "info.sampling.title": "Point d'échantillonnage",
    "info.samplingdesign": "Les informations fournies concernent votre protocole, en termes de normes de pièges photo. Celles-ci s'appliquent à l'ensemble de votre projet et non à des déploiements individuels. Par exemple: vous pouvez tester plusieurs types d'appâts parfumés. Ou placez vos caméras aux points d'eau. Fournissez une description pour ajouter plus de détails.",
    "info.samplingdesign.title": "Protocole de projet",
    "info.specieslist": "Agouti utilise la liste d'espèces normalisées du <a href=\"http://www.catalogueoflife.org/\" target=\"_blank\">Catalogue de la vie</a>. Vous pouvez construire un sous-ensemble de cette liste à utiliser dans ce projet.",
    "info.specieslist.title": "Liste des espèces",
    "info.tags": "Des tags peuvent être attribués aux déploiements pour structurer votre projet. Ils sont facultatifs, peuvent être librement étiquetés et plusieurs tags peuvent être attribués à un seul déploiement. Par exemple: utilisez-les pour suivre l'appât utilisé, le type d'habitat ou une classe abstraite que vous définissez.",
    "info.tags.title": "Tags",
    "info.unfinishedupload": "Si les fichiers ont été chargés, mais le formulaire <i>Déploiement d'Import</i> n'est pas complet, il sera affiché dans cette liste. Cliquer sur <i>Compléter</i> pour remplir à nouveau le formulaire et terminer l'import. Si le chargement a été interrompu, il est préférable de supprimer le déploiement.",
    "info.unfinishedupload.title": "Déploiements incomplets",
    "info.user.settings": "Ici, vous pouvez mettre à jour vos données personnelles et changer la langue d'interface d'Agouti. Notez que la modification de la langue de l'interface ne modifie pas certains éléments spécifiés par l'utilisateur, tels que les descriptions de projet ou les libellés.",
    "info.user.settings.title": "Configuration personnelle",
    "invalid": "Invalid",
    "language.locale.de": "German",
    "language.locale.en": "English",
    "language.locale.es": "Spanish",
    "language.locale.fr": "French",
    "language.locale.hr": "Croatian",
    "language.locale.nl": "Dutch",
    "language.locale.pl": "Polish",
    "leaflet-map-cartodb": "Default",
    "leaflet-map-here-hybrid.day": "Hybrid",
    "leaflet-map-here-normal.day": "Normal",
    "leaflet-map-here-terrain.day": "Terrain",
    "link": "Link",
    "login.desc": "Pour obtenir l'accès à ce projet, merci de s'identifier avec votre compte Agouti.",
    "login.email-address": "E-mail",
    "login.password": "Mot de passe",
    "login.remember-me": "Se souvenir de moi",
    "login.sign-in": "Identification",
    "login.sign-in-email": "L'adresse e-mail est inconune.",
    "login.sign-in-error": "Identifants invalides",
    "login.sign-in-password": "Le mot de passe est incorrect.",
    "login.title": "Identification",
    "menu-item.annotate": "Annotater",
    "menu-item.browse": "Parcourir",
    "menu-item.cameras": "Caméras",
    "menu-item.deployments": "Déploiements",
    "menu-item.export-data": "Export des données",
    "menu-item.import": "Importer",
    "menu-item.locations": "Emplacements",
    "menu-item.observations": "Observations",
    "menu-item.overview": "Résumé",
    "menu-item.project-settings": "Configuration du projet",
    "menu-item.sampling-management": "Gestion de l'échantillonnage",
    "menu-item.settings": "Paramètres",
    "menu-item.settings.behaviour": "Comportement",
    "menu-item.settings.deployment-groups": "Groupes de déploiement",
    "menu-item.settings.deployment-tags": "Balises de déploiement",
    "menu-item.settings.general": "Général",
    "menu-item.settings.individuals": "Individus",
    "menu-item.species": "Espèce",
    "menu-item.user-management": "Gestion des utilisateurs",
    "menu-item.users": "Utilisateurs",
    "menu-item.validate": "Valider",
    "observation.id": "Observation ID",
    "portal.statistics.deployments": "Déploiements",
    "portal.statistics.images": "Images",
    "portal.statistics.observations": "Observations",
    "portal.statistics.projects": "Projets",
    "project-overview.show-less": "Afficher moins",
    "project-overview.show-more": "Afficher plus",
    "project-thumbnail.members": "{count, plural, other {# membres} =1 {# membre}}",
    "project-thumbnail.you-are": "Vous êtes {projectrole}",
    "project.annotate.annotate": "Annotater",
    "project.annotate.annotate-ai": "Annoter par AI",
    "project.annotate.annotate-view": "View annotations",
    "project.annotate.end": "Fin",
    "project.annotate.inspected-sequences": "Séquences terminées",
    "project.annotate.is-blank": "Séquences vides",
    "project.annotate.is-setup-pickup": "Configuration / prise en charge",
    "project.annotate.location": "Point d'échantillonnage",
    "project.annotate.no-unannotated-deployments": "Déploiements non annotés",
    "project.annotate.progress": "Progression",
    "project.annotate.sequence.annotate-overview": "Annotater",
    "project.annotate.sequence.back": "Précédent",
    "project.annotate.sequence.back-to-overview": "Back to overview",
    "project.annotate.sequence.dashboard": "Tableau de bord",
    "project.annotate.sequence.exposure": "Exposition",
    "project.annotate.sequence.finish-annotating": "Finir d'annoter",
    "project.annotate.sequence.identify": "Identification",
    "project.annotate.sequence.navigate-by-observation": "Liste des observations",
    "project.annotate.sequence.navigate-by-sequences": "Liste des séquences",
    "project.annotate.sequence.next": "Suivant",
    "project.annotate.sequence.next-unannotated": "Suivant non annoté",
    "project.annotate.sequence.observations.age": "Ages",
    "project.annotate.sequence.observations.back": "Retour à la liste",
    "project.annotate.sequence.observations.cancel": "Annuler",
    "project.annotate.sequence.observations.quantity": "Quantité",
    "project.annotate.sequence.observations.sequence": "Séquence",
    "project.annotate.sequence.observations.sex": "Sexe",
    "project.annotate.sequence.observations.species": "Espèce",
    "project.annotate.sequence.observations.suggested": "Suggéré",
    "project.annotate.sequence.observations.title": "Observations sur {name}",
    "project.annotate.sequence.order": "Ordre",
    "project.annotate.sequence.photo-of": "Photo {x} sur {y}",
    "project.annotate.sequence.sequence-of": "Séquence",
    "project.annotate.sequence.sequence-of2": "sur {y}",
    "project.annotate.sequence.validation-status": "Statut de validation",
    "project.annotate.sequence.warning.unsaved-work.body": "Vous éditiez une observation, soyez certain d'enregistrer votre travail avant de continuer.",
    "project.annotate.sequence.warning.unsaved-work.close-modal": "Ok",
    "project.annotate.sequence.warning.unsaved-work.header": "Attention: observations non enregistrées",
    "project.annotate.sequences": "Séquences",
    "project.annotate.start": "Début",
    "project.annotate.title": "Annotater",
    "project.annotate.unannotated-deployments": "Déploiements non annotés",
    "project.annotate.uninspected-sequences": "Séquences non terminées",
    "project.browse.assign-to": "Attribuer à",
    "project.browse.assigned-to": "Attribué à",
    "project.browse.deployments": "Déploiements",
    "project.browse.desc": "Sur cette page vous pouvez parcourir tous les déploiements de ce projet. <br/> Montrer les détails pour voir les tags, notes, spécifications et la capacité d'éditer les spécifications.",
    "project.browse.edit-deployment.title": "Editer un déploiement",
    "project.browse.end": "Fin",
    "project.browse.groups": "Groupes",
    "project.browse.location": "Point d'échantillonnage",
    "project.browse.modal.close": "Fermer",
    "project.browse.no-deployments": "Aucun déploiement disponible.",
    "project.browse.sequences": "Nombre de séquences",
    "project.browse.start": "Début",
    "project.browse.tags": "Tags",
    "project.browse.title": "Parcourir",
    "project.browse.unannotated": "Non annoté",
    "project.browse.view-observations.delete": "Êtes-vous sûr de vouloir supprimer cette observation?",
    "project.browse.view-observations.filter": "Montrer uniquement les observations avec",
    "project.browse.view-observations.split": "Are you sure you want to split this observation?",
    "project.deployment.aimodel.analyzing": "AI est occupée",
    "project.deployment.aimodel.failed": "AI a échoué",
    "project.deployment.aimodel.scheduled": "AI est programmée ..",
    "project.deployment.aimodel.success": "AI est prête ..",
    "project.deployment.complete.annotate": "Annotater ce déploiement",
    "project.deployment.complete.deployment-successfully-imported": "Déploiement importé avec succès",
    "project.deployment.complete.desc": "Merci de remplir ces détails pour terminer l'import de ce déploiement",
    "project.deployment.complete.import-new": "Importer un autre déploiement",
    "project.deployment.complete.success-message": "<b>Import terminé.</b>",
    "project.deployment.complete.success-message.close": "Annotater ce déploiement.",
    "project.deployment.complete.title": "Nouveau déploiement",
    "project.deployment.set-timestamp.filter": "Filtrer",
    "project.deployment.set-timestamp.save": "Continuer",
    "project.deployment.set-timestamps.desc": "Agouti ne peut pas trouver le timestamp pour ces ressources.<br>Merci d'insérer le timestamp manuellement pour qu'Agouti puisse traiter les séquencces correctement.",
    "project.deployment.set-timestamps.file": "Nom de fichier",
    "project.deployment.set-timestamps.timestamp": "Timestamp (notation 24 heures)",
    "project.deployment.set-timestamps.title": "Ajouter des timestamps",
    "project.export.create-export": "Créer l'export",
    "project.export.datasetId": "Dataset ID",
    "project.export.delete": "Supprimer",
    "project.export.download": "Télécharger",
    "project.export.filename": "Nom du fichier",
    "project.export.no-exports-created": "Aucun export créé.",
    "project.export.previous-exports": "Exports précédents",
    "project.export.size": "Taille",
    "project.export.status": "Status",
    "project.export.time-of-export": "Heure de l'export",
    "project.export.title": "Exporter les données",
    "project.export.title.sub": "Exporter les observations",
    "project.import.assets": "Ressources",
    "project.import.complete": "Complet",
    "project.import.create": "Nouveau déploiement",
    "project.import.create-sequence-confirmation": "Êtes-vous sûr de vouloir créer des séquences pour cet import?",
    "project.import.create-sequences": "Créer des séquences",
    "project.import.delete": "Supprimer",
    "project.import.delete-deployment-confirmation": "Êtes-vous sûr de vouloir supprimer cet import?",
    "project.import.end": "Fin",
    "project.import.files": "Fichiers sélectionnés",
    "project.import.folder": "Répertoires",
    "project.import.guidance": "Selectionner le dossier contenant les images ou les vidéos pour ce déploiement.",
    "project.import.images": "Photos",
    "project.import.new-deployment.new-deployment": "Importer un déploiement",
    "project.import.new-deployment.no-unfinished-imports": "Il n'y a aucun déploiement en attente.",
    "project.import.new-deployment.title": "Importer",
    "project.import.new-deployment.unfinished-imports": "Imports non terminés",
    "project.import.process": "Processus",
    "project.import.sequences": "Séquences",
    "project.import.start": "Début",
    "project.import.status": "Status",
    "project.import.timestamp": "Timestamp",
    "project.import.type": "Type",
    "project.import.upload": "Charger",
    "project.import.upload-modal.cancel": "Fermer",
    "project.import.upload-modal.desc1": "Merci de sélectionner et de charger vos fichiers. Pour le moment, seulement les fichiers JPG and AVI sont supportés.",
    "project.import.upload-modal.desc2": "Ou glisser déposer les images dans cette zone.",
    "project.import.upload-modal.select": "Selectionner des fichiers",
    "project.import.upload-modal.title": "Charger des fichiers",
    "project.import.upload-modal.uploading": "Uploading {queueFilesLength} files. Succesfully uploaded {uploaded} files, {duplicates} duplicates and {failed} files failed.",
    "project.import.uploaded": "Téléchargés",
    "project.import.viabrowser": "Depuis le navigateur",
    "project.import.viaftp": "Depuis le FTP",
    "project.overview.achievements": "Réalisations",
    "project.overview.browse": "Parcourir",
    "project.overview.deployment": "Déploiement",
    "project.overview.deployment-for-location": "Déploiement pour le point d'échantillonnage",
    "project.overview.deployments": "Déploiements",
    "project.overview.desc": "Description du project",
    "project.overview.favourite-assets": "Favourites",
    "project.overview.favourite-sequences": "Séquences favorites",
    "project.overview.import-deployment": "Importer un déploiement",
    "project.overview.imported-deployments": "Déploiements importés",
    "project.overview.most.observed.species": "Espèce la plus observée",
    "project.overview.no-deployments": "Aucun déploiement disponible.",
    "project.overview.observations": "Observations",
    "project.overview.read.hide": "Masquer",
    "project.overview.read.show": "Voir plus",
    "project.overview.records": "Enregistrements",
    "project.overview.records-content": "observés le plus d'espèces sur ce projet",
    "project.overview.sequences": "Séquences",
    "project.overview.stats": "Statistiques",
    "project.overview.team.members": "Membres de l'équipe",
    "project.overview.unfinished-deployments": "Déploiements non terminés",
    "project.request-access.description": "Cliquer sur 'Demander' pour confirmer la demande d'accès à ce projet.",
    "project.request-access.request": "Demander l'accès",
    "project.request-access.title": "Demander l'accès",
    "project.sampling-management.add-group": "Ajouter un groupe",
    "project.sampling-management.add-group.title": "Ajouter un groupe",
    "project.sampling-management.add-sampling-point": "Ajouter un point d'échantillonnage",
    "project.sampling-management.add-sampling-point.title": "Ajouter un nouveau point d'échantillonnage",
    "project.sampling-management.add-samplingpoint.cancel": "Annuler",
    "project.sampling-management.add-samplingpoint.missing-coordinates": "Veuillez entrer le nom et les coordonnées.",
    "project.sampling-management.add-samplingpoint.save": "Enregistrer",
    "project.sampling-management.add-tag": "Ajouter un tag",
    "project.sampling-management.add-tag.cancel": "Annuler",
    "project.sampling-management.add-tag.name": "Nom",
    "project.sampling-management.add-tag.name-placeholder": "Nom",
    "project.sampling-management.add-tag.save": "Enregistrer",
    "project.sampling-management.add-tag.title": "Ajouter un tag",
    "project.sampling-management.delete-tag.confirmation": "Es-tu sûr(e) de vouloir supprimer cette balise?",
    "project.sampling-management.edit-group.title": "Editer un groupe",
    "project.sampling-management.edit-sampling-point.cancel": "Annuler",
    "project.sampling-management.edit-sampling-point.save": "Enregistrer",
    "project.sampling-management.edit-sampling-point.title": "Editer un point d'échantillonnage",
    "project.sampling-management.edit-tag.cancel": "Annuler",
    "project.sampling-management.edit-tag.name": "Nom",
    "project.sampling-management.edit-tag.name-placeholder": "Nom",
    "project.sampling-management.edit-tag.save": "Enregistrer",
    "project.sampling-management.edit-tag.title": "Editer un tag",
    "project.sampling-management.sampling-points": "Points d'échantillonnage",
    "project.sampling-management.sampling-points.name": "Nom",
    "project.sampling-management.samplingpoints.delete": "Supprimer",
    "project.sampling-management.samplingpoints.edit": "Editer",
    "project.sampling-management.tags": "Tags de déploiement",
    "project.sampling-management.tags.delete": "Supprimer",
    "project.sampling-management.tags.edit": "Editer",
    "project.sampling-management.tags.name": "Nom",
    "project.sampling-management.title": "Gestion de l'échantillonnage",
    "project.sampling-management.upload-csv": "Charger un CSV",
    "project.sequence.import.failed": "Sequence import failed.",
    "project.sequence.import.importing": "Importing sequences",
    "project.sequence.import.importing.note": "Note: depending on the size of the upload, how busy the server is, this can take a while.<br>You don't need to wait for the creation to complete.",
    "project.sequence.import.importing.success": "Sequences ready!",
    "project.sequence.import.status": "Status of sequence import",
    "project.settings.add-behaviour": "Ajouter un nouveau comportement",
    "project.settings.add-behaviour.cancel": "Annuler",
    "project.settings.add-behaviour.description": "Description",
    "project.settings.add-behaviour.description-placeholder": "Description",
    "project.settings.add-behaviour.name": "Nom",
    "project.settings.add-behaviour.name-placeholder": "Nom",
    "project.settings.add-behaviour.save": "Enregistrer",
    "project.settings.add-behaviour.title": "Ajouter un comportement",
    "project.settings.add-camera": "Ajouter une nouvelle caméra",
    "project.settings.add-camera.cancel": "Annuler",
    "project.settings.add-camera.label": "Label",
    "project.settings.add-camera.label-placeholder": "Label",
    "project.settings.add-camera.make": "Créer",
    "project.settings.add-camera.make-placeholder": "Créer",
    "project.settings.add-camera.model": "Modèle",
    "project.settings.add-camera.model-placeholder": "Modèle",
    "project.settings.add-camera.save": "Enregistrer",
    "project.settings.add-camera.serial-number": "Numéro de série",
    "project.settings.add-camera.serial-number-placeholder": "Numéro de série",
    "project.settings.add-camera.title": "Ajouter une caméra",
    "project.settings.add-camera.year-of-purchase": "Année d'achat",
    "project.settings.add-deployment": "Ajouter un déploiement",
    "project.settings.add-individual": "Ajouter un nouveau individu",
    "project.settings.add-individual.age": "Age",
    "project.settings.add-individual.cancel": "Annuler",
    "project.settings.add-individual.name": "Nom",
    "project.settings.add-individual.name-placeholder": "Nom",
    "project.settings.add-individual.notes": "Notes",
    "project.settings.add-individual.notes-placeholder": "Notes",
    "project.settings.add-individual.save": "Enregistrer",
    "project.settings.add-individual.sex": "Sexe",
    "project.settings.add-individual.species": "Espèce",
    "project.settings.add-individual.title": "Ajouter un individu",
    "project.settings.add-location": "Ajouter un emplacement",
    "project.settings.add-species": "Ajouter une espèce",
    "project.settings.add-species-list": "Ajouter une liste d'espèces",
    "project.settings.add-species-to-add": "Espèces à ajouter",
    "project.settings.add-species.cancel": "Annuler",
    "project.settings.add-species.save": "Enregistrer",
    "project.settings.add-species.species": "Espèce",
    "project.settings.add-species.species-placeholder": "Espèce",
    "project.settings.add-species.title": "Ajouter une espèce",
    "project.settings.annotate-calibration-and-tracking": "Annoter la calibration et le tracking",
    "project.settings.annotate-timelapses": "Annoter les timelapses",
    "project.settings.annotate-timelapses-disabled": "Les timelapses ne peuvent pas être annotés lorsque des séquences ont été ajoutées.",
    "project.settings.annotation": "Annotation",
    "project.settings.automatic-annotation": "Détection automatique",
    "project.settings.automatic-annotation-on-upload": "Automatiquement avec de nouvelles séries de photos",
    "project.settings.automatic-annotation.decription": "Lorsqu'ils sont activés, les nouveaux déploiements sont d'abord traités par l'IA et peuvent être modifiés une fois cette opération terminée.",
    "project.settings.automatic-annotation.placeholder": "Sélectionnez un modèle",
    "project.settings.behaviour": "Comportement",
    "project.settings.behaviour.delete": "Supprimer",
    "project.settings.behaviour.description": "Description",
    "project.settings.behaviour.edit": "Editer",
    "project.settings.behaviour.name": "Nom",
    "project.settings.camera.delete": "Sauvegarder",
    "project.settings.camera.edit": "Editer",
    "project.settings.camera.label": "Label",
    "project.settings.camera.make": "Créer",
    "project.settings.camera.model": "Modèle",
    "project.settings.camera.serial-number": "Numéro de série",
    "project.settings.camera.year-of-purchase": "Année d'achat",
    "project.settings.cameras": "Caméras",
    "project.settings.delete-behaviour-confirmation": "Êtes-vous sûr de vouloir supprimer ce comportement?",
    "project.settings.delete-camera-confirmation": "Êtes-vous sûr de vouloir supprimer cette caméra?",
    "project.settings.delete-individual-confirmation": "Êtes-vous sûr de vouloir supprimer cet individu?",
    "project.settings.delete-sampling-point-confirmation": "Êtes-vous sûr de vouloir supprimer ce point d'échantillonnage?",
    "project.settings.delete-species-confirmation": "Êtes-vous sûr de vouloir supprimer cette espèce?",
    "project.settings.deployments-are-assigned": "Les déploiements sont attribués",
    "project.settings.description": "Description",
    "project.settings.description-placeholder": "Description",
    "project.settings.edit-behaviour.cancel": "Annuler",
    "project.settings.edit-behaviour.description": "Description",
    "project.settings.edit-behaviour.description-placeholder": "Description",
    "project.settings.edit-behaviour.name": "Nom",
    "project.settings.edit-behaviour.name-placeholder": "Nom",
    "project.settings.edit-behaviour.save": "Enregistrer",
    "project.settings.edit-behaviour.title": "Modifier le comportement",
    "project.settings.edit-camera": "Editer la caméra",
    "project.settings.edit-camera.cancel": "Annuler",
    "project.settings.edit-camera.label": "Label",
    "project.settings.edit-camera.label-placeholder": "Label",
    "project.settings.edit-camera.make": "Créer",
    "project.settings.edit-camera.make-placeholder": "Créer",
    "project.settings.edit-camera.model": "Modèle",
    "project.settings.edit-camera.model-placeholder": "Modèle",
    "project.settings.edit-camera.save": "Enregistrer",
    "project.settings.edit-camera.serial-number": "Numéro de série",
    "project.settings.edit-camera.serial-number-placeholder": "Numéro de série",
    "project.settings.edit-camera.title": "Editer la caméra",
    "project.settings.edit-camera.year-of-purchase": "Année d'achat",
    "project.settings.edit-individual.age": "Age",
    "project.settings.edit-individual.cancel": "Annuler",
    "project.settings.edit-individual.name": "Name",
    "project.settings.edit-individual.name-placeholder": "Nom",
    "project.settings.edit-individual.notes": "Notes",
    "project.settings.edit-individual.notes-placeholder": "Notes",
    "project.settings.edit-individual.save": "Enregistrer",
    "project.settings.edit-individual.sex": "Sexe",
    "project.settings.edit-individual.species": "Espèce",
    "project.settings.edit-individual.title": "Nom un individu",
    "project.settings.edit-location": "Editer l'emplacement",
    "project.settings.edit-location.images": "Images",
    "project.settings.edit-species.cancel": "Annuler",
    "project.settings.edit-species.common-names": "Editer le nom vernaculaire",
    "project.settings.edit-species.is-identifiable-as-domesticated": "Identifié comme domestiqué",
    "project.settings.edit-species.name": "Espèce",
    "project.settings.edit-species.save": "Enregistrer",
    "project.settings.edit-species.title": "Editer l'espèce",
    "project.settings.feature": "Fonctionnalité",
    "project.settings.feature-methodology": "Méthodologie des fonctionnalités",
    "project.settings.feature-methodology-placeholder": "Méthodologie des fonctionnalités",
    "project.settings.feature.select-feature": "Select feature",
    "project.settings.general": "Général",
    "project.settings.hide-deployment-locations": "Masquer les emplacements des déploiements",
    "project.settings.image": "Image",
    "project.settings.individual.add-individual": "Ajouter un  nouvel individu",
    "project.settings.individual.delete": "Supprimer",
    "project.settings.individual.edit": "Editer",
    "project.settings.individual.name": "Nom",
    "project.settings.individual.notes": "Notes",
    "project.settings.individual.species": "Espèce",
    "project.settings.individual.zooniverse": "Zooniverse",
    "project.settings.individuals": "Individus",
    "project.settings.location": "Emplacement",
    "project.settings.min-image-resolution": "Minimum {min_width} par {min_height} pixels",
    "project.settings.name": "Nom",
    "project.settings.name-placeholder": "Nom",
    "project.settings.open-for-subscriptions": "Ouvert pour abonnement",
    "project.settings.organisation": "Organisation",
    "project.settings.organisation-help-block": "Organisations are considered rights holders of the data.",
    "project.settings.organisation-placeholder": "Organisation",
    "project.settings.project-investigator": "PI",
    "project.settings.project-investigator-placeholder": "Chercheur principal",
    "project.settings.project-owner": "Contact",
    "project.settings.project-owner-placeholder": "Projetcontact",
    "project.settings.quiet-period": "Délais de réarmement",
    "project.settings.restricted-access": "Accès restreint",
    "project.settings.restricted-images": "Images restreintes",
    "project.settings.restricted-images.note": "Lorsque cette case est cochée, les URL d'image dans les exportations/données publiées ne seront pas accessibles aux utilisateurs externes.",
    "project.settings.restricted-locations": "Emplacements restreints",
    "project.settings.restricted-locations.note": "Lorsque cette case est cochée, les processeurs de photos et les volontaires ne verront que les emplacements de leurs propres déploiements.",
    "project.settings.restricted-project": "Projet restreint",
    "project.settings.restricted-project.note": "Lorsque cette case est cochée, votre projet sera masqué de la page d'accueil et du portail publics sur agouti.eu.",
    "project.settings.sampling-design": "Plan d'échantillonnage",
    "project.settings.sampling-design.clustered-random": "Groupé aléatoire (déploiement au hasard)",
    "project.settings.sampling-design.experimental": "Expérimental",
    "project.settings.sampling-design.label": "Conception d'échantillonnage",
    "project.settings.sampling-design.opportunistic": "Opportuniste",
    "project.settings.sampling-design.placeholder": "Sélectionner les conceptions d'échantillonnage",
    "project.settings.sampling-design.simple-random": "Simple au hasard",
    "project.settings.sampling-design.systematic-random": "Systématique au hasard",
    "project.settings.sampling-design.targeted": "Ciblé",
    "project.settings.seconds": "secondes",
    "project.settings.sensormethod": "Méthode de détection",
    "project.settings.sensormethod.motion-detection": "Détection de mouvement",
    "project.settings.sensormethod.select-sensormethod": "Sélectionner une méthode de détection",
    "project.settings.sensormethod.timelapse": "À intervalle régulier",
    "project.settings.sequence-cut-off": "Intervalle de séquence",
    "project.settings.sequence-cutoff-disabled": "L'intervalle de séquence ne peut être changé quand des séquences ont été créées.",
    "project.settings.species": "Espèce",
    "project.settings.species.added": "Ajouté au projet",
    "project.settings.species.available": "Espèces disponibles",
    "project.settings.species.common-name": "Nom vernaculaire",
    "project.settings.species.countDeployments": "Deployments",
    "project.settings.species.countObservations": "Observations",
    "project.settings.species.countValObservations": "Validated",
    "project.settings.species.edit": "Editer",
    "project.settings.species.family": "Famille",
    "project.settings.species.genus": "Genre",
    "project.settings.species.isIdentifiableAsDomesticated": "Domestiqué",
    "project.settings.species.order": "Ordre",
    "project.settings.species.remove": "Supprimer",
    "project.settings.species.species": "Espèce",
    "project.settings.success-message": "Configuration du projet enregistré.",
    "project.settings.title": "Configuration du projet",
    "project.settings.update-location.cancel": "Annuler",
    "project.settings.update-location.save": "Enregistrer",
    "project.settings.update-location.title": "Editer l'emplacement",
    "project.settings.utc-offset": "Décalage UTC par défaut",
    "project.user-management.accept-pending-request-confirmation": "Êtes-vous sûr de vouloir accepter cette demande?",
    "project.user-management.awaiting-access": "En attente d'accès",
    "project.user-management.copy": "Copier le lien d'invitation",
    "project.user-management.copy.confirmation": "Lien copié",
    "project.user-management.delete-user-confirmation": "Êtes-vous sûr de vouloir révoquer l'accès de ce membre à ce projet?",
    "project.user-management.edit-user.cancel": "Annuler",
    "project.user-management.edit-user.email": "Adresse e-mail",
    "project.user-management.edit-user.email-placeholder": "Adresse e-mail",
    "project.user-management.edit-user.first-name": "Prénom",
    "project.user-management.edit-user.first-name-placeholder": "Prénom",
    "project.user-management.edit-user.last-name": "Nom de famille",
    "project.user-management.edit-user.last-name-placeholder": "Nom de famille",
    "project.user-management.edit-user.member-since": "Membre depuis",
    "project.user-management.edit-user.revoke-access": "Accès révoqué",
    "project.user-management.edit-user.role": "Rôle",
    "project.user-management.edit-user.save": "Enregistrer",
    "project.user-management.edit-user.title": "Editer l'utilisateur",
    "project.user-management.email-address": "Adresse e-mail",
    "project.user-management.first-name": "Prénom",
    "project.user-management.last-name": "Nom de famille",
    "project.user-management.link-description": "Utiliser ce lien pour autoriser les utilisateurs à demander l'accès à votre projet.",
    "project.user-management.pending-requests": "Demandes en attente",
    "project.user-management.pending-requests.accept": "Accepter",
    "project.user-management.pending-requests.decline": "Refuser",
    "project.user-management.pending-requests.empty": "Aucune demande en attente trouvée",
    "project.user-management.principal-investigators": "Chercheur principal",
    "project.user-management.project-access": "Accès au projet",
    "project.user-management.registered-users": "Membres",
    "project.user-management.registered-users.delete": "Supprimer",
    "project.user-management.registered-users.edit": "Editer",
    "project.user-management.role": "Rôle",
    "project.user-management.title": "Gestion des  utilisteurs",
    "project.user-management.userbase": "Membres",
    "project.user-management.userbase.title": "Base d'utilisateurs",
    "project.user-management.volunteers": "Volontaires",
    "project.validate.accept-sequence": "Accept sequence observations",
    "project.validate.accepted-by-at": "Validated by {validator} on {date}",
    "project.validate.add-new-query": "Add new query",
    "project.validate.all-are-true": "All of the following predicates are true",
    "project.validate.edit.title": "Edit validation query",
    "project.validate.filter.created-from": "Created from",
    "project.validate.filter.created-to": "Created to",
    "project.validate.filter.is-equal-to": "Is equal to",
    "project.validate.filter.is-unknown": "Is unknown",
    "project.validate.filter.sampling-point": "Sampling point",
    "project.validate.filter.select-filter-type": "Select filter type",
    "project.validate.filter.select-role": "Select project role",
    "project.validate.filter.select-sampling-point": "Select sampling point",
    "project.validate.filter.select-species": "Select species",
    "project.validate.filter.select-user": "Select user",
    "project.validate.filter.species": "Species",
    "project.validate.filter.user": "User",
    "project.validate.filter.user-project-role": "Project role",
    "project.validate.new.title": "New validation query",
    "project.validate.no-rules-defined-yet": "No predicates defined yet...",
    "project.validate.no-validation-queries": "There are no validation queries defined for this project yet",
    "project.validate.none-are-true": "None of the following predicates are true",
    "project.validate.predicates": "Predicates",
    "project.validate.query-is-failed": "Something went wrong during the execution of the query...",
    "project.validate.query-is-running": "Query is running...",
    "project.validate.query-is-scheduled": "Query is scheduled...",
    "project.validate.results.assest-count": "Asset count",
    "project.validate.results.no-results": "No results found...",
    "project.validate.results.show-done": "Show validated sequences",
    "project.validate.results.total-results": "Total results",
    "project.validate.results.total-results-of": "Showing {count} of {total} results (max {limit})",
    "project.validate.rule-presets": "Rule presets",
    "project.validate.scheduling-explanation": "Queries are run every hour. Execution takes a few minutes, up to half an hour. Please refresh the page to update the status of the validation queries.",
    "project.validate.sequences": "Sequences",
    "project.validate.table.header.candidates": "Matches",
    "project.validate.table.header.name": "Name",
    "project.validate.title": "Validate",
    "project.validate.unaccept-sequence": "Remove validation",
    "project.validate.validate": "validate",
    "project.validate.validation-query-results": "Validation query results",
    "project.zooniverse.password": "Mot de passe Zooniverse",
    "project.zooniverse.password-placeholder": "Mot de passe Zooniverse",
    "project.zooniverse.projectid": "Projectid de Zooniverse",
    "project.zooniverse.projectid-placeholder": "Projectid de Zooniverse",
    "project.zooniverse.username": "Utilisateur Zooniverse",
    "project.zooniverse.username-placeholder": "Utilisateur Zooniverse",
    "projectrole.ADMIN": "Administrateur",
    "projectrole.AWAITING_ACCESS": "En attente d'accès",
    "projectrole.DUMMY": "Dummy",
    "projectrole.PHOTO_PROCESSOR": "Opérateur photo",
    "projectrole.PRINCIPAL_INVESTIGATOR": "Chercheur principal",
    "projectrole.PROJECT_COORDINATOR": "Coordinateur de projet",
    "projectrole.TAXONOMIC_EXPERT": "Expert taxonomique",
    "projectrole.VIEW_ONLY": "View Only",
    "projectrole.VOLUNTEER": "Volontaire",
    "projects.add": "Ajouter nouveau projet",
    "projects.independent-projects": "Projets indépendants",
    "projects.no-projects-available": "Pas encore de projet. Merci de contacter le coordinateur de projet pour obtenir les instructions.",
    "projects.search-for": "Chercher...",
    "projects.search-for.filter": "Filtrer les projets",
    "projects.search-for.filterOrg": "Filter on organisation",
    "projects.title": "Projets",
    "projects.title.user": "Vos projets",
    "projects.your-role": "Votre rôle",
    "publichome.block1.title": "Qu'est ce qu'Agouti?",
    "publichome.block1.txt": "Les pièges photos sont une façon simple et non invasive d'étudier les animaux. Cependant, le traitement et le stockage des milliers d'images générées par une étude typique deviennent rapidement difficiles. En outre, la gestion de projet peut devenir difficile, surtout si plusieurs caméras et plusieurs personnes sont impliquées.",
    "publichome.block1.txt2": "Agouti aide les utilisateurs à résoudre ces problèmes. C'est une solution complète pour les organisations et les particuliers qui utilisent des pièges photographiques pour étudier la faune. Il permet aux trappeurs de caméras d'organiser des enquêtes collaboratives, de traiter rapidement et facilement des images, d'obtenir des résumés normalisés des résultats, d'archiver en toute sécurité des images et des données et, finalement, de résumer et de partager des données. Agouti respecte les normes de métadonnées, facilitant la comparaison entre les projets.",
    "publichome.block2.title": "Flux de travail",
    "publichome.block2.txt": "Agouti est un ensemble de projets initiés par des organisations ou des individus. Les participants au projet téléchargent l'intégralité du contenu des cartes mémoire à partir de pièges photographiques et saisissent l'emplacement et d'autres métadonnées. Agouti importe ces images, extrait les dates, heures et autres données des images elles-mêmes, et les regroupe dans des séquences représentant le même événement. Les observateurs inspectent chaque séquence et les annotent avec une ou plusieurs observations, à l'aide d'une interface simple.",
    "publichome.block2.txt2": "Vous pouvez exporter vos données sous forme de package de données Camera Trap (<a target=\"_blank\" href=\"https://tdwg.github.io/camtrap-dp/\">Camtrap DP</a>) pour analyse dans des logiciels tels comme R. Nous vous recommandons d'utiliser le package R <a target=\"_blank\" href=\"https://inbo.github.io/camtraptor/\">camtraptor</a> pour commencer.",
    "publichome.block3.title": "Importer",
    "publichome.block3.txt": "Charger des images et des vidéos en utilisant seulement le navigateur",
    "publichome.block3.txt2": "Annotater",
    "publichome.block3.txt3": "Tagger des images avec des observations, en utilisant les outils appropriés",
    "publichome.block3.txt4": "Analyser",
    "publichome.block3.txt5": "Parcourir les données avec les outils intégrés et présentations",
    "publichome.block3.txt6": "Exporter",
    "publichome.block3.txt7": "Générer des fichiers de données standardisés pour analyse et partage",
    "publichome.block4.title": "Interface intuitive",
    "publichome.block4.txt": "L'interface d'Agouti est propre, moderne, et orienté vers la productivité. Tout personne peut commencer à travailler avec peu de formation. Les vidéos ci-dessous illustrent différents aspects du système.",
    "publichome.block4.txt1": "Commencer",
    "publichome.block4.txt2": "Importer des déploiements",
    "publichome.block4.txt3": "Annoter des séquences",
    "publichome.block4.vid1": "/assets/videos/GettingStarted-FR.mp4",
    "publichome.block4.vid2": "/assets/videos/ImportingDeployments-FR.mp4",
    "publichome.block4.vid3": "/assets/videos/AnnotatingSequences-FR.mp4",
    "publichome.block5.title": "Projets soulignés",
    "publichome.block5.txt": "Agouti héberge une variété de projets. Explorez certains de ces projets publics pour découvrir les possibilités d'Agouti.",
    "publichome.block6.f1": "Gérer",
    "publichome.block6.f2": "Plan d'échantillonnage",
    "publichome.block6.f3": "Plan spatial",
    "publichome.block6.f4": "Suivre les pièges photo",
    "publichome.block6.f5": "Tag de déploiement",
    "publichome.block6.f6": "Control de qualité",
    "publichome.block6.g1": "Processus",
    "publichome.block6.g2": "Ajouter des observations",
    "publichome.block6.g3": "Liste des espèces standardisées",
    "publichome.block6.g4": "Exporter fichiers CSV",
    "publichome.block6.g5": "Suivre les animaux individuellement",
    "publichome.block6.g6": "Annoter les comportements",
    "publichome.block6.h1": "Collaborer",
    "publichome.block6.h2": "Inviter des membres",
    "publichome.block6.h3": "Assigner des rôles",
    "publichome.block6.h4": "Assigner des tâches",
    "publichome.block6.h5": "Superviser la progression",
    "publichome.block6.h6": "Organiser les données",
    "publichome.block6.title": "Caractéristiques",
    "publichome.block6.txt": "Avec Agouti vous disposez d'un vaste ensemble d'outils.",
    "publichome.block7.title": "Privé & Sécurisé",
    "publichome.block7.txt": "La propriété des données est importante. Agouti ne revendique aucun droit de propriété sur les données et ne les distribue pas à des tiers. Vous décidez qui y a accès, vous pouvez donc collaborer selon vos propres conditions..",
    "publichome.block7.txt2": "Agouti fournit un endroit sûr pour stocker vos données. Éliminez le risque de perte de données en cas de panne de disque dur, d'erreur utilisateur ou de panne d'ordinateur. Les sauvegardes quotidiennes hors site et une infrastructure entièrement redondante garantissent un accès optimal à vos données.",
    "publichome.block8.f1": "Académique",
    "publichome.block8.f2": "Gratuit",
    "publichome.block8.f3": "Stockage illimité",
    "publichome.block8.f4": "Projets et utilisateurs illimités",
    "publichome.block8.f5": "Best effort support",
    "publichome.block8.f6": "Postuler",
    "publichome.block8.g1": "Non lucratif",
    "publichome.block8.g2": "Gratuit",
    "publichome.block8.g3": "Stockage illimité",
    "publichome.block8.g4": "Projets et utilisateurs illimités",
    "publichome.block8.g5": "Best effort support",
    "publichome.block8.g6": "Postuler",
    "publichome.block8.h1": "Commerciale",
    "publichome.block8.h2": "Pay-what-you-can",
    "publichome.block8.h3": "Stockage illimité",
    "publichome.block8.h4": "Projets et utilisateurs illimités",
    "publichome.block8.h5": "Best effort support",
    "publichome.block8.h6": "Postuler",
    "publichome.block8.title": "Commencer à utiliser Agouti",
    "publichome.block8.txt": "Les coûts d'hébergement et de développement d'Agouti sont à la charge des utilisateurs. Les étudiants peuvent utiliser Agouti gratuitement, tandis que les autres partis apportent une contribution. Les utilisateurs commerciaux doivent contribuer davantage, car ils gagnent du temps et de l'argent en utilisant Agouti. Dans ce modèle collaboratif, les coûts sont répartis de manière à ce que tout le monde ait accès à la plateforme.",
    "publichome.explore-projects": "Explorer les projets",
    "publichome.footer1": "Agouti",
    "publichome.footer10": "6708 PB Wageningen",
    "publichome.footer11": "Pays-Bas",
    "publichome.footer12": "Institut de recherche sur la nature et la forêt (INBO)",
    "publichome.footer13": "Gestion de la faune et des espèces exotiques & laboratoire scientifique ouvert sur la biodiversité",
    "publichome.footer14": "Havenlaan 88 bus 73",
    "publichome.footer15": "1000 Brussel",
    "publichome.footer16": "Belgique",
    "publichome.footer2": "Liste de diffusion pour les utilisateurs",
    "publichome.footer3": "Politique de confidentialité",
    "publichome.footer5": "Organisation",
    "publichome.footer6": "Agouti est une initiative de l'Université de Wageningen et de l'Institut de recherche sur la nature et la forêt (INBO)",
    "publichome.footer7": "Université de Wageningen",
    "publichome.footer8": "Département des sciences de l'environnement",
    "publichome.footer9": "Wildlife Ecology and Conservation Group",
    "publichome.slogan": "Une plateforme pour gérer les projets animaliers utilisant des pièges photos",
    "publichome.stats1": "Projets",
    "publichome.stats2": "Déploiements",
    "publichome.stats3": "Observations",
    "publichome.stats4": "Images",
    "publichome.title": "Agouti",
    "sequence.id": "Sequence ID",
    "sex.": "N.A.",
    "sex.female": "Femelle",
    "sex.male": "Mâle",
    "sex.unknown": "Inconnu",
    "taxonRank": "Taxon Rank",
    "toggle-processed": "Hide 100%",
    "user.register.desc": "S'enregistrer pour un compte Agouti",
    "user.register.email-address": "E-mail",
    "user.register.email-address-duplicate": "Adresse e-mail déjà utilisée.",
    "user.register.email-address-error": "Merci de renseigner votre e-mail.",
    "user.register.first-name": "Prénom",
    "user.register.first-name-error": "Merci de renseigner votre prénom.",
    "user.register.gdpr-error": "Tu dois confirmer ton consentement au RGPD",
    "user.register.last-name": "Nom de famille",
    "user.register.last-name-error": "Merci de renseigner votre nom de famille.",
    "user.register.password": "Mot de passe",
    "user.register.password-error": "Merci de renseigner votre mot de passe.",
    "user.register.title": "S'enregistrer",
    "user.request-reset-password.desc": "Request a password reset link",
    "user.request-reset-password.title": "Reset password",
    "user.reset-password.desc": "Choose a new Agouti password",
    "user.reset-password.email-address": "E-mail",
    "user.reset-password.email-address-duplicate": "Email address already in use.",
    "user.reset-password.email-address-error": "Please fill in your email.",
    "user.reset-password.password": "Password",
    "user.reset-password.password-are-different-error": "Please enter the same password twice.",
    "user.reset-password.password-confirmation": "Password confirmation",
    "user.reset-password.password-confirmation-error": "Please fill in a password confirmation.",
    "user.reset-password.password-error": "Please fill in a password.",
    "user.reset-password.title": "Reset password",
    "user.settings.account": "Compte",
    "user.settings.adaptive-image-size": "Taille d'image adaptative",
    "user.settings.annotate-shortcuts-label": "Annoter raccourcis",
    "user.settings.anonymous-in-export": "Exportations anonymes",
    "user.settings.anonymous-in-export-explanation": "Une fois vérifiées, tes données personnelles seront exportées de manière anonyme. Ton nom et ton adresse e-mail ne seront pas inclus dans les exportations de données et tu ne seras pas crédité en tant que contributeur, préparateur et/ou classificateur.",
    "user.settings.autoscroll-thumbnails-annotating": "Auto-scroll thumbnails when annotating",
    "user.settings.common": "Vernaculaire",
    "user.settings.email-address": "Adresse E-mail",
    "user.settings.email-address-label": "E-mail",
    "user.settings.email-in-use": "Adresse e-mail déjà utilisée.",
    "user.settings.first-name": "Prénom",
    "user.settings.first-name-note": "Voici ce que les autres membres verront.",
    "user.settings.gravatar": "Ou activer <a href=\"http://gravatar.com\" title=\"http://gravatar.com\">Gravatar</a>",
    "user.settings.gravatar.disable": "Désactiver",
    "user.settings.gravatar.enable": "Activer",
    "user.settings.language": "Langue",
    "user.settings.last-name": "Nom de famille",
    "user.settings.organisation": "Organisation",
    "user.settings.organisation-placeholder": "Organisation",
    "user.settings.password": "Mot de passe",
    "user.settings.picture": "Avatar publique",
    "user.settings.picture.browse": "Parcourir...",
    "user.settings.picture.file": "Pas de fichier selectionné",
    "user.settings.picture.remove": "Supprimer",
    "user.settings.picture.upload": "Charger un nouvel avatar",
    "user.settings.save": "Sauver les changements",
    "user.settings.save.update": "Mettre à jour les paramètres",
    "user.settings.saved": "Configuration sauvegardée.",
    "user.settings.scientific": "Scientifique",
    "user.settings.species-names": "Noms des espèces",
    "user.settings.title": "Configuration de l'utilisateur",
    "user.settings.url.label": "Url (ORCID)",
    "user.settings.url.placeholder": ""
  }], ["hr", {
    "age.": "N.A.",
    "age.adult": "Odrasli",
    "age.infant": "Mladi",
    "age.subadult": "Srednjedobni",
    "age.unknown": "Nepoznato",
    "application.form.cancel": "Prekini",
    "application.form.close": "Zatvori",
    "application.form.delete": "Izbriši",
    "application.form.edit": "Uredi",
    "application.form.fields.description": "Opis",
    "application.form.fields.name": "Naziv",
    "application.form.save": "Spremi",
    "application.form.saved": "Spremljeno",
    "application.format.date": "MM/DD/YYYY",
    "application.format.date-time": "MM/DD/YYYY HH:mm",
    "application.gdpr.continue": "Prije nego što nastavite odobrite Sveučilištu i istraživačkom centru Wageningen da pohranjuje i obrađuje vaše ime i prezime te adresu e-pošte za potrebe znanstvenog istraživanja",
    "application.gdpr.text": "Takvim odobrenjem suglasni ste da ste dobrovoljno omogućili te osobne podatke. Imate pravo opozvati suglasnost koju ste pružili. Osobni podatci koje ste nam pružili upotrebljavat će se isključivo za potrebe za koje ste nam ih pružili. Imate pravo provjeriti, izbrisati, ispraviti i ograničiti obradu osobnih podataka kao i pravo na žalbu te prenosivost podataka <a target=\"_blank\" href=\"https://www.wur.nl/en/About-Wageningen/Integrity-and-privacy.htm\">https://www.wur.nl/en/About-Wageningen/Integrity-and-privacy.htm</a> Napomena: ne postoji apsolutno pravo uključenih osoba. Navedeno će se uvijek morati razmatrati za pojedinačan slučaj i pravo na koje se poziva. Ako je obrada nužna za izvršavanje statutom propisanih zadataka ili radi općeg interesa (npr. za potrebe arhiviranja koje su od javnog interesa, potrebe znanstvenog ili povijesnog istraživanja ili statističkih podataka), pravo na zaborav ne postoji. U slučaju pritužbe obratite se Sveučilištu i istraživačkom centru Wageningen putem <a href=\"mailto:privacy@wur.nl\">privacy@wur.nl</a>. Pritužbu možete podnijeti i nizozemskom tijelu za zaštitu podataka. Više informacija o navedenom potražite na <a target=\"_blank\" href=\"https://www.autoriteitpersoonsgegevens.nl\">www.autoriteitpersoonsgegevens.nl</a>. U slučaju pitanja obratite se službeniku za zaštitu podataka Sveučilišta i istraživačkog centra Wageningen putem <a href=\"mailto:functionarisgegevensbescherming@wur.nl\">functionarisgegevensbescherming@wur.nl</a>.",
    "application.gdpr.title": "Opća uredba o zaštiti podataka",
    "application.gdpr.user-consent": "Ovim putem Sveučilištu i istraživačkom centru Wageningen dajem suglasnost za pohranu i obradu svojeg imena i prezimena te adrese e-pošte za potrebe znanstvenog istraživanja",
    "application.menu.dashboard": "Kontrolna ploća",
    "application.menu.login": "Prijava",
    "application.menu.logout": "Odjava",
    "application.menu.organisations": "Organizacije",
    "application.menu.register": "Registracija",
    "application.menu.settings": "Postavke",
    "application.menu.userguide": "Használati útmutató",
    "application.menu.yourtasks": "A feladataidat",
    "class": "Class",
    "colophon.contributors": "Organizacije",
    "colophon.desc": "Agouti je aplikacija za upravljanje projektima, za praćenje kamerama i analizu vaših podataka.",
    "colophon.inbo": "Istraživački institut za prirodu i šume (INBO)",
    "colophon.partners": "Partneri",
    "colophon.release": "Pusti",
    "colophon.title": "O Agouti-ju",
    "colophon.version": "v3.8.0 / 27-5-2021",
    "colophon.wur": "Sveučilište Wageningen",
    "component.bait-selector.bait": "Mamac",
    "component.bait-selector.baits": "Mamce",
    "component.bait-selector.select-bait": "Odaberite mamac",
    "component.bait-selector.select-baits": "Odaberite mamce",
    "component.deployment-calibration-form": "Telepítési kalibrálás",
    "component.deployment-calibration-form.go-to-marking": "Ugorj",
    "component.deployment-calibration-form.height": "magasság",
    "component.deployment-calibration-form.select-label.bottom": "A bot alja",
    "component.deployment-calibration-form.select-label.top": "Bot teteje",
    "component.deployment-row.age": "Dob",
    "component.deployment-row.blanks": "Prazno",
    "component.deployment-row.datetime": "Datum/vrijeme",
    "component.deployment-row.edit": "Uredi implementaciju",
    "component.deployment-row.hideDetails": "Sakrij detalje",
    "component.deployment-row.no-observations-added": "Nisu dodana opažanja.",
    "component.deployment-row.observed": "Opažene vrste",
    "component.deployment-row.person": "Osoba",
    "component.deployment-row.quantity": "Količina",
    "component.deployment-row.setup": "Postavljanje / podizanje",
    "component.deployment-row.sex": "Spol",
    "component.deployment-row.show": "Pokaži",
    "component.deployment-row.showDetails": "Pkaži detalje",
    "component.deployment-row.species": "Vrsta",
    "component.deployment-row.specifications": "Specifikacije",
    "component.deployment-row.view": "Pregledaj opažanja",
    "component.droplet-deployment-uploader.cancel": "Otkaži",
    "component.droplet-deployment-uploader.creating-sequences": "Stvaranje sekvenci...",
    "component.droplet-deployment-uploader.guidance-part-1": "Agouti prihvaća prijenos pojedinačnih fotografija / videozapisa kao i ZIP datoteka. Koji će postupak najbolje raditi ovisi o količini datoteka.",
    "component.droplet-deployment-uploader.guidance-part-2": "Ako imate implementaciju manju od 4000 fotografija ili 25 videozapisa, potražite mapu koja sadrži te fotografije / videozapise i odaberite ih sve.",
    "component.droplet-deployment-uploader.guidance-part-3": "Ako imate više od 4000 fotografija ili 25 videozapisa, prvo ih spakirajte u jednu ZIP datoteku i umjesto toga prenesite tu datoteku. Provjerite jesu li sve fotografije / videozapisi upakirani u zip datoteku. Podmape nisu podržane.",
    "component.droplet-deployment-uploader.new-deployment": "Nova implementacija",
    "component.droplet-deployment-uploader.progress": "Napredak",
    "component.droplet-deployment-uploader.select-files": "Pretraži",
    "component.droplet-deployment-uploader.start-upload": "Počni učitavati",
    "component.droplet-deployment-uploader.uploading": "Učitavanje...",
    "component.edit-deployment.add-sampling-point": "Dodaj točku uzorkovanja",
    "component.edit-deployment.camera-heading": "Camera heading",
    "component.edit-deployment.camera-height": "Visina kamere",
    "component.edit-deployment.camera-height.empty": "Nije pronađen podatak o visini kamere",
    "component.edit-deployment.camera-identifier": "Identifikator kamere",
    "component.edit-deployment.camera-identifier-placeholder": "Identifikator kamere",
    "component.edit-deployment.camera-tilt": "Camera tilt",
    "component.edit-deployment.cm": "cm",
    "component.edit-deployment.created-at": "Létrehozva",
    "component.edit-deployment.created-by": "Készítette",
    "component.edit-deployment.distance-detection": "Detekcija udaljenosti",
    "component.edit-deployment.distance-detection.empty": "Nije pronađena detekcija udaljenosti",
    "component.edit-deployment.end": "Kraj",
    "component.edit-deployment.end-placeholder": "Kraj",
    "component.edit-deployment.error.missing-fields": "Please complete all required fields.",
    "component.edit-deployment.groups-placeholder": "Odaberite jednu ili više grupa",
    "component.edit-deployment.invalid": "Les déploiements invalides sont exclus des exports de données.",
    "component.edit-deployment.location": "Točka uzorkovanja",
    "component.edit-deployment.location-placeholder": "Točka uzorkovanja",
    "component.edit-deployment.notes": "Bilješke",
    "component.edit-deployment.notes-placeholder": "Opaske , komentari",
    "component.edit-deployment.notes.empty": "Bilješke nisu nađene",
    "component.edit-deployment.person-deploy": "Zapošljavanje osobe",
    "component.edit-deployment.person-deploy-placeholder": "Odaberi osobu",
    "component.edit-deployment.person-deploy.empty": "Nije pronađena nijedna osoba za raspoređivanje",
    "component.edit-deployment.person-detect": "Odabir osobe",
    "component.edit-deployment.person-detect-placeholder": "Odaberi osobu",
    "component.edit-deployment.person-detect.empty": "Nije pronađena nijedna otkrivena osoba",
    "component.edit-deployment.save": "Završi implementaciju",
    "component.edit-deployment.start": "Start",
    "component.edit-deployment.start-placeholder": "Start",
    "component.edit-deployment.tags": "Oznake",
    "component.edit-deployment.tags-placeholder": "Odaberite jednu ili više oznaka",
    "component.edit-deployment.tags.empty": "Nisu pronađene oznake",
    "component.edit-deployment.utc-offset": "UTC pomak",
    "component.edit-location.latitude": "Geografska širina",
    "component.edit-location.longitude": "Geografska dužina",
    "component.edit-location.name": "Naziv",
    "component.identification-form.age": "Dob",
    "component.identification-form.amount": "Količina",
    "component.identification-form.behaviour": "Ponašanje",
    "component.identification-form.created-at": "Létrehozva",
    "component.identification-form.created-by": "Készítette",
    "component.identification-form.delete-observation": "Obriši opažanja",
    "component.identification-form.hide-optional": "Sakrij neobavezna polja",
    "component.identification-form.identification": "Dodaj opažanje",
    "component.identification-form.individual": "Pojedinačno",
    "component.identification-form.is-domesticated": "Domestificiran",
    "component.identification-form.modified-at": "Utoljára módosítva",
    "component.identification-form.modified-by": "Utoljára módosította",
    "component.identification-form.no-individual": "Ne pojedinačno",
    "component.identification-form.notes": "Bilješke",
    "component.identification-form.save-observation": "Pohrani  opažanja",
    "component.identification-form.select-age": "Odaberi dob",
    "component.identification-form.select-sex": "Odaberi spol",
    "component.identification-form.sex": "Spol",
    "component.identification-form.show-all": "Prikaži sva polja",
    "component.identification-form.species": "Vrsta",
    "component.identification-form.species-placeholder": "Vrsta",
    "component.identification-form.uncertain": "Nesigurno / Vjerojatno",
    "component.identification-form.uncertain-short": "Nesigurno",
    "component.identification-form.validated": "Potvrđeno",
    "component.is-identifiable-as-domesticated.no": "Ne",
    "component.is-identifiable-as-domesticated.yes": "Da",
    "component.observation-position-form.view-position": "Ugorj",
    "component.observations-list.hide": "Sakrij",
    "component.observations-list.observations": "Popis opažanja",
    "component.observations-list.quantity": "Količina",
    "component.observations-list.sex": "Spol",
    "component.observations-list.show": "Pokaži",
    "component.observations-list.species": "Vrsta",
    "component.observations-list.uncertain": "Nesigurno / Vjerojatno",
    "component.observations-table.observation-type": "Megfigyelés típusa",
    "component.observations-table.observator": "Megfigyelő",
    "component.overview-marker-popup.annotate": "Označeno",
    "component.overview-marker-popup.browse": "Traži",
    "component.overview-marker-popup.collected-person": "Prikupljena osoba",
    "component.overview-marker-popup.date-of-import": "Datum unosa",
    "component.overview-marker-popup.end": "Kraj",
    "component.overview-marker-popup.set-up-person": "Postavi osobu",
    "component.overview-marker-popup.start": "Početak",
    "component.overview-marker-popup.statistics": "{sequencesAnnotatedCount} od {sequencesCount} oznaèenih sekvenci.",
    "component.overview-marker-popup.uploaded-person": "Učitana osoba",
    "component.page-footer.all-rights-reserved": "Foto-kamera laboratorij i Sveučilište Wegeningen",
    "component.page-footer.colophon": "O",
    "component.page-footer.power": "Pod vodstvom Agouti za",
    "component.positioning.angle": "Kut",
    "component.positioning.calculate-speed": "Izračunaj brzinu",
    "component.positioning.camera-and-deployment-calibration-missing": "Nedostaje kalibracija kamere i implementacije",
    "component.positioning.camera-calibration-missing": "Nedostaje kalibracija kamere",
    "component.positioning.camera-calibration-present": "Kalibracija kamere prisutna",
    "component.positioning.camera-details": "Detalji kamere",
    "component.positioning.camera-model-missing-with-support": "Nedostaje kalibracija kamere za ovu implementaciju. Molimo kontaktirajte podršku.",
    "component.positioning.deployment-calibration-missing": "Nedostaje kalibracija implementacije",
    "component.positioning.dimensions": "Dimenzije",
    "component.positioning.distance": "Udaljenost",
    "component.positioning.manufacturer": "Proizvođač",
    "component.positioning.model": "Model",
    "component.positioning.preview-plots": "Pregled grafikona",
    "component.positioning.radius": "Radijus",
    "component.positioning.speed": "Brzina",
    "component.positioning.warning": "Upozorenje",
    "component.remove-full-deployment.cancel": "Otkaži",
    "component.remove-full-deployment.confirm-removal": "Potvrdite uklanjanje",
    "component.remove-full-deployment.delete": "Obriši implementaciju",
    "component.remove-full-deployment.modal.error": "Došlo je do pogreške prilikom uklanjanja. Pogreška je:",
    "component.remove-full-deployment.modal.info": "<p>Uklonit ćete implementaciju i sve povezane resurse. Ova se radnja ne može poništiti.</p> <p>Unesite \"delete\" za potvrdu uklanjanja</p>",
    "component.remove-full-deployment.modal.title": "Upozorenje: potvrdite uklanjanje implememtacije",
    "component.remove-full-deployment.validation-word": "obriši",
    "component.shortcuts-list.blank": "Prazno",
    "component.shortcuts-list.hide-all": "Sakrij sve",
    "component.shortcuts-list.merge-with-previous": "Merge with previous",
    "component.shortcuts-list.merge-with-previous-time": "{amountOfMinutes} minutes elapsed since previous sequence",
    "component.shortcuts-list.merge-with-previous-time-seconds": "{amountOfSeconds} seconds elapsed since previous sequence",
    "component.shortcuts-list.merge-with-previous.modal.body": "Are you sure you want to merge this sequence with the previous one? Observations of this sequence will be removed.",
    "component.shortcuts-list.merge-with-previous.modal.confirm": "Yes, merge with previous",
    "component.shortcuts-list.merge-with-previous.modal.title": "Merge with previous",
    "component.shortcuts-list.setup-pickup": "Postavljanje / podizanje",
    "component.shortcuts-list.shortcuts": "Prečaci",
    "component.shortcuts-list.show-all": "Prikaži sve",
    "component.shortcuts-list.unknown": "Nepoznato",
    "component.shortcuts-list.vehicle": "Jármű",
    "component.utc-offset-selector.example": "Oznaka vremena slike za <code>{originalExampleTime}</code> postaje <code>{convertedExampleTime}</code> UTC.",
    "component.utc-offset-selector.help-text": "UTC pomak ovisi o vremenskoj zoni u kojoj je konfiguriran vaš fotoaparat. Upotrijebite alat poput <a href=\"https://www.timeanddate.com/worldclock/converter.html\" target=\"_blank\">timeanddate.com</a> za izračunavanje odmaka.",
    "component.utc-offset-selector.placeholder": "Odaberite pomak",
    "copied.id": "Id Copied to Clipboard",
    "deployment-row.camera-deployment-id": "Cam Dep ID",
    "deployment-row.card-id": "Id kartice",
    "deployment-row.information": "Informacije",
    "deployment-row.location": "Lokacija",
    "deployment-row.observations": "Osmatranja",
    "deployment-row.tags": "Oznake",
    "deployment-row.usable": "Upotrebljiv",
    "deployment.id": "Deployment ID",
    "index.partners.1": "Suradnici i financiranje",
    "index.partners.2": "Agouti podržava i surađuje s mnogim inicijativama i projektima, a ljubazno je podržan sredstvima više organizacija. Među njima su:",
    "index.partners.3": "Za izradu projekata <a href=\"https://mammalnet.com/\" target=\"_blank\">MammalNet</a> kontaktirajte nas putem dolje navedenih podataka.",
    "index.partners.4": "Uz financiranje od <a href=\"https://www.efsa.europa.eu/\" target=\"_blank\">European Food and Safety Authority</a>",
    "index.partners.5": "Podržan od <a href=\"https://www.lifewatch.be/\" target=\"_blank\">LifeWatch</a>",
    "info.annotate": "Nakon unosa implementacije, sekvence treba obraditi. Svaka implementacija koja ima sekvence kojima je potrebna dorada prikazana je ovdje. Pritisnite <i>Pribilježiti</i> za početak rada na toj implementaciji. Možete prestati s obradom u bilo kojem trenutku i nastaviti kasnije.",
    "info.annotate.title": "Obrada implementacije",
    "info.annotationsettings": "Ova polja kontroliraju prikaz imena vrsta na stranici napomena i interval isključivanja za izgradnju slikovnih sekvenci.",
    "info.annotationsettings.title": "Postavke napomena",
    "info.behaviours": "Dodajte ponašanje opažanjima dodavanjem opcija na ovaj popis.",
    "info.behaviours.title": "Ponašanje",
    "info.browse": "Nakon što pribilježite sekvence na ovoj stranici možete vidjeti dodana opažanja. Odaberite <i>Prikaži</i> pored implementacije kako bi odmah pogledali sekvence i ispravili promatranja ako je potrebno..",
    "info.browse.title": "Pretraži opažanja",
    "info.cameras": "Foto - kamere su identificirane serijskim brojem i nalaze se na ovom središnjem popisu. To znači da se ovdje pojavljuju i foto - kamere iz drugih projekata. Prije dodavanja novih unosa provjerite jesu li vaše foto - kamere već na popisu. Serijski broj Reconyx kamera može se pronaći na dnu kamere nakon otvaranja, odmah ispod barkoda.",
    "info.cameras.title": "Foto-Kamere",
    "info.downloaddata": "Ovdje preuzmite izvezene pakete podataka. Svi dokumenti su arhivirani, pa je moguće preuzimanje ili nadopuna na određenu verziju.",
    "info.downloaddata.title": "Preuzimanje datoteka",
    "info.export": "Prilikom izvoza podataka sva se zapažanja zapakiraju u standardizirani format (CSV) koji gotovo svaki softver može očitati za daljnju obradu. Nakon generiranja nove datoteke podataka, preuzmite je iz tablice u nastavku.",
    "info.export.title": "Izvoz podataka",
    "info.generalsettings": "Ovaj odjeljak sadrži opće informacije o vašem projektu. Molim ispunite barem <i>Naziv, Opis</i> i <i>Vlasnik projekta</i>",
    "info.generalsettings.title": "Informacije o projektu",
    "info.import.upload.title": "Učitaj datoteke",
    "info.import.uploadfiles": "Odaberi <i>Učitaj datoteke</i> za učitavanje fotografija ili videa. Idite do mape koja sadrži sve fotografije ili videozapise vaše implementacije. Preporučuje se prijenos materijala izravno s memorijske kartice kako bi se spriječilo da softverski obrazac ošteti podatke.",
    "info.import.uploadfiles.title": "Učitaj fotografije ili video",
    "info.individuals": "Pratite uvid u pojedine životinje dodajući ih na ovaj popis. Po želji ih možete dodati tijekom pribilježbi.",
    "info.individuals.title": "Pojedine životinje",
    "info.pendingusers": "Kad registrirani korisnik zatraži pristup Vašem projektu, prvo mu treba odobrenje od koordinatora. Na ovom se popisu prikazuju zahtjevi na čekanju. Nakon prihvačanja korisnika, po potrebi promijenite njegovu ulogu.",
    "info.pendingusers.title": "Dolazni zahtjevi",
    "info.registeredusers": "Svi koji imaju pristup Vašem projektu prikazani su ovdije. Ovdje možete promijeniti ulogu korisnika ili u potpunosti opozvati pristup. Imajte na umu da je za poništavanje opozvanog pristupa potrebn novi zahtjev za ponovni pristup!",
    "info.registeredusers.title": "Registrirani korisnik",
    "info.sampling.": "Točka uzorkovanja je lokacija kojoj korisnik može dodijeliti implementaciju. Na jednu točku uzorkovanja možete dodijeliti više implementacija, ukoliko je potrebno.",
    "info.sampling.title": "Točke uzorkovanja",
    "info.samplingdesign": "Ovdje navedene informacije govore o vašem protokolu, u smislu podataka o foto - kamerama i standardima. To se odnosi na vaš cijeli projekt, a ne na pojedinačne implementacije. Na primjer: možete testirati nekoliko vrsta mirisnih mamaca. Ili postavite svoje foto - kamere na vodotokove. Dodajte opis sa što više podataka.",
    "info.samplingdesign.title": "Protokol projekta",
    "info.specieslist": "Agouti koristi standardizirani popis vrsta <a href=\"http://www.catalogueoflife.org/\" target=\"_blank\">Catalogue of Life</a>.Izradite podskup ovog popisa koji će se koristiti u vašem projektu.",
    "info.specieslist.title": "Popis vrsta",
    "info.tags": "Oznake mogu biti dodjeljene implementacijama za bolje strukturiranje projekta. One su neobavezne, mogu se proizvoljno označiti i više oznaka može se dodijeliti jednoj implementaciji. Na primjer: upotrijebite ih za pračenje korištenih mamaca, vrste staništa ili neke opcije koju sami definirate.",
    "info.tags.title": "Oznake",
    "info.unfinishedupload": "Ukoliko su datoteke učitane, ali <i>Učitavanje implementacije</i>  nije potpuno, prikazati će se na ovom popisu. Pritisni <i>Dovrši</i> za dovršavanje učitavanja. Ukoliko je učitavanje bilo prekinuto, radije izbrišite implementaciju.",
    "info.unfinishedupload.title": "Nepotpuna implementacija",
    "info.user.settings": "Ovdje možete ažurirati svoje osobne podatke i promijeniti jezik Agouti sučelja. Imajte na umu da promjena jezika sučelja ne mijenja određene stavke kao što su opisi projekata ili oznake projekata.",
    "info.user.settings.title": "Osobne postavke",
    "invalid": "Invalid",
    "language.locale.de": "German",
    "language.locale.en": "English",
    "language.locale.es": "Spanish",
    "language.locale.fr": "French",
    "language.locale.hr": "Croatian",
    "language.locale.nl": "Dutch",
    "language.locale.pl": "Polish",
    "leaflet-map-cartodb": "Zadano",
    "leaflet-map-here-hybrid.day": "Hibrid",
    "leaflet-map-here-normal.day": "Normal",
    "leaflet-map-here-terrain.day": "Teren",
    "link": "Link",
    "login.desc": "Za pristup ovom projektu, molim ulogirajte se sa svojim Agouti računom.",
    "login.email-address": "E-mail",
    "login.password": "Lozinka",
    "login.remember-me": "Zapamti me",
    "login.sign-in": "Ulogiraj se",
    "login.sign-in-email": "E-mail adresa nije poznata",
    "login.sign-in-error": "Netočni podatci",
    "login.sign-in-password": "Unesena kriva lozinka",
    "login.title": "Ulogiraj se",
    "menu-item.annotate": "Komentar",
    "menu-item.browse": "Pretraži",
    "menu-item.cameras": "Fényképezőgépek",
    "menu-item.deployments": "Implementacije",
    "menu-item.export-data": "Izvoz podataka",
    "menu-item.import": "Unos",
    "menu-item.locations": "Helyszíneken",
    "menu-item.observations": "Observations",
    "menu-item.overview": "Pregled",
    "menu-item.project-settings": "Projektne postavke",
    "menu-item.sampling-management": "Upravljanje uzorkovanjem",
    "menu-item.settings": "Beállítások",
    "menu-item.settings.behaviour": "Viselkedés",
    "menu-item.settings.deployment-groups": "Telepítési csoportok",
    "menu-item.settings.deployment-tags": "Telepítési címkék",
    "menu-item.settings.general": "Općenito",
    "menu-item.settings.individuals": "Magánszemélyek",
    "menu-item.species": "Vrsta",
    "menu-item.user-management": "Upravljanje korisnicima",
    "menu-item.users": "Felhasználók",
    "menu-item.validate": "Odobri",
    "observation.id": "Observation ID",
    "portal.statistics.deployments": "Implementacije",
    "portal.statistics.images": "Fotografije",
    "portal.statistics.observations": "Observations",
    "portal.statistics.projects": "Projekti",
    "project-overview.show-less": "Show less",
    "project-overview.show-more": "Prikaži više",
    "project-thumbnail.members": "{count, plural, other {# members} =1 {# member}}",
    "project-thumbnail.you-are": "Vi ste {projectrole}",
    "project.annotate.annotate": "Staviti pribilješke",
    "project.annotate.annotate-ai": "Bilježi AI",
    "project.annotate.annotate-view": "View annotations",
    "project.annotate.end": "Kraj",
    "project.annotate.inspected-sequences": "Završene sekvence",
    "project.annotate.is-blank": "Prazne sekvence",
    "project.annotate.is-setup-pickup": "Postavljanje / preuzimanje sekvenci",
    "project.annotate.location": "Točka uzorkovanja",
    "project.annotate.no-unannotated-deployments": "Nema nepribilježenih implementacija",
    "project.annotate.progress": "Napredak",
    "project.annotate.sequence.annotate-overview": "Staviti pribilješke",
    "project.annotate.sequence.back": "Natrag",
    "project.annotate.sequence.back-to-overview": "Back to overview",
    "project.annotate.sequence.dashboard": "Kontrolna ploča",
    "project.annotate.sequence.exposure": "Izlaganje",
    "project.annotate.sequence.finish-annotating": "Završna pribilješka",
    "project.annotate.sequence.identify": "Identifikacija",
    "project.annotate.sequence.navigate-by-observation": "Popis opažanja",
    "project.annotate.sequence.navigate-by-sequences": "Popis sekvenci",
    "project.annotate.sequence.next": "Iduće",
    "project.annotate.sequence.next-unannotated": "Iduće ne pribilježeno",
    "project.annotate.sequence.observations.age": "Dob",
    "project.annotate.sequence.observations.back": "Natrag na popis",
    "project.annotate.sequence.observations.cancel": "Otkaži",
    "project.annotate.sequence.observations.quantity": "Količina",
    "project.annotate.sequence.observations.sequence": "Sekvenca",
    "project.annotate.sequence.observations.sex": "Spol",
    "project.annotate.sequence.observations.species": "Vrsta",
    "project.annotate.sequence.observations.suggested": "Predloženo",
    "project.annotate.sequence.observations.title": "Opažanja u {name}",
    "project.annotate.sequence.order": "Nalog",
    "project.annotate.sequence.photo-of": "Fotografija {x} od {y}",
    "project.annotate.sequence.sequence-of": "Sekvenca",
    "project.annotate.sequence.sequence-of2": "od {y}",
    "project.annotate.sequence.validation-status": "Status provjere valjanosti",
    "project.annotate.sequence.warning.unsaved-work.body": "Uredivali ste opažanje, budite sigurni da ste pohranili promjene prije napuštanja stranice.",
    "project.annotate.sequence.warning.unsaved-work.close-modal": "OK",
    "project.annotate.sequence.warning.unsaved-work.header": "Upozorenje: nepohranjeno opažanje",
    "project.annotate.sequences": "Sekvence",
    "project.annotate.start": "Start",
    "project.annotate.title": "Staviti pribilješke",
    "project.annotate.unannotated-deployments": "Nepribilježene implementacije",
    "project.annotate.uninspected-sequences": "Sekvence u toku",
    "project.browse.assign-to": "Dodijeli",
    "project.browse.assigned-to": "Dodijeljeno",
    "project.browse.deployments": "Implementacije",
    "project.browse.desc": "Na ovoj stranici možete pregledati sve implementacije u ovom projektu. <br/> Pokažite detalje za prikaz oznaka, napomena, specifikacija i moguænost uređivanja tih specifikacija.",
    "project.browse.edit-deployment.title": "Uredi implementaciju",
    "project.browse.end": "Kraj",
    "project.browse.groups": "Grupa",
    "project.browse.location": "Točka uzorkovanja",
    "project.browse.modal.close": "Zatvori",
    "project.browse.no-deployments": "Nema dostupnih implementacija",
    "project.browse.sequences": "Broj sekvenci",
    "project.browse.start": "Start",
    "project.browse.tags": "Oznake",
    "project.browse.title": "Pretraži",
    "project.browse.unannotated": "Ne pribilježeno",
    "project.browse.view-observations.delete": "Jeste li sigurni da želite obrisati ovo opažanje?",
    "project.browse.view-observations.filter": "Pokaži samo opažanja s/sa",
    "project.browse.view-observations.split": "Are you sure you want to split this observation?",
    "project.deployment.aimodel.analyzing": "AI je zauzet",
    "project.deployment.aimodel.failed": "AI nije uspio",
    "project.deployment.aimodel.scheduled": "AI je zakazan ..",
    "project.deployment.aimodel.success": "AI je spreman ..",
    "project.deployment.complete.annotate": "Pribilježi implementaciju",
    "project.deployment.complete.deployment-successfully-imported": "Implementacija uspješno unesena",
    "project.deployment.complete.desc": "Moim ispunite ove podatke u svrhu završavanja unosa ove implementacije.",
    "project.deployment.complete.import-new": "Unesi drugu implementaciju",
    "project.deployment.complete.success-message": "Unos završen.",
    "project.deployment.complete.success-message.close": "Obavijestite ovo raspoređivanje.",
    "project.deployment.complete.title": "Nova implementacija",
    "project.deployment.set-timestamp.filter": "Filtriraj",
    "project.deployment.set-timestamp.save": "Nastavi",
    "project.deployment.set-timestamps.desc": "Agouti ne može pronaći vremenske oznake za ovaj primjer.<br>Molimo Vas unesite vremenske oznake ručno kako bi Agouti mogao pravilno obraditi sekvence.",
    "project.deployment.set-timestamps.file": "Ime datoteke",
    "project.deployment.set-timestamps.timestamp": "Vremenska oznaka ( 24 satni zapis)",
    "project.deployment.set-timestamps.title": "Dodajte vremenske oznake",
    "project.export.create-export": "Stvori izvoz",
    "project.export.datasetId": "ID Skupa podataka",
    "project.export.delete": "Obriši",
    "project.export.download": "Preuzimanje",
    "project.export.filename": "Naziv datoteke",
    "project.export.no-exports-created": "Nema kreiranih izvoza",
    "project.export.previous-exports": "Predhotni izvoz",
    "project.export.size": "Veličina",
    "project.export.status": "Status",
    "project.export.time-of-export": "Vrijeme izvoza",
    "project.export.title": "Izlazni podatci",
    "project.export.title.sub": "Izlazna zapažanja",
    "project.import.assets": "Imovina",
    "project.import.complete": "Potpuno",
    "project.import.create": "Nova implementacija",
    "project.import.create-sequence-confirmation": "Jeste li sigurni da želite stvoriti sekvence za ovaj unos?",
    "project.import.create-sequences": "Kreiraj sekvencu",
    "project.import.delete": "Izbriši",
    "project.import.delete-deployment-confirmation": "Jeste li sigurni da želite izbrisati ovaj unos?",
    "project.import.end": "Kraj",
    "project.import.files": "Odabrane datoteke",
    "project.import.folder": "Datoteka",
    "project.import.guidance": "Odaberite mapu koja sadrži slike ili videozapis za ovu implementaciju.",
    "project.import.images": "Fotografije",
    "project.import.new-deployment.new-deployment": "Unos implementacija",
    "project.import.new-deployment.no-unfinished-imports": "Ne postoje implementacije na čekanju",
    "project.import.new-deployment.title": "Unos",
    "project.import.new-deployment.unfinished-imports": "Nedovršeni unosi",
    "project.import.process": "Proces",
    "project.import.sequences": "Sekvence",
    "project.import.start": "Start",
    "project.import.status": "Status",
    "project.import.timestamp": "Vremenska oznaka",
    "project.import.type": "Tip",
    "project.import.upload": "Učitaj",
    "project.import.upload-modal.cancel": "Zatvori",
    "project.import.upload-modal.desc1": "Molim označiti i prenjeti datoteke. Trenutno podržava JPG i AVI datoteke.",
    "project.import.upload-modal.desc2": "Ili povuci i ubaci sliku u ovo područje.",
    "project.import.upload-modal.select": "Označi datoteke",
    "project.import.upload-modal.title": "Učitane datoteke",
    "project.import.upload-modal.uploading": "Uploading {queueFilesLength} files. Succesfully uploaded {uploaded} files, {duplicates} duplicates and {failed} files failed.",
    "project.import.uploaded": "Podignuto",
    "project.import.viabrowser": "Putem preglednika",
    "project.import.viaftp": "Putem FTP",
    "project.overview.achievements": "Postignuća",
    "project.overview.browse": "Pretraži",
    "project.overview.deployment": "Implementacija",
    "project.overview.deployment-for-location": "Implementacija za lokaciju",
    "project.overview.deployments": "Implementacije",
    "project.overview.desc": "Opis projekta",
    "project.overview.favourite-assets": "Favourites",
    "project.overview.favourite-sequences": "Omiljene sekvence",
    "project.overview.import-deployment": "Uvezi implementaciju",
    "project.overview.imported-deployments": "Uvezeni implementacije",
    "project.overview.most.observed.species": "Najčešće viđene vrste",
    "project.overview.no-deployments": "Nema dostupnih implementacija",
    "project.overview.observations": "Osmatranja",
    "project.overview.read.hide": "Sakrij",
    "project.overview.read.show": "Čitaj više",
    "project.overview.records": "Zapisi",
    "project.overview.records-content": "Osmotrili najviše vrsta u ovom projektu",
    "project.overview.sequences": "Sekvence",
    "project.overview.stats": "Statistika",
    "project.overview.team.members": "Članovi tima",
    "project.overview.unfinished-deployments": "Nedovršene implementacije",
    "project.request-access.description": "Pritisnite 'Zatražiti' kako bi potvrdili traženje pristupa ovom projektu.",
    "project.request-access.request": "Zatražite pristup",
    "project.request-access.title": "Zatražite pristup",
    "project.sampling-management.add-group": "Dodaj novu grupu",
    "project.sampling-management.add-group.title": "Dodaj novu grupu",
    "project.sampling-management.add-sampling-point": "Dodaj novo mjesto uzorkovanja",
    "project.sampling-management.add-sampling-point.title": "Dodaj novo mjesto uzorkovanja",
    "project.sampling-management.add-samplingpoint.cancel": "Otkaži",
    "project.sampling-management.add-samplingpoint.missing-coordinates": "Kérjük, adja meg a nevet és a koordinátákat.",
    "project.sampling-management.add-samplingpoint.save": "Pohrani",
    "project.sampling-management.add-tag": "Dodaj novu oznaku",
    "project.sampling-management.add-tag.cancel": "Otkaži",
    "project.sampling-management.add-tag.name": "Naziv",
    "project.sampling-management.add-tag.name-placeholder": "Naziv",
    "project.sampling-management.add-tag.save": "Pohrani",
    "project.sampling-management.add-tag.title": "Dodaj oznaku",
    "project.sampling-management.delete-tag.confirmation": "Jeste li sigurni da želite izbrisati ovu oznaku?",
    "project.sampling-management.edit-group.title": "Uredi grupu",
    "project.sampling-management.edit-sampling-point.cancel": "Otkaži",
    "project.sampling-management.edit-sampling-point.save": "Pohrani",
    "project.sampling-management.edit-sampling-point.title": "Uredi mjesto uzorkovanja",
    "project.sampling-management.edit-tag.cancel": "Otkaži",
    "project.sampling-management.edit-tag.name": "Naziv",
    "project.sampling-management.edit-tag.name-placeholder": "Naziv",
    "project.sampling-management.edit-tag.save": "Pohrani",
    "project.sampling-management.edit-tag.title": "Uredi oznaku",
    "project.sampling-management.sampling-points": "Mjesta uzorkovanja",
    "project.sampling-management.sampling-points.name": "Naziv",
    "project.sampling-management.samplingpoints.delete": "Izbriši",
    "project.sampling-management.samplingpoints.edit": "Uredi",
    "project.sampling-management.tags": "Oznake za primjenu",
    "project.sampling-management.tags.delete": "Izriši",
    "project.sampling-management.tags.edit": "Uredi",
    "project.sampling-management.tags.name": "Naziv",
    "project.sampling-management.title": "Upravlanje uzorkovanjem",
    "project.sampling-management.upload-csv": "Učitaj CSV",
    "project.sequence.import.failed": "Uvoz sekvence nije uspio.",
    "project.sequence.import.importing": "Uvođenje sekvence",
    "project.sequence.import.importing.note": "Napomena: ovisno o veličini prijenosa i  zauzetosti poslužitelja, ovo može potrajati.<br>Ne trebate čekati da se kreacija završi.",
    "project.sequence.import.importing.success": "Sekvenca je spremna!",
    "project.sequence.import.status": "Status uvoza sekvence",
    "project.settings.add-behaviour": "Dodaj novo ponašanje",
    "project.settings.add-behaviour.cancel": "Otkaži",
    "project.settings.add-behaviour.description": "Opis",
    "project.settings.add-behaviour.description-placeholder": "Opis",
    "project.settings.add-behaviour.name": "Ime",
    "project.settings.add-behaviour.name-placeholder": "Ime",
    "project.settings.add-behaviour.save": "Pohrani",
    "project.settings.add-behaviour.title": "Dodaj ponašanje",
    "project.settings.add-camera": "Dodaj novu kameru",
    "project.settings.add-camera.cancel": "Otkaži",
    "project.settings.add-camera.label": "Oznaka",
    "project.settings.add-camera.label-placeholder": "Oznaka",
    "project.settings.add-camera.make": "Proizvedeno",
    "project.settings.add-camera.make-placeholder": "Proizvedeno",
    "project.settings.add-camera.model": "Model",
    "project.settings.add-camera.model-placeholder": "Model",
    "project.settings.add-camera.save": "Pohrani",
    "project.settings.add-camera.serial-number": "Serijski br.",
    "project.settings.add-camera.serial-number-placeholder": "Serijski br.",
    "project.settings.add-camera.title": "Dodaj kameru",
    "project.settings.add-camera.year-of-purchase": "Godina kupnje",
    "project.settings.add-deployment": "Implementacije hozzáadása",
    "project.settings.add-individual": "Dodaj novu jedinku",
    "project.settings.add-individual.age": "Dob",
    "project.settings.add-individual.cancel": "Otkaži",
    "project.settings.add-individual.name": "Ime",
    "project.settings.add-individual.name-placeholder": "Ime",
    "project.settings.add-individual.notes": "Bilješka",
    "project.settings.add-individual.notes-placeholder": "Bilješka",
    "project.settings.add-individual.save": "Pohrani",
    "project.settings.add-individual.sex": "Spol",
    "project.settings.add-individual.species": "Vrsta",
    "project.settings.add-individual.title": "Dodaj jedinku",
    "project.settings.add-location": "Dodaj lokaciju",
    "project.settings.add-species": "Dodaj vrstu",
    "project.settings.add-species-list": "Fajlista hozzáadása",
    "project.settings.add-species-to-add": "Hozzáadandó fajok",
    "project.settings.add-species.cancel": "Otkaži",
    "project.settings.add-species.save": "Pohrani",
    "project.settings.add-species.species": "Vrsta",
    "project.settings.add-species.species-placeholder": "Vrsta",
    "project.settings.add-species.title": "Dodaj vrstu",
    "project.settings.annotate-calibration-and-tracking": "Annotate calibration and tracking",
    "project.settings.annotate-timelapses": "Annotate timelapses",
    "project.settings.annotate-timelapses-disabled": "Timelapses can not be annotated when sequences have been added.",
    "project.settings.annotation": "Komentar",
    "project.settings.automatic-annotation": "Automatsko otkrivanje",
    "project.settings.automatic-annotation-on-upload": "Automatikusan új fotósorozattal",
    "project.settings.automatic-annotation.decription": "Ha engedélyezve van, az új telepítéseket először az AI dolgozza fel, és ennek befejezése után szerkeszthetők.",
    "project.settings.automatic-annotation.placeholder": "Odaberite model",
    "project.settings.behaviour": "Ponašanje",
    "project.settings.behaviour.delete": "Izbriši",
    "project.settings.behaviour.description": "Opis",
    "project.settings.behaviour.edit": "Uredi",
    "project.settings.behaviour.name": "Ime",
    "project.settings.camera.delete": "Izbriši",
    "project.settings.camera.edit": "Uredi",
    "project.settings.camera.label": "Oznaka",
    "project.settings.camera.make": "Proizvedeno",
    "project.settings.camera.model": "Model",
    "project.settings.camera.serial-number": "Serijski br.",
    "project.settings.camera.year-of-purchase": "Godina kupnje",
    "project.settings.cameras": "Kamere",
    "project.settings.delete-behaviour-confirmation": "Jeste li sigurni da želite ukoliniti ovo ponašanje?",
    "project.settings.delete-camera-confirmation": "Jeste li sigurni da želite ukloniti ovu kameru?",
    "project.settings.delete-individual-confirmation": "Jeste li sigurni da želite ukloniti ovu jedinku?",
    "project.settings.delete-sampling-point-confirmation": "Jeste li sigurni da želite obrisati ovo mjesto uzorkovanja?",
    "project.settings.delete-species-confirmation": "Jeste li sigurni da želite ukloniti ovu vrstu?",
    "project.settings.deployments-are-assigned": "Raspoređivanja su dodijeljena",
    "project.settings.description": "Opis",
    "project.settings.description-placeholder": "Opis",
    "project.settings.edit-behaviour.cancel": "Otkaži",
    "project.settings.edit-behaviour.description": "Opis",
    "project.settings.edit-behaviour.description-placeholder": "Opis",
    "project.settings.edit-behaviour.name": "Ime",
    "project.settings.edit-behaviour.name-placeholder": "Ime",
    "project.settings.edit-behaviour.save": "Pohrani",
    "project.settings.edit-behaviour.title": "Uredi ponašanje",
    "project.settings.edit-camera": "Uredi kameru",
    "project.settings.edit-camera.cancel": "Otkaži",
    "project.settings.edit-camera.label": "Oznaka",
    "project.settings.edit-camera.label-placeholder": "Oznaka",
    "project.settings.edit-camera.make": "Proizvedeno",
    "project.settings.edit-camera.make-placeholder": "Proizvedeno",
    "project.settings.edit-camera.model": "Model",
    "project.settings.edit-camera.model-placeholder": "Model",
    "project.settings.edit-camera.save": "Pohrani",
    "project.settings.edit-camera.serial-number": "Serijski br.",
    "project.settings.edit-camera.serial-number-placeholder": "Serijski br.",
    "project.settings.edit-camera.title": "Uredi kameru",
    "project.settings.edit-camera.year-of-purchase": "Godina kupnje",
    "project.settings.edit-individual.age": "Dob",
    "project.settings.edit-individual.cancel": "Otkaži",
    "project.settings.edit-individual.name": "Ime",
    "project.settings.edit-individual.name-placeholder": "Ime",
    "project.settings.edit-individual.notes": "Bilješka",
    "project.settings.edit-individual.notes-placeholder": "Bilješka",
    "project.settings.edit-individual.save": "Pohrani",
    "project.settings.edit-individual.sex": "Spol",
    "project.settings.edit-individual.species": "Vrsta",
    "project.settings.edit-individual.title": "Uredi jedinku",
    "project.settings.edit-location": "Uredi lokaciju",
    "project.settings.edit-location.images": "Slike",
    "project.settings.edit-species.cancel": "Otkaži",
    "project.settings.edit-species.common-names": "Uredi naziv",
    "project.settings.edit-species.is-identifiable-as-domesticated": "Domestificirano",
    "project.settings.edit-species.name": "Vrsta",
    "project.settings.edit-species.save": "Pohrani",
    "project.settings.edit-species.title": "Uredi vrstu",
    "project.settings.feature": "Značajke",
    "project.settings.feature-methodology": "Značajke metodologije",
    "project.settings.feature-methodology-placeholder": "Značajke metodologije",
    "project.settings.feature.select-feature": "Select feature",
    "project.settings.general": "Općenito",
    "project.settings.hide-deployment-locations": "Sakrij lokacije raspoređivanja",
    "project.settings.image": "Slika",
    "project.settings.individual.add-individual": "Dodaj jedinku",
    "project.settings.individual.delete": "Izbriši",
    "project.settings.individual.edit": "Uredi",
    "project.settings.individual.name": "Ime",
    "project.settings.individual.notes": "Bilješka",
    "project.settings.individual.species": "Vrsta",
    "project.settings.individual.zooniverse": "Zooniverse",
    "project.settings.individuals": "Jedinke",
    "project.settings.location": "Lokacija",
    "project.settings.min-image-resolution": "Minimalna {min_width} x {min_height} piksela",
    "project.settings.name": "Ime",
    "project.settings.name-placeholder": "Ime",
    "project.settings.open-for-subscriptions": "Otvoreno za pretplatnike",
    "project.settings.organisation": "Organizacija",
    "project.settings.organisation-help-block": "Organisations are considered rights holders of the data.",
    "project.settings.organisation-placeholder": "Organizacija",
    "project.settings.project-investigator": "PI",
    "project.settings.project-investigator-placeholder": "Istraživač projekta",
    "project.settings.project-owner": "Contact",
    "project.settings.project-owner-placeholder": "Project Contact",
    "project.settings.quiet-period": "Tihi period",
    "project.settings.restricted-access": "Ograničen pristup",
    "project.settings.restricted-images": "Korlátozott képek",
    "project.settings.restricted-images.note": "Ha be van jelölve, az exportált/közzétett adatok kép URL-jei nem lesznek elérhetők a külső felhasználók számára.",
    "project.settings.restricted-locations": "Korlátozott helyek",
    "project.settings.restricted-locations.note": "Ha be van jelölve, a fotófeldolgozók és az önkéntesek csak a saját telepítéseik helyszíneit látják.",
    "project.settings.restricted-project": "Korlátozott projekt",
    "project.settings.restricted-project.note": "Ha be van jelölve, projektje el lesz rejtve az agouti.eu nyilvános kezdőlapjáról és portáljáról.",
    "project.settings.sampling-design": "Dizajn uzorkovanja",
    "project.settings.sampling-design.clustered-random": "U skupinama nasumični (nasumični nizovi)",
    "project.settings.sampling-design.experimental": "Eksperimentalni",
    "project.settings.sampling-design.label": "Dizajn uzorkovanja",
    "project.settings.sampling-design.opportunistic": "Oportunistički",
    "project.settings.sampling-design.placeholder": "Odaberi dizajn uzorkovanja",
    "project.settings.sampling-design.simple-random": "Jednostavno nasumično",
    "project.settings.sampling-design.systematic-random": "Sustavno nasumično",
    "project.settings.sampling-design.targeted": "Ciljano",
    "project.settings.seconds": "Sekunde",
    "project.settings.sensormethod": "Senzorna metoda",
    "project.settings.sensormethod.motion-detection": "Otkrivanje pokreta",
    "project.settings.sensormethod.select-sensormethod": "Odaberi senzornu metodu",
    "project.settings.sensormethod.timelapse": "Ubrzano",
    "project.settings.sequence-cut-off": "Presjek sekvenci",
    "project.settings.sequence-cutoff-disabled": "Presjek sekvenci ne može se mijenjati nakon što su sekvence već dodane",
    "project.settings.species": "Vrsta",
    "project.settings.species.added": "Dodano projektu",
    "project.settings.species.available": "Dostupne vrste",
    "project.settings.species.common-name": "Naziv",
    "project.settings.species.countDeployments": "Deployments",
    "project.settings.species.countObservations": "Observations",
    "project.settings.species.countValObservations": "Validated",
    "project.settings.species.edit": "Uredi",
    "project.settings.species.family": "Obitelj",
    "project.settings.species.genus": "Rod",
    "project.settings.species.isIdentifiableAsDomesticated": "Domestificiran",
    "project.settings.species.order": "Red",
    "project.settings.species.remove": "Ukloni",
    "project.settings.species.species": "Vrsta",
    "project.settings.success-message": "Projektne postavke pohranjene.",
    "project.settings.title": "Projektne postavke",
    "project.settings.update-location.cancel": "Otkaži",
    "project.settings.update-location.save": "Pohrani",
    "project.settings.update-location.title": "Uredi lokaciju",
    "project.settings.utc-offset": "Zadani UTC pomak",
    "project.user-management.accept-pending-request-confirmation": "Jeste li sigurni da želite prihvatiti ovaj zahtjev?",
    "project.user-management.awaiting-access": "Čeka se pristup",
    "project.user-management.copy": "Meghívó link másolása",
    "project.user-management.copy.confirmation": "Poveznica kopirana",
    "project.user-management.delete-user-confirmation": "Jeste li sigurni da želite opozvati ovg člana/ptistup ovom projektu?",
    "project.user-management.edit-user.cancel": "Otkaži",
    "project.user-management.edit-user.email": "E-mail adresa",
    "project.user-management.edit-user.email-placeholder": "E-mail adresa",
    "project.user-management.edit-user.first-name": "Ime",
    "project.user-management.edit-user.first-name-placeholder": "Ime",
    "project.user-management.edit-user.last-name": "Prezime",
    "project.user-management.edit-user.last-name-placeholder": "Prezime",
    "project.user-management.edit-user.member-since": "Tag ...-óta",
    "project.user-management.edit-user.revoke-access": "Hozzáférés visszavonása",
    "project.user-management.edit-user.role": "Uloga",
    "project.user-management.edit-user.save": "Pohrani",
    "project.user-management.edit-user.title": "Uredi korisnika",
    "project.user-management.email-address": "E-mail adrese",
    "project.user-management.first-name": "Ime",
    "project.user-management.last-name": "Prezime",
    "project.user-management.link-description": "Koristite ovu poveznicu za dopuštenje korisnicima i traženje pristupa Vašem projektu.",
    "project.user-management.pending-requests": "Zahtjevi na čekanju",
    "project.user-management.pending-requests.accept": "Prihvati",
    "project.user-management.pending-requests.decline": "Odbij",
    "project.user-management.pending-requests.empty": "Nema zahtjeva na čekanju",
    "project.user-management.principal-investigators": "Glavni istraživač",
    "project.user-management.project-access": "Pristup projektu",
    "project.user-management.registered-users": "Članovi",
    "project.user-management.registered-users.delete": "Izbriši",
    "project.user-management.registered-users.edit": "Uredi",
    "project.user-management.role": "Uloga",
    "project.user-management.title": "Upravljanje korisnicima",
    "project.user-management.userbase": "Članovi",
    "project.user-management.userbase.title": "Korisnička baza",
    "project.user-management.volunteers": "Volonteri",
    "project.validate.accept-sequence": "Accept sequence observations",
    "project.validate.accepted-by-at": "Validated by {validator} on {date}",
    "project.validate.add-new-query": "Add new query",
    "project.validate.all-are-true": "All of the following predicates are true",
    "project.validate.edit.title": "Edit validation query",
    "project.validate.filter.created-from": "Created from",
    "project.validate.filter.created-to": "Created to",
    "project.validate.filter.is-equal-to": "Is equal to",
    "project.validate.filter.is-unknown": "Is unknown",
    "project.validate.filter.sampling-point": "Sampling point",
    "project.validate.filter.select-filter-type": "Select filter type",
    "project.validate.filter.select-role": "Select project role",
    "project.validate.filter.select-sampling-point": "Select sampling point",
    "project.validate.filter.select-species": "Select species",
    "project.validate.filter.select-user": "Select user",
    "project.validate.filter.species": "Species",
    "project.validate.filter.user": "User",
    "project.validate.filter.user-project-role": "Project role",
    "project.validate.new.title": "New validation query",
    "project.validate.no-rules-defined-yet": "No predicates defined yet...",
    "project.validate.no-validation-queries": "There are no validation queries defined for this project yet",
    "project.validate.none-are-true": "None of the following predicates are true",
    "project.validate.predicates": "Predicates",
    "project.validate.query-is-failed": "Something went wrong during the execution of the query...",
    "project.validate.query-is-running": "Query is running...",
    "project.validate.query-is-scheduled": "Query is scheduled...",
    "project.validate.results.assest-count": "Asset count",
    "project.validate.results.no-results": "No results found...",
    "project.validate.results.show-done": "Show validated sequences",
    "project.validate.results.total-results": "Total results",
    "project.validate.results.total-results-of": "Showing {count} of {total} results (max {limit})",
    "project.validate.rule-presets": "Rule presets",
    "project.validate.scheduling-explanation": "Queries are run every hour. Execution takes a few minutes, up to half an hour. Please refresh the page to update the status of the validation queries.",
    "project.validate.sequences": "Sequences",
    "project.validate.table.header.candidates": "Matches",
    "project.validate.table.header.name": "Name",
    "project.validate.title": "Validate",
    "project.validate.unaccept-sequence": "Remove validation",
    "project.validate.validate": "validate",
    "project.validate.validation-query-results": "Validation query results",
    "project.zooniverse.password": "Zooniverse lozinka",
    "project.zooniverse.password-placeholder": "Zooniverse lozinka",
    "project.zooniverse.projectid": "Zooniverse projekt ID",
    "project.zooniverse.projectid-placeholder": "Zooniverse projekt ID",
    "project.zooniverse.username": "Zooniverse korisničko ime",
    "project.zooniverse.username-placeholder": "Zooniverse korisničko ime",
    "projectrole.ADMIN": "Administrator",
    "projectrole.AWAITING_ACCESS": "Čekanje pristupa",
    "projectrole.DUMMY": "Početnik",
    "projectrole.PHOTO_PROCESSOR": "Foto procesor",
    "projectrole.PRINCIPAL_INVESTIGATOR": "Glavni istraživač",
    "projectrole.PROJECT_COORDINATOR": "Koordinator projekta",
    "projectrole.TAXONOMIC_EXPERT": "Stručnjak za taksonomiju",
    "projectrole.VIEW_ONLY": "View Only",
    "projectrole.VOLUNTEER": "Volonter",
    "projects.add": "Dodaj novi projekt",
    "projects.independent-projects": "Neovisni projekti",
    "projects.no-projects-available": "Još nema traženog projekta. Molim kontaktirajte Vašeg koordinatora projekta za daljnje upute.",
    "projects.search-for": "Traži",
    "projects.search-for.filter": "Filtriraj projekte",
    "projects.search-for.filterOrg": "Filtriranje po organizaciji",
    "projects.title": "Projekti",
    "projects.title.user": "Tvoji projekti",
    "projects.your-role": "Tvoja uloga",
    "publichome.block1.title": "Što je Agouti?",
    "publichome.block1.txt": "Foto - kamere pružaju jednostavan i neinvazivan način proučavanja divljih životinja. Međutim, obrada i pohrana tisuća slika koje istraživanje sakupi brzo postaje teška. Takoðer, upravljanje projektima može postati izazovno, posebno ako je uključeno više kamera i više ljudi.",
    "publichome.block1.txt2": "Agouti pomaže korisnicima da riješe ove izazove. To je cjelovito rješenje za organizacije i pojedince koji koriste foto - kamere za istraživanja divljih životinja. Omogučuje korisnicima foto - kamera da organiziraju zajedničke ankete, brzo i lako obrađuju slike, dobiju standardizirane sažetke rezultata, sigurno arhiviraju slike i podatke te u konačnici sažimaju i dijele podatke. Agouti slijedi standarde metapodataka, što olakšava usporedbu među projektima.",
    "publichome.block2.title": "Tijek rada",
    "publichome.block2.txt": "Agouti je skup projekata koje su pokrenule organizacije ili pojedinci. Sudionici projekta prenose cjelokupni sadržaj memorijskih kartica iz foto - kamera i unose lokaciju i ostale podatke. Agouti unosi ove slike, povlači datume, vrijeme i druge podatke iz samih slika te grupira slike u sekvence koje predstavljaju isti događaj. Analitičari pregledavaju svaku sekvencu i bilježe ih s jednim ili više promatranja, koristeći jednostavno sučelje.",
    "publichome.block2.txt2": "Adatait Camera Trap adatcsomagként (<a target=\"_blank\" href=\"https://tdwg.github.io/camtrap-dp/\">Camtrap DP</a>) exportálhatja olyan szoftverek elemzéséhez, mint pl. mint R. Kezdésként a <a target=\"_blank\" href=\"https://inbo.github.io/camtraptor/\">camtraptor</a> R csomag használatát javasoljuk.",
    "publichome.block3.title": "Uvoz",
    "publichome.block3.txt": "Prenesite slike i videozapise koristeći samo svoj preglednik",
    "publichome.block3.txt2": "Staviti pribilješke",
    "publichome.block3.txt3": "Označite slike opažanja pomoću prikladnih alata",
    "publichome.block3.txt4": "Analiziraj",
    "publichome.block3.txt5": "Pregledaj podatke pomoću ugrađenih alata i prezentacija",
    "publichome.block3.txt6": "Izvoz",
    "publichome.block3.txt7": "Stvorite standardizirane datoteke podataka za analizu ili dijeljenje",
    "publichome.block4.title": "Intuitivno sučelje",
    "publichome.block4.txt": "Agouti sučelje je jednostavno, moderno i usmjereno na produktivnost. Svatko može početi raditi s malo obuke. Videozapisi u nastavku ilustriraju nekoliko aspekata sustava.",
    "publichome.block4.txt1": "Početak",
    "publichome.block4.txt2": "Unos implementacija",
    "publichome.block4.txt3": "Pribilježavanje sekvenci",
    "publichome.block4.vid1": "/assets/videos/GettingStarted-HR.mp4",
    "publichome.block4.vid2": "/assets/videos/ImportingDeployments-HR.mp4",
    "publichome.block4.vid3": "/assets/videos/AnnotatingSequences-HR.mp4",
    "publichome.block5.title": "Istaknuti projekti",
    "publichome.block5.txt": "Agouti je domaćin različitim projekatima. Fedezzen fel néhány ilyen nyilvános projektet, hogy felfedezze Agouti lehetőségeit.",
    "publichome.block6.f1": "Upravljanje",
    "publichome.block6.f2": "Dizajn uzorkovanja",
    "publichome.block6.f3": "Prostorni plan",
    "publichome.block6.f4": "Foto - kamere",
    "publichome.block6.f5": "Unašanje oznaka",
    "publichome.block6.f6": "Kontrola kvalitete",
    "publichome.block6.g1": "Proces",
    "publichome.block6.g2": "Dodaj zapažanje",
    "publichome.block6.g3": "Standardizirani popis vrsta",
    "publichome.block6.g4": "Izvoz CSV datoteka",
    "publichome.block6.g5": "Pračenje pojedinih životinja",
    "publichome.block6.g6": "Bilježenje ponašanja",
    "publichome.block6.h1": "Surađuj",
    "publichome.block6.h2": "Pozovite članove",
    "publichome.block6.h3": "Dodijelite uloge",
    "publichome.block6.h4": "Dodijelite zadatke",
    "publichome.block6.h5": "Pratite napredak",
    "publichome.block6.h6": "Organizirajte podatke",
    "publichome.block6.title": "Značajke",
    "publichome.block6.txt": "S Agoutijem imate na raspolaganju opsežni set alata.",
    "publichome.block7.title": "Privatno i sigurno",
    "publichome.block7.txt": "Vlasništvo podataka je važno. Agouti ne polaže nikakvo vlasništvo nad podatcima i ne distribuira podatke trećim stranama. Vi odlučujete tko će imati pristup, tako da možete surađivati prema vlastitim uvjetima.",
    "publichome.block7.txt2": "Agouti pruža sigurno mjesto za pohranu vaših podataka. Uklonite rizik od gubitka podataka padom tvrdih diskova, greškom korisnika ili padom računala. Svakodnevne sigurnosne kopije izvan web mjesta i potpuno infrastruktura osiguravaju optimalan pristup vašim podacima.",
    "publichome.block8.f1": "Akademski",
    "publichome.block8.f2": "Besplatni",
    "publichome.block8.f3": "Neograničena pohrana",
    "publichome.block8.f4": "Neograničeni projekti i korisnici",
    "publichome.block8.f5": "Najbolja podrška",
    "publichome.block8.f6": "Primjeni",
    "publichome.block8.g1": "Neprofitno",
    "publichome.block8.g2": "Besplatni",
    "publichome.block8.g3": "Neograničena pohrana",
    "publichome.block8.g4": "Neograničeni projekti i korisnici",
    "publichome.block8.g5": "Najbolja podrška",
    "publichome.block8.g6": "Primjeni",
    "publichome.block8.h1": "Komerciijalno",
    "publichome.block8.h2": "Besplatni",
    "publichome.block8.h3": "Neograničena pohrana",
    "publichome.block8.h4": "Neograničeni projekti i korisnici",
    "publichome.block8.h5": "Najbolja podrška",
    "publichome.block8.h6": "Primjeni",
    "publichome.block8.title": "Počni koristiti Agouti",
    "publichome.block8.txt": "Troškove za korištenje i razvoj Agoutija snose korisnici. Studenti mogu Agouti koristiti besplatno, dok druge stranke daju svoj doprinos. Očekuje se da æe komercijalne stranke doprinijeti više jer koristeći Agouti štede na vremenu.",
    "publichome.explore-projects": "Istraži projekte",
    "publichome.footer1": "Agouti",
    "publichome.footer10": "6708 PB Wageningen",
    "publichome.footer11": "Nizozemska",
    "publichome.footer12": "Istraživački institut za prirodu i šume (INBO)",
    "publichome.footer13": "Upravljanje divljim i stranim vrstama i otvoreni znanstveni laboratorij za bioraznolikost",
    "publichome.footer14": "Havenlaan 88 bus 73",
    "publichome.footer15": "1000 Brisel",
    "publichome.footer16": "Belgija",
    "publichome.footer2": "Korisnička mailing lista",
    "publichome.footer3": "Pravila privatnosti",
    "publichome.footer5": "Organizacija",
    "publichome.footer6": "Agouti je inicijativa Sveučilišta Wageningen i Istraživačkog instituta za prirodu i šume (INBO).",
    "publichome.footer7": "Sveučilište Wageningen",
    "publichome.footer8": "Odjel za okolišne znanosti",
    "publichome.footer9": "Wildlife Ecology and Conservation Group",
    "publichome.slogan": "Platforma za upravljanj projektima za pračenje divljih životinja foto - kamerama.",
    "publichome.stats1": "Projekti",
    "publichome.stats2": "Implementacija",
    "publichome.stats3": "Zapažanja",
    "publichome.stats4": "Slike",
    "publichome.title": "Agouti",
    "sequence.id": "Sequence ID",
    "sex.": "N.A.",
    "sex.female": "Žensko",
    "sex.male": "Muško",
    "sex.unknown": "Nepoznato",
    "taxonRank": "Taxon Rank",
    "toggle-processed": "Hide 100%",
    "user.register.desc": "Registracija za Agouti račun",
    "user.register.email-address": "E-mail",
    "user.register.email-address-duplicate": "E-mail adresa se već koristi",
    "user.register.email-address-error": "Molim unesite Vaš e-mail",
    "user.register.first-name": "Ime",
    "user.register.first-name-error": "Molim unesite Vaše ime",
    "user.register.gdpr-error": "Potvrdite suglasnost u skladu s Općom uredbom o zaštiti podataka",
    "user.register.last-name": "Prezime",
    "user.register.last-name-error": "Molim unesite Vaše prezime",
    "user.register.password": "Lozinka",
    "user.register.password-error": "Molim unesite Vašu lozinku",
    "user.register.title": "Registracija",
    "user.request-reset-password.desc": "Request a password reset link",
    "user.request-reset-password.title": "Reset password",
    "user.reset-password.desc": "Choose a new Agouti password",
    "user.reset-password.email-address": "E-mail",
    "user.reset-password.email-address-duplicate": "Email address already in use.",
    "user.reset-password.email-address-error": "Please fill in your email.",
    "user.reset-password.password": "Password",
    "user.reset-password.password-are-different-error": "Please enter the same password twice.",
    "user.reset-password.password-confirmation": "Password confirmation",
    "user.reset-password.password-confirmation-error": "Please fill in a password confirmation.",
    "user.reset-password.password-error": "Please fill in a password.",
    "user.reset-password.title": "Reset password",
    "user.settings.account": "Račun",
    "user.settings.adaptive-image-size": "Prilagodljiva veličina slike ",
    "user.settings.annotate-shortcuts-label": "Označi prečace",
    "user.settings.anonymous-in-export": "Anonimno pri izvozu",
    "user.settings.anonymous-in-export-explanation": "Vaši će podatci nakon provjere biti anonimni pri izvozu. Vaše ime i prezime te adresa e-pošte neće biti uključeni u izvoze podataka i nećete biti priznati kao suradnik, osoba za uspostavljanje i/ili razvrstavač.",
    "user.settings.autoscroll-thumbnails-annotating": "Automatsko pomicanje slika",
    "user.settings.common": "Uobičajen",
    "user.settings.email-address": "E-mail adresa",
    "user.settings.email-address-label": "E-mail",
    "user.settings.email-in-use": "E-mail adresa se već koristi",
    "user.settings.first-name": "Ime",
    "user.settings.first-name-note": "Ovako to vide ostali članovi",
    "user.settings.gravatar": "Ili omogućiti <a href=\"http://gravatar.com\" title=\"http://gravatar.com\">Gravatar</a>",
    "user.settings.gravatar.disable": "Onemogućiti",
    "user.settings.gravatar.enable": "Omogućiti",
    "user.settings.language": "Jezik",
    "user.settings.last-name": "Prezime",
    "user.settings.organisation": "Organizacija",
    "user.settings.organisation-placeholder": "Organizacija",
    "user.settings.password": "Lozinka",
    "user.settings.picture": "Javni avatar",
    "user.settings.picture.browse": "Traži",
    "user.settings.picture.file": "Niste odabrali niti jednu datoteku",
    "user.settings.picture.remove": "Ukloniti",
    "user.settings.picture.upload": "Učitaj novi avatar",
    "user.settings.save": "Spremi promjene",
    "user.settings.save.update": "Ažuriraj postavke",
    "user.settings.saved": "Postavke spremljene",
    "user.settings.scientific": "Znanstveni",
    "user.settings.species-names": "Imena vrsta",
    "user.settings.title": "Korisničke postavke",
    "user.settings.url.label": "Url (ORCID)",
    "user.settings.url.placeholder": ""
  }], ["nl", {
    "age.": "N.A.",
    "age.adult": "Volwassen",
    "age.infant": "Juveniel",
    "age.subadult": "Sub-adult",
    "age.unknown": "Onbekend",
    "application.form.cancel": "Annuleren",
    "application.form.close": "Sluiten",
    "application.form.delete": "Verwijderen",
    "application.form.edit": "Wijzig",
    "application.form.fields.description": "Omschrijving",
    "application.form.fields.name": "Naam",
    "application.form.save": "Save",
    "application.form.saved": "Opgeslagen",
    "application.format.date": "YYYY-MM-DD",
    "application.format.date-time": "YYYY-MM-DD HH:mm",
    "application.gdpr.continue": "Geef, voordat je verdergaat, Wageningen University & Research toestemming om jouw naam en e-mailadres op te slaan en te verwerken voor wetenschappelijk onderzoek",
    "application.gdpr.text": "Door toestemming te geven, geef je aan dat je deze persoonsgegevens vrijwillig verstrekt hebt. Je behoudt het recht om de gegeven toestemming in te trekken. De persoonlijke informatie die je verstrekt hebt, zal uitsluitend gebruikt worden voor de doeleinden waarvoor ze door jou verstrekt zijn. Je hebt het recht om deze persoonsgegevens in te zien, te verwijderen, te corrigeren, en de verwerking daarvan te beperken, alsmede het recht om beroep aan te tekenen en het recht op dataportabiliteit <a target=\"_blank\" href=\"https://www.wur.nl/en/About-Wageningen/Integrity-and-privacy.htm\">https://www.wur.nl/en/About-Wageningen/Integrity-and-privacy.htm</a> N.B.: betrokkenen hebben geen absolute rechten. Wat precies van toepassing is, zal altijd per situatie en afhankelijk van het ingeroepen recht moeten worden bekeken. Indien de verwerking noodzakelijk is voor de uitvoering van een wettelijke taak of een taak van algemeen belang (bijv. voor archiveringsdoeleinden in het algemeen belang, voor wetenschappelijke of historische onderzoeksdoeleinden, of voor statistische doeleinden), dan bestaat het recht om vergeten te worden niet. Mocht je een klacht hebben, dan kun je deze indienen bij WUR via <a href=\"mailto:privacy@wur.nl\">privacy@wur.nl</a>. Je kunt ook een klacht indienen bij de Autoriteit Persoonsgegevens. Meer informatie hierover kun je vinden op <a target=\"_blank\" href=\"https://www.autoriteitpersoonsgegevens.nl\">www.autoriteitpersoonsgegevens.nl</a>. Mocht je vragen hebben, dan kun je contact opnemen met de Functionaris voor Gegevensbescherming van WUR via <a href=\"mailto:functionarisgegevensbescherming@wur.nl\">functionarisgegevensbescherming@wur.nl</a>.",
    "application.gdpr.title": "AVG",
    "application.gdpr.user-consent": "Ik geef Wageningen University & Research toestemming om mijn naam en e-mailadres op te slaan en te verwerken voor wetenschappelijk onderzoek",
    "application.menu.dashboard": "Overzicht",
    "application.menu.login": "Aanmelden",
    "application.menu.logout": "Afmelden",
    "application.menu.organisations": "Organisaties",
    "application.menu.register": "Registreren",
    "application.menu.settings": "Instellingen",
    "application.menu.userguide": "Gebruikershandleiding",
    "application.menu.yourtasks": "Mijn taken",
    "application.terms-of-service": "Dit wordt een lange tekst met de algemene voorwaarden. Dit wordt een lange tekst met de algemene voorwaarden. Dit wordt een lange tekst met de algemene voorwaarden. Dit wordt een lange tekst met de algemene voorwaarden. Dit wordt een lange tekst met de algemene voorwaarden. Dit wordt een lange tekst met de algemene voorwaarden. Dit wordt een lange tekst met de algemene voorwaarden. Dit wordt een lange tekst met de algemene voorwaarden. Dit wordt een lange tekst met de algemene voorwaarden. Dit wordt een lange tekst met de algemene voorwaarden. Dit wordt een lange tekst met de algemene voorwaarden. Dit wordt een lange tekst met de algemene voorwaarden. Dit wordt een lange tekst met de algemene voorwaarden. Dit wordt een lange tekst met de algemene voorwaarden. Dit wordt een lange tekst met de algemene voorwaarden. Dit wordt een lange tekst met de algemene voorwaarden. Dit wordt een lange tekst met de algemene voorwaarden. Dit wordt een lange tekst met de algemene voorwaarden. Dit wordt een lange tekst met de algemene voorwaarden.",
    "application.terms-of-service.agree": "Ik ga akkoord met de algemene voorwaarden",
    "application.terms-of-service.title": "Leest u a.u.b. onze algemene voorwaarden",
    "application.terms-of-service.why": "We hebben onze algemene voorwaarden vernieuwd. Leest u deze a.u.b. aandachtig en klik vervolgens op \"akkoord\" om onze algemene voorwaarden te accepteren.",
    "class": "Class",
    "colophon.contributors": "Organisaties",
    "colophon.desc": "Agouti is een applicatie voor het beheren van projecten met cameravallen, en verwerken van data.",
    "colophon.inbo": "Instituut voor Natuur- en Bosonderzoek (INBO)",
    "colophon.partners": "Partners",
    "colophon.release": "Versie",
    "colophon.title": "Over Agouti",
    "colophon.version": "v3.8.0 / 27-5-2021",
    "colophon.wur": "Wageningen Universiteit",
    "component.bait-selector.bait": "Lokstof",
    "component.bait-selector.baits": "Lokstoffen",
    "component.bait-selector.select-bait": "Selecteer lokstof",
    "component.bait-selector.select-baits": "Selecteer lokstoffen",
    "component.deployment-calibration-form": "Fotoserie kalibratie",
    "component.deployment-calibration-form.go-to-marking": "Spring naar marking",
    "component.deployment-calibration-form.height": "hoogte",
    "component.deployment-calibration-form.select-label.bottom": "Onderkant stok",
    "component.deployment-calibration-form.select-label.top": "Bovenkant stok",
    "component.deployment-row.age": "Leeftijd",
    "component.deployment-row.blanks": "Blanks",
    "component.deployment-row.datetime": "Datum & tijd",
    "component.deployment-row.edit": "Bewerk",
    "component.deployment-row.hideDetails": "Verberg details",
    "component.deployment-row.no-observations-added": "Nog geen waarneming toegevoegd.",
    "component.deployment-row.observed": "Waargenomen soorten",
    "component.deployment-row.person": "Persoon",
    "component.deployment-row.quantity": "Aant.",
    "component.deployment-row.setup": "Opstellen / ophalen",
    "component.deployment-row.sex": "Geslacht",
    "component.deployment-row.show": "Weergeven",
    "component.deployment-row.showDetails": "Toon details",
    "component.deployment-row.species": "soort",
    "component.deployment-row.specifications": "Specificaties",
    "component.deployment-row.view": "Bekijk waarnemingen",
    "component.droplet-deployment-uploader.cancel": "Annuleren",
    "component.droplet-deployment-uploader.creating-sequences": "Aanmaken fotoreeksen...",
    "component.droplet-deployment-uploader.guidance-part-1": "Agouti ondersteunt het uploaden van individuele foto's/video's en ZIP bestanden. Welke methode het beste werkt hangt af van de hoeveelheid bestanden in de fotoserie.",
    "component.droplet-deployment-uploader.guidance-part-2": "Als de fotoserie uit minder dan 4000 foto's of 25 video's bestaat kunt u deze uploaden door naar de map te bladeren en alle foto's/video's te selecteren.",
    "component.droplet-deployment-uploader.guidance-part-3": "Als de fotoserie uit meer dan 4000 foto's of 25 video's bestaat verpakt u deze eerst in een ZIP bestand. Dit ZIP bestand selecteert u vervolgens om te uploaden. Let op dat alle foto's/video's in het ZIP bestand gelijk in de hoofdmap zitten. Submappen worden niet ondersteund.",
    "component.droplet-deployment-uploader.new-deployment": "Nieuwe fotoserie",
    "component.droplet-deployment-uploader.progress": "Voortgang",
    "component.droplet-deployment-uploader.select-files": "Bladeren",
    "component.droplet-deployment-uploader.start-upload": "Verstuur",
    "component.droplet-deployment-uploader.uploading": "Uploaden...",
    "component.edit-deployment.add-sampling-point": "Meetpunt toevoegen",
    "component.edit-deployment.camera-heading": "Camera heading",
    "component.edit-deployment.camera-height": "Hoogte camera",
    "component.edit-deployment.camera-height.empty": "Geen camerahoogte ingevoerd",
    "component.edit-deployment.camera-identifier": "Camera label",
    "component.edit-deployment.camera-identifier-placeholder": "Camera label",
    "component.edit-deployment.camera-tilt": "Camera tilt",
    "component.edit-deployment.cm": "cm",
    "component.edit-deployment.created-at": "Aangemaakt op",
    "component.edit-deployment.created-by": "Aangemaakt door",
    "component.edit-deployment.distance-detection": "Detectieafstand",
    "component.edit-deployment.distance-detection.empty": "Geen detectieafstand opgegeven",
    "component.edit-deployment.end": "Einde",
    "component.edit-deployment.end-placeholder": "Einde",
    "component.edit-deployment.error.missing-fields": "Gelieve eerst alle verplichte velden in te vullen",
    "component.edit-deployment.groups-placeholder": "Selecteer één of meer groepen",
    "component.edit-deployment.invalid": "Ongeldige deployments worden uitgesloten in de exports.",
    "component.edit-deployment.location": "Meetpunt",
    "component.edit-deployment.location-placeholder": "Meetpunt",
    "component.edit-deployment.notes": "Notities",
    "component.edit-deployment.notes-placeholder": "Notities",
    "component.edit-deployment.notes.empty": "Geen notities gevonden",
    "component.edit-deployment.person-deploy": "Persoon plaatsen",
    "component.edit-deployment.person-deploy-placeholder": "Selecteer persoon",
    "component.edit-deployment.person-deploy.empty": "Geen persoon plaatsen gevonden",
    "component.edit-deployment.person-detect": "Persoon ophalen",
    "component.edit-deployment.person-detect-placeholder": "Selecteer persoon",
    "component.edit-deployment.person-detect.empty": "Geen persoon ophalen gevonden",
    "component.edit-deployment.save": "Opslaan",
    "component.edit-deployment.start": "Begin",
    "component.edit-deployment.start-placeholder": "Begin",
    "component.edit-deployment.tags": "Labels",
    "component.edit-deployment.tags-placeholder": "Selecteer één of meer labels",
    "component.edit-deployment.tags.empty": "Geen labels gevonden",
    "component.edit-deployment.utc-offset": "UTC afwijking",
    "component.edit-location.latitude": "Breedtegraad",
    "component.edit-location.longitude": "Lengtegraad",
    "component.edit-location.name": "Naam",
    "component.identification-form.age": "Leeftijd",
    "component.identification-form.amount": "Aantal",
    "component.identification-form.behaviour": "Gedrag",
    "component.identification-form.created-at": "Aangemaakt op",
    "component.identification-form.created-by": "Aangemaakt door",
    "component.identification-form.delete-observation": "Waarneming verwijderen",
    "component.identification-form.hide-optional": "Verberg optionele velden",
    "component.identification-form.identification": "Nieuwe waarneming",
    "component.identification-form.individual": "Individu",
    "component.identification-form.is-domesticated": "Gedomesticeerd",
    "component.identification-form.modified-at": "Laatst gewijzigd op",
    "component.identification-form.modified-by": "Laatst gewijzigd door",
    "component.identification-form.no-individual": "Geen individu",
    "component.identification-form.notes": "Notities",
    "component.identification-form.save-observation": "Waarneming opslaan",
    "component.identification-form.select-age": "Selecteer leeftijd",
    "component.identification-form.select-sex": "Selecteer geslacht",
    "component.identification-form.sex": "Geslacht",
    "component.identification-form.show-all": "Toon alle velden",
    "component.identification-form.species": "Soort",
    "component.identification-form.species-placeholder": "Soort",
    "component.identification-form.uncertain": "Onzeker / Waarschijnlijk",
    "component.identification-form.uncertain-short": "Onzeker",
    "component.identification-form.validated": "Gevalideerd",
    "component.is-identifiable-as-domesticated.no": "Nee",
    "component.is-identifiable-as-domesticated.yes": "Ja",
    "component.observation-position-form.view-position": "Spring naar positie",
    "component.observations-list.hide": "Verberg",
    "component.observations-list.observations": "Waarnemingen",
    "component.observations-list.quantity": "Aant.",
    "component.observations-list.sex": "Geslacht",
    "component.observations-list.show": "Toon",
    "component.observations-list.species": "Soort",
    "component.observations-list.uncertain": "Onzeker / Waarschijnlijk",
    "component.observations-table.observation-type": "Observatie type",
    "component.observations-table.observator": "Observator",
    "component.overview-marker-popup.annotate": "Annoteren",
    "component.overview-marker-popup.browse": "Bladeren",
    "component.overview-marker-popup.collected-person": "Persoon ophalen",
    "component.overview-marker-popup.date-of-import": "Datum van invoer",
    "component.overview-marker-popup.end": "Einde",
    "component.overview-marker-popup.set-up-person": "Persoon plaatsen",
    "component.overview-marker-popup.start": "Begin",
    "component.overview-marker-popup.statistics": "{sequencesAnnotatedCount} van {sequencesCount} reeksen geannoteerd",
    "component.overview-marker-popup.uploaded-person": "Geüpload door",
    "component.page-footer.all-rights-reserved": "Wageningen Universiteit & INBO",
    "component.page-footer.colophon": "Over",
    "component.page-footer.power": "Aangeboden door",
    "component.positioning.angle": "Hoek",
    "component.positioning.calculate-speed": "Bereken snelheid",
    "component.positioning.camera-and-deployment-calibration-missing": "Camera- en deployment kalibratie ontbreekt",
    "component.positioning.camera-calibration-missing": "Camera kalibratie ontbreekt",
    "component.positioning.camera-calibration-present": "Camera kalibratie aanwezig",
    "component.positioning.camera-details": "Camera Details",
    "component.positioning.camera-model-missing-with-support": "Camera kalibratie ontbreekt voor deze deployment. Neem contact op met support.",
    "component.positioning.deployment-calibration-missing": "Deployment kalibratie ontbreekt",
    "component.positioning.dimensions": "Afmetingen",
    "component.positioning.distance": "Afstand",
    "component.positioning.manufacturer": "Fabrikant",
    "component.positioning.model": "Model",
    "component.positioning.preview-plots": "Preview plots",
    "component.positioning.radius": "Straal",
    "component.positioning.speed": "Snelheid",
    "component.positioning.warning": "Waarschuwing",
    "component.remove-full-deployment.cancel": "Annuleren",
    "component.remove-full-deployment.confirm-removal": "Bevestig de verwijdering",
    "component.remove-full-deployment.delete": "Fotoserie verwijderen",
    "component.remove-full-deployment.modal.error": "Fout bij het verwijderen. De foutmelding is:",
    "component.remove-full-deployment.modal.info": "<p>U staat op het punt om de fotoserie en alle gerelateerde info te verwijderen. Het verwijderen is definitief.</p><p>Gelieve \"verwijderen\" te typen om te bevestigen.</p>",
    "component.remove-full-deployment.modal.title": "Opgelet: gelieve het verwijderen van de fotoserie te bevestigen",
    "component.remove-full-deployment.validation-word": "verwijderen",
    "component.shortcuts-list.animal": "Dier",
    "component.shortcuts-list.blank": "Blanco",
    "component.shortcuts-list.hide-all": "Verberg alles",
    "component.shortcuts-list.merge-with-previous": "Voeg samen met vorige",
    "component.shortcuts-list.merge-with-previous-time": "{amountOfMinutes} minuten verstreken sinds vorige reeks",
    "component.shortcuts-list.merge-with-previous-time-seconds": "{amountOfSeconds} seconden verstreken sinds vorige reeks",
    "component.shortcuts-list.merge-with-previous.modal.body": "Weet je zeker dat je deze reeks wilt samenvoegen met de vorige?",
    "component.shortcuts-list.merge-with-previous.modal.confirm": "Bevestig",
    "component.shortcuts-list.merge-with-previous.modal.title": "Voeg samen met vorige",
    "component.shortcuts-list.setup-pickup": "Plaatsen / Ophalen",
    "component.shortcuts-list.shortcuts": "Snelkoppelingen",
    "component.shortcuts-list.show-all": "Toon alles",
    "component.shortcuts-list.unknown": "Onbekend",
    "component.shortcuts-list.vehicle": "Voertuig",
    "component.utc-offset-selector.example": "Foto timestamp van <code>{originalExampleTime}</code> wordt <code>{convertedExampleTime}</code> UTC.",
    "component.utc-offset-selector.help-text": "De UTC afwijking is afhankelijk van de tijdzone waarin uw camera is geconfigureerd. Gebruik een tool zoals <a href=\"https://www.timeanddate.com/worldclock/converter.html\" target=\"_blank\">timeanddate.com</a> om de afwijking te berekenen.",
    "component.utc-offset-selector.placeholder": "Selecteer afwijking",
    "copied.id": "Id gekopieerdnaar klembord",
    "deployment-row.camera-deployment-id": "Fotoserie",
    "deployment-row.card-id": "Geheugenkaart",
    "deployment-row.information": "Gegevens",
    "deployment-row.location": "Meetpunt",
    "deployment-row.observations": "Waarnemingen",
    "deployment-row.tags": "Labels",
    "deployment-row.usable": "Bruikbaar",
    "deployment.id": "Deployment ID",
    "index.partners.1": "Partners & Financiering",
    "index.partners.2": "Agouti ondersteunt verschillende projecten, en ontvangt financiering van meerdere organisaties. Enkele hiervan zijn:",
    "index.partners.3": "Neem contact met ons op voor het aanmaken van <a href=\"https://mammalnet.com/\" target=\"_blank\">MammalNet</a> projecten.",
    "index.partners.4": "Met financiering van de <a href=\"https://www.efsa.europa.eu/\" target=\"_blank\">European Food and Safety Authority</a>",
    "index.partners.5": "Ondersteund door <a href=\"https://www.lifewatch.be/\" target=\"_blank\">LifeWatch</a>",
    "info.annotate": "Nadat een fotoserie is geïmporteerd moeten de reeksen worden verwerkt. Een fotoserie waarvan nog tenminste één reeks moet worden verwerkt verschijnt in deze lijst. Het is mogelijk om op elk willekeurig moment te stoppen met de fotoverwerking en later weer verder te gaan.",
    "info.annotate.title": "Fotoverwerking",
    "info.annotationsettings": "Hier kunt u aangeven of de algemene of wetenschappelijke namen op de annotatiepagina moeten worden getoond, en welk interval er wordt gehanteerd voor het indelen van foto's in reeksen.",
    "info.annotationsettings.title": "Annotatieinstellingen",
    "info.behaviours": "Voeg hier gedragingen toe die kunnen worden toegewezen aan waarnemingen tijdens annotatie.",
    "info.behaviours.title": "Gedrag",
    "info.browse": "Dit overzicht toont alle waarnemingen die binnen een fotoserie zijn toegevoegd. Bekijk een fotoserie door de regel uit te vouwen. Klik op <i>Toon</i> om direct naar deze waarneming te gaan en deze zonodig aan te passen.",
    "info.browse.title": "Waarnemingen",
    "info.cameras": "Camera's worden in deze centrale lijst bijgehouden. Hierdoor worden er dus ook camera's van andere projecten weergegeven. Controleer of een camera niet al is toegevoegd alvorens een nieuwe in te voeren. Het serienummer van Reconyx camera's is te vinden in direct onder de barcode, onderaan de opengeklapte camera.",
    "info.cameras.title": "Camera's",
    "info.downloaddata": "Alle aangemaakte datapakketten worden in deze tabel getoond. Pakketten worden gearchiveerd, zodat er naar een specifieke versie kan worden verwezen.",
    "info.downloaddata.title": "Datapakket Downloaden",
    "info.export": "Ingevoerde waarnemingen kunnen worden geëxporteerd als gestandaardiseerd pakket met CSV bestanden. Deze kunnen direct worden ingelezen in verreweg de meeste software voor verdere verwerking. Nadat een databestand is aangemaakt wordt deze getoond in de tabel hieronder.",
    "info.export.title": "Data Exporteren",
    "info.generalsettings": "Dit onderdeel bevat algemene projectinformatie. Vul tenminste de <i>Naam, Beschrijving</i> en <i>Projecteigenaar</i> in.",
    "info.generalsettings.title": "Project Informatie",
    "info.import.upload.title": "Bestanden uploaden",
    "info.import.uploadfiles": "Selecteer <i>Upload Bestanden</i> om alle foto's of video's van een deployment te uploaden. Navigeer naar de map met bestanden en selecteer al het materiaal, bij voorkeur direct van het geheugenkaartje.",
    "info.import.uploadfiles.title": "Foto's of video's uploaden",
    "info.individuals": "Volg individuele dieren door ze aan deze lijst toe te voegen. Hierna zijn de opties beschikbaar tijdens annotatie.",
    "info.individuals.title": "Individuen",
    "info.pendingusers": "Als een geregistreerde gebruiker toegang aanvraagt tot het project moet deze eerst worden geaccepteerd door de coördinator. Binnengekomen verzoeken worden in deze lijst getoond. Na het accepteren kan de coordinator eventueel een andere rol aan de gebruiker toewijzen.",
    "info.pendingusers.title": "Toegangsaanvragen",
    "info.registeredusers": "Alle gebruikers met toegang tot het project worden hier getoond. De coördinator kan hier de rol van een gebruiker wijzigen, of wachtwoorden resetten. Let op: als de rechten van een gebruiker worden ingetrokken zal deze opnieuw toegang moeten aanvragen om weer bij het project te kunnen!",
    "info.registeredusers.title": "Geregistreerde Gebruikers",
    "info.sampling.": "Een meetpunt is een locatie met een door de gebruiker bepaald label waaraan fotoseries kunnen worden toegewezen. Meerdere fotoseries kunnen aan één meetpunt worden toegewezen.",
    "info.sampling.title": "Meetpunten",
    "info.samplingdesign": "Vul hier de detail van het gebruikte protocol in. Dit gaat om het projectniveau, niet over individuele fotoseries.",
    "info.samplingdesign.title": "Protocol",
    "info.specieslist": "Agouti gebruikt de gestandaardiseerde soortenlijst uit de <a href=\"http://www.catalogueoflife.org/\" target=\"_blank\">Catalogue of Life</a>. Maak hier een subset van deze lijst aan om binnen dit project mee te werken.",
    "info.specieslist.title": "Soortenlijst",
    "info.tags": "Labels zijn vrije structuurelementen waarmee fotoseries kunnen worden gecategoriseerd. Het gebruik hiervan is optioneel. Meerdere labels kunnen aan een fotoserie worden toegewezen. Labels kunnen gebruikt worden om fotoseries te groeperen zodat deze later gemakkelijk geanalyseerd kunnen worden.",
    "info.tags.title": "Labels",
    "info.unfinishedupload": "Een fotoserie verschijnt in deze lijst als er bestanden zijn geupload maar het formulier <i>Importeer Fotoserie</i> niet is opgeslagen. Selecteer <i>Afronden</i> om het formulier alsnog in te vullen en het importeren af te ronden. Als het uploaden is onderbroken, verwijder dan de fotoserie.",
    "info.unfinishedupload.title": "Incomplete Fotoseries",
    "info.user.settings": "Hier kunt u uw persoonlijke instellingen en taal wijzigen. De taal heeft alleen invloed op de gebruikersinterface, niet op gegevens zoals gedrag of labels. Soortennamen worden wel vertaald, maar zullen de wetenschappelijke naam tonen als een vertaling ontbreekt.",
    "info.user.settings.title": "Persoonlijke Instellingen",
    "invalid": "Ongeldig",
    "language.locale.de": "German",
    "language.locale.en": "English",
    "language.locale.es": "Spanish",
    "language.locale.fr": "French",
    "language.locale.hr": "Croatian",
    "language.locale.nl": "Dutch",
    "language.locale.pl": "Polish",
    "leaflet-map-cartodb": "Standaard",
    "leaflet-map-here-hybrid.day": "Hybride",
    "leaflet-map-here-normal.day": "Normaal",
    "leaflet-map-here-terrain.day": "Terrein",
    "link": "Link",
    "login.desc": "Om toegang te vragen tot dit project, log je in met je Agouti account.",
    "login.email-address": "E-mailadres",
    "login.password": "Wachtwoord",
    "login.remember-me": "Onthoud mij",
    "login.sign-in": "Aanmelden",
    "login.sign-in-email": "E-mailadres niet gevonden.",
    "login.sign-in-error": "Combinatie van e-mailadres en wachtwoord onjuist.",
    "login.sign-in-password": "Verkeerde wachtwoord ingetoetst.",
    "login.title": "Aanmelden",
    "menu-item.annotate": "Annotatie",
    "menu-item.browse": "Bladeren",
    "menu-item.cameras": "Camera's",
    "menu-item.deployments": "Fotoseries",
    "menu-item.export-data": "Exporteren",
    "menu-item.import": "Importeren",
    "menu-item.locations": "Locaties",
    "menu-item.observations": "Observaties",
    "menu-item.overview": "Overzicht",
    "menu-item.project-settings": "Project instellingen",
    "menu-item.sampling-management": "Meetpunten",
    "menu-item.settings": "Instellingen",
    "menu-item.settings.behaviour": "Gedrag",
    "menu-item.settings.deployment-groups": "Fotoreeks groepen",
    "menu-item.settings.deployment-tags": "Fotoreeks labels",
    "menu-item.settings.general": "Algemeen",
    "menu-item.settings.individuals": "Individuen",
    "menu-item.species": "Soort",
    "menu-item.user-management": "Gebruikersbeheer",
    "menu-item.users": "Gebruikers",
    "menu-item.validate": "Validatie",
    "observation.id": "Observatie ID",
    "portal.statistics.deployments": "Fotoseries",
    "portal.statistics.images": "Foto's",
    "portal.statistics.observations": "Observaties",
    "portal.statistics.projects": "Projecten",
    "project-overview.show-less": "Toon minder",
    "project-overview.show-more": "Toon meer",
    "project-thumbnail.members": "{count, plural, other {# leden} =1 {# lid}}",
    "project-thumbnail.you-are": "Je bent {projectrole}",
    "project.annotate.annotate": "Annoteren",
    "project.annotate.annotate-ai": "Annoteren door AI",
    "project.annotate.annotate-view": "Annotaties bekijken",
    "project.annotate.end": "Einde",
    "project.annotate.inspected-sequences": "Afgeronde fotoreeksen",
    "project.annotate.is-blank": "Lege fotoreeks",
    "project.annotate.is-setup-pickup": "Opzetten / ophalen fotoreeks",
    "project.annotate.location": "Meetpunt",
    "project.annotate.no-unannotated-deployments": "Alle fotoreeksen zijn afgerond.",
    "project.annotate.progress": "Voortgang",
    "project.annotate.sequence.annotate-overview": "Annotatie",
    "project.annotate.sequence.back": "Terug",
    "project.annotate.sequence.back-to-overview": "Terug naar overzicht",
    "project.annotate.sequence.dashboard": "Overzicht",
    "project.annotate.sequence.exposure": "Belichting",
    "project.annotate.sequence.finish-annotating": "Annoteren afronden",
    "project.annotate.sequence.identify": "Identificatie",
    "project.annotate.sequence.navigate-by-observation": "Waarnemingen lijst",
    "project.annotate.sequence.navigate-by-sequences": "Fotoreeksen lijst",
    "project.annotate.sequence.next": "Volgende",
    "project.annotate.sequence.next-unannotated": "Volgende niet geannoteerde reeks",
    "project.annotate.sequence.observations.age": "Leeftijd",
    "project.annotate.sequence.observations.back": "Terug naar lijst",
    "project.annotate.sequence.observations.cancel": "Annuleren",
    "project.annotate.sequence.observations.quantity": "Aantal",
    "project.annotate.sequence.observations.sequence": "Fotoreeks",
    "project.annotate.sequence.observations.sex": "Geslacht",
    "project.annotate.sequence.observations.species": "Soort",
    "project.annotate.sequence.observations.suggested": "Voorgesteld",
    "project.annotate.sequence.observations.title": "Waarnemingen in {name}",
    "project.annotate.sequence.order": "Volgorde",
    "project.annotate.sequence.photo-of": "Foto {x} van {y}",
    "project.annotate.sequence.sequence-of": "Fotoreeks",
    "project.annotate.sequence.sequence-of2": "van {y}",
    "project.annotate.sequence.validation-status": "Validatie status",
    "project.annotate.sequence.warning.unsaved-work.body": "Gelieve eerst de waarneming te bewaren om verder te gaan.",
    "project.annotate.sequence.warning.unsaved-work.close-modal": "Ok",
    "project.annotate.sequence.warning.unsaved-work.header": "Opgelet: een waarneming werd niet bewaard.",
    "project.annotate.sequences": "Fotoreeksen",
    "project.annotate.start": "Begin",
    "project.annotate.title": "Annotatie",
    "project.annotate.unannotated-deployments": "Onafgeronde Fotoseries",
    "project.annotate.uninspected-sequences": "Te verwerken fotoreeksen",
    "project.browse.assign-to": "Wijs toe",
    "project.browse.assigned-to": "Toegewezen aan",
    "project.browse.deployments": "Fotoseries",
    "project.browse.desc": "Op deze pagina kunt u alle fotoseries in dit project doorzoeken en bewerken.",
    "project.browse.edit-deployment.title": "Bewerk fotoserie",
    "project.browse.end": "Einde",
    "project.browse.groups": "Groepen",
    "project.browse.location": "Meetpunt",
    "project.browse.modal.close": "Sluiten",
    "project.browse.no-deployments": "Nog geen fotoseries beschikbaar.",
    "project.browse.sequences": "Fotoreeksen",
    "project.browse.start": "Begin",
    "project.browse.tags": "Labels",
    "project.browse.title": "Bladeren",
    "project.browse.unannotated": "Onafgerond",
    "project.browse.view-observations.delete": "Weet je zeker dat je deze waarneming wilt verwijderen?",
    "project.browse.view-observations.filter": "Laat aangepaste waarnemingen zien",
    "project.browse.view-observations.split": "Weet je zeker dat je deze waarneming wilt splitsen?",
    "project.deployment.aimodel.analyzing": "AI bezig met annoteren",
    "project.deployment.aimodel.failed": "AI heeft gefaald",
    "project.deployment.aimodel.scheduled": "AI is ingepland ..",
    "project.deployment.aimodel.success": "AI is klaar ..",
    "project.deployment.complete.annotate": "Annoteer deze fotoserie",
    "project.deployment.complete.deployment-successfully-imported": "Fotoserie opgeslagen.",
    "project.deployment.complete.desc": "Vul het formulier in om de invoer van deze fotoserie af te ronden",
    "project.deployment.complete.import-new": "Voeg nog een fotoserie toe",
    "project.deployment.complete.success-message": "<b>Fotoserie opgeslagen.</b>",
    "project.deployment.complete.success-message.close": "Annoteer deze fotoserie.",
    "project.deployment.complete.title": "Nieuwe fotoserie",
    "project.deployment.set-timestamp.filter": "Filter",
    "project.deployment.set-timestamp.save": "Volgende",
    "project.deployment.set-timestamps.desc": "Agouti kon geen timestamps vinden voor deze beelden.<br>Gelieve deze handmatig in te voeren zodat Agouti de sequenties correct kan aanmaken.",
    "project.deployment.set-timestamps.file": "Bestandsnaam",
    "project.deployment.set-timestamps.timestamp": "Tijdstempel (24 uur notatie)",
    "project.deployment.set-timestamps.title": "Opnamedata invoeren",
    "project.export.create-export": "Maak export",
    "project.export.datasetId": "Dataset ID",
    "project.export.delete": "Verwijderen",
    "project.export.download": "Download",
    "project.export.filename": "Bestandsnaam",
    "project.export.no-exports-created": "Er zijn nog geen exports aangemaakt.",
    "project.export.previous-exports": "Eerdere exports",
    "project.export.size": "Grootte",
    "project.export.status": "Status",
    "project.export.time-of-export": "Aangemaakt op",
    "project.export.title": "Exporteren",
    "project.export.title.sub": "Export waarnemingen",
    "project.import.assets": "Assets",
    "project.import.complete": "Afronden",
    "project.import.create": "Nieuwe fotoserie",
    "project.import.create-sequence-confirmation": "Weet u zeker dat u fotoreeksen wilt maken van deze import?",
    "project.import.create-sequences": "Maak fotoreeksen",
    "project.import.delete": "Verwijderen",
    "project.import.delete-deployment-confirmation": "Weet u zeker dat u deze import wil verwijderen?",
    "project.import.end": "Einde",
    "project.import.files": "bestanden geselecteerd",
    "project.import.folder": "Map",
    "project.import.guidance": "Selecteer de map die de afbeeldingen of video bevat voor deze implementatie.",
    "project.import.images": "Foto's",
    "project.import.new-deployment.new-deployment": "Nieuwe fotoserie",
    "project.import.new-deployment.no-unfinished-imports": "Er zijn geen onafgeronde fotoseries.",
    "project.import.new-deployment.title": "Importeren",
    "project.import.new-deployment.unfinished-imports": "Onafgeronde fotoseries",
    "project.import.process": "Verwerken",
    "project.import.sequences": "Fotoreeksen",
    "project.import.start": "Begin",
    "project.import.status": "Status",
    "project.import.timestamp": "Tijdstempel",
    "project.import.type": "Type",
    "project.import.upload": "Upload",
    "project.import.upload-modal.cancel": "Sluiten",
    "project.import.upload-modal.desc1": "Selecteer handmatig bestanden om te uploaden.",
    "project.import.upload-modal.desc2": "of sleep bestanden in dit venster.",
    "project.import.upload-modal.select": "Selecteer bestanden",
    "project.import.upload-modal.title": "Upload bestanden",
    "project.import.upload-modal.uploading": "Uploading {queueFilesLength} files. Succesfully uploaded {uploaded} files, {duplicates} duplicates and {failed} files failed.",
    "project.import.uploaded": "Geüpload",
    "project.import.viabrowser": "Via browser",
    "project.import.viaftp": "Via FTP",
    "project.overview.achievements": "Prestaties",
    "project.overview.browse": "Bladeren",
    "project.overview.deployment": "Fotoserie",
    "project.overview.deployment-for-location": "Fotoserie voor meetpunt",
    "project.overview.deployments": "Fotoseries",
    "project.overview.desc": "Projectbeschrijving",
    "project.overview.favourite-assets": "Favoriete beelden",
    "project.overview.favourite-sequences": "Favoriete fotoseries",
    "project.overview.import-deployment": "Importeer fotoserie",
    "project.overview.imported-deployments": "Geïmporteerde fotoseries",
    "project.overview.most.observed.species": "Meest waargenomen soorten",
    "project.overview.no-deployments": "Geen fotoseries beschikbaar.",
    "project.overview.observations": "Waarnemingen",
    "project.overview.read.hide": "Verberg",
    "project.overview.read.show": "Meer lezen",
    "project.overview.records": "Archief",
    "project.overview.records-content": "heeft de meeste soorten in dit project waargenomen",
    "project.overview.sequences": "Fotoreeksen",
    "project.overview.stats": "Statistieken",
    "project.overview.team.members": "Leden van het team",
    "project.overview.unfinished-deployments": "Onvoltooide fotoseries",
    "project.request-access.description": "Klik op 'Toegang aanvragen' om toegang aan te vragen.",
    "project.request-access.request": "Toegang aanvragen",
    "project.request-access.title": "Toegang aanvragen",
    "project.sampling-management.add-group": "Voeg een groep toe",
    "project.sampling-management.add-group.title": "Voeg een groep toe",
    "project.sampling-management.add-sampling-point": "Voeg een meetpunt toe",
    "project.sampling-management.add-sampling-point.title": "Voeg een meetpunt toe",
    "project.sampling-management.add-samplingpoint.cancel": "Annuleren",
    "project.sampling-management.add-samplingpoint.missing-coordinates": "Gelieve naam en coördinaten in te vullen.",
    "project.sampling-management.add-samplingpoint.save": "Opslaan",
    "project.sampling-management.add-tag": "Voeg een label toe",
    "project.sampling-management.add-tag.cancel": "Annuleren",
    "project.sampling-management.add-tag.name": "Naam",
    "project.sampling-management.add-tag.name-placeholder": "Naam",
    "project.sampling-management.add-tag.save": "Save",
    "project.sampling-management.add-tag.title": "Voeg een label toe",
    "project.sampling-management.delete-tag.confirmation": "Weet u zeker dat u dit label wilt verwijderen?",
    "project.sampling-management.edit-group.title": "Bewerk groep",
    "project.sampling-management.edit-sampling-point.cancel": "Annuleren",
    "project.sampling-management.edit-sampling-point.save": "Opslaan",
    "project.sampling-management.edit-sampling-point.title": "Bewerk meetpunt",
    "project.sampling-management.edit-tag.cancel": "Annuleren",
    "project.sampling-management.edit-tag.name": "Naam",
    "project.sampling-management.edit-tag.name-placeholder": "Naam",
    "project.sampling-management.edit-tag.save": "Opslaan",
    "project.sampling-management.edit-tag.title": "Bewerk meetpunt",
    "project.sampling-management.sampling-points": "Meetpunten",
    "project.sampling-management.sampling-points.name": "Naam",
    "project.sampling-management.samplingpoints.delete": "Verwijderen",
    "project.sampling-management.samplingpoints.edit": "Bewerken",
    "project.sampling-management.tags": "Labels",
    "project.sampling-management.tags.delete": "Verwijderen",
    "project.sampling-management.tags.edit": "Bewerken",
    "project.sampling-management.tags.name": "Naam",
    "project.sampling-management.title": "Meetpunten",
    "project.sampling-management.upload-csv": "Upload CSV",
    "project.sequence.import.failed": "Sequence import failed.",
    "project.sequence.import.importing": "Importing sequences",
    "project.sequence.import.importing.note": "Note: depending on the size of the upload, how busy the server is, this can take a while.<br>You don't need to wait for the creation to complete.",
    "project.sequence.import.importing.success": "Sequences ready!",
    "project.sequence.import.status": "Status of sequence import",
    "project.settings.add-behaviour": "Voeg gedraging toe",
    "project.settings.add-behaviour.cancel": "Annuleren",
    "project.settings.add-behaviour.description": "Omschrijving",
    "project.settings.add-behaviour.description-placeholder": "Omschrijving",
    "project.settings.add-behaviour.name": "Naam",
    "project.settings.add-behaviour.name-placeholder": "Naam",
    "project.settings.add-behaviour.save": "Opslaan",
    "project.settings.add-behaviour.title": "Voeg gedraging toe",
    "project.settings.add-camera": "Voeg camera toe",
    "project.settings.add-camera.cancel": "Annuleren",
    "project.settings.add-camera.label": "Label",
    "project.settings.add-camera.label-placeholder": "Label",
    "project.settings.add-camera.make": "Merk",
    "project.settings.add-camera.make-placeholder": "Merk",
    "project.settings.add-camera.model": "Model",
    "project.settings.add-camera.model-placeholder": "Model",
    "project.settings.add-camera.save": "Opslaan",
    "project.settings.add-camera.serial-number": "Serienummer",
    "project.settings.add-camera.serial-number-placeholder": "Serienummer",
    "project.settings.add-camera.title": "Voeg camera toe",
    "project.settings.add-camera.year-of-purchase": "Jaar van aanschaf",
    "project.settings.add-deployment": "Fotoserie toevoegen",
    "project.settings.add-individual": "Voeg individu toe",
    "project.settings.add-individual.age": "Leeftijd",
    "project.settings.add-individual.cancel": "Annuleren",
    "project.settings.add-individual.name": "Naam",
    "project.settings.add-individual.name-placeholder": "Naam",
    "project.settings.add-individual.notes": "Notities",
    "project.settings.add-individual.notes-placeholder": "Notities",
    "project.settings.add-individual.save": "Opslaan",
    "project.settings.add-individual.sex": "Geslacht",
    "project.settings.add-individual.species": "Soort",
    "project.settings.add-individual.title": "Voeg individu toe",
    "project.settings.add-location": "Locatie toevoegen",
    "project.settings.add-species": "Voeg soort toe",
    "project.settings.add-species-list": "Voeg soortenlijst toe",
    "project.settings.add-species-to-add": "Soorten om toe te voegen",
    "project.settings.add-species.cancel": "Annuleren",
    "project.settings.add-species.save": "Opslaan",
    "project.settings.add-species.species": "Soort",
    "project.settings.add-species.species-placeholder": "Soort",
    "project.settings.add-species.title": "Voeg soort toe",
    "project.settings.annotate-calibration-and-tracking": "Calibratie en tracking",
    "project.settings.annotate-timelapses": "Annoteer timelapses",
    "project.settings.annotate-timelapses-disabled": "Annoteer timelapses kan niet worden gewijzigd als er reeds fotoreeksen zijn toegevoegd.",
    "project.settings.annotation": "Annotatie",
    "project.settings.automatic-annotation": "Automatische detectie",
    "project.settings.automatic-annotation-on-upload": "Automatisch bij nieuwe fotoreeks",
    "project.settings.automatic-annotation.decription": "Indien ingeschakel worden nieuwe fotoreeksen eerst verwerkt door de AI. Ze kunnen worden bewerkt nadat dit is voltooid.",
    "project.settings.automatic-annotation.placeholder": "Selecteer een model",
    "project.settings.behaviour": "Gedrag",
    "project.settings.behaviour.delete": "Verwijderen",
    "project.settings.behaviour.description": "Omschrijving",
    "project.settings.behaviour.edit": "Bewerken",
    "project.settings.behaviour.name": "Naam",
    "project.settings.camera.delete": "Verwijderen",
    "project.settings.camera.edit": "Bewerken",
    "project.settings.camera.label": "Label",
    "project.settings.camera.make": "Merk",
    "project.settings.camera.model": "Model",
    "project.settings.camera.serial-number": "Serienummer",
    "project.settings.camera.year-of-purchase": "Jaar van aanschaf",
    "project.settings.cameras": "Camera's",
    "project.settings.delete-behaviour-confirmation": "De gedraging wordt verwijderd. Weet je het zeker?",
    "project.settings.delete-camera-confirmation": "De camera wordt verwijderd. Weet je het zeker?",
    "project.settings.delete-individual-confirmation": "Het individu wordt verwijderd. Weet je het zeker?",
    "project.settings.delete-sampling-point-confirmation": "Weet u zeker dat u deze meetpunt wilt verwijderen ?",
    "project.settings.delete-species-confirmation": "De soort wordt verwijderd. Weet je het zeker?",
    "project.settings.deployments-are-assigned": "Deployments worden toegewezen",
    "project.settings.description": "Beschrijving",
    "project.settings.description-placeholder": "Beschrijving",
    "project.settings.edit-behaviour.cancel": "Annuleren",
    "project.settings.edit-behaviour.description": "Omschrijving",
    "project.settings.edit-behaviour.description-placeholder": "Omschrijving",
    "project.settings.edit-behaviour.name": "Naam",
    "project.settings.edit-behaviour.name-placeholder": "Naam",
    "project.settings.edit-behaviour.save": "Opslaan",
    "project.settings.edit-behaviour.title": "Bewerk gedraging",
    "project.settings.edit-camera": "Bewerk camera",
    "project.settings.edit-camera.cancel": "Annuleren",
    "project.settings.edit-camera.label": "Label",
    "project.settings.edit-camera.label-placeholder": "Label",
    "project.settings.edit-camera.make": "Merk",
    "project.settings.edit-camera.make-placeholder": "Merk",
    "project.settings.edit-camera.model": "Model",
    "project.settings.edit-camera.model-placeholder": "Model",
    "project.settings.edit-camera.save": "Opslaan",
    "project.settings.edit-camera.serial-number": "Serienummer",
    "project.settings.edit-camera.serial-number-placeholder": "Serienummer",
    "project.settings.edit-camera.title": "Bewerk camera",
    "project.settings.edit-camera.year-of-purchase": "Jaar van aanschaf",
    "project.settings.edit-individual.age": "Leeftijd",
    "project.settings.edit-individual.cancel": "Annuleren",
    "project.settings.edit-individual.name": "Naam",
    "project.settings.edit-individual.name-placeholder": "Naam",
    "project.settings.edit-individual.notes": "Notities",
    "project.settings.edit-individual.notes-placeholder": "Notities",
    "project.settings.edit-individual.save": "Opslaan",
    "project.settings.edit-individual.sex": "Geslacht",
    "project.settings.edit-individual.species": "Soort",
    "project.settings.edit-individual.title": "Bewerk individu",
    "project.settings.edit-location": "Bewerk locatie",
    "project.settings.edit-location.images": "Locatie afbeeldingen",
    "project.settings.edit-species.cancel": "Annuleren",
    "project.settings.edit-species.common-names": "Wijzig algemene namen",
    "project.settings.edit-species.is-identifiable-as-domesticated": "Kom als gedomesticeerd voor",
    "project.settings.edit-species.name": "Soort",
    "project.settings.edit-species.save": "Opslaan",
    "project.settings.edit-species.title": "Bewerk soort",
    "project.settings.feature": "Object",
    "project.settings.feature-methodology": "Beschrijving object",
    "project.settings.feature-methodology-placeholder": "Beschrijving object",
    "project.settings.feature.select-feature": "Select feature",
    "project.settings.general": "Algemeen",
    "project.settings.hide-deployment-locations": "Verberg deployment locaties",
    "project.settings.image": "Afbeelding",
    "project.settings.individual.add-individual": "Voeg individu toe",
    "project.settings.individual.delete": "Verwijderen",
    "project.settings.individual.edit": "Bewerken",
    "project.settings.individual.name": "Naam",
    "project.settings.individual.notes": "Notities",
    "project.settings.individual.species": "Soort",
    "project.settings.individual.zooniverse": "Zooniverse",
    "project.settings.individuals": "Individuen",
    "project.settings.location": "Locatie",
    "project.settings.min-image-resolution": "Minimaal {min_width} x {min_height} pixels",
    "project.settings.name": "Naam",
    "project.settings.name-placeholder": "Naam",
    "project.settings.open-for-subscriptions": "Open voor aanmeldingen",
    "project.settings.organisation": "Organisatie",
    "project.settings.organisation-help-block": "Organisations are considered rights holders of the data.",
    "project.settings.organisation-placeholder": "Organisatie",
    "project.settings.project-investigator": "PI",
    "project.settings.project-investigator-placeholder": "Hoofdonderzoeker",
    "project.settings.project-owner": "Concactpersoon",
    "project.settings.project-owner-placeholder": "Project Contactpersoon",
    "project.settings.quiet-period": "Rustperiode na opname",
    "project.settings.restricted-access": "Gegevens afgeschermd",
    "project.settings.restricted-images": "Afgeschermde afbeeldingen",
    "project.settings.restricted-images.note": "Indien aangevinkt, afbeelding URLs zijn niet toegankelijk voor externe gebruikers.",
    "project.settings.restricted-locations": "Afgeschermde locaties",
    "project.settings.restricted-locations.note": "Indien aangevinkt, zien fotoverwerkers en vrijwilligers alleen de locaties voor hun eigen fotoreeksen.",
    "project.settings.restricted-project": "Afgeschermd project",
    "project.settings.restricted-project.note": "Indien aangevinkt, wordt uw project verborgen voor de openbare homepage en portal op agouti.eu.",
    "project.settings.sampling-design": "Protocol",
    "project.settings.sampling-design.clustered-random": "Willekeurig geclusterd (willekeurige opstellingen)",
    "project.settings.sampling-design.experimental": "Experimenteel",
    "project.settings.sampling-design.label": "Steekproefontwerp",
    "project.settings.sampling-design.opportunistic": "Opportunistisch",
    "project.settings.sampling-design.placeholder": "Selecteer steekproefontwerpen",
    "project.settings.sampling-design.simple-random": "Eenvoudig willekeurig",
    "project.settings.sampling-design.systematic-random": "Systematisch willekeurig",
    "project.settings.sampling-design.targeted": "Gericht",
    "project.settings.seconds": "seconden",
    "project.settings.sensormethod": "Sensormethode",
    "project.settings.sensormethod.motion-detection": "Bewegingsdetectie",
    "project.settings.sensormethod.select-sensormethod": "Selecteer sensormethode",
    "project.settings.sensormethod.timelapse": "Timelapse",
    "project.settings.sequence-cut-off": "Indeling Reeksen",
    "project.settings.sequence-cutoff-disabled": "Indeling Reeksen kan niet worden gewijzigd als er reeds fotoreeksen zijn toegevoegd.",
    "project.settings.species": "Soort",
    "project.settings.species.added": "Toegevoegd aan project",
    "project.settings.species.available": "Beschikbare soorten",
    "project.settings.species.common-name": "Algemene naam",
    "project.settings.species.countDeployments": "Fotoreeksen",
    "project.settings.species.countObservations": "Observaties",
    "project.settings.species.countValObservations": "Gevalideerd",
    "project.settings.species.edit": "Bewerken",
    "project.settings.species.family": "Familie",
    "project.settings.species.genus": "Geslacht",
    "project.settings.species.isIdentifiableAsDomesticated": "Gedomesticeerd",
    "project.settings.species.order": "Orde",
    "project.settings.species.remove": "Verwijderen",
    "project.settings.species.species": "Soort",
    "project.settings.success-message": "Instellingen opgeslagen.",
    "project.settings.title": "Project Instellingen",
    "project.settings.update-location.cancel": "Annuleren",
    "project.settings.update-location.save": "Opslaan",
    "project.settings.update-location.title": "Bewerk locatie",
    "project.settings.utc-offset": "Default UTC afwijking",
    "project.user-management.accept-pending-request-confirmation": "De aanvraag wordt goedgekeurd. Weet je het zeker?",
    "project.user-management.awaiting-access": "Wacht op toegang",
    "project.user-management.copy": "Uitnodigingslink kopieren",
    "project.user-management.copy.confirmation": "Link gekopieerd",
    "project.user-management.delete-user-confirmation": "De gebruiker wordt uit dit project verwijderd. Weet je het zeker?",
    "project.user-management.edit-user.cancel": "Annuleren",
    "project.user-management.edit-user.email": "E-mail-madres",
    "project.user-management.edit-user.email-placeholder": "E-mailadres",
    "project.user-management.edit-user.first-name": "Voornaam",
    "project.user-management.edit-user.first-name-placeholder": "voornaam",
    "project.user-management.edit-user.last-name": "Achternaam",
    "project.user-management.edit-user.last-name-placeholder": "Achternaam",
    "project.user-management.edit-user.member-since": "Lid sinds",
    "project.user-management.edit-user.revoke-access": "Toegang intrekken",
    "project.user-management.edit-user.role": "Rol",
    "project.user-management.edit-user.save": "Opslaan",
    "project.user-management.edit-user.title": "Bewerk gebruiker",
    "project.user-management.email-address": "E-mail addres",
    "project.user-management.first-name": "Voornaam",
    "project.user-management.last-name": "Achternaam",
    "project.user-management.link-description": "Gebruik deze link om gebruikers toegang te laten aanvragen tot dit project.",
    "project.user-management.pending-requests": "Aanvragen",
    "project.user-management.pending-requests.accept": "Accepteren",
    "project.user-management.pending-requests.decline": "Afwijzen",
    "project.user-management.pending-requests.empty": "Geen aanvragen gevonden",
    "project.user-management.principal-investigators": "Project eigenaars",
    "project.user-management.project-access": "Toegang tot project",
    "project.user-management.registered-users": "Leden",
    "project.user-management.registered-users.delete": "Verwijderen",
    "project.user-management.registered-users.edit": "Bewerken",
    "project.user-management.role": "Rol",
    "project.user-management.title": "Gebruikersbeheer",
    "project.user-management.userbase": "Leden",
    "project.user-management.userbase.title": "Userbase",
    "project.user-management.volunteers": "Vrijwilligers",
    "project.validate.accept-sequence": "Accepteer sequence observaties",
    "project.validate.accepted-by-at": "Gevalideerd door {validator} op {date}",
    "project.validate.add-new-query": "Voeg query toe",
    "project.validate.all-are-true": "Alle volgende criteria zijn waar",
    "project.validate.edit.title": "Wijzig validatie query",
    "project.validate.filter.created-from": "Aangemaakt van",
    "project.validate.filter.created-to": "Aangemaakt tot",
    "project.validate.filter.is-equal-to": "Is gelijk aan",
    "project.validate.filter.is-unknown": "Is onbekend",
    "project.validate.filter.sampling-point": "Meetpunt",
    "project.validate.filter.select-filter-type": "Selecteer filter type",
    "project.validate.filter.select-role": "Selecteer project rol",
    "project.validate.filter.select-sampling-point": "Selecteer meetpunt",
    "project.validate.filter.select-species": "Selecteer diersoort",
    "project.validate.filter.select-user": "Selecteer gebruiker",
    "project.validate.filter.species": "Diersoort",
    "project.validate.filter.user": "Gebruiker",
    "project.validate.filter.user-project-role": "Rol in project",
    "project.validate.new.title": "Nieuwe validatie query",
    "project.validate.no-rules-defined-yet": "Nog geen criteria...",
    "project.validate.no-validation-queries": "Er zijn nog geen validatie queries gedefinieerd voor dit project",
    "project.validate.none-are-true": "Geen van de volgende criteria zijn waar",
    "project.validate.predicates": "Criteria",
    "project.validate.query-is-failed": "Er is iets misgegaan bij het uitvoeren van de query...",
    "project.validate.query-is-running": "Query wordt uitgevoerd...",
    "project.validate.query-is-scheduled": "Query is ingepland...",
    "project.validate.results.assest-count": "Aantal assets",
    "project.validate.results.no-results": "Geen resultaten gevonden...",
    "project.validate.results.show-done": "Toon gevalideerde sequences",
    "project.validate.results.total-results": "Totaal",
    "project.validate.results.total-results-of": "{count} van {total} resultaten worden afgebeeld (max {limit})",
    "project.validate.rule-presets": "Opgeslagen filters",
    "project.validate.scheduling-explanation": "Queries worden elk uur uitgevoerd. Dit kan een paar minuten tot een half uur duren. Ververs de pagina om de status van de validatie queries te updaten .",
    "project.validate.sequences": "Fotoreeksen",
    "project.validate.table.header.candidates": "Matches",
    "project.validate.table.header.name": "Naam",
    "project.validate.title": "Validatie",
    "project.validate.unaccept-sequence": "Verwijder validatie",
    "project.validate.validate": "valideer",
    "project.validate.validation-query-results": "Validatie query resultaten",
    "project.zooniverse.password": "Zooniverse wachtwoord",
    "project.zooniverse.password-placeholder": "Zooniverse wachtwoord",
    "project.zooniverse.projectid": "Zooniverse Projectid",
    "project.zooniverse.projectid-placeholder": "Zooniverse Projectid",
    "project.zooniverse.username": "Zooniverse gebruikersnaam",
    "project.zooniverse.username-placeholder": "Zooniverse gebruikersnaam",
    "projectrole.ADMIN": "Beheerder",
    "projectrole.AWAITING_ACCESS": "Wacht op toegang",
    "projectrole.DUMMY": "Dummy",
    "projectrole.PHOTO_PROCESSOR": "Fotoverwerker",
    "projectrole.PRINCIPAL_INVESTIGATOR": "Project Eigenaar",
    "projectrole.PROJECT_COORDINATOR": "Project Coördinator",
    "projectrole.TAXONOMIC_EXPERT": "Taxonomisch Expert",
    "projectrole.VIEW_ONLY": "View Only",
    "projectrole.VOLUNTEER": "Vrijwilliger",
    "projects.add": "Add new project",
    "projects.independent-projects": "Onafhankelijke projecten",
    "projects.no-projects-available": "Geen projecten beschikbaar. Neem contact op met de project coördinator voor instructies.",
    "projects.search-for": "Zoeken...",
    "projects.search-for.filter": "Projecten filteren",
    "projects.search-for.filterOrg": "Filter op organisatie",
    "projects.title": "Projecten",
    "projects.title.user": "Uw Projecten",
    "projects.your-role": "Uw rol",
    "publichome.block1.title": "Wat is Agouti?",
    "publichome.block1.txt": "Cameravallen bieden een makkelijke en niet-invasieve manier om wild te bestuderen. Echter, het handmatig verwerken de miljoenen beelden die binnen een doorsnee studie worden verzameld wordt al snel een uitdaging. Door het toepassing van kunstmatige intelligentie (AI) kan Agouti automatisch soorten herkennen en lege beelden en mensen filteren.",
    "publichome.block1.txt2": "Agouti biedt een volledige oplossing voor organisaties en professionals werken met cameravallen. Werk efficiënt samen, verwerk gemakkelijk de beelden en genereer gestandaardiseerde output. Agouti volgt de <a target=\"_blank\" href=\"https://tdwg.github.io/camtrap-dp\">Camera Trap Data Package</a> standaard, een door de cameravalonderzoekers ontwikkelde standaard voor het uitwisselen van cameravaldata.",
    "publichome.block2.title": "Workflow",
    "publichome.block2.txt": "Na het ophalen van de camera's uit het veld, upload je de foto's of video's direct vanaf de geheugenkaart naar Agouti en geeft de locatie op. De beelden worden vervolgens automatisch uitgelezen en gegroepeert fotoreeksen die samen één bezoek van een dier vormen. Vervolgens kun je de beelden laten verwerken door onze automatisch soortherkenning, of handmatig annoteren met behulp van de gebruiksvriendelijke interface.",
    "publichome.block2.txt2": "U kunt uw gegevens exporteren als een Camera Trap Data Package (<a target=\"_blank\" href=\"https://tdwg.github.io/camtrap-dp/\">Camtrap DP</a>) voor verdere analyse met behulp van software zoals R. Wij raden om het R pakket <a target=\"_blank\" href=\"https://inbo.github.io/camtraptor/\">camtraptor</a> te gebruiken.",
    "publichome.block3.title": "Importeer",
    "publichome.block3.txt": "Upload foto's en video's direct vanuit de browser",
    "publichome.block3.txt2": "Annoteer",
    "publichome.block3.txt3": "Gebruik AI om beelden automatisch van waarnemingen te voorzien",
    "publichome.block3.txt4": "Analyseer",
    "publichome.block3.txt5": "Verken en controller data met ingebouwde overzichten en tabellen",
    "publichome.block3.txt6": "Exporteer",
    "publichome.block3.txt7": "Genereer gestandaardiseerde camtrap-do databestanden voor analyse of delen",
    "publichome.block4.title": "Intuïtieve Interface",
    "publichome.block4.txt": "Agouti's interface is ontworpen voor productiviteit. Iedereen kan met minimale training aan de slag. De screenshots hieronder laten verschillende onderdelen van het systeem zien.",
    "publichome.block4.txt1": "Registreren en aanmelden",
    "publichome.block4.txt2": "Fotosessies importeren",
    "publichome.block4.txt3": "Fotoreeksen annoteren",
    "publichome.block4.vid1": "/assets/videos/GettingStarted-NL.mp4",
    "publichome.block4.vid2": "/assets/videos/ImportingDeployments-NL.mp4",
    "publichome.block4.vid3": "/assets/videos/AnnotatingSequences-NL.mp4",
    "publichome.block5.title": "Uitgelichte projecten",
    "publichome.block5.txt": "Al honderden projecten maken gebruik van Agouti. Verken enkele van deze openbare projecten om de mogelijkheden van Agouti te ontdekken.",
    "publichome.block6.f1": "Projectbeheer",
    "publichome.block6.f2": "Protocol",
    "publichome.block6.f3": "Ruimtelijk plan",
    "publichome.block6.f4": "Volg camera's",
    "publichome.block6.f5": "Label camerasessies",
    "publichome.block6.f6": "Kwaliteitscontrole",
    "publichome.block6.g1": "Verwerk",
    "publichome.block6.g2": "Voer waarnemingen in",
    "publichome.block6.g3": "Gestandaardiseerde soortenlijst",
    "publichome.block6.g4": "Exporteer CSV bestanden",
    "publichome.block6.g5": "Volg individuele dieren",
    "publichome.block6.g6": "Annotateer gedrag",
    "publichome.block6.h1": "Werk samen",
    "publichome.block6.h2": "Nodig collega's uit",
    "publichome.block6.h3": "Rollenbeheer",
    "publichome.block6.h4": "Taakbeheer",
    "publichome.block6.h5": "Volg voortgang",
    "publichome.block6.h6": "Organiseer data",
    "publichome.block6.title": "Functies",
    "publichome.block6.txt": "Agouti biedt complete functionaliteit voor elk project.",
    "publichome.block7.title": "Afgeschermd & Veilig",
    "publichome.block7.txt": "Goede afspraken over eigendom van data zijn belangrijk. Agouti claimt geen eigendom op uw data en verstrekt geen gegevens aan derden zonder uw geschreven toestemming. U bepaalt wie toegang krijgt tot uw projecten zodat u volgens eigen inzicht kunt samenwerken.",
    "publichome.block7.txt2": "Agouti bewaart alle data in de twee datacenters van Wageningen Universiteit in Nederland. Onze volledig dubbel uitgevoerde infrastructuur garandeert optimale toegang tot uw gegevens en voorkomt dataverlies door defecte harde schijven, vergissingen of crashende computers. Agouti gebruikt geen commerciële cloudleveranciers voor dataopslag.",
    "publichome.block8.f1": "Academisch",
    "publichome.block8.f2": "Gratis",
    "publichome.block8.f3": "Onbeperkte opslag",
    "publichome.block8.f4": "Onbeperkte projecten & gebruikers",
    "publichome.block8.f5": "Best Effort Support",
    "publichome.block8.f6": "Aanvragen",
    "publichome.block8.g1": "Non-profit",
    "publichome.block8.g2": "Gratis",
    "publichome.block8.g3": "Onbeperkte opslag",
    "publichome.block8.g4": "Onbeperkte projecten & gebruikers",
    "publichome.block8.g5": "Best Effort Support",
    "publichome.block8.g6": "Aanvragen",
    "publichome.block8.h1": "Commercieel",
    "publichome.block8.h2": "Gratis, donaties welkom",
    "publichome.block8.h3": "Onbeperkte opslag",
    "publichome.block8.h4": "Onbeperkte projecten & gebruikers",
    "publichome.block8.h5": "Best Effort Support",
    "publichome.block8.h6": "Aanvragen",
    "publichome.block8.title": "Begin met Agouti",
    "publichome.block8.txt": "Met Agouti willen iedereen die met cameravallen werkt faciliteren en voorkomen dat data verloren gaat. Daarom is Agouti gratis te gebruiken voor academisch, non-profit en commercieël gebruik. We stellen het wel op prijs als commerciële partijen een financiële bijdrage doen om de kosten voor de ontwikkeling en hosting van Agouti te dekken.",
    "publichome.explore-projects": "Verken projecten",
    "publichome.footer1": "Agouti",
    "publichome.footer10": "6708 PB Wageningen",
    "publichome.footer11": "Nederland",
    "publichome.footer12": "Instituut voor Natuur- en Bosonderzoek (INBO)",
    "publichome.footer13": "Faunabeheer en Invasieve soorten & Open science lab for biodiversity",
    "publichome.footer14": "Havenlaan 88 bus 73",
    "publichome.footer15": "1000 Brussel",
    "publichome.footer16": "België",
    "publichome.footer2": "Mailinglijst voor gebruikers",
    "publichome.footer3": "Privacybeleid",
    "publichome.footer4": "Disclaimer",
    "publichome.footer5": "Organization",
    "publichome.footer6": "Agouti is initiatief van Wageningen Universiteit en het Instituut voor Natuur- en Bosonderzoek (INBO)",
    "publichome.footer7": "Wageningen Universiteit",
    "publichome.footer8": "Department Omgevingswetenschappen",
    "publichome.footer9": "Wildlife Ecology and Conservation Group",
    "publichome.slogan": "Een door AI aangedreven oplossing voor het beheren van projecten met cameravallen",
    "publichome.stats1": "Projecten",
    "publichome.stats2": "Camerasessies",
    "publichome.stats3": "Waarnemingen",
    "publichome.stats4": "Beelden",
    "publichome.title": "Agouti",
    "sequence.id": "Sequence ID",
    "sex.": "N.A.",
    "sex.female": "Vrouw",
    "sex.male": "Man",
    "sex.unknown": "Onbekend",
    "taxonRank": "Taxon Rank",
    "toggle-processed": "Verberg 100%",
    "user.register.desc": "Registreer voor een Agouti account",
    "user.register.email-address": "E-mailadres",
    "user.register.email-address-duplicate": "E-mail wordt al gebruikt.",
    "user.register.email-address-error": "Vul aub uw e-mail in.",
    "user.register.first-name": "Voornaam",
    "user.register.first-name-error": "Vul aub uw voornaam in.",
    "user.register.gdpr-error": "Bevestig AVG-toestemming a.u.b.",
    "user.register.last-name": "Achternaam",
    "user.register.last-name-error": "Vul aub uw acthernaam in",
    "user.register.password": "Wachtwoord",
    "user.register.password-error": "Vul aub een wachtwoord in.",
    "user.register.terms-of-service-error": "Bevestig a.u.b. onze algemene voorwaarden voor gebruik van onze service",
    "user.register.terms-of-service-link-and-label": "Ik ga akkoord met de&nbsp;<a href=\"/#/termsOfService\">algemene voorwaarden</a>",
    "user.register.title": "Registreren",
    "user.request-reset-password.desc": "Request a password reset link",
    "user.request-reset-password.title": "Reset password",
    "user.reset-password.desc": "Kies een nieuw wachtwoord",
    "user.reset-password.email-address": "E-mail",
    "user.reset-password.email-address-duplicate": "E-mailadres is al in gebruik",
    "user.reset-password.email-address-error": "Vul a.u.b. uw e-mail in.",
    "user.reset-password.password": "Wachtwoord",
    "user.reset-password.password-are-different-error": "Wachtwoorden zijn niet gelijk",
    "user.reset-password.password-confirmation": "Bevestig uw wachtwoord a.u.b.",
    "user.reset-password.password-confirmation-error": "Vul a.u.b. een wachtwoord bevestiging in.",
    "user.reset-password.password-error": "Vul a.u.b. een wachtwoord in.",
    "user.reset-password.title": "Reset wachtwoord",
    "user.settings.account": "Account",
    "user.settings.adaptive-image-size": "Adaptieve beeldgrootte",
    "user.settings.annotate-shortcuts-label": "Snelkoppelingen annoteren",
    "user.settings.anonymous-in-export": "Anoniem in exports",
    "user.settings.anonymous-in-export-explanation": "When checked, your personal data will be anonymized in exports. Your name and email will not be included in data exports and you will not be credited as contributor, setup person and/or classifier.",
    "user.settings.autoscroll-thumbnails-annotating": "Automatisch scrollen tijdens annoteren",
    "user.settings.common": "Algemeen",
    "user.settings.email-address": "E-mailadres",
    "user.settings.email-address-label": "E-mail",
    "user.settings.email-in-use": "E-mail addres is al in gebruik.",
    "user.settings.first-name": "Voornaam",
    "user.settings.first-name-note": "Dit is wat andere leden te zien krijgen.",
    "user.settings.gravatar": "Of <a target=\"_blank\" href=\"http://gravatar.com\" title=\"http://gravatar.com\">Gravatar</a> inschakelen",
    "user.settings.gravatar.disable": "Uitschakelen",
    "user.settings.gravatar.enable": "Inschakelen",
    "user.settings.language": "Taal",
    "user.settings.last-name": "Achternaam",
    "user.settings.organisation": "Organisatie",
    "user.settings.organisation-placeholder": "Organisatie",
    "user.settings.password": "Wachtwoord",
    "user.settings.picture": "Publieke avatar",
    "user.settings.picture.browse": "Bladeren...",
    "user.settings.picture.file": "Geen bestand gekozen",
    "user.settings.picture.remove": "Verwijderen",
    "user.settings.picture.upload": "Nieuwe avatar uploaden",
    "user.settings.save": "Wijzigingen opslaan",
    "user.settings.save.update": "Instellingen aanpassen",
    "user.settings.saved": "Instellingen opgeslagen.",
    "user.settings.scientific": "Wetenschappelijk",
    "user.settings.species-names": "Soortennamen",
    "user.settings.title": "Profiel Instellingen",
    "user.settings.url.label": "URL (ORCID)",
    "user.settings.url.placeholder": ""
  }], ["pl", {
    "age.": "N.A.",
    "age.adult": "Dorosły",
    "age.infant": "Młody",
    "age.subadult": "Młodociany",
    "age.unknown": "Nieznany",
    "application.form.cancel": "Anuluj",
    "application.form.close": "Zamknij",
    "application.form.delete": "Usuń",
    "application.form.edit": "Edytuj",
    "application.form.fields.description": "Opis",
    "application.form.fields.name": "Nazwa",
    "application.form.save": "Zapisz",
    "application.form.saved": "Zapisano",
    "application.format.date": "MM/DD/YYYY",
    "application.format.date-time": "MM/DD/YYYY HH:mm",
    "application.gdpr.continue": "Zanim przejdziesz dalej, udziel zgody Wageningen University & Research na przechowywanie i przetwarzanie Twojego imienia i nazwiska oraz adresu e-mail do celów badań naukowych",
    "application.gdpr.text": "W ten sposób wyrażasz zgodę na dobrowolne podanie swoich danych osobowych. Masz prawo do cofnięcia wyrażonej zgody. Dane osobowe będą wykorzystywane wyłącznie w celu, w jakim zostały podane. Masz prawo do sprawdzania, usuwania, poprawiania i ograniczania przetwarzania swoich danych osobowych, prawo do odwołania oraz prawo do przeniesienia danych <a target=\"_blank\" href=\"https://www.wur.nl/en/About-Wageningen/Integrity-and-privacy.htm\">https://www.wur.nl/en/About-Wageningen/Integrity-and-privacy.htm</a> Uwaga: prawo to nie jest prawem bezwzględnym. Każdy przypadek rozpatrywany jest w zależności od sytuacji i prawa, na które powołuje się upoważniona osoba. Jeżeli przetwarzanie danych jest niezbędne do wykonania zadań ustawowych lub w interesie ogólnym (np. do celów archiwalnych w interesie publicznym, do celów badań naukowych lub historycznych lub do celów statystycznych), prawo do bycia zapomnianym nie istnieje. Wszelkie skargi można składać do WUR, pisząc na adres <a href=\"mailto:privacy@wur.nl\">privacy@wur.nl</a>. Skargę można również złożyć do holenderskiego organu ochrony danych. Więcej informacji na ten temat można znaleźć na stronie <a target=\"_blank\" href=\"https://www.autoriteitpersoonsgegevens.nl\">www.autoriteitpersoonsgegevens.nl</a>. W przypadku jakichkolwiek pytań możesz skontaktować się z inspektorem ochrony danych osobowych w WUR, pisząc na adres <a href=\"mailto:functionarisgegevensbescherming@wur.nl\">functionarisgegevensbescherming@wur.nl</a>.",
    "application.gdpr.title": "RODO",
    "application.gdpr.user-consent": "Udzielam zgody Wageningen University & Research na przechowywanie i przetwarzanie mojego imienia i nazwiska oraz adresu e-mail do celów badań naukowych",
    "application.menu.dashboard": "Pulpit nawigacyjny",
    "application.menu.login": "Zaloguj się",
    "application.menu.logout": "Wyloguj",
    "application.menu.organisations": "Organisations",
    "application.menu.register": "Zarejestruj się",
    "application.menu.settings": "Ustawienia",
    "application.menu.userguide": "Podręcznik użytkownika",
    "application.menu.yourtasks": "Twoje zadania",
    "class": "Class",
    "colophon.contributors": "Twórcy",
    "colophon.desc": "Agouti to aplikacja do zarządzania projektami, m.i. nagraniami z fotopułapek i analizowania danych.",
    "colophon.inbo": "Instytut Badawczy Przyrody i Lasu (INBO)",
    "colophon.partners": "Partnerzy",
    "colophon.release": "Wydanie aplikacji",
    "colophon.title": "O Agouti",
    "colophon.version": "v3.8.0 / 9-5-2021",
    "colophon.wur": "Uniwersytet w Wageningen",
    "component.bait-selector.bait": "Przynętę",
    "component.bait-selector.baits": "Przynęty",
    "component.bait-selector.select-bait": "Wybierz przynętę",
    "component.bait-selector.select-baits": "Wybierz przynęty",
    "component.deployment-calibration-form": "Kalibracja wdrożenia",
    "component.deployment-calibration-form.go-to-marking": "Skocz do",
    "component.deployment-calibration-form.height": "wysokość",
    "component.deployment-calibration-form.select-label.bottom": "Dno kija",
    "component.deployment-calibration-form.select-label.top": "Góra kija",
    "component.deployment-row.age": "Wiek",
    "component.deployment-row.blanks": "Puste",
    "component.deployment-row.datetime": "Data i godzina",
    "component.deployment-row.edit": "Edytuj nagranie",
    "component.deployment-row.hideDetails": "Pokaż szczegóły",
    "component.deployment-row.no-observations-added": "Nie dodano żadnych obserwacji.",
    "component.deployment-row.observed": "Obserwowany gatunek",
    "component.deployment-row.person": "Użytkownik",
    "component.deployment-row.quantity": "Liczba",
    "component.deployment-row.setup": "Rozpocznij / wybierz sekwencje",
    "component.deployment-row.sex": "Płeć",
    "component.deployment-row.show": "Pokaż",
    "component.deployment-row.showDetails": "Pokaż szczegóły",
    "component.deployment-row.species": "Gatunek",
    "component.deployment-row.specifications": "Opis",
    "component.deployment-row.view": "Obejrzyj obserwacje",
    "component.droplet-deployment-uploader.cancel": "Anuluj",
    "component.droplet-deployment-uploader.creating-sequences": "Tworzenie sekwencji...",
    "component.droplet-deployment-uploader.guidance-part-1": "Agouti akceptuje przesyłanie pojedynczych zdjęć / filmów wideo, a także plików ZIP. Efektywność metody zależy od liczby plików w nagraniu.",
    "component.droplet-deployment-uploader.guidance-part-2": "Jeśli masz na nagraniu mniej niż 4000 zdjęć lub 25 filmów wideo, przejdź do folderu zawierającego te zdjęcia / filmy wideo i wybierz je wszystkie.",
    "component.droplet-deployment-uploader.guidance-part-3": "Jeśli masz ponad 4000 zdjęć lub 25 filmów wideo, najpierw skompresuj je w jednym pliku ZIP, a następnie prześlij ten plik. Upewnij się, że wszystkie zdjęcia / filmy wideo znajdują się w katalogu głównym pliku zip. Podfoldery nie są obsługiwane.",
    "component.droplet-deployment-uploader.new-deployment": "Nowe nagranie",
    "component.droplet-deployment-uploader.progress": "Postęp",
    "component.droplet-deployment-uploader.select-files": "Przeglądaj",
    "component.droplet-deployment-uploader.start-upload": "Rozpocznij przesyłanie",
    "component.droplet-deployment-uploader.uploading": "Przesyłanie...",
    "component.edit-deployment.add-sampling-point": "Dodaj miejsce obserwacji",
    "component.edit-deployment.camera-heading": "Camera heading",
    "component.edit-deployment.camera-height": "Wysokość ustawienia fotopułapki",
    "component.edit-deployment.camera-height.empty": "Nie znaleziono danych wysokości ustawienia fotopułapki",
    "component.edit-deployment.camera-identifier": "Numer identyfikacyjny fotopułapki",
    "component.edit-deployment.camera-identifier-placeholder": "Numer identyfikacyjny fotopułapki",
    "component.edit-deployment.camera-tilt": "Camera tilt",
    "component.edit-deployment.cm": "cm",
    "component.edit-deployment.created-at": "Utworzony przy",
    "component.edit-deployment.created-by": "Stworzony przez",
    "component.edit-deployment.distance-detection": "Odległość wykrywania",
    "component.edit-deployment.distance-detection.empty": "Nie znaleziono odległości wykrywania",
    "component.edit-deployment.end": "Koniec",
    "component.edit-deployment.end-placeholder": "Koniec",
    "component.edit-deployment.error.missing-fields": "Please complete all required fields.",
    "component.edit-deployment.groups-placeholder": "Wybierz jedną lub więcej grup",
    "component.edit-deployment.invalid": "Nieważne wdrożenia są wyłączone z eksportu danych.",
    "component.edit-deployment.location": "Miejsce obserwacji",
    "component.edit-deployment.location-placeholder": "Miejsce obserwacji",
    "component.edit-deployment.notes": "Notatki",
    "component.edit-deployment.notes-placeholder": "Uwagi, komentarze",
    "component.edit-deployment.notes.empty": "Nie znaleziono notatek",
    "component.edit-deployment.person-deploy": "Użytkownik ustawiający fotopułapkę",
    "component.edit-deployment.person-deploy-placeholder": "Wybierz użytkownika",
    "component.edit-deployment.person-deploy.empty": "Nie znaleziono użytkownik ustawiający fotopułapkę",
    "component.edit-deployment.person-detect": "Użytkownik zabierający fotopułapke",
    "component.edit-deployment.person-detect-placeholder": "Wybierz użytkownika",
    "component.edit-deployment.person-detect.empty": "Nie znaleziono użytkownik zabierający fotopułapke",
    "component.edit-deployment.save": "Zakończ nagranie",
    "component.edit-deployment.start": "Początek",
    "component.edit-deployment.start-placeholder": "Początek",
    "component.edit-deployment.tags": "Tagi",
    "component.edit-deployment.tags-placeholder": "Wybierz jeden lub więcej tagów",
    "component.edit-deployment.tags.empty": "Nie znaleziono tagów",
    "component.edit-deployment.utc-offset": "UTC przesunięcie",
    "component.edit-location.latitude": "Szerokość geograficzna",
    "component.edit-location.longitude": "Długość geograficzna",
    "component.edit-location.name": "Nazwa",
    "component.identification-form.age": "Wiek",
    "component.identification-form.amount": "Liczba",
    "component.identification-form.behaviour": "Zachowanie",
    "component.identification-form.created-at": "Utworzony w",
    "component.identification-form.created-by": "Stworzony przez",
    "component.identification-form.delete-observation": "Usuń obserwacje",
    "component.identification-form.hide-optional": "Ukryj pola opcjonalne",
    "component.identification-form.identification": "Dodaj adnotacje",
    "component.identification-form.individual": "Osobnik",
    "component.identification-form.is-domesticated": "Hodowlany",
    "component.identification-form.modified-at": "Ostatnio zmieniony o",
    "component.identification-form.modified-by": "Ostatnio zmodyfikowany przez",
    "component.identification-form.no-individual": "Brak",
    "component.identification-form.notes": "Notatki",
    "component.identification-form.save-observation": "Zapisz obserwacje",
    "component.identification-form.select-age": "Wybierz wiek",
    "component.identification-form.select-sex": "Wybierz płeć",
    "component.identification-form.sex": "Płeć",
    "component.identification-form.show-all": "Pokaż wszystkie pola",
    "component.identification-form.species": "Gatunek",
    "component.identification-form.species-placeholder": "Gatunek",
    "component.identification-form.uncertain": "Niepewne / Prawdopodobne",
    "component.identification-form.uncertain-short": "Niepewne",
    "component.identification-form.validated": "Zatwierdzony",
    "component.is-identifiable-as-domesticated.no": "Nie",
    "component.is-identifiable-as-domesticated.yes": "Tak",
    "component.observation-position-form.view-position": "Skocz do",
    "component.observations-list.hide": "Ukryj",
    "component.observations-list.observations": "Lista obserwacji",
    "component.observations-list.quantity": "Liczba",
    "component.observations-list.sex": "Płeć",
    "component.observations-list.show": "Pokaż",
    "component.observations-list.species": "Gatunek",
    "component.observations-list.uncertain": "Niepewne / Prawdopodobne",
    "component.observations-table.observation-type": "Rodzaj obserwacji",
    "component.observations-table.observator": "Obserwator",
    "component.overview-marker-popup.annotate": "Dodaj adnotację",
    "component.overview-marker-popup.browse": "Przeglądaj",
    "component.overview-marker-popup.collected-person": "Użytkownik, który zebrał nagrania",
    "component.overview-marker-popup.date-of-import": "Data importu",
    "component.overview-marker-popup.end": "Koniec",
    "component.overview-marker-popup.set-up-person": "Użytkownik zarządajacy ustawieniami",
    "component.overview-marker-popup.start": "Początek",
    "component.overview-marker-popup.statistics": "{sequencesAnnotatedCount}  {sequencesCount} sekwencji z adnotacją",
    "component.overview-marker-popup.uploaded-person": "Użytkownik, który przesłał nagrania",
    "component.page-footer.all-rights-reserved": "CameraTrapLab i Uniwersytet w Wageningen",
    "component.page-footer.colophon": "O projekcie",
    "component.page-footer.power": "Obsługiwane przez Agouti dla",
    "component.positioning.angle": "Kąt",
    "component.positioning.calculate-speed": "Oblicz prędkość",
    "component.positioning.camera-and-deployment-calibration-missing": "Brak kalibracji kamery i wdrożenia",
    "component.positioning.camera-calibration-missing": "Brak kalibracji kamery",
    "component.positioning.camera-calibration-present": "Kalibracja kamery obecna",
    "component.positioning.camera-details": "Szczegóły kamery",
    "component.positioning.camera-model-missing-with-support": "Brak kalibracji kamery dla tego wdrożenia. Proszę skontaktować się z obsługą.",
    "component.positioning.deployment-calibration-missing": "Brak kalibracji wdrożenia",
    "component.positioning.dimensions": "Wymiary",
    "component.positioning.distance": "Odległość",
    "component.positioning.manufacturer": "Producent",
    "component.positioning.model": "Model",
    "component.positioning.preview-plots": "Wykresy podglądowe",
    "component.positioning.radius": "Promień",
    "component.positioning.speed": "Prędkość",
    "component.positioning.warning": "Ostrzeżenie",
    "component.remove-full-deployment.cancel": "Anuluj",
    "component.remove-full-deployment.confirm-removal": "Potwierdź usunięcie",
    "component.remove-full-deployment.delete": "Usuń import",
    "component.remove-full-deployment.modal.error": "Podczas usuwania wystąpił błąd. Błąd jest następujący:",
    "component.remove-full-deployment.modal.info": "<p>Zamierzasz usunąć import i wszystkie powiązane zasoby. Nie będzie można cofnąć tej akcji.</p><p>Wpisz 'usuń', aby potwierdzić usunięcie</p>",
    "component.remove-full-deployment.modal.title": "Ostrzeżenie: potwierdź usunięcie importu",
    "component.remove-full-deployment.validation-word": "usuń",
    "component.shortcuts-list.animal": "Zwierzę",
    "component.shortcuts-list.blank": "Pusty",
    "component.shortcuts-list.hide-all": "Ukryj wszystko",
    "component.shortcuts-list.merge-with-previous": "Połącz z poprzednim",
    "component.shortcuts-list.merge-with-previous-time": "{amountOfMinutes} minuty minęły od poprzedniej sekwencji",
    "component.shortcuts-list.merge-with-previous-time-seconds": "{amountOfSeconds} sekundy minęły od poprzedniej sekwencji",
    "component.shortcuts-list.merge-with-previous.modal.body": "Czy na pewno chcesz połączyć tę sekwencję z poprzednią?",
    "component.shortcuts-list.merge-with-previous.modal.confirm": "Połącz",
    "component.shortcuts-list.merge-with-previous.modal.title": "Połącz z poprzednim",
    "component.shortcuts-list.setup-pickup": "Rozpocznij / wybierz sekwencję",
    "component.shortcuts-list.shortcuts": "Skróty",
    "component.shortcuts-list.show-all": "Pokaż wszystko",
    "component.shortcuts-list.unknown": "Nieznany",
    "component.shortcuts-list.vehicle": "Pojazd",
    "component.utc-offset-selector.example": "Sygnatura czasowa obrazu <code>{originalExampleTime}</code> zmienia się na <code>{convertedExampleTime}</code> UTC.",
    "component.utc-offset-selector.help-text": "Przesunięcie czasu UTC zależy od strefy czasowej, w której skonfigurowano kamerę. Użyj narzędzia takiego jak <a href=\"https://www.timeanddate.com/worldclock/converter.html\" target=\"_blank\">timeanddate.com</a>, aby obliczyć przesunięcie.",
    "component.utc-offset-selector.placeholder": "Wybierz przesunięcie",
    "copied.id": "Id Copied to Clipboard",
    "deployment-row.camera-deployment-id": "Numer fotopułapki",
    "deployment-row.card-id": "Numer karty",
    "deployment-row.information": "Informacja",
    "deployment-row.location": "Lokalizacja",
    "deployment-row.observations": "Obserwacje",
    "deployment-row.tags": "Tagi",
    "deployment-row.usable": "Użytkowana",
    "deployment.id": "Deployment ID",
    "index.partners.1": "Współpracownicy i fundusze",
    "index.partners.2": "Agouti wspiera i współpracuje z wieloma inicjatywami i projektami i jest uprzejmie wspierany przez fundusze z kilku organizacji. Wśród nich są:",
    "index.partners.3": "Aby utworzyć projekty <a href=\"https://mammalnet.com/\" target=\"_blank\">MammalNet</a>, skontaktuj się z nami za pośrednictwem informacji podanych poniżej.",
    "index.partners.4": "Ze środków z <a href=\"https://www.efsa.europa.eu/\" target=\"_blank\">European Food and Safety Authority</a>",
    "index.partners.5": "Wspierany przez <a href=\"https://www.lifewatch.be/\" target=\"_blank\">LifeWatch</a>",
    "info.annotate": "Po zaimportowaniu nagrania sekwencje muszą zostać przetworzone. Tutaj pokazane są wszystkie nagrania z sekwencjami wymagającymi adnotacji. Wybierz <i>Dodaj adnotację</i> aby rozpocząć pracę nad tym nagraniem. Możesz przerwać przetwarzanie w dowolnym momencie i kontynuować później od miejsca, w którym je opuściłeś.",
    "info.annotate.title": "Nagranie przetwarzane",
    "info.annotationsettings": "Te pola kontrolują wyświetlanie nazw gatunków na stronie opisu obserwacji i odstęp czasowy zastosowany do budowania sekwencji zdjęć.",
    "info.annotationsettings.title": "Ustawienia adnotacji",
    "info.behaviours": "Dodaj opis zachowania do obserwacji, dodając opcje do tej listy.",
    "info.behaviours.title": "Rodzaj zachowania",
    "info.browse": "Po dodaniu adnotacji do sekwencji można wyświetlić obserwacje dodane na tej stronie. Click <i>Pokaż</i> obok nagrania, aby natychmiast wyświetlić tę sekwencję i w razie potrzeby poprawić obserwacje.",
    "info.browse.title": "Przeglądanie obserwacji",
    "info.cameras": "Fotopułapki są oznaczone numerem seryjnym i znajdują się na tej centralnej liście. Oznacza to, że pojawiają się tu również fotopułapki z innych projektów. Przed dodaniem nowych wpisów sprawdź, , czy nie ma tam już twoich fotopułapek. Numer seryjny fotopułapki najczęściej znajduje się w środku w dolnej części fotopułapki, tuż pod kodem kreskowym.",
    "info.cameras.title": "Fotopułapki",
    "info.downloaddata": "Pobierz wyeksportowane pakiety danych tutaj. Wszystkie pakiety są archiwizowane, więc możliwe jest pobranie lub odniesienie do konkretnej wersji.",
    "info.downloaddata.title": "Pobieranie danych",
    "info.export": "Podczas eksportowania danych wszystkie obserwacje są zapisywane w formacie CSV, który może być odczytany przez prawie wszystkie oprogramowania do dalszego przetwarzania. Po utworzeniu nowego pliku danych pobierz go z poniższej tabeli.",
    "info.export.title": "Eksportowanie danych",
    "info.generalsettings": "Ta część zawiera ogólne informacje o twoim projekcie. Proszę wypełnić przynajmniej <i>Imię, Opis</i> i <i>Właściciel projektu</i>",
    "info.generalsettings.title": "Informacje o projekcie",
    "info.import.upload.title": "Prześlij pliki",
    "info.import.uploadfiles": "Wybierz <i>Prześlij pliki</i> aby przesyłać zdjęcia lub filmy wideo. Przejdź do pliku zawierającego wszystkie zdjęcia lub filmy wideo z nagrania. Zaleca się przesyłanie materiałów bezpośrednio z karty pamięci, aby zapobiec uszkodzeniu metadanych przez oprogramowanie.",
    "info.import.uploadfiles.title": "Przesyłanie zdjęć lub filmów wideo",
    "info.individuals": "Śledź obserwacje poszczególnych zwierząt, dodając je do tej listy. Możesz opcjonalnie dodać je podczas adnotacji.",
    "info.individuals.title": "Poszczególne zwierzęta",
    "info.pendingusers": "Gdy zarejestrowany użytkownik poprosi o dostęp do twojego projektu, musi najpierw uzyskać zgodę koordynatora. Oczekujące prośby pojawiają się na tej liście. Po zaakceptowaniu użytkownika zmień rolę, jeśli to konieczne.",
    "info.pendingusers.title": "Użytkownicy oczekujący na dostęp",
    "info.registeredusers": "W tym miejscu pokazany jest każdy, kto ma dostęp do twojego projektu. Możesz tu zmienić rolę użytkownika lub całkowicie odwołać dostęp. Pamiętaj, że cofnięcie odwołanego dostępu wymaga nowej prośby o dostęp!",
    "info.registeredusers.title": "Zarejestrowani użytkownicy",
    "info.sampling.": "Miejsca obserwacji prób to lokalizacja oznaczona przez użytkownika, do której można przypisać nagranie. W razie potrzeby do miejsca obserwacji można przypisać wiele nagrań.",
    "info.sampling.title": "Miejsca obserwacji",
    "info.samplingdesign": "Poniższe informacje mówią o twoim protokole projektu, tj. danych zebranych przy użyciu fotopułapek. Dotyczą całego projektu, a nie poszczególnych nagrań. Na przykład: możesz przetestować kilka rodzajów zapachowych przynęt lub umieścić fotopułapki przy wodopoju. Podaj opis, aby dodać więcej szczegółów.",
    "info.samplingdesign.title": "Protokół projektu",
    "info.specieslist": "Agouti stosuje znormalizowaną listę gatunków <a href=\"http://www.catalogueoflife.org/\" target=\"_blank\">Catalogue of Life</a>. Zbuduj podzbiór tej listy do użycia w tym projekcie.",
    "info.specieslist.title": "Lista gatunków",
    "info.tags": "Tagi mogą być przypisane do nagrań w celu uporządkowania projektu. Są opcjonalne, można je dowolnie oznaczać, a do jednego nagrania można przypisać wiele tagów. Na przykład: używaj ich do śledzenia używanej przynęty, typu siedliska lub innej zdefiniowanej przez ciebie klasyfikacji.",
    "info.tags.title": "Tagi",
    "info.unfinishedupload": "Jeśli pliki zostały przesłane, ale <i>Import nagrania</i> formularz nie został wypełniony, pojawi się na tej liście. Kliknij <i>Dokończ</i> aby wypełnić formularz i zakończyć import. Jeśli przesyłanie zostało przerwane, zamiast tego usuń nagranie.",
    "info.unfinishedupload.title": "Niepełne nagrania",
    "info.user.settings": "Tutaj możesz zaktualizować swoje dane osobowe i zmienić język interfejsu Agouti. Pamiętaj, że zmiana języka interfejsu nie zmienia niektórych elementów określonych przez użytkownika, takich jak opisy projektów lub etykiety.",
    "info.user.settings.title": "Ustawienia osobiste",
    "invalid": "Invalid",
    "language.locale.de": "German",
    "language.locale.en": "English",
    "language.locale.es": "Spanish",
    "language.locale.fr": "French",
    "language.locale.hr": "Croatian",
    "language.locale.nl": "Dutch",
    "language.locale.pl": "Polish",
    "leaflet-map-cartodb": "Domyślna",
    "leaflet-map-here-hybrid.day": "Hybrydowy",
    "leaflet-map-here-normal.day": "Normal",
    "leaflet-map-here-terrain.day": "Teren",
    "link": "Link",
    "login.desc": "Aby poprosić o dostęp do tego projektu, zaloguj się przy użyciu swojego konta Agouti.",
    "login.email-address": "E-mail",
    "login.password": "Hasło",
    "login.remember-me": "Zapamiętaj mnie",
    "login.sign-in": "Zaloguj się",
    "login.sign-in-email": "Nieznany adres e-mail.",
    "login.sign-in-error": "Niepoprawne dane logowania",
    "login.sign-in-password": "Podano nieprawidłowe hasło.",
    "login.title": "Zaloguj się",
    "menu-item.annotate": "Dodaj adnotację",
    "menu-item.browse": "Przeglądaj",
    "menu-item.cameras": "Kamery",
    "menu-item.deployments": "Nagrania",
    "menu-item.export-data": "Eksportuj dane",
    "menu-item.import": "Importuj",
    "menu-item.locations": "Lokalizacje",
    "menu-item.observations": "Observations",
    "menu-item.overview": "Przeglądaj",
    "menu-item.project-settings": "Ustawienia projektu",
    "menu-item.sampling-management": "Zarządzaj spobem zbierania danych",
    "menu-item.settings": "Ustawienia",
    "menu-item.settings.behaviour": "Zachowanie",
    "menu-item.settings.deployment-groups": "Grupy wdrożeń",
    "menu-item.settings.deployment-tags": "Tagi wdrożenia",
    "menu-item.settings.general": "Ogólne",
    "menu-item.settings.individuals": "Osoby fizyczne",
    "menu-item.species": "Gatunek",
    "menu-item.user-management": "Zarządzaj użytkownikami",
    "menu-item.users": "Użytkownicy",
    "menu-item.validate": "Potwierdź",
    "observation.id": "Observation ID",
    "portal.statistics.deployments": "Nagrania",
    "portal.statistics.images": "Zdjęcia",
    "portal.statistics.observations": "Obserwacji",
    "portal.statistics.projects": "Projekty",
    "project-overview.show-less": "Pokaż mniej",
    "project-overview.show-more": "Pokaż więcej",
    "project-thumbnail.members": "{count, plural, other {# members} =1 {# member}}",
    "project-thumbnail.you-are": "Jesteś {projectrole}",
    "project.annotate.annotate": "Dodaj adnotację",
    "project.annotate.annotate-ai": "Adnotacja przez AI",
    "project.annotate.annotate-view": "View annotations",
    "project.annotate.end": "Koniec",
    "project.annotate.inspected-sequences": "Zakończone sekwencje",
    "project.annotate.is-blank": "Puste sekwencje",
    "project.annotate.is-setup-pickup": "Rozpocznij / wybierz sekwencje",
    "project.annotate.location": "Miejsce obserwacji",
    "project.annotate.no-unannotated-deployments": "Brak nagrań bez adnotacji",
    "project.annotate.progress": "Postęp",
    "project.annotate.sequence.annotate-overview": "Dodaj adnotację",
    "project.annotate.sequence.back": "Poprzedni",
    "project.annotate.sequence.back-to-overview": "Back to overview",
    "project.annotate.sequence.dashboard": "Pulpit nawigacyjny",
    "project.annotate.sequence.exposure": "Ekspozycja",
    "project.annotate.sequence.finish-annotating": "Zakończ adnotację",
    "project.annotate.sequence.identify": "Identifikuj",
    "project.annotate.sequence.navigate-by-observation": "Lista obserwacji",
    "project.annotate.sequence.navigate-by-sequences": "Lista sekwencji",
    "project.annotate.sequence.next": "Następny",
    "project.annotate.sequence.next-unannotated": "Następny bez adnotacji",
    "project.annotate.sequence.observations.age": "Wiek",
    "project.annotate.sequence.observations.back": "Powrót do listy",
    "project.annotate.sequence.observations.cancel": "Anuluj",
    "project.annotate.sequence.observations.quantity": "Liczba",
    "project.annotate.sequence.observations.sequence": "Sekwencja",
    "project.annotate.sequence.observations.sex": "Płeć",
    "project.annotate.sequence.observations.species": "Gatunek",
    "project.annotate.sequence.observations.suggested": "Sugerowane",
    "project.annotate.sequence.observations.title": "Obserwacja {name}",
    "project.annotate.sequence.order": "Zamów",
    "project.annotate.sequence.photo-of": "Zdjęcie {x}  {y}",
    "project.annotate.sequence.sequence-of": "Sekwencja",
    "project.annotate.sequence.sequence-of2": " {y}",
    "project.annotate.sequence.validation-status": "Status potwierdzenia",
    "project.annotate.sequence.warning.unsaved-work.body": "Edytowałeś obserwację, pamiętaj o zapisaniu swojej pracy przed kontynuowaniem",
    "project.annotate.sequence.warning.unsaved-work.close-modal": "Ok",
    "project.annotate.sequence.warning.unsaved-work.header": "Uwaga: niezapisana obserwacja",
    "project.annotate.sequences": "Sekwencje",
    "project.annotate.start": "Początek",
    "project.annotate.title": "Dodaj adnotację",
    "project.annotate.unannotated-deployments": "Nagranie bez adnotacji",
    "project.annotate.uninspected-sequences": "Oczekujące sekwencje",
    "project.browse.assign-to": "Przypisz do",
    "project.browse.assigned-to": "Przypisano do",
    "project.browse.deployments": "Nagrania",
    "project.browse.desc": "Na tej stronie możesz przeglądać wszystkie nagrania w tym projekcie. <br/> Pokaż szczegóły, aby wyświetlić tagi, uwagi, specyfikacje i możliwość edycji tych specyfikacji.",
    "project.browse.edit-deployment.title": "Edytuj nagranie",
    "project.browse.end": "Koniec",
    "project.browse.groups": "Grupy",
    "project.browse.location": "Miejsce obserwacji",
    "project.browse.modal.close": "Zamknij",
    "project.browse.no-deployments": "Brak nagrań.",
    "project.browse.sequences": "Liczba sekwencji",
    "project.browse.start": "Początek",
    "project.browse.tags": "Tagi",
    "project.browse.title": "Przeglądaj",
    "project.browse.unannotated": "Bez adnotacji",
    "project.browse.view-observations.delete": "Czy na pewno chcesz usunąć tę obserwację?",
    "project.browse.view-observations.filter": "Pokaż tylko obserwacje z",
    "project.browse.view-observations.split": "Are you sure you want to split this observation?",
    "project.deployment.aimodel.analyzing": "AI jest zajęta",
    "project.deployment.aimodel.failed": "AI zawiodło",
    "project.deployment.aimodel.scheduled": "AI jest zaplanowane ..",
    "project.deployment.aimodel.success": "AI jest gotowa ..",
    "project.deployment.complete.annotate": "Dodaj adnotację do nagrania",
    "project.deployment.complete.deployment-successfully-imported": "Nagranie pomyślnie zaimportowane",
    "project.deployment.complete.desc": "Proszę podać następujące dane, aby zakończyć import tego nagrania",
    "project.deployment.complete.import-new": "Importuj następne nagranie",
    "project.deployment.complete.success-message": "<b>Import zakończony.</b>",
    "project.deployment.complete.success-message.close": "Dodaj adnotację do nagrania.",
    "project.deployment.complete.title": "Nowe nagranie",
    "project.deployment.set-timestamp.filter": "Filter",
    "project.deployment.set-timestamp.save": "Kontynuuj",
    "project.deployment.set-timestamps.desc": "Agouti nie mógł znaleźć znacznika czasu dla tych zasobów.<br>Proszę wstawić znacznik czasu ręcznie, aby Agouti mógł poprawnie przetwarzać sekwencje.",
    "project.deployment.set-timestamps.file": "Nazwa pliku",
    "project.deployment.set-timestamps.timestamp": "Znacznik czasu (notacja 24-godzinna)",
    "project.deployment.set-timestamps.title": "Dodaj znacznik czasu",
    "project.export.create-export": "Utwórz eksport danych",
    "project.export.datasetId": "Numer identyfikacyjny zbioru danych",
    "project.export.delete": "Usuń",
    "project.export.download": "Prześlij",
    "project.export.filename": "Nazwa pliku",
    "project.export.no-exports-created": "Nie utworzono eksportu danych.",
    "project.export.previous-exports": "Poprzedni eksport danych",
    "project.export.size": "Rozmiar",
    "project.export.status": "Status",
    "project.export.time-of-export": "Czas eksportu danych",
    "project.export.title": "Eksportuj dane",
    "project.export.title.sub": "Eksportuj obserwacje",
    "project.import.assets": "Zasoby",
    "project.import.complete": "Zakończony",
    "project.import.create": "Nowe nagranie",
    "project.import.create-sequence-confirmation": "Czy na pewno chcesz utworzyć sekwencje dla tego importu?",
    "project.import.create-sequences": "Stwórz sekwencje",
    "project.import.delete": "Usuń",
    "project.import.delete-deployment-confirmation": "Czy na pewno chcesz usunąć ten import?",
    "project.import.dst": "Czas letni",
    "project.import.end": "Koniec",
    "project.import.files": "wybrane pliki",
    "project.import.folder": "Folder",
    "project.import.guidance": "Wybierz folder zawierający zdjęcia lub wideo dla tego nagrania.",
    "project.import.images": "Zdjęcia",
    "project.import.new-deployment.new-deployment": "Importuj nagranie",
    "project.import.new-deployment.no-unfinished-imports": "Brak oczekujących nagrań.",
    "project.import.new-deployment.title": "Importuj",
    "project.import.new-deployment.unfinished-imports": "Niedokończony import",
    "project.import.process": "Proces",
    "project.import.sequences": "Sekwencje",
    "project.import.start": "Początek",
    "project.import.status": "Status",
    "project.import.timestamp": "Znacznik czasu",
    "project.import.type": "Typ",
    "project.import.upload": "Prześlij",
    "project.import.upload-modal.cancel": "Zamknij",
    "project.import.upload-modal.desc1": "Wybierz i prześlij swoje pliki. Obecnie, obsługiwane są pliki JPG i AVI.",
    "project.import.upload-modal.desc2": "Lub przeciągnij i upuść zdjęcia w tym miejscu.",
    "project.import.upload-modal.select": "Wybierz pliki",
    "project.import.upload-modal.title": "Prześlij pliki",
    "project.import.upload-modal.uploading": "Uploading {queueFilesLength} files. Succesfully uploaded {uploaded} files, {duplicates} duplicates and {failed} files failed.",
    "project.import.uploaded": "Przesłane",
    "project.import.viabrowser": "Przez przeglądarkę",
    "project.import.viaftp": "Przez FTP",
    "project.overview.achievements": "Osiągnięcia",
    "project.overview.browse": "Przeglądaj",
    "project.overview.deployment": "Nagranie",
    "project.overview.deployment-for-location": "Nagranie dla lokalizacji",
    "project.overview.deployments": "Nagrania",
    "project.overview.desc": "Opis projektu",
    "project.overview.favourite-assets": "Favourites",
    "project.overview.favourite-sequences": "Ulubione sekwencje",
    "project.overview.import-deployment": "Importuj nagranie",
    "project.overview.imported-deployments": "Importowane nagrania",
    "project.overview.most.observed.species": "Najczęściej obserwowane gatunki",
    "project.overview.no-deployments": "Brak dostępnych nagrań.",
    "project.overview.observations": "Obserwacje",
    "project.overview.read.hide": "Ukryj",
    "project.overview.read.show": "Dowiedz się więcej",
    "project.overview.records": "Obserwacje",
    "project.overview.records-content": "Zaobserwowałem najwięcej gatunków w tym projekcie",
    "project.overview.sequences": "Sekwencje",
    "project.overview.stats": "Statystyka",
    "project.overview.team.members": "Członkowie zespołu",
    "project.overview.unfinished-deployments": "Niezakończone nagrania",
    "project.request-access.description": "Kliknij 'Poproś' aby potwierdzić prośbę o dostęp do tego projektu.",
    "project.request-access.request": "Poproś o dostęp",
    "project.request-access.title": "Poproś o dostęp",
    "project.sampling-management.add-group": "Dodaj nową grupę",
    "project.sampling-management.add-group.title": "Dodaj nową grupę",
    "project.sampling-management.add-sampling-point": "Dodaj nowe miejsce obserwacji",
    "project.sampling-management.add-sampling-point.title": "Dodaj nowe miejsce obserwacji",
    "project.sampling-management.add-samplingpoint.cancel": "Anuluj",
    "project.sampling-management.add-samplingpoint.missing-coordinates": "Podaj nazwę i współrzędne.",
    "project.sampling-management.add-samplingpoint.save": "Zapisz",
    "project.sampling-management.add-tag": "Dodaj nowy tag",
    "project.sampling-management.add-tag.cancel": "Usuń",
    "project.sampling-management.add-tag.name": "Nazwa",
    "project.sampling-management.add-tag.name-placeholder": "Nazwa",
    "project.sampling-management.add-tag.save": "Zapisz",
    "project.sampling-management.add-tag.title": "Dodaj tag",
    "project.sampling-management.delete-tag.confirmation": "Czy na pewno chcesz usunąć to oznaczenie?",
    "project.sampling-management.edit-group.title": "Edytuj grupę",
    "project.sampling-management.edit-sampling-point.cancel": "Anuluj",
    "project.sampling-management.edit-sampling-point.save": "Zapisz",
    "project.sampling-management.edit-sampling-point.title": "Edytuj miejsce obserwacji",
    "project.sampling-management.edit-tag.cancel": "Anuluj",
    "project.sampling-management.edit-tag.name": "Nazwa",
    "project.sampling-management.edit-tag.name-placeholder": "Nazwa",
    "project.sampling-management.edit-tag.save": "Zapisz",
    "project.sampling-management.edit-tag.title": "Edytuj tag",
    "project.sampling-management.sampling-points": "Miejsca obserwacji",
    "project.sampling-management.sampling-points.name": "Nazwa",
    "project.sampling-management.samplingpoints.delete": "Usuń",
    "project.sampling-management.samplingpoints.edit": "Edytuj",
    "project.sampling-management.tags": "Tagi nagrania",
    "project.sampling-management.tags.delete": "Usuń",
    "project.sampling-management.tags.edit": "Edytuj",
    "project.sampling-management.tags.name": "Nazwa",
    "project.sampling-management.title": "Zarządzanie spobem zbierania danych",
    "project.sampling-management.upload-csv": "Prześlij CSV",
    "project.sequence.import.failed": "Sequence import failed.",
    "project.sequence.import.importing": "Importing sequences",
    "project.sequence.import.importing.note": "Note: depending on the size of the upload, how busy the server is, this can take a while.<br>You don't need to wait for the creation to complete.",
    "project.sequence.import.importing.success": "Sequences ready!",
    "project.sequence.import.status": "Status of sequence import",
    "project.settings.add-behaviour": "Dodaj nowy opis zachowania",
    "project.settings.add-behaviour.cancel": "Anuluj",
    "project.settings.add-behaviour.description": "Opis",
    "project.settings.add-behaviour.description-placeholder": "Opis",
    "project.settings.add-behaviour.name": "Nazwa",
    "project.settings.add-behaviour.name-placeholder": "Nazwa",
    "project.settings.add-behaviour.save": "Zapisz",
    "project.settings.add-behaviour.title": "Dodaj opis zachowania",
    "project.settings.add-camera": "Dodaj nową fotopułapke",
    "project.settings.add-camera.cancel": "Anuluj",
    "project.settings.add-camera.label": "Etykieta",
    "project.settings.add-camera.label-placeholder": "Etykieta",
    "project.settings.add-camera.make": "Marka",
    "project.settings.add-camera.make-placeholder": "Marka",
    "project.settings.add-camera.model": "Model",
    "project.settings.add-camera.model-placeholder": "Model",
    "project.settings.add-camera.save": "Zapisz",
    "project.settings.add-camera.serial-number": "Numer seryjny",
    "project.settings.add-camera.serial-number-placeholder": "Numer seryjny",
    "project.settings.add-camera.title": "Dodaj fotopułapke",
    "project.settings.add-camera.year-of-purchase": "Rok produkcji",
    "project.settings.add-deployment": "Dodaj wdrożenie",
    "project.settings.add-individual": "Dodaj nowego osobnika",
    "project.settings.add-individual.age": "Wiek",
    "project.settings.add-individual.cancel": "Usuń",
    "project.settings.add-individual.name": "Nazwa",
    "project.settings.add-individual.name-placeholder": "Notatki",
    "project.settings.add-individual.notes": "Notatki",
    "project.settings.add-individual.notes-placeholder": "Notatki",
    "project.settings.add-individual.save": "Zapisz",
    "project.settings.add-individual.sex": "Płeć",
    "project.settings.add-individual.species": "Gatunek",
    "project.settings.add-individual.title": "Dodaj osobnika",
    "project.settings.add-location": "Dodaj lokalizacje",
    "project.settings.add-species": "Dodaj gatunek",
    "project.settings.add-species-list": "Dodaj listę gatunków",
    "project.settings.add-species-to-add": "Gatunki do dodania",
    "project.settings.add-species.cancel": "Anuluj",
    "project.settings.add-species.save": "Zapisz",
    "project.settings.add-species.species": "Gatunek",
    "project.settings.add-species.species-placeholder": "Gatunek",
    "project.settings.add-species.title": "Dodaj gatunek",
    "project.settings.annotate-calibration-and-tracking": "Kalibracja i śledzenie",
    "project.settings.annotate-timelapses": "Opis nagrania",
    "project.settings.annotate-timelapses-disabled": "Timelapses can not be annotated when sequences have been added.",
    "project.settings.annotation": "Opis",
    "project.settings.automatic-annotation": "Automatyczne wykrywanie",
    "project.settings.automatic-annotation.placeholder": "Wybierz model",
    "project.settings.behaviour": "Opis zachowania",
    "project.settings.behaviour.delete": "Usuń",
    "project.settings.behaviour.description": "Opis",
    "project.settings.behaviour.edit": "Edytuj",
    "project.settings.behaviour.name": "Nazwa",
    "project.settings.camera.delete": "Usuń",
    "project.settings.camera.edit": "Edytuj",
    "project.settings.camera.label": "Etykieta",
    "project.settings.camera.make": "Marka",
    "project.settings.camera.model": "Model",
    "project.settings.camera.serial-number": "Numer seryjny",
    "project.settings.camera.year-of-purchase": "Rok produkcji",
    "project.settings.cameras": "Fotopułapki",
    "project.settings.delete-behaviour-confirmation": "Czy na pewno chcesz usunąć ten opis zachowania?",
    "project.settings.delete-camera-confirmation": "Czy na pewno chcesz usunąć tę fotopułapkę?",
    "project.settings.delete-individual-confirmation": "Czy na pewno chcesz usunąć tę osobę?",
    "project.settings.delete-sampling-point-confirmation": "Czy na pewno chcesz usunąć miejsce obserwacji?",
    "project.settings.delete-species-confirmation": "Czy na pewno chcesz usunąć ten gatunek?",
    "project.settings.deployments-are-assigned": "Przypisano lokalizacje",
    "project.settings.description": "Opis",
    "project.settings.description-placeholder": "Opis",
    "project.settings.edit-behaviour.cancel": "Anuluj",
    "project.settings.edit-behaviour.description": "Opis",
    "project.settings.edit-behaviour.description-placeholder": "Opis",
    "project.settings.edit-behaviour.name": "Nazwa",
    "project.settings.edit-behaviour.name-placeholder": "Nazwa",
    "project.settings.edit-behaviour.save": "Zapisz",
    "project.settings.edit-behaviour.title": "Edytuj opis zachowania",
    "project.settings.edit-camera": "Edytuj fotopułapke",
    "project.settings.edit-camera.cancel": "Anuluj",
    "project.settings.edit-camera.label": "Etykieta",
    "project.settings.edit-camera.label-placeholder": "Etykieta",
    "project.settings.edit-camera.make": "Marka",
    "project.settings.edit-camera.make-placeholder": "Marka",
    "project.settings.edit-camera.model": "Model",
    "project.settings.edit-camera.model-placeholder": "Model",
    "project.settings.edit-camera.save": "Zapisz",
    "project.settings.edit-camera.serial-number": "Numer seryjny",
    "project.settings.edit-camera.serial-number-placeholder": "Numer seryjny",
    "project.settings.edit-camera.title": "Edytuj fotopułapke",
    "project.settings.edit-camera.year-of-purchase": "Rok produkcji",
    "project.settings.edit-individual.age": "Wiek",
    "project.settings.edit-individual.cancel": "Anuluj",
    "project.settings.edit-individual.name": "Nazwa",
    "project.settings.edit-individual.name-placeholder": "Nazwa",
    "project.settings.edit-individual.notes": "Notatki",
    "project.settings.edit-individual.notes-placeholder": "Notatki",
    "project.settings.edit-individual.save": "Zapisz",
    "project.settings.edit-individual.sex": "Płeć",
    "project.settings.edit-individual.species": "Gatunek",
    "project.settings.edit-individual.title": "Edytuj osobnika",
    "project.settings.edit-location": "Edytuj lokalizacje",
    "project.settings.edit-location.images": "Zdjęcia",
    "project.settings.edit-species.cancel": "Anuluj",
    "project.settings.edit-species.common-names": "Edytuj nazwy zwyczajowe",
    "project.settings.edit-species.is-identifiable-as-domesticated": "Hodowlany",
    "project.settings.edit-species.name": "Gatunek",
    "project.settings.edit-species.save": "Zapisz",
    "project.settings.edit-species.title": "Edytuj gatunek",
    "project.settings.feature": "Opis nagrania",
    "project.settings.feature-methodology": "Metoda nagrania",
    "project.settings.feature-methodology-placeholder": "Metoda nagrania",
    "project.settings.feature.select-feature": "Select feature",
    "project.settings.general": "Ogólne",
    "project.settings.hide-deployment-locations": "Ukryj lokalizacje",
    "project.settings.image": "Zdjęcie",
    "project.settings.individual.add-individual": "Dodaj osobnika",
    "project.settings.individual.delete": "Usuń",
    "project.settings.individual.edit": "Edytuj",
    "project.settings.individual.name": "Nazwa",
    "project.settings.individual.notes": "Notatki",
    "project.settings.individual.species": "Gatunek",
    "project.settings.individual.zooniverse": "Zooniverse",
    "project.settings.individuals": "Osobniki",
    "project.settings.location": "Lokalizacja",
    "project.settings.min-image-resolution": "Minimum {min_width} x {min_height} pikseli",
    "project.settings.name": "Nazwa",
    "project.settings.name-placeholder": "Nazwa",
    "project.settings.open-for-subscriptions": "Otwarty dostęp przez zarejestrowanie się",
    "project.settings.organisation": "Organizacja",
    "project.settings.organisation-help-block": "Organisations are considered rights holders of the data.",
    "project.settings.organisation-placeholder": "Organizacja",
    "project.settings.project-investigator": "Badacz projektu",
    "project.settings.project-investigator-placeholder": "Badacz projektu",
    "project.settings.project-owner": "Contact",
    "project.settings.project-owner-placeholder": "Project Contact",
    "project.settings.quiet-period": "Fotopułapka nieaktywna",
    "project.settings.restricted-access": "Ograniczony dostęp",
    "project.settings.restricted-images": "Obrazy objęte ograniczeniami",
    "project.settings.restricted-images.note": "Po zaznaczeniu adresy URL obrazów w eksportowanych/opublikowanych danych nie będą dostępne dla użytkowników zewnętrznych.",
    "project.settings.restricted-locations": "Lokalizacje z ograniczeniami",
    "project.settings.restricted-locations.note": "Po zaznaczeniu osoby zajmujące się przetwarzaniem zdjęć i wolontariusze zobaczą tylko lokalizacje dla swoich własnych wdrożeń.",
    "project.settings.restricted-project": "Projekt ograniczony",
    "project.settings.restricted-project.note": "Po zaznaczeniu Twój projekt zostanie ukryty na publicznej stronie głównej i portalu agouti.eu.",
    "project.settings.sampling-design": "Sposób zbierania danych",
    "project.settings.sampling-design.clustered-random": "Klastrowa losowe (tablice losowe)",
    "project.settings.sampling-design.experimental": "Eksperymentalna",
    "project.settings.sampling-design.label": "Strategie pobierania próbek",
    "project.settings.sampling-design.opportunistic": "Oportunistyczna",
    "project.settings.sampling-design.placeholder": "Wybierz strategie pobierania próbek",
    "project.settings.sampling-design.simple-random": "Losowa prosta",
    "project.settings.sampling-design.systematic-random": "Losowa systematyczna",
    "project.settings.sampling-design.targeted": "Losowa celowa",
    "project.settings.seconds": "sekundy",
    "project.settings.sensormethod": "Sposób działania czujnika",
    "project.settings.sensormethod.motion-detection": "Czujnik ruchu",
    "project.settings.sensormethod.select-sensormethod": "Wybierz sposób działania czujnika",
    "project.settings.sensormethod.timelapse": "Zdjęcia poklatkowe",
    "project.settings.sequence-cut-off": "Wycinek sekwencji",
    "project.settings.sequence-cutoff-disabled": "Wycinek sekwencji nie może być zmieniony po dodaniu sekwencji.",
    "project.settings.species": "Gatunek",
    "project.settings.species.added": "Dodany do projektu",
    "project.settings.species.available": "Dostępne gatunki",
    "project.settings.species.common-name": "Nazwa zwyczajowa",
    "project.settings.species.countDeployments": "Deployments",
    "project.settings.species.countObservations": "Observations",
    "project.settings.species.countValObservations": "Validated",
    "project.settings.species.edit": "Edytuj",
    "project.settings.species.family": "Rodzina",
    "project.settings.species.genus": "Rodzaj",
    "project.settings.species.isIdentifiableAsDomesticated": "Hodowlany",
    "project.settings.species.order": "Rząd",
    "project.settings.species.remove": "Usuń",
    "project.settings.species.species": "Gatunek",
    "project.settings.success-message": "Ustawienia projektu zostały zapisane.",
    "project.settings.title": "Ustawienia projektu",
    "project.settings.update-location.cancel": "Anuluj",
    "project.settings.update-location.save": "Zapisz",
    "project.settings.update-location.title": "Edytuj lokalizacje",
    "project.settings.utc-offset": "Domyślne UTC przesunięcie",
    "project.user-management.accept-pending-request-confirmation": "Czy na pewno chcesz zaakceptować tę prośbę?",
    "project.user-management.awaiting-access": "Oczekujący na dostęp",
    "project.user-management.copy": "Skopiuj link z zaproszeniem",
    "project.user-management.copy.confirmation": "Link skopiowany",
    "project.user-management.delete-user-confirmation": "Czy na pewno chcesz usunąć dostęp tego użytkownika do tego projektu?",
    "project.user-management.edit-user.cancel": "Anuluj",
    "project.user-management.edit-user.email": "Adres e-mail",
    "project.user-management.edit-user.email-placeholder": "Adres e-mail",
    "project.user-management.edit-user.first-name": "Imię",
    "project.user-management.edit-user.first-name-placeholder": "Imię",
    "project.user-management.edit-user.last-name": "Nazwisko",
    "project.user-management.edit-user.last-name-placeholder": "Nazwisko",
    "project.user-management.edit-user.member-since": "Członek od",
    "project.user-management.edit-user.revoke-access": "Odwołaj dostęp",
    "project.user-management.edit-user.role": "Rola",
    "project.user-management.edit-user.save": "Zapisz",
    "project.user-management.edit-user.title": "Edytuj użytkownika",
    "project.user-management.email-address": "Adres e-mail",
    "project.user-management.first-name": "Imię",
    "project.user-management.last-name": "Nazwisko",
    "project.user-management.link-description": "Użyj tego linku, aby zezwolić użytkownikom na dostęp do twojego projektu.",
    "project.user-management.pending-requests": "Oczekujący na dostęp",
    "project.user-management.pending-requests.accept": "Zaakceptowana",
    "project.user-management.pending-requests.decline": "Odrzucona",
    "project.user-management.pending-requests.empty": "Nie znaleziono oczekujących na dostęp",
    "project.user-management.principal-investigators": "Główni badacze",
    "project.user-management.project-access": "Dostęp do projektu",
    "project.user-management.registered-users": "Użytkownicy",
    "project.user-management.registered-users.delete": "Usuń",
    "project.user-management.registered-users.edit": "Edytuj",
    "project.user-management.role": "Rola",
    "project.user-management.title": "Zarządzaj użytkownikami",
    "project.user-management.userbase": "Użytkownicy",
    "project.user-management.userbase.title": "Baza użytkowników",
    "project.user-management.volunteers": "Wolontariusze",
    "project.validate.accept-sequence": "Accept sequence observations",
    "project.validate.accepted-by-at": "Validated by {validator} on {date}",
    "project.validate.add-new-query": "Add new query",
    "project.validate.all-are-true": "All of the following predicates are true",
    "project.validate.edit.title": "Edit validation query",
    "project.validate.filter.created-from": "Created from",
    "project.validate.filter.created-to": "Created to",
    "project.validate.filter.is-equal-to": "Is equal to",
    "project.validate.filter.is-unknown": "Is unknown",
    "project.validate.filter.sampling-point": "Sampling point",
    "project.validate.filter.select-filter-type": "Select filter type",
    "project.validate.filter.select-role": "Select project role",
    "project.validate.filter.select-sampling-point": "Select sampling point",
    "project.validate.filter.select-species": "Select species",
    "project.validate.filter.select-user": "Select user",
    "project.validate.filter.species": "Species",
    "project.validate.filter.user": "User",
    "project.validate.filter.user-project-role": "Project role",
    "project.validate.new.title": "New validation query",
    "project.validate.no-rules-defined-yet": "No predicates defined yet...",
    "project.validate.no-validation-queries": "There are no validation queries defined for this project yet",
    "project.validate.none-are-true": "None of the following predicates are true",
    "project.validate.predicates": "Predicates",
    "project.validate.query-is-failed": "Something went wrong during the execution of the query...",
    "project.validate.query-is-running": "Query is running...",
    "project.validate.query-is-scheduled": "Query is scheduled...",
    "project.validate.results.assest-count": "Asset count",
    "project.validate.results.no-results": "No results found...",
    "project.validate.results.show-done": "Show validated sequences",
    "project.validate.results.total-results": "Total results",
    "project.validate.results.total-results-of": "Showing {count} of {total} results (max {limit})",
    "project.validate.rule-presets": "Rule presets",
    "project.validate.scheduling-explanation": "Queries are run every hour. Execution takes a few minutes, up to half an hour. Please refresh the page to update the status of the validation queries.",
    "project.validate.sequences": "Sequences",
    "project.validate.table.header.candidates": "Matches",
    "project.validate.table.header.name": "Name",
    "project.validate.title": "Validate",
    "project.validate.unaccept-sequence": "Remove validation",
    "project.validate.validate": "validate",
    "project.validate.validation-query-results": "Validation query results",
    "project.zooniverse.password": "Zooniverse hasło",
    "project.zooniverse.password-placeholder": "Zooniverse hasło",
    "project.zooniverse.projectid": "Zooniverse numer identyfikacyjny projektu",
    "project.zooniverse.projectid-placeholder": "Zooniverse numer identyfikacyjny projektu",
    "project.zooniverse.username": "Zooniverse nazwa użytkownika",
    "project.zooniverse.username-placeholder": "Zooniverse nazwa użytkownika",
    "projectrole.ADMIN": "Administrator",
    "projectrole.AWAITING_ACCESS": "Oczekujący na dostęp",
    "projectrole.DUMMY": "Początkujący",
    "projectrole.PHOTO_PROCESSOR": "Obrabiający zdjęcia",
    "projectrole.PRINCIPAL_INVESTIGATOR": "Główny badacz",
    "projectrole.PROJECT_COORDINATOR": "Koordynator projektu",
    "projectrole.TAXONOMIC_EXPERT": "Ekspert od taksonomii",
    "projectrole.VIEW_ONLY": "View Only",
    "projectrole.VOLUNTEER": "Wolontariusz",
    "projects.add": "Dodaj nowy projekt",
    "projects.independent-projects": "Niezależne projekty",
    "projects.no-projects-available": "Nie ma jeszcze projektów. Aby uzyskać instrukcje, skontaktuj się z koordynatorem projektu.",
    "projects.search-for": "Szukaj...",
    "projects.search-for.filter": "Filtruj projekty",
    "projects.search-for.filterOrg": "Filter on organisation",
    "projects.title": "Projekty",
    "projects.title.user": "Twoje projekty",
    "projects.your-role": "Twoja rola",
    "publichome.block1.title": "Co to jest Agouti?",
    "publichome.block1.txt": "Fotopułapki zapewniają łatwy i nieinwazyjny sposób badania przyrody. Jednak przetwarzanie i przechowywanie tysięcy zdjęć,które w badaniach z użyciem fotopułapek generują się bardzo szybko, staje się coraz trudniejszym zadaniem. Równie trudne może być również zarządzanie projektami, zwłaszcza jeśli używa się wielu fotopułapek i angażuje duży zespół osób.",
    "publichome.block1.txt2": "Agouti pomaga użytkownikom rozwiązać te problemy. Jest to kompletne rozwiązanie dla organizacji i osób, które używają fotopułapek do badania dzikich zwierząt. Pozwala traperom - użytkownikom fotopułapek organizować wspólne badania, szybko i łatwo przetwarzać nagrania, uzyskiwać znormalizowane podsumowania wyników, bezpiecznie archiwizować zdjęcia i dane, a w końcowym etapie podsumowywać i udostępniać dane. Agouti przestrzega standardów metadanych, co ułatwia porównywanie projektów.",
    "publichome.block2.title": "Opis pracy",
    "publichome.block2.txt": "Agouti jest zbiorem projektów zainicjowanych przez organizacje lub osoby prywatne. Uczestnicy projektu przesyłają całą zawartość kart pamięci z fotopułapek i wprowadzają lokalizację oraz inne metadane. Agouti importuje te nagrania, pobiera daty, godziny i inne dane z samych nagrań oraz grupuje zdjęcia w sekwencje reprezentujące to samo zdarzenie. Użytkownicy analizujący zdjęcia sprawdzają każdą sekwencję i opisują ją za pomocą jednej lub więcej obserwacji, korzystając z łatwego interfejsu.",
    "publichome.block2.txt2": "Możesz wyeksportować swoje dane jako pakiet danych Camera Trap (<a target=\"_blank\" href=\"https://tdwg.github.io/camtrap-dp/\">Camtrap DP</a>) do analizy w oprogramowaniu takim jak jako R. Na początek zalecamy użycie pakietu R <a target=\"_blank\" href=\"https://inbo.github.io/camtraptor/\">camtraptor</a>.",
    "publichome.block3.title": "Importuj",
    "publichome.block3.txt": "Prześlij zdjęcia i filmy wideo używając tylko swojej przeglądarki",
    "publichome.block3.txt2": "Opisz",
    "publichome.block3.txt3": "Opisuj zdjęcia za pomocą wygodnych narzędzi",
    "publichome.block3.txt4": "Analizuj",
    "publichome.block3.txt5": "Przeglądaj dane za pomocą wbudowanych narzędzi i prezentacji",
    "publichome.block3.txt6": "Eksportuj",
    "publichome.block3.txt7": "Generuj znormalizowane pliki danych do analizy lub dalszego udostępniania",
    "publichome.block4.title": "Intuicyjny interfejs",
    "publichome.block4.txt": "Interfejs Agouti jest prosty w użyciu, nowoczesny i nastawiony na produktywność. Każdy może rozpocząć pracę po krótkim przeszkoleniu. Poniższe filmy ilustrują kilka aspektów systemu.",
    "publichome.block4.txt1": "Pierwsze kroki",
    "publichome.block4.txt2": "Importuj nagrania",
    "publichome.block4.txt3": "Dodaj adnotacje do sekwencji",
    "publichome.block4.vid1": "/assets/videos/GettingStarted-PL.mp4",
    "publichome.block4.vid2": "/assets/videos/ImportingDeployments-PL.mp4",
    "publichome.block4.vid3": "/assets/videos/AnnotatingSequences-PL.mp4",
    "publichome.block5.title": "Wyróżnione projekty",
    "publichome.block5.txt": "Agouti wspiera wiele projektów. Zapoznaj się z niektórymi z tych publicznych projektów, aby odkryć możliwości Agouti.",
    "publichome.block6.f1": "Zarządzaj",
    "publichome.block6.f2": "Sposób zbierania danych",
    "publichome.block6.f3": "Miejsca obserwacji",
    "publichome.block6.f4": "Śledź fotopułapki",
    "publichome.block6.f5": "Taguj nagrania",
    "publichome.block6.f6": "Kontrola jakości",
    "publichome.block6.g1": "Proces",
    "publichome.block6.g2": "Dodaj obserwacje",
    "publichome.block6.g3": "Znormalizowana lista gatunków",
    "publichome.block6.g4": "Eksportuj plik CSV",
    "publichome.block6.g5": "Śledz poszczególne osobniki",
    "publichome.block6.g6": "Dodaj adnotację do zachowania",
    "publichome.block6.h1": "Współpracuj",
    "publichome.block6.h2": "Zaproś użytkowników",
    "publichome.block6.h3": "Przydziel role",
    "publichome.block6.h4": "Przydziel zadania",
    "publichome.block6.h5": "Monitoruj postęp",
    "publichome.block6.h6": "Organizuj dane",
    "publichome.block6.title": "Właściwości",
    "publichome.block6.txt": "Dzięki Agouti masz do dyspozycji szeroki zestaw narzędzi.",
    "publichome.block7.title": "Prywatność i bezpieczeństwo",
    "publichome.block7.txt": "Prawo własności danych powinno być respektowane. Agouti nie rości sobie prawa własności danych i nie rozpowszechnia danych na rzecz osób trzecich. Ty decydujesz, kto ma dostęp do danych, więc możesz współpracować na własnych warunkach.",
    "publichome.block7.txt2": "Agouti zapewnia bezpieczne miejsce do przechowywania danych. Eliminuje ryzyko utraty danych przez awarie dysków twardych, błędy użytkowników lub awarie komputerów. Codzienne kopie zapasowe poza witryną oraz w pełni redundantna infrastruktura zapewniają optymalny dostęp do danych.",
    "publichome.block8.f1": "Naukowy",
    "publichome.block8.f2": "Bespłatny",
    "publichome.block8.f3": "Nieograniczone miejsce na dane",
    "publichome.block8.f4": "Nieograniczone projekty i użytkownicy",
    "publichome.block8.f5": "Best Effort Support",
    "publichome.block8.f6": "Zgłoś się",
    "publichome.block8.g1": "Non-profit",
    "publichome.block8.g2": "Bespłatny",
    "publichome.block8.g3": "Nieograniczone miejsce na dane",
    "publichome.block8.g4": "Nieograniczone projekty i użytkownicy",
    "publichome.block8.g5": "Best Effort Support",
    "publichome.block8.g6": "Zgłoś się",
    "publichome.block8.h1": "Komercyjny",
    "publichome.block8.h2": "Bespłatny",
    "publichome.block8.h3": "Nieograniczone miejsce na dane",
    "publichome.block8.h4": "Nieograniczone projekty i użytkownicy",
    "publichome.block8.h5": "Best Effort Support",
    "publichome.block8.h6": "Zgłoś się",
    "publichome.block8.title": "Zacznij używać Agouti",
    "publichome.block8.txt": "Koszty hostingu i rozwoju Agouti ponoszą użytkownicy. Studenci mogą korzystać z Agouti za darmo, podczas gdy inne strony wnoszą wkład finansowy. Oczekuje się, że podmioty komercyjne będą wnosić większy wkład finansowy, ponieważ dzięki Agouti oszczędzają czas, a tym samym pieniądze. W tym modelu współpracy koszty są rozłożone, aby każdy miał dostęp do platformy.",
    "publichome.explore-projects": "Przeglądaj projekty",
    "publichome.footer1": "Agouti",
    "publichome.footer10": "6708 PB Wageningen",
    "publichome.footer11": "Holandia",
    "publichome.footer12": "Instytut Badawczy Przyrody i Lasu(INBO)",
    "publichome.footer13": "Zarządzanie dziką przyrodą oraz gatunkami obcymi i otwarte laboratorium naukowe ds. różnorodności biologicznej",
    "publichome.footer14": "Havenlaan 88 bus 73",
    "publichome.footer15": "1000 Bruksela",
    "publichome.footer16": "Belgia",
    "publichome.footer2": "Lista mailingowa użytkowników",
    "publichome.footer3": "Polityka prywatności",
    "publichome.footer5": "Organizacja",
    "publichome.footer6": "Agouti to wspólna inicjatywa Uniwersytetu w Wageningen i Instytutu Badawczego Przyrody i Lasu (INBO)",
    "publichome.footer7": "Uniwersytetu w Wageningen",
    "publichome.footer8": "Wydział Nauk o Środowisku",
    "publichome.footer9": "Wildlife Ecology and Conservation Group",
    "publichome.slogan": "Platforma do zarządzania projektami nagrań dzikich zwierząt wykonanych przez fotopułapki",
    "publichome.stats1": "Projekty",
    "publichome.stats2": "Nagrania",
    "publichome.stats3": "Obserwacje",
    "publichome.stats4": "Zdjęcia",
    "publichome.title": "Agouti",
    "sequence.id": "Sequence ID",
    "sex.": "N.A.",
    "sex.female": "Samica",
    "sex.male": "Samiec",
    "sex.unknown": "Nieznana",
    "taxonRank": "Taxon Rank",
    "toggle-processed": "Hide 100%",
    "user.register.desc": "Zarejestruj konto Agouti",
    "user.register.email-address": "E-mail",
    "user.register.email-address-duplicate": "Adres e-mailowy jest już w użyciu.",
    "user.register.email-address-error": "Proszę podać swój e-mail.",
    "user.register.first-name": "Imię",
    "user.register.first-name-error": "Proszę podać swoje imię.",
    "user.register.gdpr-error": "Potwierdź zgodę na przetwarzanie danych",
    "user.register.last-name": "Nazwisko",
    "user.register.last-name-error": "Proszę podać swoje nazwisko.",
    "user.register.password": "Hasło",
    "user.register.password-error": "Proszę podać swoje hasło.",
    "user.register.title": "Zarejestruj się",
    "user.request-reset-password.desc": "Request a password reset link",
    "user.request-reset-password.title": "Reset password",
    "user.reset-password.desc": "Choose a new Agouti password",
    "user.reset-password.email-address": "E-mail",
    "user.reset-password.email-address-duplicate": "Email address already in use.",
    "user.reset-password.email-address-error": "Please fill in your email.",
    "user.reset-password.password": "Password",
    "user.reset-password.password-are-different-error": "Please enter the same password twice.",
    "user.reset-password.password-confirmation": "Password confirmation",
    "user.reset-password.password-confirmation-error": "Please fill in a password confirmation.",
    "user.reset-password.password-error": "Please fill in a password.",
    "user.reset-password.title": "Reset password",
    "user.settings.account": "Konto",
    "user.settings.adaptive-image-size": "Adaptacyjny rozmiar obrazu",
    "user.settings.annotate-shortcuts-label": "Opisz skróty",
    "user.settings.anonymous-in-export": "Anonimizowane w eksporcie",
    "user.settings.anonymous-in-export-explanation": "Po zaznaczeniu Twoje dane osobowe zostaną zanonimizowane w eksporcie. Twoje imię i nazwisko oraz adres e-mail nie zostaną uwzględnione w eksporcie danych i nie będą podawane  jako dane współtwórcy, osoby konfigurującej i/lub klasyfikatora.",
    "user.settings.autoscroll-thumbnails-annotating": "Automatyczne przewijanie miniatur podczas opisywania",
    "user.settings.common": "Zwyczajowa",
    "user.settings.email-address": "Adres e-mail",
    "user.settings.email-address-label": "E-mail",
    "user.settings.email-in-use": "Adres e-mailowy jest już w użyciu.",
    "user.settings.first-name": "Imię",
    "user.settings.first-name-note": "To będzie widoczne dla innych użytkowników.",
    "user.settings.gravatar": "Lub włącz <a href=\"http://gravatar.com\" title=\"http://gravatar.com\">Gravatar</a>",
    "user.settings.gravatar.disable": "Wyłącz",
    "user.settings.gravatar.enable": "Włącz",
    "user.settings.language": "Język",
    "user.settings.last-name": "Nazwisko",
    "user.settings.organisation": "Organizacja",
    "user.settings.organisation-placeholder": "Organizacja",
    "user.settings.password": "Hasło",
    "user.settings.picture": "Awatar publiczny",
    "user.settings.picture.browse": "Przeglądaj...",
    "user.settings.picture.file": "Nie wybrano żadnego pliku",
    "user.settings.picture.remove": "Usuń",
    "user.settings.picture.upload": "Załaduj nowy awatar",
    "user.settings.save": "Zapisz zmiany",
    "user.settings.save.update": "Zaktualizuj ustawienia",
    "user.settings.saved": "Ustawienia zostały zapisane",
    "user.settings.scientific": "Naukowa",
    "user.settings.species-names": "Nazwa gatunku",
    "user.settings.title": "Ustawienia użytkownika",
    "user.settings.url.label": "Twój profil ORCID",
    "user.settings.url.placeholder": ""
  }]];
});
define("ember-contextual-table/templates/data-paginator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "EFd7kbiF",
    "block": "[[[18,1,[[28,[37,1],null,[[\"data\",\"currentPage\",\"next\",\"previous\",\"first\",\"last\",\"pagesTotal\",\"hasNext\",\"hasPrevious\"],[[33,2],[33,3],[28,[37,4],[[30,0],\"next\"],null],[28,[37,4],[[30,0],\"previous\"],null],[28,[37,4],[[30,0],\"first\"],null],[28,[37,4],[[30,0],\"last\"],null],[33,5],[33,6],[33,7]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"paginatedData\",\"currentPage\",\"action\",\"pagesTotal\",\"hasNext\",\"hasPrevious\"]]",
    "moduleName": "ember-contextual-table/templates/data-paginator.hbs",
    "isStrictMode": false
  });
});
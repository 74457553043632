define("ember-data-relationship-tracker/index", ["exports", "@ember/object/computed", "@ember/object", "@ember/object/mixin", "lodash-es/isEqual"], function (_exports, _computed, _object, _mixin, _isEqual) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _mixin.default.create({
    init() {
      this._super();
      this._relationshipTracker = Object.create(null);
    },
    async save() {
      await this._super(...arguments);
      this.notifyPropertyChange('relationshipTrackerVersion');
    },
    watchRelationship(field, fn) {
      let entry = this._relationshipTracker[field];
      if (!entry) {
        entry = this._relationshipTracker[field] = Object.create(null);
      }
      let version = versionKey(this);
      if (!(version in entry)) {
        entry[version] = currentState(this, field);
      }
      fn();
      this.notifyPropertyChange('dirtyRelationships');
    },
    hasDirtyRelationships: (0, _computed.gt)('dirtyRelationships.length', 0),
    hasDirtyFields: (0, _computed.or)('hasDirtyAttributes', 'hasDirtyRelationships'),
    dirtyRelationships: (0, _object.computed)('relationshipTrackerVersion', function () {
      let version = versionKey(this);
      let dirty = [];
      this._forEachRelationship(field => {
        let entry = this._relationshipTracker[field];
        let relationshipChanged = version in entry && !(0, _isEqual.default)(entry[version], currentState(this, field));
        if (relationshipChanged) {
          dirty.push(field);
        }
      });
      return dirty;
    }),
    rollbackRelationships() {
      let version = versionKey(this);
      let tracker = this._relationshipTracker;
      this._forEachRelationship(field => {
        if (!tracker[field] || !(version in tracker[field])) {
          return;
        }
        this.set(field, tracker[field][version]);
      });
      this.notifyPropertyChange('dirtyRelationships');
    },
    _forEachRelationship(fn) {
      let tracker = this._relationshipTracker;
      Object.keys(tracker).forEach(field => {
        fn(field);
      });
    }
  });
  function currentState(model, field) {
    let config = (0, _object.get)(model.constructor, 'relationshipsByName').get(field);
    if (config.kind === 'hasMany') {
      let reference = model.hasMany(field);
      let refValue = reference.value();
      return refValue ? refValue.toArray() : [];
    } else {
      let reference = model.belongsTo(field);
      return reference.value();
    }
  }
  function versionKey(model) {
    let version = model.get('relationshipTrackerVersion');
    if (!version) {
      return -1;
    }
    if (typeof version.getTime === 'function') {
      return version.getTime();
    }
    return version;
  }
});
define("ember-contextual-table/mixins/filter-and-sortable-column", ["exports", "ember-contextual-table/mixins/filterable-column", "ember-contextual-table/mixins/sortable-column", "ember-contextual-table/templates/dt-filter-and-sortable-column"], function (_exports, _filterableColumn, _sortableColumn, _dtFilterAndSortableColumn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create(_filterableColumn.default, _sortableColumn.default, {
    layout: _dtFilterAndSortableColumn.default
  });
});
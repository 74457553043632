define("ember-contextual-table/components/dt-filter-and-sortable-column-header", ["exports", "ember-contextual-table/mixins/filter-and-sortable-column"], function (_exports, _filterAndSortableColumn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_filterAndSortableColumn.default, {
    tagName: 'th',
    header: true,
    classNames: ['contextual-filter-and-sortable-header-cell']
  });
});
define("ember-contextual-table/templates/dt-column-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "I10OOR67",
    "block": "[[[41,[52,[48,[30,1]],[52,[33,2],false,true],false],[[[1,\"  \"],[18,1,[[28,[37,4],null,[[\"header\"],[true]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[34,5]],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"defaultHeader\",\"yield\",\"hash\",\"name\"]]",
    "moduleName": "ember-contextual-table/templates/dt-column-header.hbs",
    "isStrictMode": false
  });
});